export const projectCardVersions = {
  v1: [
    { card: 'StatusSummary' },
    { card: 'Parts' },
    { card: 'Reports' },
    { card: 'Documents' },
    { card: 'UnbilledHours' },
    { card: 'Invoiced' },
    { card: 'WorkInstructions' },
    { card: 'Directory' }
  ],
  v2: [
    { card: 'StatusSummary' },
    { card: 'Parts' },
    { card: 'Reports' },
    { card: 'Documents' },
    { card: 'UnbilledHours' },
    { card: 'Invoiced' },
    { card: 'WorkInstructions' },
    { card: 'Authorization' },
    { card: 'Directory' }
  ],
  v3: [
    { card: 'StatusSummary' },
    { card: 'Parts' },
    { card: 'Reports' },
    { card: 'Documents' },
    { card: 'UnbilledHours' },
    { card: 'Invoiced' },
    { card: 'WorkInstructions' },
    { card: 'PIFRevisions' },
    { card: 'Authorization' },
    { card: 'Directory' }
  ],
  v4: [
    { card: 'StatusSummary' },
    { card: 'Parts' },
    { card: 'Reports' },
    { card: 'Documents' },
    { card: 'UnbilledHours' },
    { card: 'Invoiced' },
    { card: 'WorkInstructions' },
    { card: 'PIFRevisions' },
    { card: 'Authorization' },
    { card: 'Audits' },
    { card: 'Directory' }
  ]
};
