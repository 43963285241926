import React, { useRef } from 'react';
import { navigate } from '@reach/router';
import { RLeanState, useSave } from '@rlean/core';
import { getValue, hasValue, uuid } from '@rlean/utils';
import { Card, Table, Typography, Dropdown, Menu, Skeleton } from 'antd';
import MaterialIcon from '@material/react-material-icon';
import { EntityKey } from 'lib/enums/EntityKey';
import { downloadFileType, downloadFile, formatDate } from 'lib/helpers';
import { reportNames, formats } from 'components/shared/SQReport';

const { Text } = Typography;

export const ProjectSummaryCard = props => {
  const [project, projectSummary, businessContact] = RLeanState().select(({ state }) => [
    state.project,
    state.projectSummary,
    state.businessContact
  ]);
  const save = useSave();
  const projectSummaries = getValue(projectSummary, 'data', null);
  const projects = getValue(project, 'data', null);
  const onLastPage = useRef(false);
  const { currentProject } = props;

  if (!currentProject) {
    return <></>;
  }

  if (projectSummaries && projects) {
    const projectStatus = letter => {
      let status;

      switch (letter) {
        case 'A':
          status = 'Active Project';
          break;
        case 'C':
          status = 'Closed Project';
          break;
        default:
          status = '--';
          break;
      }

      return status;
    };

    const title = `Project #${currentProject.projectId} - Reference Number: ${
      currentProject.customerReference
    } - Status: ${projectStatus(currentProject.projectStatus)}`;
    const data = projectSummaries.filter(item => item.projectId === currentProject.projectId);

    const columns = [
      {
        title: 'Part',
        dataIndex: 'partNumber',
        sorter: (a, b) => {
          if (a.partNumber) {
            return b.partNumber ? a.partNumber.localeCompare(b.partNumber) : -1;
          }
          if (b.partNumber) {
            return a.partNumber ? b.partNumber.localeCompare(a.partNumber) : 1;
          }
          return 0 - 0;
        }
      },
      {
        title: 'Good',
        dataIndex: 'goodTotal',
        sorter: (a, b) => a.goodTotal - b.goodTotal
      },
      {
        title: 'Reworked',
        dataIndex: 'reworkTotal',
        sorter: (a, b) => a.reworkTotal - b.reworkTotal
      },
      {
        title: 'Non-Conforming',
        dataIndex: 'nonconformTotal',
        sorter: (a, b) => a.nonconformTotal - b.nonconformTotal
      },
      {
        title: 'Observations',
        dataIndex: 'observationTotal',
        sorter: (a, b) => a.observationTotal - b.observationTotal
      },
      {
        title: 'Total Inspected',
        dataIndex: 'inspectedTotal',
        sorter: (a, b) => a.inspectedTotal - b.inspectedTotal
      },
      {
        title: 'Fallout %',
        dataIndex: 'fallout',
        sorter: (a, b) => a.fallout - b.fallout,
        render: fallout => <>{(fallout * 100).toFixed(2)}</>
      },
      {
        title: 'PPM',
        dataIndex: 'ppm',
        sorter: (a, b) => a.ppm - b.ppm,
        render: ppm => <>{Math.round((ppm + Number.EPSILON) * 100) / 100}</>
      }
    ];

    const paginationSize = 5;

    const setActiveProject = () => {
      save({ key: EntityKey.ActiveProject, value: { id: currentProject.projectId } });
      navigate('/project/details');
    };

    const exportProjectSummary = () => {
      const businessContactId = getValue(businessContact, 'data.id', 0);

      const params = {
        businessContactId,
        format: formats.EXCELOPENXML,
        reportName: reportNames.PROJECT_SUMMARIES,
        Projectid: currentProject.projectId
      };

      const options = {
        filename: `Project Summary - ${currentProject.projectId} - ${formatDate(new Date())}`,
        endpoint: '/SsrsReportFiles',
        fileType: downloadFileType.EXCELOPENXML
      };

      downloadFile(options, params);
    };

    const getLastPageStatus = (page, pageSize) => {
      const totalPages = Math.ceil(data.length / pageSize);
      onLastPage.current = totalPages === page;
    };

    const menu = (
      <Menu>
        <Menu.Item key={uuid()} onClick={() => setActiveProject()}>
          View Project
        </Menu.Item>
        <Menu.Item key={uuid()} onClick={() => exportProjectSummary()}>
          Export
        </Menu.Item>
      </Menu>
    );

    const extra = (
      <Dropdown className='export-to-csv' overlay={menu} trigger={['click']} placement='bottomRight'>
        <button className='link-button' type='button'>
          <MaterialIcon role='button' icon='more_vert' />
        </button>
      </Dropdown>
    );

    return (
      <Card title={title} style={{ width: '100%', marginBottom: 25 }} key={uuid()} extra={extra}>
        <p>
          <b>Customer:</b> {currentProject.customerSupplierName}
          <br />
          <b>Started:</b> {formatDate(currentProject.startDate)}
          <br />
          <b>Project Type:</b> {currentProject.serviceTypeName} <br />
          <b>Stratosphere Quality Office:</b> {currentProject.officeName}
          <br />
          <b>Project Location:</b> {currentProject.workSiteCompanyName} - {currentProject.workSiteCity}
          <br />
          <b>Estimated Project Hours to Date: </b>
          {Math.round(currentProject.estimatedTotalHours)}
        </p>
        <p style={{ fontWeight: 'bold' }}>Inspection Results</p>
        {!project || (hasValue(project, 'isLoading') && project.isLoading) ? (
          <Skeleton active />
        ) : (
          <Table
            className='project-table project-summary-totals'
            scroll={{ x: true }}
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: paginationSize, onChange: getLastPageStatus }}
            summary={pageData => {
              let good = 0;
              let rework = 0;
              let nonconform = 0;
              let observation = 0;
              let inspected = 0;

              data.forEach(({ goodTotal, reworkTotal, nonconformTotal, observationTotal, inspectedTotal }) => {
                good += goodTotal;
                rework += reworkTotal;
                nonconform += nonconformTotal;
                observation += observationTotal;
                inspected += inspectedTotal;
              });

              const calculatePpm = () => {
                if (!rework && !nonconform && !observation && !inspected) {
                  return 0;
                }

                const ppm = (1000000 * (rework + nonconform + observation)) / inspected;

                return ppm.toFixed(2);
              };

              const selectedProject = projects.find(x => x.projectId === currentProject.projectId);
              const fallout = selectedProject ? (selectedProject.fallout * 100).toFixed(2) : 'N/A';
              const isPaginated = pageData.length < data.length;

              const TableSummary = () => (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell>Project Total</Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text>{good}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text>{rework}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text>{nonconform}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text>{observation}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text>{inspected}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text>{fallout}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text>{calculatePpm()}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );

              if (isPaginated) {
                if (!onLastPage.current) {
                  return <></>;
                }
              }

              if (data.length < 1) {
                return <></>;
              }

              return <TableSummary />;
            }}
          />
        )}
      </Card>
    );
  }

  return <></>;
};
