export const detectIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');
  let version;

  // Test values

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  if (msie > 0) {
    // IE 10 or older
    version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    return true;
  }

  if (trident > 0) {
    // IE 11
    const rv = ua.indexOf('rv:');
    version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    return true;
  }

  // other browser
  return false;
};
