import React from 'react';
import { hasValue } from '@rlean/utils';
import moment from 'moment';
import { CopyOutlined, FileImageOutlined } from '@ant-design/icons';
import MaterialIcon from '@material/react-material-icon';
import { echoDataType, echoElementType } from 'lib/types';
import { Tooltip, message } from 'antd';

const columnSorter = (id, typeId) => {
  switch (typeId) {
    case echoDataType.DATE:
    case echoDataType.DATETIME:
      return (a, b) => (a[id] ? new Date(a[id]) : new Date()) - (b[id] ? new Date(b[id]) : new Date());
    case echoDataType.INT:
      return (a, b) => (a[id] ?? 0) - (b[id] ?? 0);
    case echoDataType.STRING:
      return (a, b) => (a[id] ?? '').localeCompare(b[id] ?? '');
    case echoDataType.BOOLEAN:
      return (a, b) => (a[id] ?? true) - (b[id] ?? false);
    default:
      return false;
  }
};

const isTrue = value => {
  return value === true || value === 'true' || value === 'True' || value === 1;
};

const renderCell = (value, typeId, elementTypeId, handlePictureModal) => {
  const enabledButtonStyle = {
    fontSize: '20px',
    padding: '0'
  };
  const disabledButtonStyle = {
    fontSize: '20px',
    padding: '0',
    cursor: 'not-allowed',
    color: '#8c8c8c'
  };

  switch (typeId) {
    case echoDataType.DATE:
      return value ? moment(value).format('MM/DD/YYYY') : '--';
    case echoDataType.DATETIME:
      return value ? moment(value).format('MM/DD/YYYY HH:mm') : '--';
    case echoDataType.INT:
      return value ?? '0';
    case echoDataType.STRING:
      return value ?? '--';
    case echoDataType.BOOLEAN:
      return isTrue(value) ? 'Yes' : 'No';
    case echoDataType.URI:
      if (value && elementTypeId === echoElementType.CapturedAtMapUri) {
        return (
          <a href={value} target='_blank' className='table-uri-icon' rel='noreferrer'>
            <MaterialIcon className='action' role='button' icon='map' />
          </a>
        );
      } else {
        return value ? (
          <a href={value} target='_blank' rel='noreferrer'>
            {value}
          </a>
        ) : (
          '--'
        );
      }

    case echoDataType.PICTURE:
      return (
        <div style={{ textAlign: 'center' }}>
          <button
            className='link-button'
            style={!value ? disabledButtonStyle : enabledButtonStyle}
            type='button'
            onClick={() => handlePictureModal(value)}
            disabled={!value}
          >
            <FileImageOutlined title='See attached photos' />
          </button>
        </div>
      );
    default:
      return value ?? '--';
  }
};

export const buildTableColumns = (data, setImageModal) =>
  hasValue(data, 'fieldDefinitions')
    ? data.fieldDefinitions
        .filter(definition => definition.isVisible)
        .map(definition => {
          const {
            label,
            fieldId,
            dataTypeId,
            elementTypeId,
            isVisible,
            supportCalculation,
            supportAggregation
          } = definition;

          return {
            // Properties for antd
            title: label,
            dataIndex: fieldId,
            key: fieldId,
            ellipsis: {
              showTitle: false
            },
            render: value => {
              const displayValue = renderCell(value, dataTypeId, elementTypeId, formId =>
                setImageModal({ formId, visibility: true })
              );
              const TooltipComponent = () => {
                const handleCopy = () => {
                  message.info('Value copied to clipboard!');

                  navigator.clipboard.writeText(displayValue);
                };

                return (
                  <>
                    <span style={{ margin: '0px', marginRight: '12px' }}>{displayValue}</span>
                    <button
                      style={{
                        fontSize: '14px',
                        padding: '0',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer'
                      }}
                      type='button'
                      onClick={handleCopy}
                    >
                      <CopyOutlined title='Copy to Clipboard' />
                    </button>
                  </>
                );
              };

              return dataTypeId === echoDataType.PICTURE || dataTypeId === echoDataType.URI ? (
                <>{displayValue}</>
              ) : (
                <Tooltip placement='topLeft' title={TooltipComponent}>
                  {displayValue}
                </Tooltip>
              );
            },
            sorter: columnSorter(fieldId, dataTypeId),

            // Property for Chart creation
            supportAggregation,

            // Properties for Excel Download Endpoint
            dataTypeId,
            elementTypeId,

            // Properties for SQTable controls
            sqVisible: isVisible,
            sqToggle: isVisible,
            sqSearch: dataTypeId === echoDataType.PICTURE ? false : true,
            sqCalc: supportCalculation
          };
        })
    : null;
