import React, { memo } from 'react';
import { Row, Col, Menu, Dropdown } from 'antd';
import MaterialIcon from '@material/react-material-icon';
import { LogoutOutlined } from '@ant-design/icons';
import { navigate, Link } from '@reach/router';
import logo from 'assets/SQ-logo-white.png';
import { RLeanState } from '@rlean/core';
import { getValue, uuid } from '@rlean/utils';
import { Auth } from 'lib/utilities';
import { strings } from 'config';
import { SidebarMobile } from '../SidebarMobile';
import ImpersonateMenuItem from '../ImpersonateMenuItem';

export const AppBar = memo(() => {
  const [activePage, impersonation, userDescription] = RLeanState().select(({ state }) => {
    return [state.activePage, state.impersonation, state.userDescription];
  });
  const isMobileUser = getValue(userDescription, 'data.isMobileUser', true);
  const isImpersonating = getValue(impersonation, 'isImpersonating', false);
  const logoLink = isMobileUser && !isImpersonating ? '/' : '/dashboard';

  const signOut = async navigateTo => {
    await Auth.signOut(navigateTo);
  };

  const menu = (
    <Menu className='user-dropdown-menu'>
      <ImpersonateMenuItem />
      <Menu.Item key={uuid()}>
        <button className='link-button' type='button' onClick={() => signOut(navigate)}>
          <LogoutOutlined /> {strings.signOut}
        </button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Row className='main-app-bar' type='flex' justify='space-between'>
      <Col span={6} className='left'>
        <span className='desktop-only'>
          <strong>SOLAR engage</strong> | {activePage.title}
        </span>
        <SidebarMobile />
      </Col>
      <Col span={10} className='middle'>
        <Link to={logoLink}>
          <img src={logo} alt='Stratosphere Quality logo' />
        </Link>
      </Col>
      <Col span={6} className='right'>
        {/* <a href='#!' className='alert-action'>
          <MaterialIcon role='button' icon='notifications' />
        </a> */}
        <Dropdown overlay={menu} trigger={['click']} placement='bottomRight'>
          <MaterialIcon className='action' role='button' icon='person' />
        </Dropdown>
      </Col>
    </Row>
  );
});
