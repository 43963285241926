import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { FullWidthDrawer, SQTable } from 'components/shared';
import { Button, Modal, Select, Space, Row, Col, Divider, Drawer, Tag, Form, DatePicker } from 'antd';
import { chartColors } from 'config/chartColors';
import Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';
import HCexporting from 'highcharts/modules/exporting';
import OfflineExportingModule from 'highcharts/modules/offline-exporting';
import moment from 'moment';
import { FilterIndicators } from 'components/shared/FilterIndicators';
import { DrilldownContext } from './DrilldownContext';
import { echoElementType } from 'lib/types';

HCexporting(Highcharts);
OfflineExportingModule(Highcharts);

const CHART_LEGEND_WIDTH = 200;

const TOTALS_ELEMENT_TYPE_IDS = {
  // INSPECTED: 19,
  REJECTED: 20,
  REWORKED: 21,
  GOOD: 34
};

const COLORS = {
  [TOTALS_ELEMENT_TYPE_IDS.REJECTED]: chartColors.RED,
  [TOTALS_ELEMENT_TYPE_IDS.REWORKED]: chartColors.BLUE,
  [TOTALS_ELEMENT_TYPE_IDS.GOOD]: chartColors.GREEN,
  any: chartColors.BLUE
};

function DateFilterDrawer({ setValues, closeDrawer, filters, clearFilters, form }) {
  const handleFinish = formValues => {
    setValues(formValues);
    closeDrawer();
  };

  const handleClear = () => {
    clearFilters();
    closeDrawer();
  };

  return (
    <>
      <Form layout='vertical' onFinish={handleFinish} initialValues={filters} form={form}>
        <Form.Item label='Start Date' name='startDate'>
          <DatePicker format={'MM/DD/YYYY'} />
        </Form.Item>
        <Form.Item label='End Date' name='endDate'>
          <DatePicker format={'MM/DD/YYYY'} />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Apply Filter
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type='primary' onClick={handleClear}>
            Clear Filter
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

const filterProperties = ['extraStartDate', 'extraEndDate'];

function DrilldownChartModal({
  columns,
  dataSource,
  projectId,
  chartCreateModals,
  setChartCreateModal,
  filters,
  elementTypes = 'default',
  updateFilters: setExtraDateFilters,
  options: { title = '', reportName = '', filenamePrefix = '' } = {}
}) {
  const [seriesDataForTable, setSeriesDataForTable] = useState([]);
  const [seriesObj, setSeriesObj] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [dateFilterVisible, setDateFilterVisible] = useState(false);
  const [extraDateFilteredDataSource, setExtraDateFilteredDataSource] = useState([]);
  const chartRef = useRef(null);
  const chartComponentRef = useRef(null);
  const [extraDateForm] = Form.useForm();
  const ALL_CATEGORIES = `Every ${chartCreateModals.selectedTitle}`;
  const isExtraDateFilterOn =
    filters.startDate && filters.endDate
      ? !filters.startDate.isSame(filters.startDate) || !filters.endDate.isSame(filters.endDate)
      : false;

  const echoInspectionCtx = useContext(DrilldownContext) ?? null;

  useEffect(() => {
    if (dataSource && columns) {
      const sortDateColumn = columns.find(column => column.elementTypeId === 2);

      const filteredData = sortDateColumn
        ? dataSource.filter(
            row =>
              moment(row[sortDateColumn.dataIndex]).isSameOrAfter(filters.extraStartDate.startOf('day')) &&
              moment(row[sortDateColumn.dataIndex]).isSameOrBefore(filters.extraEndDate.endOf('day'))
          )
        : null;

      setExtraDateFilteredDataSource(filteredData ?? dataSource);
    }
  }, [filters, dataSource, columns, selectedCategory]);

  // set initial selected category
  useEffect(() => {
    if (!selectedCategory || !categories.some(category => category === selectedCategory)) {
      setSelectedCategory(ALL_CATEGORIES);
    }
  }, [chartCreateModals]);

  useEffect(() => {
    let filteredDataSource = extraDateFilteredDataSource;
    if (selectedCategory && selectedCategory !== ALL_CATEGORIES) {
      filteredDataSource = extraDateFilteredDataSource.filter(
        formItem => formItem[chartCreateModals.selectedFieldId] === selectedCategory
      );
    }

    setSeriesDataForTable(filteredDataSource);
  }, [selectedCategory, extraDateFilteredDataSource]);

  const handleCategorySelect = category => {
    setSelectedCategory(category);
  };

  const addLegendCallback = (mainCategories, mainSeriesObj) => chart => {
    const defaultTextSize = 11;
    const itemColumnWidth = 40;
    const baseMargin = 15;
    const baseLegendHeight = (defaultTextSize + 6) * mainCategories.length;

    const { chartHeight, chartWidth, plotTop } = chart;

    let posX = chartWidth - CHART_LEGEND_WIDTH - 15;
    let posY = plotTop;

    const mainGroup = chart.renderer
      .g('legend-group')
      .attr({
        zIndex: 99
      })
      .add();

    const legendContainer = chart.renderer
      .rect()
      .attr({
        class: 'legendBox',
        'stroke-width': 1,
        stroke: '#aaa',
        fill: 'white',
        zIndex: 6,
        x: posX,
        y: posY,
        width: CHART_LEGEND_WIDTH,
        height: baseLegendHeight + baseMargin * 3
      })
      .add(mainGroup);

    const { legendHeight } = chart.legend;

    let baseMarginBottom = baseLegendHeight + legendHeight + baseMargin * 6;
    let legendYPos = baseLegendHeight + baseMargin * 2 + legendHeight - 8;
    let lastSize = chartHeight;
    let isSmallWidth = false;

    // If the window pane is small, then update the chart and legend position
    if (chartWidth < 600) {
      posX = 15;
      posY = lastSize + baseMargin * 6;
      lastSize = lastSize + baseMarginBottom + baseMargin * 2;
      isSmallWidth = true;

      chart.setSize(chartWidth, lastSize);
      chart.update({
        chart: {
          marginBottom: baseMarginBottom
        },
        legend: {
          y: -legendYPos
        }
      });
      legendContainer.attr({ x: posX, y: posY });
    } else {
      chart.update({
        chart: {
          marginRight: CHART_LEGEND_WIDTH + 45
        }
      });
    }

    const posXText = posX + 6;
    posY += baseMargin;
    chart.renderer
      .text(`Legend`)
      .attr({
        zIndex: 7,
        x: posXText,
        y: posY
      })
      .css({ fontSize: '13px', backgroundColor: '#ef00aa' })
      .add(mainGroup);

    posY += baseMargin;
    chart.renderer
      .text('Item')
      .attr({
        zIndex: 7,
        x: posXText,
        y: posY
      })
      .css({ fontSize: `${defaultTextSize + 1}px` })
      .add(mainGroup);

    // Add the type of category
    chart.renderer
      .text(`${chartCreateModals.selectedTitle}`)
      .attr({
        zIndex: 7,
        x: posXText + itemColumnWidth,
        y: posY
      })
      .css({ fontSize: `${defaultTextSize + 1}px`, width: CHART_LEGEND_WIDTH - itemColumnWidth })
      .add(mainGroup);

    posY += baseMargin;
    mainCategories.forEach((category, index) => {
      // Add the category item number
      chart.renderer
        .text(`${index + 1}`)
        .attr({
          zIndex: 8,
          x: posXText,
          y: posY
        })
        .css({ fontSize: `${defaultTextSize}px` })
        .add(mainGroup);

      // Add the category value
      const text = chart.renderer
        .text(category)
        .on('click', () => handleCategorySelect(category, mainSeriesObj))
        .on('mouseover', () => {
          text.css({
            color: chartColors.RED
          });
        })
        .on('mouseout', () => {
          text.css({
            color: 'black'
          });
        })
        .attr({
          zIndex: 8,
          x: posXText + itemColumnWidth,
          y: posY
        })
        .css({ fontSize: `${defaultTextSize}px`, width: CHART_LEGEND_WIDTH - itemColumnWidth - 10, cursor: 'pointer' })
        .add(mainGroup);

      // Update legend container and chart if needed
      const textHeight = text.element.getBoundingClientRect().height;
      const numberOfLines = Math.floor(textHeight / defaultTextSize);

      if (numberOfLines >= 1) {
        // Getting the height of the whole text and extra lines
        const totalLineHeight = numberOfLines * defaultTextSize;
        const totalExtraLinesHeight = totalLineHeight - defaultTextSize;
        posY += totalExtraLinesHeight;

        legendContainer.attr({
          height: legendContainer.element.getBoundingClientRect().height + totalExtraLinesHeight
        });

        if (posY > chartHeight && !isSmallWidth) {
          lastSize += totalLineHeight + 15;
          chart.setSize(undefined, lastSize);
        }

        if (isSmallWidth) {
          lastSize += totalExtraLinesHeight;
          baseMarginBottom += totalExtraLinesHeight;
          legendYPos += totalExtraLinesHeight;
          chart.setSize(undefined, lastSize);
          chart.update({
            chart: {
              marginBottom: baseMarginBottom
            },
            legend: {
              y: -legendYPos
            }
          });
        }
      }

      posY += defaultTextSize + 7;
    });
  };

  const getExportedChartInPNG = () => {
    return new Promise((res, rej) => {
      const { chart } = chartRef.current;
      const svg = new XMLSerializer().serializeToString(chart.container.childNodes[0]);
      const canvas = document.getElementById('canvasId');
      const ctx = canvas.getContext('2d');
      ctx.canvas.height = chart.chartHeight;
      ctx.canvas.width = chart.chartWidth;
      const img = new Image();
      const svgBlob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
      const url = URL.createObjectURL(svgBlob);
      img.onload = function() {
        ctx.drawImage(img, 0, 0);
        const png = canvas.toDataURL('image/png').split(',')[1];
        if (echoInspectionCtx) {
          res(png);
        }
        URL.revokeObjectURL(png);
      };
      img.src = url;
    });
  };

  useEffect(() => {
    echoInspectionCtx.getExportedChartInPNG.current = getExportedChartInPNG;
  }, []);

  const buildChart = () => {
    if (chartCreateModals.selectedFieldId === null) {
      return null;
    }

    const sample = (columns ?? []).find(item => item.dataIndex === chartCreateModals.selectedFieldId);

    // Get elementTypesIds from the column data or use the default (GOOD/REWORKED/REJECTED)
    const elementTypesIds =
      elementTypes === 'default' ? TOTALS_ELEMENT_TYPE_IDS : { COUNT: sample ? sample.elementTypeId : null };

    const totalFields = Object.keys(elementTypesIds).map(key => {
      const findColumn = columns.find(column => column.elementTypeId === elementTypesIds[key]);
      return {
        index: findColumn.dataIndex,
        title: findColumn.title,
        elementId: elementTypesIds[key]
      };
    });

    const seriesDataForChart =
      isExtraDateFilterOn || !selectedCategory || selectedCategory === ALL_CATEGORIES
        ? extraDateFilteredDataSource
        : seriesDataForTable;

    const mainCategories = extraDateFilteredDataSource.reduce((pV, cV) => {
      const value = cV[chartCreateModals.selectedFieldId];
      if (pV.includes(value)) {
        return pV;
      }
      return [...pV, value];
    }, []);

    // For each value, get it's total data + data category
    const mainSeriesObj = seriesDataForChart.reduce((pV, cV) => {
      const totalData = totalFields.map(totalField => parseInt(cV[totalField.index], 10));
      const dataCategory = cV[chartCreateModals.selectedFieldId];

      if (dataCategory in pV) {
        const prevData = pV[dataCategory].sumData;
        const sumData = prevData.map((num, index) => num + totalData[index]);
        return { ...pV, [dataCategory]: { sumData } };
      }

      return { ...pV, [dataCategory]: { sumData: totalData } };
    }, {});

    const categories =
      selectedCategory && selectedCategory !== ALL_CATEGORIES
        ? mainCategories.filter(category => category === selectedCategory)
        : mainCategories;

    const seriesArr = totalFields.map((totalField, index) => ({
      name: totalField.title,
      data: categories.map(category => {
        const data = mainSeriesObj[category].sumData;
        return data[index];
      }),
      color: COLORS[totalField.elementId] ?? COLORS.any
    }));

    const options = {
      chart: {
        type: 'column'
        //  marginRight: CHART_LEGEND_WIDTH + 45
      },
      title: { text: `Project ${projectId} Sort Statistics by ${chartCreateModals.selectedTitle}` },
      subtitle: {
        text: isExtraDateFilterOn
          ? `${moment(filters.extraStartDate).format('MM/DD/YYYY')} - ${moment(filters.extraEndDate).format(
              'MM/DD/YYYY'
            )}`
          : `${moment(filters.startDate).format('MM/DD/YYYY')} - ${moment(filters.endDate).format('MM/DD/YYYY')}`,
        style: {
          fontWeight: isExtraDateFilterOn ? '700' : '300'
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      xAxis: {
        categories: Object.keys(mainCategories).map(key => parseInt(key, 10) + 1)
      },
      yAxis: {
        title: { text: 'Inspected Quantity' },
        min: 0
      },
      series: seriesArr,
      exporting: {
        fallbackToExportServer: false
      }
    };

    setCategories([ALL_CATEGORIES, ...mainCategories]);
    setSeriesObj(mainSeriesObj);

    return (
      <Chart
        ref={chartRef}
        options={options}
        highcharts={Highcharts}
        immutable
        callback={addLegendCallback(categories, mainSeriesObj)}
      />
    );
  };

  useEffect(() => {
    if (columns && columns.length > 0 && extraDateFilteredDataSource) {
      chartComponentRef.current = buildChart();
    }
  }, [columns, extraDateFilteredDataSource]);

  const showChart = () => {
    if (columns && columns.length > 0 && extraDateFilteredDataSource) {
      chartComponentRef.current = buildChart();
    }
    setChartCreateModal(previousState => ({ ...previousState, resultChartModal: true, selectColumnModal: false }));
  };

  const clearExtraDateFilter = () => {
    extraDateForm.resetFields();
    const { startDate, endDate } = filters;
    setExtraDateFilters({ startDate, endDate });
  };

  const closeChartModals = () => {
    setSelectedCategory(undefined);
    setSeriesDataForTable([]);
    clearExtraDateFilter();
    setChartCreateModal(previousState => ({
      selectedTitle: '',
      selectedFieldId: null,
      resultChartModal: false,
      selectColumnModal: false
    }));
  };

  const handleChartModalChange = value => {
    let columnTitle = '';
    if (columns && columns.length > 0) {
      columnTitle = columns.find(column => column.dataIndex === value).title;
    }
    setChartCreateModal(previousState => ({ ...previousState, selectedTitle: columnTitle, selectedFieldId: value }));
  };

  const handleDateFilterClose = () => {
    setDateFilterVisible(false);
  };

  const handleFilterClick = () => {
    setDateFilterVisible(true);
  };

  const titleBarData = {
    tableTitle: selectedCategory ? `Detail for ${chartCreateModals.selectedTitle}: ${selectedCategory}` : '',
    downloadBtn: true,
    settingsBtn: true,
    downloadInfo: {
      reportName,
      fileName: `${filenamePrefix}_${moment().format('YYYY-MM-DDTHH:MM:SSZ')}`
    }
  };

  const updateExtraDates = newValues => {
    // Keep startDate and endDate, and overwrite extra dates
    setExtraDateFilters(newValues, filterProperties);
  };

  return (
    <>
      <Modal
        title='Create a chart'
        visible={chartCreateModals.selectColumnModal}
        onCancel={closeChartModals}
        onOk={showChart}
        okButtonProps={{
          disabled: !chartCreateModals.selectedFieldId || !columns || (columns && columns.length === 0)
        }}
        okText='Select'
        key={Math.random()}
      >
        <Select
          placeholder='Select a column'
          value={chartCreateModals.selectedFieldId}
          onChange={handleChartModalChange}
          style={{ width: '100%' }}
          disabled={!columns || (columns && columns.length === 0)}
        >
          {columns
            ? columns.map(column =>
                column.supportAggregation ? (
                  <Select.Option value={column.dataIndex}>{column.title}</Select.Option>
                ) : null
              )
            : []}
        </Select>
      </Modal>
      <FullWidthDrawer
        visible={chartCreateModals.resultChartModal}
        onClose={closeChartModals}
        placement='right'
        innerComponent={
          <Space direction='vertical' style={{ width: '100%' }}>
            <h1>
              {title} - by {chartCreateModals.selectedTitle} | Project {projectId}
            </h1>
            <FilterIndicators filterProperties={filterProperties} forceShowTags />
            <Row>
              <Col xs={24} md={8}>
                <p style={{ marginTop: '6px' }}>Select a {chartCreateModals.selectedTitle} for details:</p>
              </Col>
              <Col xs={24} md={16}>
                <Select
                  showSearch
                  placeholder={`${chartCreateModals.selectedTitle}`}
                  onChange={value => handleCategorySelect(value, seriesObj)}
                  optionFilterProp='children'
                  value={selectedCategory}
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    option.children
                      .join(' ')
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) > 0
                  }
                >
                  {categories.map((category, i) => (
                    <Select.Option value={category} key={`category-option-${category}`} style={{ width: '100%' }}>
                      {i > 0 ? i + ' - ' : null}
                      {category}
                    </Select.Option>
                  ))}
                </Select>
                <span style={{ fontSize: 'small' }}>
                  * Records without {chartCreateModals.selectedTitle} data will not appear
                </span>
              </Col>
            </Row>
            <div style={{ position: 'absolute', top: 18, right: 25, zIndex: 100000 }}>
              {isExtraDateFilterOn && (
                <Tag closable onClose={clearExtraDateFilter}>
                  {`${moment(filters.extraStartDate).format('MM/DD/YYYY')} - ${moment(filters.extraEndDate).format(
                    'MM/DD/YYYY'
                  )}`}
                </Tag>
              )}
              <Button type='primary' onClick={handleFilterClick}>
                Filters
              </Button>
            </div>
            {chartCreateModals.resultChartModal && chartComponentRef.current ? chartComponentRef.current : null}
            <Divider />
            <SQTable
              dataSource={seriesDataForTable ?? []}
              columns={columns}
              skipCustomSearchPropsOf={[echoElementType.SortShift]}
              titleBarData={titleBarData}
            />
            <canvas id='canvasId' style={{ display: 'none' }} />
          </Space>
        }
      />
      <Drawer visible={dateFilterVisible} onClose={handleDateFilterClose} placement='right'>
        <DateFilterDrawer
          setValues={updateExtraDates}
          closeDrawer={handleDateFilterClose}
          filters={filters}
          clearFilters={clearExtraDateFilter}
          form={extraDateForm}
        />
      </Drawer>
    </>
  );
}

export default memo(DrilldownChartModal);
