import React, { memo } from 'react';
import { Layout } from 'antd';
import { Sidebar } from '../Sidebar';
import { AppBar } from '../AppBar';

// custom overlay needs more a11y consideration
export const Main = memo(({ children, location }) => {
  const { Header, Sider, Content } = Layout;

  if (location.pathname === '/projects/fullscreen') {
    return <>{children}</>;
  }

  return (
    <Layout>
      <Sider className='main-sider' breakpoint='xl' collapsedWidth='0'>
        <Sidebar />
      </Sider>
      <Layout className='main-section'>
        <Header className='main-header'>
          <AppBar />
        </Header>
        <Content className='main-content'>{children}</Content>
      </Layout>
    </Layout>
  );
});
