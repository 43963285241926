import useWindowSize from './useWindowSize';

export function useImageSizeDetection(props) {
  const [windowWidth] = useWindowSize();
  let imageHeight = 0;
  let imageWidth = 0;
  let containerHeight = 500; // TODO: Should set as environment variable
  const { initialImage, maxHeight, maxWidth, paddingWidth } = props;

  // Set initial image values based on container height and width
  if (containerHeight < maxHeight) {
    /**
     * set imageHeight = containerHeight
     * determine image width based on difference in height to maintain aspect ratio
     */
    imageHeight = containerHeight;
    imageWidth = (containerHeight / maxHeight) * maxWidth;
  } else {
    /**
     * set imageHeight = maxHeight
     * determine image width based on difference in height to maintain aspect ratio
     */
    imageHeight = maxHeight;
    imageWidth = maxWidth;
  }

  /**
   * if determined width > window width, set imageWidth = windowWidth
   * determine image height based on difference in width to maintain aspect ratio
   */
  if (imageWidth > windowWidth - paddingWidth) {
    const containerWidth = windowWidth - paddingWidth;

    imageWidth = containerWidth;
    imageHeight = (containerWidth / initialImage.width) * initialImage.height;
  }

  return [imageHeight, imageWidth];
}
