import React, { memo, useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { BaseCard, LOADING_STATUS, BaseList, BaseListItem } from 'components/shared';
import { RLeanState, useSave } from '@rlean/core';
import { hasValue, deepCopy } from '@rlean/utils';
import moment from 'moment';

// Enums
import { EntityKey } from 'lib/enums/EntityKey';

export const FavoriteProjects = memo(() => {
  const [projectFavorite] = RLeanState().select(({ state }) => [state.projectFavorite]);
  const [projects, setProjects] = useState();
  const [loadingStatus, setLoadingStatus] = useState();
  const save = useSave();

  const cardData = {
    title: 'Favorite Projects',
    loadingStatus,
    description: ''
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      if (!projectFavorite) {
        setProjects([]);
      }

      if (hasValue(projectFavorite, 'data')) {
        const oProjectFavorite = deepCopy(projectFavorite);
        oProjectFavorite.data = oProjectFavorite.data.filter(project => !project.inactiveInd);

        setProjects(oProjectFavorite.data);
      }
    }

    return () => (isSubscribed = false);
  }, [projectFavorite]);

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      // if (!projectFavorite) {
      //   setLoadingStatus(LOADING_STATUS.NODATA);
      // }

      if (!projectFavorite || (hasValue(projectFavorite, 'isLoading') && projectFavorite.isLoading)) {
        setLoadingStatus(LOADING_STATUS.WAITING);
      } else if (!projectFavorite.isLoading && hasValue(projectFavorite, 'data')) {
        setLoadingStatus(LOADING_STATUS.LOADED);
      } else {
        setLoadingStatus(LOADING_STATUS.NODATA);
      }
    }

    return () => (isSubscribed = false);
  }, [projectFavorite, projects]);

  const Title = ({ id, referenceNumber }) => {
    const projectIdDisplayText = `${id}${referenceNumber && referenceNumber.trim() !== '' ? ':' : ''}`;
    return (
      <>
        <strong>{projectIdDisplayText}</strong> {referenceNumber}
      </>
    );
  };

  const handleClick = projectId => {
    save({ key: EntityKey.ActiveProject, value: { id: projectId } });
    navigate('/project/details');
  };

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      <BaseList
        itemsPerPage={4}
        data={projects}
        renderItem={item => {
          const {
            project: { id, customerReference, startDate }
          } = item;
          return (
            <BaseListItem
              onClick={() => handleClick(id)}
              className='favorite-project-list-item'
              // actionComponents={[null]}
              title={<Title id={id} referenceNumber={customerReference} />}
              description={`${moment(startDate).format('MM/DD/YYYY')}`}
            />
          );
        }}
      />
    </BaseCard>
  );
});
