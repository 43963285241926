import { useEffect, memo } from 'react';
import { useSave } from '@rlean/core';
import { navigate } from '@reach/router';
import { EntityKey } from 'lib/enums/EntityKey';

const ClearImpersonation = () => {
  const save = useSave();

  useEffect(() => {
    // clear entities and navigate to stratosphere quality home
    save({ key: EntityKey.ActivePage, value: { key: null, title: null } });
    save({
      key: EntityKey.BusinessContact,
      value: { companyId: null, companyName: null, displayName: null, id: null, isReadOnly: false }
    });
    save({
      key: EntityKey.Impersonation,
      value: {
        init: false,
        isImpersonating: false,
        isReadOnly: false
      }
    });
    save({ key: EntityKey.Project, value: null });
    save({ key: EntityKey.ProjectSummary, value: null });
    save({ key: EntityKey.PendingWorkAuthorization, value: null });
    save({ key: EntityKey.PendingWorkInstruction, value: null });
    save({ key: EntityKey.Invoice, value: {} });
    save({ key: EntityKey.UserConfiguration, value: null });
    save({ key: EntityKey.WorkSite, value: null });

    navigate('/');
  }, []);

  return null;
};

export default memo(ClearImpersonation);
