export const formats = {
  PDF: 'PDF',
  EXCELOPENXML: 'EXCELOPENXML',
  CSV: 'CSV'
};

export const reportNames = {
  ACTIVITY_SUMMARY: 'Summary_Cloud',
  DAILY_HOURS: 'Estimated_Hours_Cloud',
  DEFECT_HISTORY: 'Defect_History_Cloud',
  ICHART: 'iChart_Cloud',
  MOBILE_DATA_SUMMARY: 'rpt_cometUnitScans_SMD',
  PARETO: 'Pareto_Cloud',
  SCAN_SUMMARY: 'rpt_cometUnitScans_new',
  TABULAR_ACTIVITY: 'Tabular_Activity_Cloud',
  TREND: 'Trend_Cloud',
  PROJECT_SUMMARIES: 'GetBusinessContactProjectSummary',
  DOAL_ALL_IN_ONE: 'Project_doal_all-in-one'
};
