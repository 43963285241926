import React, { memo, useState, useEffect, useCallback, useRef } from 'react';
import { Drawer, Button, Row, Col, Space, message, Skeleton, Empty } from 'antd';
import { RLeanState } from '@rlean/core';
import { hasValue } from '@rlean/utils';
import { getReportData, lookupCriteriaIdsByCriteriaNames, lookupPartIdsByPartNumber } from '.';
import { Logger } from 'lib/models';
import { strings } from 'config';
import Chart from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment';
import ReportFilters from './ReportFilters';
import { RenderChart } from './RenderChart';
import SQTable from '../SQTable';
import { FilterIndicators } from '../FilterIndicators';

function SQReport({
  reportOptions = {
    reportTitle: '',
    downloadReport: null,
    buildChart: null,
    filterProperties: [],
    filters: null,
    parts: null,
    defaultParts: null,
    defaultCriteria: null
  },
  chartOptions = { configuration: null, chartReady: false },
  tableOptions = {
    titleBarData: {
      extraTableButtons: null, // react component
      tableTitle: '', // string, required
      downloadBtn: false,
      fullscreenBtn: false,
      settingsBtn: false
    },
    dataSource: null, // required
    columns: null, // required
    summary: null
  },
  children
}) {
  const [activeProject, businessContact, projectParts] = RLeanState().select(({ state }) => [
    state.activeProject,
    state.businessContact,
    state.projectParts
  ]);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [reportParams, setReportParams] = useState(null);

  const formatParts = partDescriptions => {
    if (hasValue(projectParts, 'data')) {
      const { parts } = projectParts.data;
      const allDescriptionIds = lookupPartIdsByPartNumber(partDescriptions, parts);

      return allDescriptionIds;
    }

    // Need some type of error handling here
    return [];
  };
  const formatCriteria = criteriaByNames => {
    if (hasValue(projectParts, 'data')) {
      const { parts, criteria } = projectParts.data;
      const allCriteriaIds = lookupCriteriaIdsByCriteriaNames(criteriaByNames, parts, criteria);

      return allCriteriaIds;
    }

    // Need some type of error handling here
    return [];
  };

  const mountedRef = useRef(true);

  const fetchData = useCallback(
    filters => {
      if (!mountedRef.current) {
        return null;
      }

      setReportLoading(true);

      // Remove "extra" filters
      const filtersToUse = filters
        ? Object.keys(filters).reduce((previousValue, key) => {
            if (!key.includes('extra')) {
              previousValue[key] = filters[key];
            }

            return previousValue;
          }, {})
        : {};

      const params = {
        projectId: activeProject.id,
        businessContactId: businessContact.data.id,
        ...(filtersToUse ?? {})
      };

      if (filtersToUse && filtersToUse.partIdListCommaSeparated) {
        params.partIdListCommaSeparated = formatParts(filtersToUse.partIdListCommaSeparated);
      }

      if (filtersToUse && filtersToUse.criteriaIdListCommaSeparated) {
        params.criteriaIdListCommaSeparated = formatCriteria(filtersToUse.criteriaIdListCommaSeparated);
      }

      setReportParams(params);

      getReportData(reportOptions.urls, params)
        .then(data => {
          if (data.length === 0) {
            reportOptions.buildChart([]);
          } else if (data.length === 1) {
            reportOptions.buildChart(data[0]);
          } else {
            reportOptions.buildChart(data);
          }

          if (!mountedRef.current) {
            return null;
          }

          setReportLoading(false);
        })
        .catch(() => {
          message.error('Error Getting Data', 3);

          const logger = new Logger();
          logger.log({ level: logger.level.ERROR, message: 'Error Getting Data' });

          // loadChart([null]); // TODO: is this needed with buildChart?

          if (!mountedRef.current) {
            return null;
          }

          setReportLoading(false);
        });
    },
    [mountedRef]
  );

  useEffect(() => {
    fetchData(reportOptions.filters);

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const showFilters = () => {
    setFiltersVisible(true);
  };

  const handleFiltersClose = () => {
    setFiltersVisible(false);
  };

  const handleFiltersSubmit = updatedFilters => {
    reportOptions.updateFilters(updatedFilters, [], true);

    fetchData(updatedFilters);
  };

  const {
    reportTitle,
    downloadReport,
    filterProperties,
    filters,
    parts,
    defaultParts,
    defaultCriteria
  } = reportOptions;
  const { configuration, chartReady } = chartOptions;
  const { titleBarData, dataSource, columns, summary } = tableOptions;

  if (reportTitle && activeProject) {
    titleBarData.downloadInfo = {
      reportName: reportTitle,
      fileName: `${reportTitle}_${activeProject.id}_${moment().format('YYYY-MM-DDTHH:MM:SSZ')}`
    };
  }

  return (
    <>
      <Space direction='vertical' style={{ width: '100%' }}>
        <h1>
          {reportTitle} Report | Project {activeProject.id}
        </h1>
        {filters ? (
          <div className='table-tags-container'>
            <FilterIndicators filterProperties={filterProperties} forceShowTags />
          </div>
        ) : null}
        {downloadReport || showFilters ? (
          <div
            style={{
              position: 'absolute',
              top: '16px',
              right: '20px',
              zIndex: '100000'
            }}
          >
            {downloadReport && (
              <Button
                type='primary'
                htmlType='button'
                onClick={() => downloadReport(reportParams)}
                style={{ margin: '0 5px' }}
              >
                {strings.reportDownloadButton}
              </Button>
            )}
            {filters && showFilters && (
              <Button type='primary' htmlType='button' onClick={showFilters} style={{ margin: '0 5px' }}>
                {strings.reportFiltersButton}
              </Button>
            )}
          </div>
        ) : null}
      </Space>
      {reportLoading ? (
        <Row justify='center' type='flex'>
          <Skeleton active style={{ marginTop: '25px' }} />
        </Row>
      ) : (
        <>
          {chartReady && (
            <RenderChart
              chart={<Chart options={configuration} highcharts={Highcharts} style={{ marginBottom: '10px' }} />}
            />
          )}
          {children && children}
          {dataSource && (
            <SQTable dataSource={dataSource} columns={columns} summary={summary} titleBarData={titleBarData} />
          )}
          {!chartReady && !dataSource && !children && <Empty />}
        </>
      )}
      <Drawer visible={filtersVisible} onClose={handleFiltersClose} placement='right'>
        <ReportFilters
          handleFiltersSubmit={handleFiltersSubmit}
          defaultFilters={filters}
          handleFiltersClose={handleFiltersClose}
          parts={parts}
          defaultParts={defaultParts}
          defaultCriteria={defaultCriteria}
        />
      </Drawer>
    </>
  );
}

export default memo(SQReport);
