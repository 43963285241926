import React, { memo, useEffect, useState } from 'react';
import { useSave, usePost, RLeanState } from '@rlean/core';
import { deepCopy, getValue } from '@rlean/utils';
import { navigate } from '@reach/router';
import { pages, strings } from 'config';
import { EntityKey } from 'lib/enums/EntityKey';
import { useActivePage } from 'lib/hooks';
import { formatDate } from 'lib/helpers';
import { Alert, Button, Card, Descriptions, Space, Tag, Input } from 'antd';
import { CONCERN_POST_STATUS, CONCERN_STATUS, getTypeOfTag } from './utils';

/**
 * Concern Detail
 * Component that shows the details of a concern. It allows the user to accept or
 * reject a concern.
 */
export const ConcernDetail = memo(props => {
  const save = useSave();
  const post = usePost();
  const { id } = props;
  const [businessContact, companyConcerns] = RLeanState().select(({ state }) => [
    state.businessContact,
    state.companyConcerns
  ]);
  const [status, setStatus] = useState(CONCERN_POST_STATUS.IDLE);
  const [concern, setConcern] = useState();
  const [comment, setComment] = useState('');
  const { TextArea } = Input;

  const isMobileUser = getValue(businessContact, 'data.isMobileUser', false);

  if (isMobileUser) {
    navigate('/unauthorized');
  }

  if (!id) {
    navigate('/concerns');
  }

  useActivePage(pages.concerns);

  // get data from the global state
  useEffect(() => {
    if (companyConcerns && companyConcerns.data) {
      // find the concern in the global state
      const savedConcern = companyConcerns.data.find(c => c.id === parseInt(id));

      if (!savedConcern) {
        navigate('/concerns');
      }

      // Save the concern in the component state
      setConcern(savedConcern);
    }
  }, [companyConcerns]);

  // Updates the status of the concern
  const updateConcern = statusId => {
    setStatus(CONCERN_POST_STATUS.SENDING);

    const savedConcern = deepCopy(concern);
    // Remove all null attributes and update the status of the concern
    Object.keys(savedConcern).forEach(key => savedConcern[key] == null && delete savedConcern[key]);
    savedConcern.companyConcernStatusId = statusId;
    savedConcern.customerResponse = comment;

    // Post the updated concern
    post({ key: EntityKey.CompanyConcerns, body: savedConcern }, (res, err) => {
      if (err) {
        setStatus(CONCERN_POST_STATUS.ERROR);
      } else {
        setStatus(CONCERN_POST_STATUS.SUCCESS);
      }

      // Get the concerns from the global state to update the specific concern
      const newConcern = res.data;
      const concerns = deepCopy(companyConcerns);
      concerns.data = concerns.data ?? [];
      const concernIdx = concerns.data.findIndex(c => c.id === newConcern.id);
      concerns.data[concernIdx] = newConcern;

      // Update the global state with the updated concern
      save({ key: EntityKey.CompanyConcerns, value: concerns });
    });
  };

  // Check if the user is allowed to cancel the concern.
  const userCanCancel = () => {
    if (concern) {
      // Can cancel if the concern' status is not closed or accepted
      return ![CONCERN_STATUS.CLOSED, CONCERN_STATUS.ACCEPTED].includes(concern.companyConcernStatusId);
    } else {
      return false;
    }
  };

  return concern ? (
    <Card className='page-card' title={`${strings.concernDetailCardTitle} #${concern.id}`}>
      {status === CONCERN_POST_STATUS.SUCCESS && (
        <Alert
          className='alert-box'
          message={strings.concernsFormUpdateAlertMessageSuccess}
          description={strings.concernsFormUpdateAlertDescriptionSuccess}
          type='success'
        />
      )}
      {status === CONCERN_POST_STATUS.ERROR && (
        <Alert
          className='alert-box'
          message={strings.concernsFormAlertDescriptionFail}
          description={strings.concernsFormAlertUpdateDescriptionFail}
          type='error'
        />
      )}
      <Space direction='vertical' style={{ width: '100%' }}>
        <Descriptions title={strings.concernDetailAboutTitle} column={2} bordered>
          <Descriptions.Item label={strings.concernDetailCategoryLabel}>
            {concern.companyConcernCategoryName}
          </Descriptions.Item>
          <Descriptions.Item label={strings.concernDetailStatusLabel}>
            <Tag color={getTypeOfTag(concern.companyConcernStatusId)}>{concern.companyConcernStatusName}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label={strings.concernDetailSubmittedByLabel}>
            {concern.addedByBusinessContactDisplay}
          </Descriptions.Item>
          <Descriptions.Item label={strings.concernDetailSubmittedAtLabel}>
            {formatDate(concern.addedAt)}
          </Descriptions.Item>
          <Descriptions.Item label={strings.concernDetailLastModifiedByLabel}>
            {concern.modifiedByBusinessContactDisplay}
          </Descriptions.Item>
          <Descriptions.Item label={strings.concernDetailLastModifiedAtLabel}>
            {formatDate(concern.modifiedAt)}
          </Descriptions.Item>
          <Descriptions.Item label={strings.concernDetailSubjectLabel} span={2}>
            <strong>{concern.subject}</strong>
          </Descriptions.Item>
          <Descriptions.Item label={strings.concernDetailDetailsLabel} span={2}>
            {concern.detail}
          </Descriptions.Item>
        </Descriptions>
        {!userCanCancel() ? null : (
          <div className='description-button-space'>
            <Button danger onClick={() => updateConcern(CONCERN_STATUS.CLOSED)}>
              Cancel concern
            </Button>
          </div>
        )}
        <Descriptions title={strings.concernDetailSQResponseTitle} column={1} bordered>
          <Descriptions.Item label={strings.concernDetailResponseLabel}>{concern.response ?? '--'}</Descriptions.Item>
          <Descriptions.Item label={strings.concernDetailResponseByLabel}>
            {concern.responseByMobileUserDisplay ?? '--'}
          </Descriptions.Item>
        </Descriptions>
        {concern.customerResponse ? (
          <Space direction='vertical' className='description-button-space' style={{ width: '100%' }}>
            {concern.companyConcernStatusId === CONCERN_STATUS.WAITING_ON_SQ ? (
              <p>{strings.concernDetailCommentOnReviewText}</p>
            ) : null}
            <TextArea disabled value={concern.customerResponse} rows={3} />
          </Space>
        ) : null}
        {concern.companyConcernStatusId === CONCERN_STATUS.WAITING_ON_CUSTOMER ? (
          <Space direction='vertical' className='description-button-space' style={{ width: '100%', marginTop: 15 }}>
            <p>{strings.concernDetailAddCommentLabel}</p>
            <TextArea
              type='text'
              rows={3}
              placeholder={'Add a comment'}
              value={comment}
              onChange={e => setComment(e.target.value)}
            />
            <Space>
              <Button type='primary' onClick={() => updateConcern(CONCERN_STATUS.ACCEPTED)}>
                {strings.concernDetailAcceptButtonText}
              </Button>
              <Button danger onClick={() => updateConcern(CONCERN_STATUS.REJECTED)}>
                {strings.concernDetailRejectButtonText}
              </Button>
            </Space>
          </Space>
        ) : null}
      </Space>
    </Card>
  ) : null;
});
