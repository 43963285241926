import { define } from '@rlean/core';
import { CompanyConcern } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

// TODO: Rename to CompanyConcern
const CompanyConcerns = define<CompanyConcern>(EntityKey.CompanyConcerns, {
  getURL: '/CompanyConcerns',
  postURL: '/CompanyConcerns'
});

export default CompanyConcerns;
