import React, { memo, useState } from 'react';
import { RLeanState, usePut, useSave } from '@rlean/core';
import { hasValue, getValue, deepCopy } from '@rlean/utils';
import { Card, Form, Button, Input, Skeleton, Descriptions, message } from 'antd';
import { navigate } from '@reach/router';
import { strings } from 'config';
import { EntityKey } from 'lib/enums/EntityKey';

export const PendingProjectInformationFormRevisionReview = memo(props => {
  const { id } = props;
  const { TextArea } = Input;

  const [
    activeProject,
    project,
    businessContact,
    projectInformationFormRevisionReviews
  ] = RLeanState().select(({ state }) => [
    state.activeProject,
    state.project,
    state.businessContact,
    state.projectInformationFormRevisionReviews
  ]);
  const put = usePut();
  const [comment, setComment] = useState('');
  const [showError, setShowError] = useState(false);
  const save = useSave();
  const isPendingPIFsLoaded =
    hasValue(projectInformationFormRevisionReviews, 'isLoading') &&
    !projectInformationFormRevisionReviews.isLoading &&
    hasValue(projectInformationFormRevisionReviews, 'data');
  const isProjectLoaded = hasValue(project, 'isLoading') && !project.isLoading && hasValue(project, 'data');

  let currentProject;

  if (hasValue(activeProject, 'id') && isProjectLoaded) {
    currentProject = project.data.find(x => x.projectId === activeProject.id);
  } else if (isPendingPIFsLoaded && isProjectLoaded) {
    const currentWorkAuthorization = projectInformationFormRevisionReviews.data.find(x => x.id === +id);
    if (currentWorkAuthorization)
      currentProject = project.data.find(x => x.projectId === currentWorkAuthorization.projectId);
  }

  let body = <Skeleton active />;
  const businessContactId = getValue(businessContact, 'data.id', 0);

  if (isPendingPIFsLoaded && isProjectLoaded) {
    const selectedPIF = projectInformationFormRevisionReviews.data.find(x => +x.id === +id);

    if (!activeProject || !activeProject.id || (selectedPIF && selectedPIF.projectId !== activeProject.id)) {
      save({ key: EntityKey.ActiveProject, value: { id: selectedPIF.projectId } });
    }

    const handleUpdate = accepted => {
      if (accepted || (!accepted && comment)) {
        setShowError(false);
        put(
          {
            key: EntityKey.ProjectInformationFormRevisionReviews,
            params: { id },
            body: {
              accepted,
              reviewerNote: comment ?? '',
              reviewerBusinessContactId: businessContactId,
              id
            }
          },
          response => {
            if (response.status === 400 || response.status === 500) {
              message.error(`There was an error ${accepted ? 'accepting' : 'rejecting'} the work authorization.`);
            } else {
              if (isPendingPIFsLoaded) {
                const updatedPendingPIFReviews = deepCopy(
                  projectInformationFormRevisionReviews.data.filter(pif => +pif.id !== +id)
                );

                save({ key: EntityKey.PendingWorkAuthorization, value: updatedPendingPIFReviews });
              }
            }
          }
        );

        navigate('/project/details');
      } else {
        setShowError(true);
      }
    };

    const handleComment = e => {
      setComment(e.target.value);
    };

    if (selectedPIF && currentProject) {
      body = (
        <>
          <Descriptions title={strings.projectDetailsPIFRevisions.reviewTitle}>
            <Descriptions.Item label={strings.projectId}>{currentProject.projectId}</Descriptions.Item>
            <Descriptions.Item label={strings.customerReferenceNumber}>
              {currentProject.customerReference && currentProject.customerReference.trim()
                ? currentProject.customerReference
                : '--'}
            </Descriptions.Item>
          </Descriptions>

          <Form>
            <Form.Item>
              {showError ? <span className='red-text'>{strings.pifReviewValidation}</span> : ''}
              <TextArea type='text' rows='6' value={comment} onChange={handleComment} placeholder={strings.comment} />
            </Form.Item>
            <Form.Item>
              <Button
                type='primary'
                htmlType='button'
                onClick={() => handleUpdate(true)}
                disabled={businessContact.data.isReadOnly}
              >
                {strings.approve}
              </Button>
              <Button
                type='primary'
                htmlType='button'
                onClick={() => handleUpdate(false)}
                style={{ marginLeft: 20 }}
                disabled={businessContact.data.isReadOnly}
              >
                {strings.reject}
              </Button>
            </Form.Item>
          </Form>
        </>
      );
    } else {
      body = <p>{strings.noProjectInformationForm}</p>;
    }
  }

  return (
    <Card className='page-card' title={strings.pendingPIFReview}>
      {body}
    </Card>
  );
});
