import { useContext, useEffect, useState } from 'react';
import { BaseFilters } from 'lib/models';
import { FilterContext } from 'components/shared/FiltersContext';
import { deepCopy } from '@rlean/utils';
import moment from 'moment';

/**
 * Converts filters object (BaseFilters) into a {[filterName]: value} format
 * If filterProperties provided, only the keys in the list will be returned
 *
 * @param {BaseFilters} filters
 * @param {string[]} filterProperties
 */
export const filterConvert = (filters, filterProperties = []) => {
  return Object.keys(filters).reduce((previousResult, currentValue) => {
    if (
      !(currentValue in previousResult) &&
      (!filterProperties || filterProperties.length === 0 || filterProperties.includes(currentValue))
    ) {
      return { ...previousResult, [currentValue]: filters[currentValue].value };
    } else {
      return previousResult;
    }
  }, {});
};

/**
 * @typedef FilterHookReturnObject
 * @property {BaseFilters} filters The main filter, with access to all TableFilter attributes
 * @property {Function} setFilters Directly updates the filters object
 * @property {Object} convertedFilters Ant Design's form data structure
 * @property {Boolean} loadingFilters Flag to wait for filters to be created
 * @property {Function} updateFiltersWithFormData Updates filters using Ant Design's form data structre
 */

/**
 * Filters hook. Uses FilterContext.
 * @param {*} initialValues The initial values of the filters. Uses attributes as found in BaseFilters
 * @param {string[]} filterProperties List of filters' keys used in the component calling the hook
 * @returns {FilterHookReturnObject}
 */
export default function useFilters(initialValues, filterProperties) {
  const { filters, setFilters } = useContext(FilterContext);
  const [loadingFilters, setLoadingFilters] = useState(true);

  useEffect(() => {
    if (Object.keys(initialValues).length) {
      setFilters({
        ...filters,
        ...new BaseFilters(filterProperties, initialValues)
      });
    }

    setLoadingFilters(false);

    return () => {
      // Reset filters at unmount
      // setFilters({ ...filters, ...new BaseFilters(filterProperties) });
    };
  }, []);

  const updateFiltersWithFormData = (values, filterOnly = [], keepOriginals = false) => {
    const updatedValues = deepCopy(values);

    // Check if there's partId in formData. If so, update only the value. The
    // altValue is being updated in the ReportFilters component where the part object
    // is reachable.
    // Notice that values comes from the formData structure. It's not the same as filters.
    if (values.partId) {
      updatedValues.partId = { ...filters.partId, value: values.partId };
    }

    // Re-set the startDate, endDate, startDateTime, endDateTime, extraStartDate and extraEndDate
    // values using moment and re-setting their locale.
    // Ant-design seems to save the date from the DatePicker without locale, which then breaks
    // the rest of the code.
    ['startDate', 'endDate', 'startDateTime', 'endDateTime', 'extraStartDate', 'extraEndDate'].forEach(key => {
      if (updatedValues[key]) {
        updatedValues[key] = moment(updatedValues[key]).locale('en');
      }
    });

    setFilters({
      ...filters,
      ...new BaseFilters(
        filterOnly.length > 0 ? filterOnly : filterProperties,
        updatedValues,
        keepOriginals ? filters : {}
      )
    });
  };

  const resetSingleFilter = filterKey => {
    setFilters({
      ...filters,
      ...new BaseFilters([filterKey])
    });
  };

  return {
    filters,
    setFilters,
    convertedFilters: filterConvert(filters, filterProperties),
    loadingFilters,
    updateFiltersWithFormData,
    resetSingleFilter
  };
}
