import React, { memo } from 'react';
import { RLeanState } from '@rlean/core';
import { Row, Col, Button, Table } from 'antd';
import { exportToCsv } from 'lib/helpers';
import { strings } from 'config';
import { useProjectsParts } from 'lib/hooks';

export const PartsTable = memo(() => {
  const [activeProject] = RLeanState().select(({ state }) => [state.activeProject]);

  const [data] = useProjectsParts();

  const partsColumns = [
    {
      title: strings.projectDetailsPartsTablePartNumber,
      dataIndex: 'number',
      key: 'number'
    },
    {
      title: strings.projectDetailsPartsTablePartDescription,
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: strings.projectDetailsPartsTableCriteriaName,
      dataIndex: 'name',
      key: 'criteriaName'
    },
    {
      title: strings.projectDetailsPartsTableCriteriaType,
      dataIndex: 'criteriaType',
      key: 'criteriaType'
    }
  ];

  const handlePartClick = () => {
    const source = data;
    const formattedData = [];
    for (let i = 0; i < source.length; i += 1) {
      formattedData.push(source[i]);
      if (source[i].children) source[i].children.forEach(child => formattedData.push(child));
    }

    exportToCsv(`parts-${activeProject.id}`, partsColumns, formattedData);
  };

  return (
    (data ?? []).length > 0 && (
      <>
        <Row type='flex' justify='space-between' style={{ marginTop: 35 }}>
          <Col span={8}>
            <h1>{strings.projectDetailsPartsTablePartTitle}</h1>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button type='primary' htmlType='button' onClick={handlePartClick}>
              {strings.projectDetailsPartsTableExport}
            </Button>
          </Col>
        </Row>
        <Table dataSource={data} columns={partsColumns} bordered scroll={{ x: true }} />
      </>
    )
  );
});
