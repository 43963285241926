import React, { memo } from 'react';
import { Table } from 'antd';
import TitleBar from './TitleBar';

const buildSummaryColumns = columnsToCalc => {
  let calculatedColumns;
  if (columnsToCalc && columnsToCalc.length > 0) {
    calculatedColumns = columnsToCalc
      .filter(column => column.sqCalc)
      .map(column => {
        const { title, dataIndex, key } = column;
        return { title, dataIndex, key: `${key}-summary` };
      });
  }

  return calculatedColumns ?? columnsToCalc;
};

const buildCalculatedSummary = (data, columns) => {
  if (!data || data.length === 0) {
    return [];
  }

  const calculatedSummary = {};
  if (data && columns && columns.length > 0) {
    columns.forEach(column => {
      const columnTotal = data.reduce((total, row) => {
        // Looks for the property summaryColumns need with dataIndex
        const correspondingCell = row[column.dataIndex] ? row[column.dataIndex] : null;

        return correspondingCell ? total + parseFloat(correspondingCell) : total;
      }, 0);

      calculatedSummary[column.dataIndex] = columnTotal;
    });
  }

  return [calculatedSummary];
};

const SummaryTable = ({ dataSource, columns, filtersIndicators }) => {
  const summaryColumns = buildSummaryColumns(columns);
  const summaryDataSource = buildCalculatedSummary(dataSource, summaryColumns);

  return (
    <>
      {summaryColumns && summaryDataSource && (
        <div className='report-table summary-table' style={{ marginBottom: '50px' }}>
          <Table
            title={() => <TitleBar tableTitle='Summary' filtersIndicators={filtersIndicators} />}
            columns={summaryColumns}
            dataSource={summaryDataSource}
            scroll={{ x: true }}
            pagination={false}
          />
        </div>
      )}
    </>
  );
};

export default memo(SummaryTable);
