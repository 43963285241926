import React, { useEffect, useContext } from 'react';
import { DrilldownContext } from './CommonDrilldown/DrilldownContext';
import imageCompression from 'browser-image-compression';

const imageCompressionOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1280,
  useWebWorker: true,
  fileType: 'png'
};

const CanvasComponent = ({
  width,
  height,
  maxWidth,
  maxHeight,
  canvasRef,
  imageDataUrl,
  imageAreas,
  overlayCoordinates,
  marginLeft
}) => {
  const imageChartCtx = useContext(DrilldownContext) ?? undefined;

  const selectionAreas = imageAreas.newImage
    ? imageAreas.newImage.selectionAreas
    : imageAreas.initialImage.selectionAreas;

  // Create an offscreen canvas for buffering
  const offscreenCanvas = document.createElement('canvas');
  const offscreenCtx = offscreenCanvas.getContext('2d');

  const getExportedChartInPNG = () => {
    const canvas = canvasRef.current;
    return new Promise(async (res, rej) => {
      const imageBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));

      // Compress the blob
      const blob = await imageCompression(imageBlob, imageCompressionOptions);

      // read blob as file and convert to base64
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      reader.onloadend = () => {
        const base64result = reader.result.split(',')[1];
        res(base64result);
      };
    });
  };

  useEffect(() => {
    imageChartCtx.getExportedChartInPNG.current = getExportedChartInPNG;
  }, []);

  const draw = ctx => {
    return new Promise(resolve => {
      const drawImageAreas = () => {
        // Draw imageAreas
        if (selectionAreas) {
          ctx.save();
          selectionAreas.forEach((area, i) => {
            const number = i + 1;
            const legendItem = imageAreas.legend.find(x => x.number === number);
            let color = '';

            if (legendItem) {
              const rows = imageAreas.tableData.rows.filter(x => x['field-2'] === legendItem.label);
              let totalDefects = 0;

              for (let i = 0; i < imageAreas.tableData.rows.length; i += 1) {
                const currentRow = imageAreas.tableData.rows[i];
                totalDefects += +currentRow['field-5'];
              }

              if (rows.length > 0) {
                let totalPartAreaDefects = 0;

                for (let i = 0; i < rows.length; i += 1) {
                  const currentRow = rows[i];
                  totalPartAreaDefects += +currentRow['field-5'];
                }

                const defectPercentage = (totalPartAreaDefects / totalDefects) * 100;

                if (defectPercentage <= 5 && defectPercentage > 0) {
                  color = 'rgba(0,255,0,0.4)'; // greem
                } else if (defectPercentage > 5 && defectPercentage <= 25) {
                  color = 'rgba(255,255,0,0.4)'; // yellow
                } else if (defectPercentage > 25) {
                  color = 'rgba(255,0,0,0.4)'; // red
                }
              }
            }

            ctx.beginPath();
            ctx.setLineDash([5, 5]);
            ctx.lineWidth = 1;
            ctx.strokeStyle = imageAreas.selectedColor;
            ctx.fillStyle = color === '' ? 'rgba(0,0,0,0)' : color;

            //ctx.rect(area.x, area.y, area.width, area.height);
            ctx.rect(Math.round(area.x), Math.round(area.y), Math.round(area.width), Math.round(area.height));

            ctx.fill();
            ctx.stroke();

            // reset
            ctx.restore();
          });
        }
      };

      const drawLabels = () => {
        ctx.save();
        for (let i = 0; i < overlayCoordinates.length; i += 1) {
          const currentCoordinatePair = overlayCoordinates[i];
          const topLeftCoordinates = currentCoordinatePair.topLeftCoordinates;
          const labelLeft = topLeftCoordinates.xCoordinate;
          const labelTop = topLeftCoordinates.yCoordinate;
          const label = i + 1;

          ctx.beginPath();

          ctx.rect(labelLeft, labelTop, 20, 20);
          ctx.fillStyle = 'black';
          ctx.fill();

          // Text settings
          ctx.font = 'bold 13px Arial';
          ctx.textAlign = 'center';
          ctx.fillStyle = 'white';

          // Draw the text
          ctx.fillText(label.toString(), labelLeft + 10, labelTop + 15);

          ctx.restore();
        }
      };

      // Draw image
      const image = new Image();
      image.src = imageDataUrl || '';

      image.onload = () => {
        ctx.clearRect(0, 0, width, height);
        ctx.drawImage(image, 0, 0, width, height);
        drawImageAreas();
        drawLabels();
        resolve();
      };
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const pixelRatio = window.devicePixelRatio || 1;

    // Set dimesions of the canvas
    canvas.width = Math.min(width, maxWidth) * pixelRatio;
    canvas.height = Math.min(height, maxHeight) * pixelRatio;

    // Scale so the drawings are in the right place
    ctx.scale(pixelRatio, pixelRatio);

    // Set the css to the right width/height
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;

    // Buffer
    // Copy the offscreen canvas to the onscreen canvas
    offscreenCanvas.width = canvas.width;
    offscreenCanvas.height = canvas.height;
    offscreenCtx.fillStyle = 'red';

    draw(offscreenCtx).then(() => requestAnimationFrame(() => ctx.drawImage(offscreenCanvas, 0, 0)));

    // Draw the contents in visible canvas
  }, [width, height, imageAreas.selectedColor]);

  return (
    <canvas
      style={{
        marginLeft: marginLeft,
        border: '1px solid black'
      }}
      ref={canvasRef}
    />
  );
};

export default CanvasComponent;
