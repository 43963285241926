import { strings } from 'config';

export const pages = {
  concerns: {
    key: 'concerns',
    title: strings.concerns
  },
  dashboard: {
    key: 'dashboard',
    title: strings.dashboard
  },
  findAProject: {
    key: 'findAProject',
    title: strings.findAProject
  },
  impersonate: {
    key: null,
    title: strings.impersonate
  },
  invoices: {
    key: 'invoices',
    title: strings.invoices
  },
  projects: {
    key: 'projects',
    title: strings.projects
  },
  projectDetails: {
    key: 'projects',
    title: strings.projectDetails
  },
  stratosphereQualityHome: {
    key: 'stratosphereQualityHome',
    title: strings.sqHome
  },
  support: {
    key: 'support',
    title: strings.support
  },
  termsOfUse: {
    key: 'termsOfUse',
    title: strings.termsOfUse
  },
  unauthorized: {
    key: 'unauthorized',
    title: strings.unauthorized
  },
  unexpectedError: {
    key: 'unexpected',
    title: strings.unexpectedError
  },
  whatsNew: {
    key: 'whatsNew',
    title: strings.whatsNew
  }
};
