import React, { memo } from 'react';
import { RLeanState } from '@rlean/core';
import { hasValue, getValue } from '@rlean/utils';
import { BaseCard, LOADING_STATUS, BaseList, BaseListItem } from 'components/shared';
import MaterialIcon from '@material/react-material-icon';
import { strings } from 'config';
import { navigate } from '@reach/router';

const Reports = () => {
  const [projectReports, activeProject] = RLeanState().select(({ state }) => [
    state.projectReports,
    state.activeProject
  ]);

  const reportsUIMap = new Map([
    [
      'Pareto',
      {
        icon: 'multiline_chart',
        id: 'Pareto',
        slug: 'pareto'
      }
    ],
    ['Trend', { icon: 'show_chart', id: 'Trend', slug: 'trend' }],
    [
      'Activity Summary',
      {
        icon: 'bar_chart',
        id: 'ActivitySummary',
        slug: 'activity-summary'
      }
    ],
    [
      'Tabular Activity',
      {
        icon: 'table_chart',
        id: 'TabularActivity',
        slug: 'tabular-activity'
      }
    ],
    [
      'Defect History',
      {
        icon: 'bar_chart',
        id: 'DefectHistory',
        slug: 'defect-history'
      }
    ],
    [
      'Estimated Daily Hours',
      {
        icon: 'bar_chart',
        id: 'EstimatedDailyHours',
        slug: 'estimated-daily-hours'
      }
    ],
    ['iChart', { icon: 'bar_chart', id: 'iChart', slug: 'ichart' }],
    [
      'Mobile Data Summary',
      {
        icon: 'table_chart',
        id: 'MobileDataSummary',
        slug: 'mobile-data-summary'
      }
    ],
    [
      'Scan Data Summary',
      {
        icon: 'table_chart',
        id: 'ScanDataSummary',
        slug: 'scan-data-summary'
      }
    ],
    [
      'Uninspected Vehicles',
      {
        icon: 'table_chart',
        id: 'UninspectedVehicles',
        slug: 'uninspected-vehicles'
      }
    ],
    [
      'Vehicle Inspection All in One',
      {
        icon: 'table_chart',
        id: 'VehicleInspectionAllInOne',
        slug: 'vehicle-inspection-all-in-one'
      }
    ],
    [
      'Standard Vehicle Inspection',
      {
        icon: 'table_chart',
        id: 'StandardVehicleInspection',
        slug: 'standard-vehicle-inspection'
      }
    ],
    [
      'Echo Standard Data',
      {
        icon: 'table_chart',
        id: 'EchoStandardData',
        slug: 'echo-standard-data'
      }
    ],
    [
      'Echo Custom Data',
      {
        icon: 'table_chart',
        id: 'EchoCustomData',
        slug: 'echo-custom-data'
      }
    ],
    [
      'Part Area Defect Intensity',
      {
        icon: 'table_chart',
        id: 'PartAreaDefectIntensity',
        slug: 'part-area-defect-intensity'
      }
    ]
  ]);

  const projectReportsArray = getValue(projectReports, 'data', []);

  const listData = projectReportsArray
    .sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0))
    .map(report => ({
      ...report,
      ...reportsUIMap.get(report.activityReportName)
    }));

  const getLoadingStatus = () => {
    let loadingStatus = LOADING_STATUS.NODATA;

    if (
      hasValue(activeProject, 'id') &&
      (!projectReports || (hasValue(projectReports, 'isLoading') && projectReports.isLoading))
    ) {
      loadingStatus = LOADING_STATUS.WAITING;
    } else if (
      hasValue(activeProject, 'id') &&
      ((hasValue(projectReports, 'isLoading') && !projectReports.isLoading) || listData)
    ) {
      loadingStatus = LOADING_STATUS.LOADED;
    }

    return loadingStatus;
  };

  const showDrawer = report => {
    navigate(`/project/details/report/${report.slug}`);
  };

  const cardData = {
    title: strings.projectDetailsReportsTitle,
    loadingStatus: getLoadingStatus(),
    description: null
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      <BaseList
        data={listData}
        renderItem={item => (
          <BaseListItem
            actionComponents={[
              <button type='button' className='action-button' onClick={() => showDrawer(item)}>
                <MaterialIcon icon={item.icon} />
              </button>
            ]}
            title={item.activityReportName}
            description={item.activityReportDescription}
          />
        )}
      />
    </BaseCard>
  );
};

export default memo(Reports);
