import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
  en: {
    dashboard: 'Dashboard',
    projects: 'Projects',
    projectsSearchResults: 'Project Search',
    invoices: 'Invoices',
    startAProject: 'Start a Project',
    survey: 'How are we doing?',
    concerns: 'Concerns',
    support: 'Support',
    projectDetails: 'Project Details',
    whatsNew: "What's New",

    // Pending Action Items
    projectId: 'Project ID',
    customerReferenceNumber: 'Customer Reference Number',
    approve: 'Approve',
    reject: 'Reject',
    comment: 'Comment',
    pendingWorkAuthorization: 'Pending Work Authorization',
    pendingWorkInstruction: 'Pending Work Instruction',
    pendingPIFReview: 'Pending Project Information Form Revision Review',
    noWorkAuthorization: 'Work authorization not found.',
    noWorkInstruction: 'Work instruction not found.',
    noProjectInformationForm: 'Project Information Form not found.',
    workAuthorizationValidation: 'A comment is required when rejecting a work authorization.',
    workInstructionValidation: 'A comment is required when rejecting a work instruction.',
    pifReviewValidation: 'A comment is required when rejecting a PIF.',

    // Grid
    gridPopOverSave: 'Save',
    gridPopOverCancel: 'Cancel',
    gridPopOverReset: 'Reset To Default',
    gridPopOverTitle: 'Edit Dashboard',
    gridEditModeSave: 'Save',
    gridEditModeCancel: 'Cancel',
    invoiceCard: 'Invoiced in the last 30 days',

    // Dashboard
    workInstructionPopUpPlaceholder: 'Reason to reject...',
    workInstructionPopUpTitle: 'Reason to reject',
    workInstructionRejectButton: 'Reject',
    workAuthorizationPopUpPlaceholder: 'Reason to reject...',
    workAuthorizationPopUpTitle: 'Reason to reject',
    workAuthorizationRejectButton: 'Reject',

    // Reports
    reportDownloadButton: 'Download Report',
    reportFiltersButton: 'Filters',
    reportFiltersApplyButton: 'Apply Filters',
    reportFiltersApplyAndDownloadButton: 'Apply Filters & Download Report',
    reportFiltersShiftLabel: 'Shifts:',
    reportFiltersPartsLabel: 'Parts:',
    reportFiltersCriteriaLabel: 'Criteria:',
    reportFiltersStartDateLabel: 'Start Date:',
    reportFiltersEndDateLabel: 'End Date:',
    reportFiltersMostRecent: 'Most Recent Inspection by VIN',
    reportActivitySummaryTitle: 'Activity Summary',
    reportActivitySummaryTableTitle: 'Details',
    reportDailyHoursTitle: 'Daily Hours',
    reportDailyHoursTableTitle: 'Details',
    reportDefectHistoryTitle: 'Defect History',
    reportDefectHistoryTableTitle: 'Details',
    reportParetoTitle: 'Pareto',
    reportParetoTableSummaryTitle: 'Summary',
    reportParetoTableDetailsTitle: 'Details',
    reportTabularTitle: 'Tabular',
    reportTabularTableSummary: 'Summary',
    reportTabularTableDetails: 'Details',
    reportTrendTitle: 'Trend',
    reportTrendTableTitle: 'Details',

    // Report Tables
    reportTableColumnCriteria: 'Criteria',
    reportTableColumnCriteriaType: 'Criteria Type',
    reportTableColumnCount: 'Count',
    reportTableColumnDate: 'Date',
    reportTableColumnDaysSinceLastDefect: 'Days Since Last Defect',
    reportTableColumnDefect: 'Defect',
    reportTableColumnDefects: 'Defects',
    reportTableColumnDefectPPM: 'Defect PPM',
    reportTableColumnDefectCount: 'Defect Count',
    reportTableColumnFallout: 'Fallout %',
    reportTableColumnGood: 'Good',
    reportTableColumnHours: 'Hours',
    reportTableColumnInspected: 'Inspected',
    reportTableColumnLastDefectDate: 'Last Defect Date',
    reportTableColumnNonconforming: 'Nonconforming',
    reportTableColumnObservation: 'Observation',
    reportTableColumnPercentOfTotal: 'Percent of Total',
    reportTableColumnPartName: 'Part Name',
    reportTableColumnPartNumber: 'Part Number',
    reportTableColumnPPM: 'PPM',
    reportTableColumnProject: 'Project',
    reportTableColumnRework: 'Rework',
    reportTableColumnShift: 'Shift',
    reportTableColumnSortDate: 'Sort Date',
    reportTableColumnTotalCount: 'Total Count',
    reportTableColumnTotalGood: 'Total Good',
    reportTableColumnTotalInspected: 'Total Inspected',
    reportTableColumnTotalNonConforming: 'Total NonConforming',
    reportTableColumnTotalObservation: 'Total Observation',
    reportTableColumnTotalPPM: 'Total PPM',
    reportTableColumnTotalReworked: 'Total Reworked',
    reportTableColumnType: 'Type',

    // Report Charts
    reportChartActivitySummaryNonconformingTotal: 'Nonconforming Total',
    reportChartActivitySummaryObservationTotal: 'Observation Total',
    reportChartActivitySummaryReworkTotal: 'Rework Total',
    reportChartActivitySummaryGoodTotal: 'Good Total',
    reportChartActivitySummaryPPM: 'PPM',

    // Project Details Page
    projectDetailsBreadcrumbsTitle: 'Projects',
    projectDetailsBreadcrumbsItem: 'Project',

    projectDetailsAuthorizationTitle: 'Authorization',
    projectDetailsDirectoryTitle: 'Directory',
    projectDetailsTrainingTableRevisionLevel: 'Revision Level',
    projectDetailsTrainingTableRevisionDate: 'Revision Date',
    projectDetailsTrainingTableTrainee: 'Trainee',
    projectDetailsTrainingTableCompletedDate: 'Completed Date',
    projectDetailsTrainingTableTrainer: 'Trainer',
    projectDetailsAudits: {
      cardTitle: 'Project Audits',
      reportTitle: 'IPA Reports',
      reportDescription: 'View IPA reports'
    },
    projectDetailsDocuments: 'Documents',
    projectDetailsDocumentTitle: 'Documents',
    projectDetailsPIFRevisions: {
      reviewTitle: 'Project Information Form',
      cardTitle: 'Project Information Forms'
    },
    projectDetailsDocumentDescription: 'Select to view project information form',
    projectDetailsDocumentTrainingTitle: 'TRAINING LOG',
    projectDetailsDocumentTrainingTableTitle: 'Training Logs',
    projectDetailsDocumentTrainingDescription: 'Select to view training log',
    projectDetailsDocumentProjectResourcesTitle: 'PROJECT RESOURCES',
    projectDetailsDocumentProjectResourcesDescription: 'Select to view project resources',
    projectDetailsDocumentHoursNotInvoicedTitle: 'Hours Not Yet Invoiced',
    projectDetailsDocumentHoursNotInvoicedDescription:
      'There are no hours that have not yet been invoiced for this project.',
    projectDetailsDocumentExport: 'Export to CSV',
    projectDetailsInvoicedTitle: 'Invoiced',
    projectDetailsInvoicedDescription: 'Total as of',
    projectDetailsPartsTitle: 'Parts',
    projectDetailsPartsDescription: 'Parts Empty Description',
    projectDetailsPartsTablePartTitle: 'Parts',
    projectDetailsPartsTableCriteriaTitle: 'Criteria Without A Part',
    projectDetailsPartsTableExport: 'Export to CSV',
    projectDetailsPartsTablePartNumber: 'Part Number',
    projectDetailsPartsTablePartDescription: 'Part Description',
    projectDetailsPartsTableCriteriaName: 'Criteria Name',
    projectDetailsPartsTableCriteriaType: 'Criteria Type',
    projectDetailsPartsPart: 'PART:',
    projectDetailsPartsCriteria: 'Criteria:',
    projectDetailsPartsCriteriaType: 'Criteria Type:',
    projectDetailsCriteriaTablePartDescription: 'Part Description',
    projectDetailsCriteriaTableCriteriaName: 'Criteria Name',
    projectDetailsCriteriaTableCriteriaType: 'Criteria Type',
    projectDetailsReportsTitle: 'Reports',
    projectDetailsReportsDescription: 'Reports Empty Description',
    projectDetailsSummaryTitle: 'Summary',
    projectDetailsSummaryTotalAsOf: 'Total as of',
    projectDetailsSummaryStartDate: 'Start date',
    projectDetailsSummaryClosedDate: 'Closed date',
    projectDetailsSummaryInspectedTitle: 'INSPECTED',
    projectDetailsSummaryNonconformingTitle: 'NONCONFORMING',
    projectDetailsSummaryReworkedTitle: 'REWORKED',
    projectDetailsSummaryGoodTitle: 'GOOD',
    projectDetailsWorkInstructionsTitle: 'Work Instructions',
    projectDetailsWorkInstructionsReportTitle: 'Work Instruction Reviews',
    projectDetailsWorkInstructionsDownload: 'Download',
    projectDetailsWorkInstructionsAuthor: 'Author:',
    projectDetailsWorkInstructionsVersion: 'Version',
    projectDetailsWorkInstructionsPublished: 'Published',
    projectDetailsWorkInstructions: {
      workInstructionRevisionReviewStatus: 'Status',
      workInstructionRevisionPublishedVersionNumber: 'Version'
    },
    projectInformationFormsTable: {
      title: 'Project Information Forms Reviews',
      projectInformationFormRevisionNumber: 'Version',
      projectInformationFormRevisionReviewStatus: 'Status'
    },
    sharedTableColumns: {
      requestorFullName: 'Requestor',
      requestorEmail: 'Requestor Email',
      reviewerFullName: 'Reviewer',
      reviewerEmail: 'Reviewer Email',
      reviewerNote: 'Reviewer Note',
      reviewedAt: 'Reviewed At',
      requestedAt: 'Requested At',
      exportToCSV: 'Export to CSV'
    },

    // Impersonate
    impersonate: 'Impersonate',
    impersonateContent: `To view project data as a customer contact, enter either the contact's <b>First Name</b>, <b>Last Name</b>, or <b>Company Name</b> below or search for a company by <b>Project ID</b>.`,
    businessContactUser: 'Business Contact User',

    // Support Page
    supportCardTitle: 'Send Us Your Feedback',
    supportSelectPlaceholder: 'Select Contact Type',
    supportOptionSupport: 'Website Support',
    supportOptionFeedback: 'Website Feedback',
    supportOptionButton: 'Send',
    supportFormSelectLabel: 'Comment Type:',
    supportFormTextAreaLabel: 'Comment:',
    supportFormTextAreaPlaceholder: 'Comment... ',
    supportAlertMessageSuccess: 'Feedback Sent',
    supportAlertDescriptionSuccess: 'Your feedback was sent successfully',
    supportAlertMessageFail: 'Feedback Error',
    supportAlertDescriptionFail: 'Your feedback was not sent, please try again',
    supportErrorSelect: 'Please select the type of message',
    supportErrorText: 'Please add your message',

    // concerns Page
    concernsCardTitle: 'Concerns',
    concernsCreateCardTitle: 'Create a new concern',
    concernsFormSubjectTextAreaLabel: 'Subject:',
    concernsFormSubjectTextAreaPlaceholder: 'Subject',
    concernsFormCategorySelectLabel: 'Category:',
    concernsFormCategorySelectPlaceholder: 'Select a category',
    concernsFormDetailsTextAreaLabel: 'Details',
    concernsFormDetailsTextAreaPlaceholder: 'Write the details here',
    concernsFormButton: 'Submit',
    concernsFormButtonUpdate: 'Update',
    concernsFormButtonSending: 'Sending...',
    concernsFormAlertMessageSuccess: 'Concern sent',
    concernsFormUpdateAlertMessageSuccess: 'Concern updated',
    concernsFormAlertDescriptionSuccess: 'Your concern was sent successfully',
    concernsFormUpdateAlertDescriptionSuccess: 'Your concern was successfully updated',
    concernsFormAlertMessageFail: 'Concern creation error',
    concernsFormAlertUpdateMessageFail: 'Concern update error',
    concernsFormAlertDescriptionFail: 'Your concern was not sent, please try again',
    concernsFormAlertUpdateDescriptionFail: 'Your concern was not updated, please try again',
    concernDetailIDLabel: 'ID',
    concernDetailCardTitle: 'Concern Details',
    concernDetailAboutTitle: 'About the concern',
    concernDetailSubjectLabel: 'Subject',
    concernDetailStatusLabel: 'Status',
    concernDetailCreatedAtLabel: 'Created at',
    concernDetailSubmittedByLabel: 'Submitted by',
    concernDetailSubmittedAtLabel: 'Submitted at',
    concernDetailLastModifiedByLabel: 'Last Modified by',
    concernDetailLastModifiedAtLabel: 'Last Modified at',
    concernDetailDetailsLabel: 'Details',
    concernDetailResponseByLabel: 'Response by',
    concernDetailResponseLabel: 'Response',
    concernDetailCategoryLabel: 'Category',
    concernDetailSQResponseTitle: 'Stratosphere Proposed Response',
    concernDetailCommentOnReviewText: 'Your comment is being reviewed',
    concernDetailAddCommentLabel: 'Add a comment in respect to the response (optional)',
    concernDetailAddCommentPlaceholder: 'Add any comment',
    concernDetailAcceptButtonText: 'Accept',
    concernDetailRejectButtonText: 'Reject',

    // Terms of Use
    termsOfUse: 'Terms of Use',
    termsOfUseContent: `<p class='caption'>The following Terms of Use outline your obligations when using the SOLAR engage website.</p>
    <ol class='terms-of-use'>
      <li>
        <p>ACCEPTANCE OF TERMS</p>
        <p>
          The SOLAR engage internet site available at www.solarengage.com (the "Site"), is owned and operated by Stratosphere Quality, LLC ("Company"), and may be
          accessed and used by you under the Terms of Use described below ("Terms of Use").
        </p>
        <p>
          PLEASE READ THESE TERMS OF USE CAREFULLY. BY ACCESSING OR USING THIS SITE, OR UPLOADING ANY CONTENT TO THE SITE, OR VIEWING OR DOWNLOADING ANY CONTENT
          FROM THE SITE, YOU AGREE TO BECOME BOUND BY THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS, THEN YOU MAY NOT ACCESS THE
          SITE. YOUR USE OF THE SITE IS EXPRESSLY CONDITIONED UPON YOUR ASSENT TO ALL OF THESE TERMS AND CONDITIONS, TO THE EXCLUSION OF ALL OTHER TERM.
        </p>
      </li>
      <li>
        <p>MODIFICATIONS OF TERMS OF USE</p>
        <p>
          The Company reserves the right, at its sole discretion, to modify or replace the Terms of Use at any time. You shall be responsible for reviewing and
          becoming familiar with any such modifications. Use of the Site by you following such modifications constitutes your acceptance of the Terms of Use as
          modified.
        </p>
      </li>
      <li>
        <p>USE OF THE SITE</p>
        <p>
          Subject to full compliance with the Terms of Use, you may use the Site to access information (“Content”) and download documents (“Content”), upload
          documents (“Content”) that will be made accessible to the Company. The Company may change, suspend or discontinue the Site including any Content for any
          reason, at any time.
        </p>
        <p>
          The Company has the exclusive right to control the accessibility, hours of use, and the features on the Site. The Company can restrict access to any or
          all portions of the Site or remove any information or Content from the Site at any time. The Company reserves the right to monitor use of the Site.
        </p>
        <p>
          You are solely responsible for providing the equipment related to accessing the site, including all computer equipment, remote communications equipment,
          telephone or other equipment.
        </p>
      </li>
      <li>
        <p>YOUR IDENTIFICATION OBLIGATIONS</p>
        <p>
          As a condition to using the Site, you are required to submit to the Company your name, e-mail address, and telephone number in order to be listed as an
          authorized contact and registered as an authorized user of the Site. This information must be accurate and complete. You may not submit a name, phone
          number, or e-mail address of another person with the intent to impersonate that person. The Company reserves the right to refuse access to the Site in its
          discretion.
        </p>
        <p>
          You must also identify all Content you submit to the Site, by providing a description of the document to be uploaded. This information must be accurate
          and complete and not offensive, vulgar or obscene.
        </p>
      </li>
      <li>
        <p>USER CONDUCT</p>
        <p>
          As a condition of use, you promise not to use the Site for any purpose that is unlawful or prohibited by these Terms of Use, or any other purpose not
          reasonably intended by the Company.
        </p>
        <p>By way of example, and not as a limitation, you agree not to use the Site:</p>
        <ol type='a'>
          <li>
            <p>to abuse, harass, threaten, impersonate or intimidate others;</p>
          </li>
          <li>
            <p>
              to post or store on the Site any Content that violates or infringes the intellectual property rights of others (including but not limited to
              copyrights, trademarks, trade secrets, patents and publicity rights);
            </p>
          </li>
          <li>
            <p>
              to post or transmit, or cause to be posted or transmitted, any Content that is libelous, defamatory, obscene, pornographic, abusive, offensive,
              profane, or otherwise violates any law or right of any third party;
            </p>
          </li>
          <li>
            <p>for any illegal or unauthorized purpose;</p>
          </li>
          <li>
            <p>
              you will not use any robot, spider, scraper or other automated means to access the Site for any purpose without our express written permission.
              Additionally, you agree that you will not: (i) take any action that imposes, or may impose in our sole discretion an unreasonable or
              disproportionately large load on our infrastructure; (ii) interfere or attempt to interfere with the proper working of the Site or any activities
              conducted on the Site; or (iii) bypass any measures we may use to prevent or restrict access to the Site;
            </p>
          </li>
          <li>
            <p>
              to advertise to, or solicit, any user to buy or sell any products or services. It is also a violation of these rules to use any information obtained
              from the Services in order to contact, advertise to, solicit, or sell to any user without their prior explicit consent;
            </p>
          </li>
          <li>
            <p>attempt to impersonate another user or person;</p>
          </li>
          <li>
            <p>
              transmit, install, upload or otherwise transfer any virus or other item or process to the Site that in any way affects the use, enjoyment or service
              of the Site;
            </p>
          </li>
          <li>
            <p>
              you are also prohibited from using the Site after you leave the employment of the company you represent and from which you are authorized to access
              the Site.
            </p>
          </li>
        </ol>
        <p>
          The Company may remove any Content at any time for any reason (including, but not limited to, upon receipt of claims or allegations from third parties or
          authorities relating to such Content), or for no reason at all.
        </p>
      </li>
      <li>
        <p>CONTENT SUBMITTED OR MADE AVAILABLE ON THE SITE</p>
        <p>
          By uploading, submitting or otherwise disclosing or distributing Content for display or inclusion on the Site, you represent and warrant that you own all
          rights in the Content or have authorization from the rights owner to post the Content.
        </p>
        <p>
          All Content is protected by copyright law. If any Content you submit was created by any person other than you, including professional photographers, you
          are not the owner of the copyrights in that Content and must obtain the permission of the copyright owner before uploading the Content to the Site.
        </p>
      </li>
      <li>
        <p>COPYRIGHT COMPLAINTS</p>
        <p>
          The Company respects the intellectual property of others. It is the Company policy to respond expeditiously to claims of copyright and other intellectual
          property infringement. Company will promptly process and investigate notices of alleged infringement and will take appropriate actions under the Digital
          Millennium Copyright Act ("DMCA") and other applicable intellectual property laws. Upon receipt of notices complying with the DMCA, Company may act
          expeditiously to remove or disable access to any material claimed to be infringing or claimed to be the subject of infringing activity and may act
          expeditiously to remove or disable access to any reference or link to material or activity that is claimed to be infringing. Company will not permit
          access for users who are repeat infringers.
        </p>
        <p>
          Notifying Company of Copyright Infringement: To provide Company notice of an infringement, you must provide a written communication to the attention of
          "DMCA Infringement Notification Dept." care of <a href='mailto:itrequests@stratospherequality.com'>itrequests@stratospherequality.com</a> that sets forth
          the information specified by the DMCA (<a href='http://www.copyright.gov/title17/92chap5.html#512' target='_blank' rel='noopener noreferrer'>http://www.copyright.gov/title17/92chap5.html#512</a>).
          Please also note that you may be liable for damages (including costs and attorneys' fees) if you materially misrepresent that an activity is infringing
          your copyright.
        </p>
      </li>
      <li>
        <p>INDEMNITY</p>
        <p>
          You will indemnify and hold harmless the Company, its subsidiaries, affiliates, customers, vendors, officers and employees from any liability, damage or
          cost (including reasonable attorney fees and cost) from any claim or demand made by any third-party due to or arising out of your access to the Site,
          uploading of Content, violation of the Terms of Use by you, or the infringement by you of any intellectual property or other right of any person or
          entity.
        </p>
      </li>
      <li>
        <p>WARRANTY DISCLAIMERS</p>
        <p>
          You release the Company from all liability connected with your submission of Content to the Site or the use of Content accessed from the Site. Company
          makes no representations concerning any Content contained in or accessed through the Site, and Company will not be responsible or liable for the accuracy,
          copyright compliance, legality or decency of any material contained in or accessed through the Site. THE CONTENT AND SITE ARE PROVIDED ON AN "AS IS" AND
          “AS AVAILABLE” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS
          MAY NOT APPLY TO YOU.
        </p>
        <p>
          THIS DISCLAIMER IS APPLICABLE TO ANY DAMAGE OR INJURY RESULTING FROM THE NEGLIGENCE OR OMISSION OF THE COMPANY, A COMPUTER VIRUS OR OTHER SIMILAR ITEM,
          TELECOMMUNICATIONS ERRORS, OR UNAUTHORIZED ACCESS TO OR USE OF USER INFORMATION THROUGH THEFT OR ANY OTHER MEANS. COMPANY IS NOT LIABLE FOR CRIMINAL,
          TORTIOUS, OR NEGLIGENT ACTIONS OR OMISSIONS OF THIRD PARTIES THAT AFFECT THIS SITE.
        </p>
        <p>
          IN NO EVENT WILL THE COMPANY OR ANY OF ITS AFFILIATES, AGENTS, EMPLOYEES, ASSIGNS OR THIRD-PARTY CONTENT PROVIDERS BE HELD LIABLE FOR ANY TORTIOUS OR
          ILLEGAL CONDUCT OF OTHER USERS.
        </p>
        <p>
          IN NO EVENT WILL THE COMPANY OR ANY OF ITS AFFILIATES, AGENTS, EMPLOYEES OR ASSIGNS BE HELD LIABLE FOR ANY DAMAGE TO EQUIPMENT, HARDWARE OR OTHER PROPERTY
          OF USER OR PERSONAL INJURY THAT ARISES IN CONNECTION WITH USE OF THE SITE.
        </p>
      </li>
      <li>
        <p>TERMINATION</p>
        <p>
          The Company may terminate or suspend your access to the Site immediately, without prior notice or liability, if you breach any of these Terms of Use. All
          provisions of the Terms of Use which by their nature should survive termination shall survive the termination of your access to the Site, including,
          without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
        </p>
      </li>
      <li>
        <p>MISCELLANEOUS</p>
        <p>
          No agency, partnership, joint venture, or employment is created as a result of the Terms of Use and you do not have any authority of any kind to bind the
          Company in any respect whatsoever. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of
          any further rights hereunder. The Company shall not be liable for any failure to perform its obligations hereunder where such failure results from any
          cause beyond the Company’s reasonable control, including, without limitation, mechanical, electronic or communications failure or degradation. If any
          provision of the Terms of Use is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so
          that the Terms of Use shall otherwise remain in full force and effect and enforceable. The Terms of Use are not assignable, transferable or sublicensable
          by you except with the Company’s prior written consent.
        </p>
        <p>
          The Company may transfer, assign or delegate the Terms of Use and its rights and obligations without consent. The Terms of Use shall be governed by and
          construed in accordance with the laws of the state of Indiana, as if made within Indiana between two residents thereof, and the parties submit to the
          exclusive jurisdiction of the courts of Hamilton County, Indiana or the United States District Court for the Southern District of Indiana. You agree that
          the Terms of Use is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and
          oral agreements, communications and other understandings relating to the subject matter of the Terms of Use, and that all modifications must be in a
          writing, except as otherwise provided herein.
        </p>
      </li>
    </ol>`,

    stratosphereQualityHome: 'Stratosphere Quality Home',
    sqHome: 'SQ Home',
    stratosphereQualityHomeIntro: `<p>
        Welcome to the new SolarEngage.com starting page for Stratosphere Quality employees. For our initial version,
        the features are limited to:
      </p>
      <ul>
        <li>
          Finding and impersonating business contacts from our customers and worksites to view their projects, reports,
          and data.
        </li>
        <li>Performing project searches and downloading project lists using a wide range of options.</li>
      </ul>`,
    stratosphereQualityHomeImpersonate: `<p>You will now impersonate directly from the menu.</p>
          <p>
            Click <strong>Projects</strong> in the menu for the <strong>Find a Project</strong> search feature.
          </p>`,
    stratosphereQualityHomeFindAProject:
      'You now have many options to use to find a project or list of matching projects.',
    stratosphereQualityHomeProjectSearchResults:
      'You may click on an individual project and say Yes to impersonating based on the project to get to the usual reports and features.',
    stratosphereQualityHomeProjectSearchResultsDownload:
      'Click on the download icon to get the results in an Excel file.',

    // Find a project
    findAProject: 'Find a Project',
    findAProjectOffice: 'Office',
    findAProjectStatus: 'Status',
    findAProjectCustomer: 'Customer',
    findAProjectWorkSite: 'Work Site',
    findAProjectStartFrom: 'Start From',
    findAProjectStartTo: 'Start To',
    findAProjectAccountRep: 'Account Rep',
    findAProjectPartNumber: 'Part Number',
    findAProjectPartDescription: 'Part Description Contains',
    findAProjectCriteria: 'Criteria Contains',
    findAProjectReferenceNumber: 'Reference #',
    findAProjectServiceType: 'Service Type',
    findAProjectFormErrorMessage: 'You must enter at least one value to search.',
    findAProjectSearch: 'Search',
    findAProjectReset: 'Reset',
    findAProjectActive: 'Active',
    findAProjectClosed: 'Closed',
    findAProjectOnHold: 'On Hold',

    // Find a project search results
    projectSearchResultsProjectNumber: 'Project #',
    projectSearchResultsImpersonateProjectPrompt: 'Would you like to impersonate this project?',
    projectSearchResultsYes: 'Yes',
    projectSearchResultsNo: 'No',
    projectSearchResultsOffice: 'Office',
    projectSearchResultsStartDate: 'Start Date',
    projectSearchResultsStatus: 'Status',
    projectSearchResultsCustomer: 'Customer',
    projectSearchResultsWorkSite: 'Work Site',
    projectSearchResultsCustomerReferenceNumber: 'Customer Reference #',
    projectSearchResultsPartNumber: 'Part #',
    projectSearchResultsPartDescription: 'Part Description',
    projectSearchResultsCriteria: 'Criteria',
    projectSearchResults: 'Project Search Results',

    // Page not found
    pageNotFound: 'Page Not Found',
    pageNotFoundContent:
      'If you believe that you are seeing this page in error, please contact our help desk at <a href="mailto:itrequests@stratospherequality.com">itrequests@stratospherequality.com</a> for assistance.',

    // Privacy Policy
    privacyPolicy: 'Privacy Policy',
    privacyPolicyContent: `<p>
        This Privacy Policy explains what types of personal information, also referred to as personal data, Stratosphere Quality, LLC may collect about you. We may collect such personal information about you such as name, email address and company affiliation. With this information we create a user ID for you to use toaccess the site. We collect no other information about you and we do not share the information with any other companies.
       </>`,

    // Unauthorized
    unauthorized: 'Unauthorized Request',
    unauthorizedContent:
      'Current user is not authorized to view the requested information. If you believe that you are seeing this page in error, please contact our help desk at <a href="mailto:itrequests@stratospherequality.com">itrequests@stratospherequality.com</a> for assistance.',

    unexpectedError: 'Unexpected Error',

    signOut: 'Sign out',
    allRightsReserved: 'All rights reserved'
  }
});
