import React, { memo } from 'react';
import { ReportFilters } from 'components/shared';
import { RLeanState } from '@rlean/core';
import { reportNames, formats } from 'components/shared/SQReport';
import { downloadFileType, downloadFile } from 'lib/helpers';

export const DOALAllInOne = memo(() => {
  const [activeProject] = RLeanState().select(({ state }) => [state.activeProject]);

  const handleFiltersSubmit = () => {
    const params = {
      ProjectId: activeProject.id,
      format: formats.EXCELOPENXML,
      reportName: reportNames.DOAL_ALL_IN_ONE
    };

    const options = {
      filename: `Vehicle_Inspection_All_In_One_${activeProject.id}.xlsx`,
      endpoint: '/SsrsReportFiles',
      fileType: downloadFileType.EXCELOPENXML
    };

    downloadFile(options, params);
  };

  return <ReportFilters handleFiltersSubmit={handleFiltersSubmit} handleFiltersClose={() => null} directDownload />;
});
