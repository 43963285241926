import React, { memo } from 'react';
import { Table } from 'antd';

export const InvoiceTable = memo(props => {
  return (
    <Table
      size='middle'
      className='invoice-table hide-on-mobile'
      columns={props.columns}
      dataSource={props.dataSource}
      scroll={{ x: true }}
    />
  );
});
