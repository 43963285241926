import React, { useState } from 'react';
import { hasValue } from '@rlean/utils';
import moment from 'moment';
import { SQReport } from 'components/shared';
import { buildTableColumns } from 'lib/helpers';

const echoDetailsBuilder = row => {
  const echoDetails = {};

  row.forEach(field => (echoDetails[field.fieldId] = field.value));

  return echoDetails;
};

export const UninspectedVINs = () => {
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);

  const buildChart = data => {
    const builtDataSource = hasValue(data, 'rows') ? data.rows.map(row => echoDetailsBuilder(row)) : null;
    const builtColumns = buildTableColumns(data);

    setColumns(builtColumns);
    setDataSource(builtDataSource);
  };

  const reportOptions = {
    reportTitle: 'Uninspected Vehicles',
    urls: ['/UninspectedVins'],
    buildChart
  };

  const tableOptions = {
    titleBarData: {
      tableTitle: 'Details',
      downloadBtn: true,
      settingsBtn: true,
      downloadInfo: {
        reportName: 'Uninspected Vehicles Report',
        fileName: `Uninspected_Vehicles_${moment().format('YYYY-MM-DDTHH:MM:SSZ')}`
      }
    },
    summary: false,
    columns,
    dataSource
  };

  return <SQReport reportOptions={reportOptions} tableOptions={tableOptions} />;
};
