import React, { memo } from 'react';
import { RLeanState } from '@rlean/core';
import { hasValue, getValue } from '@rlean/utils';
import { BaseCard, LOADING_STATUS, BaseList, BaseListItem } from 'components/shared';
import { strings } from 'config';
import moment from 'moment';
import { downloadFile } from 'lib/helpers';
import { navigate } from '@reach/router';

const styles = {
  button: {
    fontSize: '0.75rem',
    fontWeight: '700'
  }
};

export const ProjectInformationFormRevisions = memo(() => {
  const [projectInformationFormRevisions, activeProject] = RLeanState().select(({ state }) => [
    state.projectInformationFormRevisions,
    state.activeProject
  ]);

  const getLoadingStatus = () => {
    return hasValue(activeProject, 'id') ? LOADING_STATUS.LOADED : LOADING_STATUS.NODATA;
  };

  const showDrawer = () => {
    navigate('/project/details/report/project-information-forms');
  };

  const cardData = {
    title: strings.projectDetailsPIFRevisions.cardTitle,
    loadingStatus: getLoadingStatus(),
    description: null
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  const listData = getValue(projectInformationFormRevisions, 'data', []).sort(
    (a, b) => moment(b.requestedAt) - moment(a.requestedAt)
  );

  const downloadPIF = id => {
    const options = {
      endpoint: `/ProjectInformationFormRevisions/${id}`,
      filename: `Project-${activeProject.id}-Information-Form-Revision-${id}`
    };

    downloadFile(options);
  };

  const Title = ({ publisher }) => (
    <>
      <strong>Publisher:</strong> {publisher}
    </>
  );

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      <BaseList
        data={listData}
        launchAction={showDrawer}
        renderItem={item => {
          const { publishedByFullName, publishedAt, revisionNumber, id } = item;
          return (
            <BaseListItem
              actionComponents={[
                <button
                  type='button'
                  style={styles.button}
                  className='action-button link-small'
                  onClick={() => downloadPIF(id)}
                >
                  Download
                </button>
              ]}
              title={<Title publisher={publishedByFullName} />}
              description={`Version ${revisionNumber} | Published ${moment(publishedAt).format('MM/DD/YYYY')}`}
            />
          );
        }}
      />
    </BaseCard>
  );
});
