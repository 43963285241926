import { useEffect, useState } from 'react';
import { RLeanState } from '@rlean/core';
import { hasValue } from '@rlean/utils';
import * as entities from 'lib/entities';

// Types
import { BusinessContact } from 'lib/types';

export const useCreateBusinessContact = () => {
  const [businessContact, setBusinessContact] = useState<BusinessContact | null>();
  const [userDescription] = RLeanState<typeof entities>().select(({ state }) => [state.userDescription]);

  useEffect(() => {
    const createFromUserDescription = () => {
      if (!userDescription.data) {
        setBusinessContact(null);
      }

      if (hasValue(userDescription, 'data') && userDescription.data.objectId && !userDescription.data.isMobileUser) {
        setBusinessContact({
          id: userDescription.data.businessContactId,
          displayName: userDescription.data.displayName,
          companyId: userDescription.data.companyId,
          companyName: userDescription.data.companyName,
          isReadOnly: false
        });
      } else if (hasValue(userDescription, 'data') && userDescription.data.objectId) {
        // setBusinessContact({
        //   id: null,
        //   displayName: null,
        //   companyId: null,
        //   companyName: null,
        //   isReadOnly: null
        // });
      }
    };

    createFromUserDescription();
  }, [userDescription]);

  return businessContact;
};
