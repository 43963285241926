import { define } from '@rlean/core';
import { WorkAuthorization } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

const PendingWorkAuthorization = define<WorkAuthorization[]>(EntityKey.PendingWorkAuthorization, {
  getURL: '/WorkAuthorizations',
  putURL: '/WorkAuthorizations/:id'
});

export default PendingWorkAuthorization;
