import React from 'react';
import { hasValue } from '@rlean/utils';
import { BaseCard, LOADING_STATUS } from 'components/shared';
import { RLeanState } from '@rlean/core';
import { strings } from 'config';
import { Textfit } from 'react-textfit';
import { navigate } from '@reach/router';

// Hooks
import { useProject } from 'lib/hooks/useProject';

import { getInvoicedData } from './details/InvoicedTable';

export const Invoiced = () => {
  const [invoice, activeProject, project, workSite, historicProject] = RLeanState().select(({ state }) => [
    state.invoice,
    state.activeProject,
    state.project,
    state.workSite,
    state.historicProject
  ]);
  const { isSupplier, isImposer } = useProject();

  const { recentInvoiceDate, invoicedTotal, isDisabled } = getInvoicedData({
    invoice,
    activeProject,
    project,
    workSite,
    historicProject,
    isSupplier,
    isImposer
  });

  const getLoadingStatus = () => {
    let loadingStatus = LOADING_STATUS.NODATA;

    if (hasValue(activeProject, 'id') && (!invoice || (hasValue(invoice, 'isLoading') && invoice.isLoading))) {
      loadingStatus = LOADING_STATUS.WAITING;
    } else if (hasValue(activeProject, 'id') && hasValue(invoice, 'isLoading') && !invoice.isLoading && invoicedTotal) {
      loadingStatus = LOADING_STATUS.LOADED;
    }

    return loadingStatus;
  };

  const cardData = {
    title: strings.projectDetailsInvoicedTitle,
    loadingStatus: getLoadingStatus(),
    description: `Total as of ${recentInvoiceDate}`
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  const showDrawer = () => {
    navigate('/project/details/report/invoiced');
  };

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      {invoicedTotal === '--' ? (
        <span className='general-card-total not-invoiced'>
          <Textfit mode='single'>{invoicedTotal}</Textfit>
        </span>
      ) : (
        <button
          type='button'
          className='link-button general-card-total invoiced'
          disabled={isDisabled}
          onClick={showDrawer}
        >
          <Textfit mode='single'>{invoicedTotal}</Textfit>
        </button>
      )}
    </BaseCard>
  );
};
