import React, { memo, useContext } from 'react';
import { RLeanState } from '@rlean/core';
import { getValue } from '@rlean/utils';
import { Row, Col, message } from 'antd';
import { DownloadOutlined, SettingOutlined, ExpandOutlined } from '@ant-design/icons';
import { echoDataType } from 'lib/types';
import { downloadFile, downloadFileType } from 'lib/helpers/downloadFile';
import moment from 'moment';
import { DrilldownContext } from 'components/pages/Projects/Details/reports/CommonDrilldown/DrilldownContext';

function formatTableData(columns, dataSource) {
  const filteredColumnKeys = columns.map(column => column.key);
  const booleanFields = columns.map(column => (column.dataTypeId === echoDataType.BOOLEAN ? column.fieldId : null));

  // Filter out columns not selected via the table settings
  // Selected columns not present in data are skipped
  const visibleData = dataSource.map(row =>
    Object.keys(row)
      .filter(fieldId => filteredColumnKeys.includes(fieldId))
      .reduce(
        (obj, key) => ({
          ...obj,
          [key]: row[key]
        }),
        {}
      )
  );

  // Add selected columns not present in data
  visibleData.forEach((row, idx, thisArr) => {
    filteredColumnKeys.forEach(column => {
      if (!(column in row)) {
        thisArr[idx][column] = null;
      }
    });
  });

  const formattedColumns = columns.map(column => {
    const { title, dataIndex, elementTypeId, dataTypeId } = column;
    // Unknown elementTypeId can be marked as type 0, but with an unknown dataTypeId we default to 1 (type: string)
    return { label: title, fieldId: dataIndex, elementTypeId: elementTypeId ?? 0, dataTypeId: dataTypeId ?? 1 };
  });

  const formattedRows = visibleData.map(row => {
    const builtRow = Object.entries(row).map(([key, value]) =>
      booleanFields.includes(key)
        ? { fieldId: key, value: value ? 'Yes' : 'No' }
        : { fieldId: key, value: value ? value.toString() : '' }
    );

    return builtRow;
  });

  return {
    fieldDefinitions: formattedColumns,
    rows: formattedRows
  };
}

// TODO: This function should not be exported from the TitleBar component
export const exportToExcel = async (filename, columns, dataSource, requiredEndpointData, image = null) => {
  if (!columns || !dataSource) {
    message.destroy();
    message.error('No data to export', 3);
    return;
  }

  const columnsToExport = columns.filter(column => column.dataTypeId !== echoDataType.PICTURE); // Quick fix until the api gives us an isExportable flag
  const { fieldDefinitions, rows } = formatTableData(columnsToExport, dataSource);

  const fetchBody = {
    ...requiredEndpointData,
    fieldDefinitions,
    rows
  };

  if (image) {
    fetchBody['image'] = image;
  }

  const options = {
    endpoint: '/ExcelReports',
    fileType: downloadFileType.EXCELOPENXML,
    filename
  };

  // TODO: Use fileName from Content-Disposition in response
  downloadFile(options, fetchBody);
};

const TitleBar = ({
  titleBarData = {
    extraTableButtons: null,
    downloadInfo: null,
    tableTitle: '',
    downloadBtn: false,
    fullscreenBtn: false,
    settingsBtn: false
  },
  showSettings,
  filteredColumns,
  currentFilteredTableData,
  filtersIndicators = null
}) => {
  const { extraTableButtons, downloadInfo, tableTitle, downloadBtn, fullscreenBtn, settingsBtn } = titleBarData;
  const [activeProject, businessContact, userDescription] = RLeanState().select(({ state }) => [
    state.activeProject,
    state.businessContact,
    state.userDescription
  ]);
  const echoInspectionCtx = useContext(DrilldownContext) ?? undefined;

  const userDescriptionDisplayName = getValue(userDescription, 'data.displayName', '');

  const handleFullScreen = e => {
    // TODO: add generic fullscreen feature
  };

  // TODO: reportName and file name should be props
  const handleDownload = async () => {
    const requiredEndpointData = {
      projectId: getValue(activeProject, 'id', 0),
      runByName: getValue(businessContact, 'data.displayName', userDescriptionDisplayName),
      reportName: downloadInfo.reportName,
      runTime: moment().format('YYYY-MM-DD LT')
    };

    let image = null;
    if (echoInspectionCtx?.getExportedChartInPNG?.current) {
      image = await echoInspectionCtx.getExportedChartInPNG.current();
    }

    exportToExcel(downloadInfo.fileName, filteredColumns, currentFilteredTableData, requiredEndpointData, image);
  };

  return (
    <>
      <Row type='flex' justify='space-between' style={{ marginBottom: 10 }}>
        <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
          <h1>{tableTitle}</h1>
        </Col>
        <Col span={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          {
            <>
              {extraTableButtons}
              {downloadBtn && (
                <button
                  className='link-button'
                  style={{ fontSize: '24px', margin: '5px' }}
                  type='button'
                  onClick={handleDownload}
                >
                  <DownloadOutlined title='Download Excel' />
                </button>
              )}
              {fullscreenBtn && (
                <button
                  className='link-button'
                  style={{ fontSize: '24px', margin: '5px' }}
                  type='button'
                  onClick={handleFullScreen}
                >
                  <ExpandOutlined title='Fullscreen View' />
                </button>
              )}
              {settingsBtn && (
                <button
                  className='link-button'
                  style={{ fontSize: '24px', margin: '5px' }}
                  type='button'
                  onClick={showSettings}
                >
                  <SettingOutlined title='Settings' />
                </button>
              )}
            </>
          }
        </Col>
      </Row>
      {filtersIndicators ? <div className='table-tags-container'>{filtersIndicators}</div> : null}
    </>
  );
};

export default memo(TitleBar);
