import { define } from '@rlean/core';
import { WorkInstructionRevision } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

const PendingWorkInstruction = define<WorkInstructionRevision[]>(EntityKey.PendingWorkInstruction, {
  getURL: '/WorkInstructionRevisionReviews',
  putURL: '/WorkInstructionRevisionReviews/:id'
});

export default PendingWorkInstruction;
