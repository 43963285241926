import React, { memo, useState, useEffect } from 'react';
import { BaseCard, LOADING_STATUS, BaseList, BaseListItem } from 'components/shared';
import { RLeanState, useSave, usePut } from '@rlean/core';
import { hasValue, deepCopy } from '@rlean/utils';
import { EntityKey } from 'lib/enums/EntityKey';
import { Form, Input, Button, Popover, message } from 'antd';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { strings } from 'config/strings';

const RejectForm = ({ hide, id }) => {
  const [form] = Form.useForm();
  const [businessContact, pendingWorkAuthorization] = RLeanState().select(({ state }) => [
    state.businessContact,
    state.pendingWorkAuthorization
  ]);
  const put = usePut();
  const save = useSave();

  const handleFinish = values => {
    message.loading('Rejecting Work Authorization...');
    put(
      {
        key: EntityKey.PendingWorkAuthorization,
        params: { id },
        body: {
          approved: false,
          declinedExplanation: values.rejectMessage,
          declinedReason: '',
          secondaryRecipients: '',
          businessContactId: businessContact.data.id,
          id
        }
      },
      () => {
        form.resetFields();
        message.destroy();

        if (hasValue(pendingWorkAuthorization, 'data')) {
          const pendingWorkAuthorizations = pendingWorkAuthorization.data.filter(
            authorization => authorization.id !== id
          );
          const updatedPendingWorkAuthorizations = deepCopy(pendingWorkAuthorizations);

          updatedPendingWorkAuthorizations.data = pendingWorkAuthorizations;

          save({ key: EntityKey.PendingWorkAuthorization, value: updatedPendingWorkAuthorizations });
          message.success('Work Authorization Rejected', 3);
        }

        hide();
      }
    );
  };

  return (
    <Form onFinish={handleFinish} name='reject_form' form={form} style={{ display: 'flex', flexDirection: 'column' }}>
      <Form.Item
        style={{ marginBottom: '0px' }}
        name='rejectMessage'
        rules={[{ required: true, message: 'required text' }]}
      >
        <Input.TextArea
          type='text'
          rows='4'
          placeholder={strings.workAuthorizationPopUpPlaceholder}
          style={{ width: '250px' }}
        />
      </Form.Item>
      <Form.Item style={{ alignSelf: 'center', marginBottom: '0px' }}>
        <Button type='primary' htmlType='submit'>
          {strings.workAuthorizationRejectButton}
        </Button>
      </Form.Item>
    </Form>
  );
};

const RejectButton = ({ id }) => {
  const [popVisible, setPopVisible] = useState(false);
  const hide = () => {
    setPopVisible(false);
  };

  const handleVisibleChange = visible => setPopVisible(visible);

  return (
    <Popover
      title={strings.workAuthorizationPopUpTitle}
      trigger='click'
      content={<RejectForm hide={hide} id={id} />}
      visible={popVisible}
      onVisibleChange={handleVisibleChange}
    >
      <button type='button' className='action-button'>
        <CloseCircleTwoTone twoToneColor='#dd6675' title='Reject Work Authorization' />
      </button>
    </Popover>
  );
};

const AcceptButton = ({ id }) => {
  const put = usePut();
  const save = useSave();
  const [businessContact, pendingWorkAuthorization] = RLeanState().select(({ state }) => [
    state.businessContact,
    state.pendingWorkAuthorization
  ]);

  const handleClick = () => {
    message.loading('Accepting Work Authorization...');
    put(
      {
        key: EntityKey.PendingWorkAuthorization,
        params: { id },
        body: {
          approved: true,
          declinedExplanation: '',
          declinedReason: '',
          secondaryRecipients: '',
          businessContactId: businessContact.data.id,
          id
        }
      },
      response => {
        if (response.status === 400 || response.status === 500) {
          message.destroy();
          message.error('There was an error approving the work authorization.');
        } else {
          message.destroy();

          if (hasValue(pendingWorkAuthorization, 'data')) {
            const pendingWorkAuthorizations = pendingWorkAuthorization.data.filter(
              authorization => authorization.id !== id
            );
            const updatedPendingWorkAuthorizations = deepCopy(pendingWorkAuthorizations);

            updatedPendingWorkAuthorizations.data = pendingWorkAuthorizations;

            save({ key: EntityKey.PendingWorkAuthorization, value: updatedPendingWorkAuthorizations });

            message.success('Work Authorization Accepted', 3);
          }
        }
      }
    );
  };

  return (
    <button type='button' className='action-button' onClick={() => handleClick(id)}>
      <CheckCircleTwoTone twoToneColor='#b2bb1c' title='Accept Work Authorization' />
    </button>
  );
};

export const PendingWorkAuthorizations = memo(() => {
  const [pendingWorkAuthorization, businessContact] = RLeanState().select(({ state }) => [
    state.pendingWorkAuthorization,
    state.businessContact
  ]);
  const [listData, setListData] = useState();
  const [loadingStatus, setLoadingStatus] = useState();
  const [controlsVisible, setControlsVisible] = useState(false);

  const cardData = {
    title: 'Pending Work Authorizations',
    loadingStatus,
    description: ''
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  useEffect(() => {
    let isSubscribed = true;

    if (hasValue(businessContact, 'data.isReadOnly')) {
      if (isSubscribed) {
        setControlsVisible(!businessContact.data.isReadOnly);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [businessContact]);

  useEffect(() => {
    let isSubscribed = true;

    if (!pendingWorkAuthorization) {
      if (isSubscribed) {
        setListData([]);
      }
    }

    if (hasValue(pendingWorkAuthorization, 'data')) {
      if (isSubscribed) {
        setListData(pendingWorkAuthorization.data);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [pendingWorkAuthorization]);

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      if (!pendingWorkAuthorization) {
        setLoadingStatus(LOADING_STATUS.NODATA);
      }

      if (
        !pendingWorkAuthorization ||
        (hasValue(pendingWorkAuthorization, 'isLoading') && pendingWorkAuthorization.isLoading)
      ) {
        setLoadingStatus(LOADING_STATUS.WAITING);
      } else if ((hasValue(pendingWorkAuthorization, 'isLoading') && !pendingWorkAuthorization.isLoading) || listData) {
        setLoadingStatus(LOADING_STATUS.LOADED);
      } else {
        setLoadingStatus(LOADING_STATUS.NODATA);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [pendingWorkAuthorization, listData]);

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      <BaseList
        itemsPerPage={5}
        data={listData}
        layout='horizontal'
        renderItem={(item, index) => {
          const { id, projectId } = item;
          const actions = controlsVisible ? [<RejectButton id={id} />, <AcceptButton id={id} />] : <></>;

          return (
            <BaseListItem
              className='pending-item'
              title={projectId}
              style={index % 2 !== 0 ? { backgroundColor: '#fafafa' } : null}
              actionComponents={actions}
            />
          );
        }}
      />
    </BaseCard>
  );
});
