import React, { memo, useEffect } from 'react';
import { Card, List } from 'antd';
import { useSave, usePost, RLeanState } from '@rlean/core';
import { getValue, hasValue, deepCopy } from '@rlean/utils';
import { EntityKey } from 'lib/enums/EntityKey';
import { useActivePage } from 'lib/hooks';
import { strings, pages } from 'config';

export const WhatsNew = memo(() => {
  const save = useSave();
  const post = usePost();
  const [userConfiguration, businessContact] = RLeanState().select(({ state }) => [
    state.userConfiguration,
    state.businessContact
  ]);
  const businessContactId = getValue(businessContact, 'data.id', null);

  useActivePage(pages.whatsNew);

  /**
   * If it's the first time a user has visited the page, set the
   * whatsNewStatus user configuration so that the notification
   * can be suppressed.
   */

  useEffect(() => {
    if (hasValue(userConfiguration, 'data')) {
      const savedWhatsNewStatus = userConfiguration.data.find(x => x.name === 'whatsNewStatus');

      if (!savedWhatsNewStatus) {
        const updatedUserConfiguration = deepCopy(userConfiguration);
        const userConfigArray = updatedUserConfiguration.data.filter(config => config.name !== 'whatsNewStatus');
        const updatedWhatsNewConfig = updatedUserConfiguration.data.find(
          config => config.name === 'whatsNewStatus'
        ) ?? {
          businessContactId,
          name: 'whatsNewStatus',
          inactiveInd: false
        };

        const configurationSettings = updatedWhatsNewConfig.configurationSettings
          ? JSON.parse(updatedWhatsNewConfig.configurationSettings)
          : { status: null };

        configurationSettings.status = 'dismissed';
        updatedWhatsNewConfig.configurationSettings = JSON.stringify(configurationSettings);

        userConfigArray.push(updatedWhatsNewConfig);

        updatedUserConfiguration.data = userConfigArray;

        save({ key: EntityKey.UserConfiguration, value: updatedUserConfiguration });

        if (!businessContact.data.isReadOnly) {
          const body = {
            businessContactId,
            name: 'whatsNewStatus',
            configurationSettings: updatedWhatsNewConfig.configurationSettings,
            inactiveInd: false
          };

          post({
            key: EntityKey.UserConfiguration,
            body
          });
        }
      }
    }
  }, []);

  const data = [
    {
      title: '1/31/2021',
      bulletedList: (
        <ul>
          <li>
            Concerns feature
            <ul>
              <li>Submit a concern to be reviewed and addressed.</li>
            </ul>
          </li>
          <li>
            Good metrics
            <ul>
              <li>Added the Good parts metric to the project table and project summary card.</li>
            </ul>
          </li>
          <li>
            Landing page
            <ul>
              <li>
                Added a landing page to provide the ability to contact customer service to request an Engage account.
              </li>
            </ul>
          </li>
          <li>
            Display Tabular Work Instruction Revision details
            <ul>
              <li>View or download details for each work instruction revision.</li>
            </ul>
          </li>
          <li>
            Added selected parameters to exported reports
            <ul>
              <li>Exported reports now display the parameters used to generate the data so it is clear when shared.</li>
            </ul>
          </li>
          <li>Added project service type as an optional project grid column</li>
        </ul>
      ),
      pdf: null
    },
    {
      title: '11/15/2020',
      bulletedList: (
        <ul>
          <li>
            Application Performance
            <ul>
              <li>New application framework to increase site performance and improve user experience</li>
            </ul>
          </li>
          <li>
            Company Dashboard
            <ul>
              <li>
                We have removed the site selector and now group all projects your company is paying for in the{' '}
                <i>My Projects</i> card and all supplier paid work in the <i>Supplier Project</i> card.
              </li>
              <li>
                The SOLAR engage dashboard now Includes active projects and projects closed within the last 90 days, up
                from 30 days.
              </li>
            </ul>
          </li>
          <li>
            Project List Grid
            <ul>
              <li>Added sort metrics for the most recent inspection date per project.</li>
              <li>Added clean point detail as an optional column.</li>
              <li>User can add or remove project attribute columns to fit their needs.</li>
              <li>
                Full screen mode
                <ul>
                  <li>
                    Pin project information to a screen and turn on the Auto-update feature to view project data changes
                    as they occur.
                    <ul>
                      <li>Table view</li>
                      <li>Card view</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Project Summaries view
            <ul>
              <li>View a project snapshot for each project.</li>
            </ul>
          </li>
          <li>
            Reports
            <ul>
              <li>One-click Reporting</li>
              <li>Ability to change parameters</li>
            </ul>
          </li>
          <li>
            Invoices
            <ul>
              <li>Invoice balance</li>
            </ul>
          </li>
        </ul>
      ),
      pdf: null
    }
  ];

  return (
    <Card className='page-card' title={strings.whatsNew}>
      <List
        dataSource={data}
        renderItem={item => (
          <div className='release-note ant-list-item'>
            <div>
              <h3>{item.title}</h3>
              <div>{item.bulletedList}</div>
              {item.pdf ? (
                <a href={`/release_notes/${item.pdf}`} target='_blank' rel='noopener noreferrer'>
                  View Details
                </a>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      />
    </Card>
  );
});
