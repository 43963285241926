import React, { memo } from 'react';
import { Row, Col, Table } from 'antd';
import { KabobMenu } from 'components/shared';

function ReportTable({
  dataSource,
  columns,
  menu,
  quickActions = null,
  title,
  pagination = true,
  style,
  onChange,
  isDrawer = false
}) {
  return (
    <div className='report-table' style={style}>
      <Row style={{ marginBottom: 10, whiteSpace: 'nowrap' }}>
        <Col span={16}>
          <div style={{ display: 'flex' }}>
            <h1 style={{ marginRight: 20 }}>{title}</h1> {quickActions}
          </div>
        </Col>
        {menu && (
          <Col span={8} style={{ textAlign: 'right' }}>
            <KabobMenu overlay={menu} isDrawer={isDrawer} />
          </Col>
        )}
      </Row>
      <Table
        dataSource={dataSource ?? []}
        columns={columns}
        scroll={{ x: true }}
        pagination={pagination}
        onChange={onChange}
      />
    </div>
  );
}

export default memo(ReportTable);
