import React, { memo } from 'react';
import { Popconfirm } from 'antd';
import { useImpersonateProject } from 'lib/hooks';
import { strings } from 'config';

const SQTableImpersonate = ({ projectId }) => {
  const [searchProject] = useImpersonateProject();

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <Popconfirm
        placement='right'
        title={strings.projectSearchResultsImpersonateProjectPrompt}
        onConfirm={() => searchProject(projectId)}
        okText={strings.projectSearchResultsYes}
        cancelText={strings.projectSearchResultsNo}
      >
        <button className='link-button link-small' type='button'>
          {projectId}
        </button>
      </Popconfirm>
    </div>
  );
};

export default memo(SQTableImpersonate);
