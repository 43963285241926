import { define } from '@rlean/core';
import { tableColumnDefaultConfig } from 'components/pages/Projects/getProjectTableColumns';
import { ProjectTableColumn } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

// TODO: rename to ProjectTableColumn
const ProjectTableColumns = define<ProjectTableColumn>(EntityKey.ProjectTableColumns, {
  initialState: tableColumnDefaultConfig
});

export default ProjectTableColumns;
