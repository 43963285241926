import React from 'react';
import { uuid } from '@rlean/utils';
import { strings } from 'config';
import {
  MyProjects,
  SupplierProjects,
  MyInvoicing,
  PendingWorkAuthorizations,
  PendingWorkInstructions,
  FavoriteProjects,
  ActiveProjects,
  HighNonconforming,
  ZeroDefects,
  SiteReports
} from 'components/pages/Dashboard/cards';
import {
  Authorization,
  Directory,
  Documents,
  HoursNotYetInvoiced,
  Invoiced,
  Parts,
  Summary,
  WorkInstructions,
  Reports,
  ProjectInformationFormRevisions,
  Audits
} from 'components/pages/Projects/Details/cards';

const cardMap = {
  MyProjects: { component: <MyProjects />, key: uuid(), text: 'My Projects', card: 'MyProjects' },
  SupplierProjects: {
    component: <SupplierProjects />,
    key: uuid(),
    text: 'Supplier Projects',
    card: 'SupplierProjects'
  },
  MyInvoiced: { component: <MyInvoicing />, key: uuid(), text: 'My Invoicing', card: 'MyInvoiced' },
  PendingWorkInstructions: {
    component: <PendingWorkInstructions />,
    key: uuid(),
    text: 'Pending Work Instructions',
    card: 'PendingWorkInstructions'
  },
  PendingWorkAuthorizations: {
    component: <PendingWorkAuthorizations />,
    key: uuid(),
    text: 'Pending Work Authorizations',
    card: 'PendingWorkAuthorizations'
  },
  FavoriteProjects: {
    component: <FavoriteProjects />,
    key: uuid(),
    text: 'Favorite Projects',
    card: 'FavoriteProjects'
  },
  ActiveProjects: { component: <ActiveProjects />, key: uuid(), text: 'Active Projects', card: 'ActiveProjects' },
  HighNonconforming: {
    component: <HighNonconforming />,
    key: uuid(),
    text: 'High Nonconforming',
    card: 'HighNonconforming'
  },
  ZeroDefects: { component: <ZeroDefects />, key: uuid(), text: 'Zero Defects', card: 'ZeroDefects' },
  StatusSummary: {
    component: <Summary />,
    key: uuid(),
    text: strings.projectDetailsSummaryTitle,
    card: 'StatusSummary'
  },
  Parts: { component: <Parts />, key: uuid(), text: strings.projectDetailsPartsTitle, card: 'Parts' },
  Reports: { component: <Reports />, key: uuid(), text: strings.projectDetailsReportsTitle, card: 'Reports' },
  Documents: { component: <Documents />, key: uuid(), text: strings.projectDetailsDocumentTitle, card: 'Documents' },
  Audits: { component: <Audits />, key: uuid(), text: strings.projectDetailsAudits.cardTitle, card: 'Audits' },
  UnbilledHours: {
    component: <HoursNotYetInvoiced />,
    key: uuid(),
    text: strings.projectDetailsDocumentHoursNotInvoicedTitle,
    card: 'UnbilledHours'
  },
  Invoiced: { component: <Invoiced />, key: uuid(), text: strings.projectDetailsInvoicedTitle, card: 'Invoiced' },
  WorkInstructions: {
    component: <WorkInstructions />,
    key: uuid(),
    text: strings.projectDetailsWorkInstructionsTitle,
    card: 'WorkInstructions'
  },
  PIFRevisions: {
    component: <ProjectInformationFormRevisions />,
    key: uuid(),
    text: strings.projectDetailsPIFRevisions.cardTitle,
    card: 'PIFRevisions'
  },
  Authorization: {
    component: <Authorization />,
    key: uuid(),
    text: strings.projectDetailsAuthorizationTitle,
    card: 'Authorization'
  },
  Directory: { component: <Directory />, key: uuid(), text: strings.projectDetailsDirectoryTitle, card: 'Directory' },
  SiteReports: { component: <SiteReports />, key: uuid(), text: 'Site Reports', card: 'SiteReports' }
};

export const cards = cardOrder => {
  if (!cardOrder) {
    return null;
  }

  return cardOrder.map(item => {
    return cardMap[item.card];
  });
};
