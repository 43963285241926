import React, { useEffect } from 'react';
import { RLeanState } from '@rlean/core';
import { navigate, useParams } from '@reach/router';
import { FullWidthDrawer } from 'components/shared';
import { DefectHistory } from './reports';

const ReportQuickView = () => {
  const [activeProject] = RLeanState().select(({ state }) => [state.activeProject]);
  const { reportName } = useParams();

  const goBack = () => {
    navigate(-1);
  };

  const reports = {
    defectHistory: {
      component: <DefectHistory />
    }
  };

  if (!Object.keys(reports).includes(reportName)) {
    goBack();
  }

  useEffect(() => {
    if (!activeProject) {
      goBack();
    }
  }, []);

  return activeProject ? (
    <FullWidthDrawer visible={true} onClose={goBack} placement='right' innerComponent={reports[reportName].component} />
  ) : null;
};

export default ReportQuickView;
