import React, { memo, useEffect, useState } from 'react';
import { DatePicker, Menu, Skeleton } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getValue } from '@rlean/utils';
import { apiUri, msalApiFetch, strings } from 'config';
import moment from 'moment';
import { useCurrentProject } from 'lib/hooks';
import { downloadFile, exportToCsv, formatDate } from 'lib/helpers';
import { ReportTable } from 'components/shared';
import MaterialIcon from '@material/react-material-icon';
import { uuid } from '@rlean/utils';

const formatCardinal = number => {
  const cardinal = ['st', 'nd', 'rd'];
  const cardinalIndex = number - 1;

  return number + cardinal[cardinalIndex];
};

export const AuditsTable = memo(() => {
  const currentProject = useCurrentProject();
  const projectId = getValue(currentProject, 'projectId');

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateFilters, setDateFilters] = useState({
    startDate: moment()
      .subtract(3, 'days')
      .startOf('day'),
    endDate: moment().endOf('day')
  });

  const reportOptions = {
    urls: ['/ProjectInProcessAuditResponses'],
    filters: {
      startDate: moment(dateFilters.startDate).format('YYYY-MM-DDTHH:mm'),
      endDate: moment(dateFilters.endDate).format('YYYY-MM-DDTHH:mm')
    }
  };

  useEffect(() => {
    setLoading(true);
    if (projectId) {
      const query =
        `?projectId=${projectId}` +
        `&startDate=${reportOptions.filters.startDate}&endDate=${reportOptions.filters.endDate}`;
      msalApiFetch(fetch, apiUri('/ProjectInProcessAuditResponses') + query).then(async resp => {
        const res = await resp.json();
        setData(
          res.sort((a, b) => {
            if (moment(a.shiftDate).diff(moment(b.shiftDate)) === 0) {
              return a.shift - b.shift; // sort by shift if shift date is the same ascending
            } else {
              return moment(b.shiftDate).diff(moment(a.shiftDate)); // sort by shift date descending
            }
          })
        );
        setLoading(false);
      });
    }
  }, [projectId, dateFilters]);

  const downloadHandler = id => {
    const options = {
      endpoint: `/ProjectInProcessAuditResponses/${id}`,
      filename: `Project_${projectId}_IPA_${formatDate(new Date())}-${id}`
    };

    downloadFile(options);
  };

  const columns = [
    {
      title: 'Employee Audited',
      dataIndex: 'auditedEmployee',
      key: 'auditedEmployee',
      render: text => <b>{text}</b>,
      sorter: (a, b) => {
        if (a.auditedEmployee) {
          return b.auditedEmployee ? a.auditedEmployee.localeCompare(b.auditedEmployee) : -1;
        }
        if (b.auditedEmployee) {
          return a.auditedEmployee ? b.auditedEmployee.localeCompare(a.auditedEmployee) : 1;
        }
        return 0;
      }
    },
    {
      title: 'Auditor',
      dataIndex: 'auditor',
      key: 'auditor',
      sorter: (a, b) => {
        if (a.auditor) {
          return b.auditor ? a.auditor.localeCompare(b.auditor) : -1;
        }
        if (b.auditor) {
          return a.auditor ? b.auditor.localeCompare(a.auditor) : 1;
        }
        return 0;
      }
    },
    {
      title: 'Shift',
      dataIndex: 'shift',
      key: 'shift',
      render: shift => formatCardinal(shift) + ' shift',
      sorter: (a, b) => {
        return b.shift - a.shift;
      }
    },
    {
      title: 'Shift Date',
      dataIndex: 'shiftDate',
      key: 'shiftDate',
      render: shiftDate => moment(shiftDate).format('MM/DD/YYYY'),
      sorter: (a, b) => {
        return moment(b.shiftDate).diff(moment(a.shiftDate));
      }
    },
    {
      title: 'Completion Date',
      dataIndex: 'completionTime',
      key: 'completionTime',
      render: text => (text ? moment(text).format('MM/DD/YYYY') : '--')
    },
    {
      title: 'Conforms',
      dataIndex: 'conforms',
      key: 'conforms',
      render: conforms => (conforms ? 'Yes' : 'No')
    },
    {
      title: 'Download',
      render: (_, record) => (
        <MaterialIcon
          onClick={() => downloadHandler(record.id)}
          role='button'
          className='action'
          icon={<DownloadOutlined />}
        />
      )
    }
  ];

  const handleExport = () => {
    exportToCsv(`audit-reports-${projectId}`, columns, data);
  };

  const menu = (
    <Menu>
      <Menu.Item key={uuid()} onClick={handleExport} disabled={!data}>
        {strings.projectDetailsDocumentExport}
      </Menu.Item>
    </Menu>
  );

  const filters = (
    <DatePicker.RangePicker
      value={[dateFilters.startDate, dateFilters.endDate]}
      size='small'
      onChange={dates => {
        setDateFilters({
          startDate: moment(dates[0]).startOf('day'),
          endDate: moment(dates[1]).endOf('day')
        });
      }}
    />
  );

  if (loading || !currentProject) {
    return <Skeleton />;
  }

  return (
    <ReportTable
      title={strings.projectDetailsAudits.reportTitle}
      menu={menu}
      quickActions={filters}
      dataSource={data}
      columns={columns}
      isDrawer={true}
    />
  );
});
