import { useState } from 'react';
import { useGet, useSave } from '@rlean/core';
import { getValue, hasValue } from '@rlean/utils';
import { navigate } from '@reach/router';
import { EntityKey } from 'lib/enums/EntityKey';

export const useImpersonateProject = () => {
  const save = useSave();
  const get = useGet();

  const [isSearchingProject, setIsSearchingProject] = useState(false);
  const [showNoProjectError, setShowNoProjectError] = useState(false);

  const searchProject = value => {
    const projectId = +value;

    setIsSearchingProject(true);

    get({ key: EntityKey.ImpersonatedProject, params: { projectId } }, res => {
      const selectedProject = res;

      if (
        !selectedProject ||
        selectedProject.data.length === 0 ||
        (hasValue(selectedProject, 'data.inactiveInd') && selectedProject.data.inactiveInd)
      ) {
        setShowNoProjectError(true);
        setIsSearchingProject(false);
      } else {
        save({ key: EntityKey.Impersonation, value: { isImpersonating: true, isReadOnly: true, init: true } });

        const projectCompanyId = getValue(selectedProject, 'data.customerCompanyId', null);

        get({ key: EntityKey.BusinessContactCompany, params: { id: projectCompanyId } }, companyRes => {
          const selectedCompany = getValue(companyRes, 'data', {});

          get({ key: EntityKey.BusinessContactUserByCompany, params: { companyId: selectedCompany.id } }, userRes => {
            if (userRes.data) {
              const selectedBusinessContact = userRes.data.find(x => +x.companyId === +selectedCompany.id);

              if (selectedBusinessContact) {
                get({
                  key: EntityKey.HistoricProject,
                  params: { projectId, businessContactId: selectedBusinessContact.id }
                });
                save(
                  {
                    key: EntityKey.BusinessContact,
                    value: {
                      data: {
                        id: selectedBusinessContact.id,
                        displayName: `${selectedBusinessContact.firstName} ${selectedBusinessContact.lastName}`,
                        companyId: selectedBusinessContact.companyId,
                        companyName: selectedCompany.name,
                        isReadOnly: true
                      }
                    }
                  },
                  () => {
                    save({ key: EntityKey.ActiveProject, value: { id: selectedProject.data.id } }, () => {
                      navigate('/project/details');
                    });
                  }
                );
              }
            }
          });
        });
      }
    });
  };

  return [searchProject, isSearchingProject, showNoProjectError];
};
