/**
 * Functions and maps for the status of the concerns
 */

// Get the color that will be used to highlight the status of the concern
export const getTypeOfTag = statusId => {
  switch (statusId) {
    case CONCERN_STATUS.SUBMITTED:
    case CONCERN_STATUS.WAITING_ON_SQ:
      return 'blue';
    case CONCERN_STATUS.WAITING_ON_CUSTOMER:
      return 'orange';
    case CONCERN_STATUS.CLOSED:
      return 'magenta';
    case CONCERN_STATUS.ACCEPTED:
      return 'green';
    case CONCERN_STATUS.REJECTED:
      return 'volcano';
  }
};

export const CONCERN_STATUS = {
  SUBMITTED: 1,
  WAITING_ON_SQ: 2,
  WAITING_ON_CUSTOMER: 3,
  CLOSED: 4,
  ACCEPTED: 5,
  REJECTED: 6
};

export const CONCERN_POST_STATUS = {
  IDLE: 'IDLE',
  SENDING: 'SENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};
