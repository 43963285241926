import React, { memo, useState } from 'react';
import { Row, Col, message } from 'antd';
import { usePost, useSave, RLeanState } from '@rlean/core';
import { deepCopy, getValue } from '@rlean/utils';
import { cards } from 'config';
import { EntityKey } from 'lib/enums/EntityKey';
import { EditGrid } from 'components/shared/EditGrid';
import { CardGrid } from 'components/shared/CardGrid';
import arrayMove from 'array-move';
import { projectCards, dashboardCards, projectVersion, dashboardVersion } from 'config/user-configuration';

export const BaseGridPage = memo(
  ({ savedCards, DashboardLeftControl, DashboardRightControl, projectId = null, configKey }) => {
    // Get all the avialable cards for the grid and convert it to its components
    const allAvailableCards =
      configKey === 'dashboard'
        ? cards(dashboardCards.configurationSettings.order)
        : cards(projectCards.configurationSettings.order);

    // Get all the components for the savedCards of the user
    const baseSavedCards = savedCards.reduce((prevValue, item) => {
      const card = allAvailableCards.find(component => item.card === component.card);
      if (card) {
        return [...prevValue, card];
      }

      return prevValue;
    }, []);

    // These are the cards that are always visible in the screen when their order
    // is not being edited
    const [shownCards, setShownCards] = useState(baseSavedCards);

    // These are the cards that are displayed when the order is edited
    const [sortingCards, setSortingCards] = useState(baseSavedCards);

    // This is used as a backup state for the shown cards
    const [visibilityCards, setVisibilityCards] = useState([]);

    // Edit state to order the cards
    const [editMode, setEditMode] = useState(false);

    const [businessContact, userConfiguration] = RLeanState().select(({ state }) => [
      state.businessContact,
      state.userConfiguration
    ]);
    const post = usePost();
    const save = useSave();

    // Backup/restore the shown and sorting cards into/from the visibilityCards state
    // when the visibility editor is opened or closed
    const changeVisibilityEditStatus = (isEditing, isSaving = false) => {
      if (isEditing) {
        setVisibilityCards(shownCards);
      } else {
        if (!isSaving) {
          setShownCards(visibilityCards);
          setSortingCards(visibilityCards);
        }
        setVisibilityCards([]);
      }
    };

    // Add a card to the shownCards and the sortingCard, depending if it is from the
    // card order editor or the case visibility editor
    const addCard = (keyToAdd, isChangingVisibility = false) => {
      const newCardIdx = allAvailableCards.findIndex(card => card.key === keyToAdd);
      if (newCardIdx !== -1) {
        const newList = deepCopy(sortingCards);
        newList.splice(newCardIdx, 0, allAvailableCards[newCardIdx]);

        if (isChangingVisibility) {
          setShownCards(newList);
        }
        setSortingCards(newList);
      }
    };

    // Remove a card from the shownCards and the sortingCard, depending if it is from the
    // card order editor or the case visibility editor
    const removeCard = (keyToRemove, isChangingVisibility = false) => {
      const filteredList = sortingCards.filter(value => value.key !== keyToRemove);
      if (isChangingVisibility) {
        setShownCards(filteredList);
      }
      setSortingCards(filteredList);
    };

    // Should load the current config version of cards,
    // save changes to state, then post the updated config object
    const resetConfig = () => {
      const updatedUserConfiguration = deepCopy(userConfiguration);
      const userConfigArray = getValue(updatedUserConfiguration, 'data', []).filter(
        config => config.name !== configKey
      );
      const updatedCardConfig = getValue(updatedUserConfiguration, 'data', []).find(
        config => config.name === configKey
      ) ?? { configurationSettings: '{}' };
      const configurationSettings = JSON.parse(updatedCardConfig.configurationSettings);

      configurationSettings.order =
        configKey === 'dashboard'
          ? dashboardCards.configurationSettings.order
          : projectCards.configurationSettings.order;

      updatedCardConfig.configurationSettings = JSON.stringify(configurationSettings);

      const body = {
        businessContactId: getValue(businessContact, 'data.id', 0),
        name: configKey,
        configurationSettings: updatedCardConfig.configurationSettings,
        inactiveInd: false
      };

      userConfigArray.push(updatedCardConfig);

      updatedUserConfiguration.data = userConfigArray;

      save({ key: EntityKey.UserConfiguration, value: updatedUserConfiguration });

      if (!businessContact.data.isReadOnly) {
        message.loading('Saving configuration...', 0);

        post({ key: EntityKey.UserConfiguration, body }, (response, error) => {
          message.destroy();

          if (error) {
            message.error('Saving configuration failed', 3);
          } else {
            message.success('Configuration saved', 3);
          }
        });
      }

      setEditMode(false);
    };

    const saveConfig = () => {
      setShownCards(sortingCards);

      const updatedUserConfiguration = deepCopy(userConfiguration);
      const userConfigArray = updatedUserConfiguration.data.filter(config => config.name !== configKey);
      let updatedCardConfig = updatedUserConfiguration.data.find(config => config.name === configKey);

      if (!updatedCardConfig) {
        const order =
          configKey === 'dashboard'
            ? dashboardCards.configurationSettings.order
            : projectCards.configurationSettings.order;
        const version = configKey === 'dashboard' ? dashboardVersion : projectVersion;

        updatedCardConfig = {
          configurationSettings: {
            order,
            version
          }
        };

        updatedCardConfig.configurationSettings = JSON.stringify(updatedCardConfig.configurationSettings);
      }

      const configurationSettings = JSON.parse(updatedCardConfig.configurationSettings);

      // just get the card value for each sorting card
      const formattedOrder = sortingCards.map(item => {
        return { card: item.card };
      });

      configurationSettings.order = formattedOrder;
      configurationSettings.version = configKey === 'dashboard' ? dashboardVersion : projectVersion;

      updatedCardConfig.configurationSettings = JSON.stringify(configurationSettings);

      if (!updatedCardConfig.name) {
        updatedCardConfig.name = configKey;
      }

      userConfigArray.push(updatedCardConfig);

      updatedUserConfiguration.data = userConfigArray;

      save({ key: EntityKey.UserConfiguration, value: updatedUserConfiguration });

      // post updated config
      if (!businessContact.data.isReadOnly) {
        const body = {
          businessContactId: getValue(businessContact, 'data.id', 0),
          name: configKey,
          configurationSettings: updatedCardConfig.configurationSettings,
          inactiveInd: false
        };

        message.loading('Saving configuration...', 0);

        post({ key: EntityKey.UserConfiguration, body }, (response, error) => {
          message.destroy();

          if (error) {
            message.error('Saving configuration failed', 3);
          } else {
            message.success('Configuration saved');
          }
        });
      }

      setEditMode(false);
    };

    const cancelConfig = () => {
      setSortingCards(shownCards);
      setEditMode(false);
    };

    const actionEditClick = () => {
      setEditMode(!editMode);
    };

    // const actionCheck = e => {
    //   if (e.target.checked) {
    //     addCard(e.target.value);
    //   } else {
    //     removeCard(e.target.value);
    //   }
    // };

    const handleOnSortEnd = ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return;
      }

      if ((oldIndex || oldIndex === 0) && (newIndex || newIndex === 0)) {
        setSortingCards(arrayMove(sortingCards, oldIndex, newIndex));
      }
    };

    const renderDashboardLeftControl = () => {
      return DashboardLeftControl ? <DashboardLeftControl /> : <></>;
    };

    return (
      <>
        <Row gutter={[25, 25]} style={{ overflow: 'hidden' }}>
          <Col xl={18} lg={24}>
            {renderDashboardLeftControl()}
          </Col>
          <Col xl={6} lg={24} className='dashboard-controls-wrapper'>
            <div className='dashboard-controls'>
              <DashboardRightControl projectId={projectId} resetConfig={resetConfig} />
              <EditGrid
                allAvailableCards={allAvailableCards}
                changeVisibilityEditStatus={changeVisibilityEditStatus}
                shownCards={shownCards}
                addCard={addCard}
                removeCard={removeCard}
                resetConfig={resetConfig}
                handleEditClick={actionEditClick}
                saveConfig={saveConfig}
                cancelConfig={cancelConfig}
                editMode={editMode}
              />
            </div>
          </Col>
        </Row>
        <CardGrid
          cards={editMode ? sortingCards : shownCards}
          onSortEnd={handleOnSortEnd}
          axis='xy'
          useDragHandle
          removeCard={removeCard}
          editMode={editMode}
        />
      </>
    );
  }
);
