import React, { memo, useState } from 'react';
import { Button, Card, DatePicker, Form, Input, Select } from 'antd';
import { RLeanState, useGet, useSave } from '@rlean/core';
import { getValue, uuid } from '@rlean/utils';
import { useActivePage, useRequireMobileUser, useWindowSize } from 'lib/hooks';
import { EntityKey } from 'lib/enums/EntityKey';
import { strings, pages } from 'config';
import ProjectSearchResults from './ProjectSearchResults';
import moment from 'moment';

const FindAProject = () => {
  useRequireMobileUser();
  useActivePage(pages.findAProject);

  const [formHasError, setFormHasError] = useState(false);
  const [accountRep, findAProjectForm, office, serviceType] = RLeanState().select(({ state }) => [
    state.accountRep,
    state.findAProjectForm,
    state.office,
    state.serviceType
  ]);
  const get = useGet();
  const save = useSave();
  const [width] = useWindowSize();

  useGet({ key: EntityKey.AccountRep });
  useGet({ key: EntityKey.Office });
  useGet({ key: EntityKey.ServiceType });

  const accountRepOptions = getValue(accountRep, 'data', []).map(item => {
    const fullName = `${item.firstName} ${item.lastName}`;

    return <Select.Option value={item.employeeId}>{fullName}</Select.Option>;
  });

  const officeOptions = getValue(office, 'data', []).map(item => {
    return <Select.Option value={item.id}>{item.name}</Select.Option>;
  });

  const serviceTypeOptions = getValue(serviceType, 'data', []).map(item => {
    return <Select.Option value={item.id}>{item.name}</Select.Option>;
  });

  let date = new Date();
  date.setDate(date.getDate() - 365);

  const defaultStartDateBegin = moment(date).startOf('day');

  const defaultValues = {
    OfficeId: '',
    Status: '',
    CustomerNameContains: null,
    WorksiteNameContains: null,
    StartDateBegin: defaultStartDateBegin,
    StartDateEnd: null,
    StratosphereContactEmployeeId: '',
    PartNumber: null,
    PartDescription: null,
    Criteria: null,
    ReferenceNumber: null,
    ServiceTypeId: ''
  };

  const initialValues = findAProjectForm ?? defaultValues;

  const search = params => {
    get({ key: EntityKey.FindAProjectSearch, params });
  };

  const onFinish = values => {
    const params = {};
    let hasAtLeastOneValue = false;

    Object.keys(values).forEach(key => {
      if (values[key]) {
        if (key.indexOf('Date') !== -1) {
          const date = new Date(values[key]);
          params[key] =
            key === 'StartDateBegin'
              ? moment(date)
                  .startOf('day')
                  .toISOString(true)
              : moment(date)
                  .endOf('day')
                  .toISOString(true);
        } else {
          params[key] = values[key];
        }

        hasAtLeastOneValue = true;
      }
    });

    if (hasAtLeastOneValue) {
      search(params);
      setFormHasError(false);
      save({ key: EntityKey.FindAProjectForm, value: values });
    } else {
      setFormHasError(true);
    }
  };

  const onFinishFailed = () => {
    console.error('Project search failed!!');
  };

  const resetForm = () => {
    save({ key: EntityKey.FindAProjectForm, value: defaultValues });
  };

  function validateCharLength(rule, value, message) {
    return new Promise(async (resolve, reject) => {
      const isValid =
        (value && value.length !== 0 && value.length >= process.env.REACT_APP_FIND_A_PROJECT_MIN_CHAR) || !value;

      if (!isValid) {
        reject(message);
      } else {
        resolve();
      }
    });
  }

  const textFieldRules = [
    {
      validator: (rule, value) =>
        validateCharLength(
          rule,
          value,
          `You must enter at least ${process.env.REACT_APP_FIND_A_PROJECT_MIN_CHAR} characters.`
        )
    }
  ];

  const FormErrorMessage = () => {
    if (formHasError) {
      return <div style={{ color: 'red', paddingBottom: 10 }}>{strings.findAProjectFormErrorMessage}</div>;
    }

    return null;
  };

  return (
    <Card className='page-card' title={strings.findAProject}>
      <FormErrorMessage />
      <Form
        labelCol={{
          span: 20
        }}
        wrapperCol={{
          span: 20
        }}
        layout='vertical'
        size='default'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
        key={uuid()}
      >
        <div style={width < 575 ? { display: 'block' } : { display: 'flex', justifyContent: 'space-between' }}>
          <div style={width < 575 ? { width: '100%' } : { width: '50%' }}>
            <Form.Item name='OfficeId' label={strings.findAProjectOffice}>
              <Select
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                <Select.Option value=''>-- Select an Office --</Select.Option>
                {officeOptions}
              </Select>
            </Form.Item>
            <Form.Item name='Status' label={strings.findAProjectStatus}>
              <Select
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                <Select.Option value=''>-- Select a Status --</Select.Option>
                <Select.Option value='A'>{strings.findAProjectActive}</Select.Option>
                <Select.Option value='C'>{strings.findAProjectClosed}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name='CustomerNameContains' label={strings.findAProjectCustomer} rules={textFieldRules}>
              <Input />
            </Form.Item>
            <Form.Item name='WorksiteNameContains' label={strings.findAProjectWorkSite} rules={textFieldRules}>
              <Input />
            </Form.Item>
            <Form.Item name='StartDateBegin' label={strings.findAProjectStartFrom}>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name='StartDateEnd' label={strings.findAProjectStartTo}>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </div>
          <div style={width < 575 ? { width: '100%' } : { width: '50%' }}>
            <Form.Item name='StratosphereContactEmployeeId' label={strings.findAProjectAccountRep}>
              <Select
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                <Select.Option value=''>-- Select an Account Rep --</Select.Option>
                {accountRepOptions}
              </Select>
            </Form.Item>
            <Form.Item name='PartNumber' label={strings.findAProjectPartNumber} rules={textFieldRules}>
              <Input />
            </Form.Item>
            <Form.Item name='PartDescription' label={strings.findAProjectPartDescription} rules={textFieldRules}>
              <Input />
            </Form.Item>
            <Form.Item name='Criteria' label={strings.findAProjectCriteria} rules={textFieldRules}>
              <Input />
            </Form.Item>
            <Form.Item name='ReferenceNumber' label={strings.findAProjectReferenceNumber} rules={textFieldRules}>
              <Input />
            </Form.Item>
            <Form.Item name='ServiceTypeId' label={strings.findAProjectServiceType}>
              <Select
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                <Select.Option value=''>-- Select a Service Type --</Select.Option>
                {serviceTypeOptions}
              </Select>
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            {strings.findAProjectSearch}
          </Button>
          <Button htmlType='button' style={{ marginLeft: 15 }} onClick={() => resetForm()}>
            {strings.findAProjectReset}
          </Button>
        </Form.Item>
      </Form>
      <ProjectSearchResults />
    </Card>
  );
};

export default memo(FindAProject);
