import { useEffect } from 'react';
import { RLeanState } from '@rlean/core';
import { getValue, hasValue } from '@rlean/utils';
import { navigate } from '@reach/router';

export default function useRequireMobileUser() {
  const [userDescription] = RLeanState().select(({ state }) => [state.userDescription]);
  const isMobileUser = getValue(userDescription, 'data.isMobileUser', false);

  useEffect(() => {
    if (hasValue(userDescription, 'error')) {
      navigate('/unexpected-error');
    }

    if (hasValue(userDescription, 'data.objectId') && !isMobileUser) {
      navigate('/dashboard');
    }
  }, [isMobileUser]);
}
