export const determineCriteriaType = criteriaLetter => {
  if (criteriaLetter !== null && criteriaLetter !== undefined) {
    if (criteriaLetter.length > 1) {
      // already a type and not a letter
      return criteriaLetter;
    }

    let criteriaType = '';

    if (criteriaLetter === 'B') {
      criteriaType = 'Nonconforming';
    } else if (criteriaLetter === 'C') {
      criteriaType = 'Rework';
    } else if (criteriaLetter === 'D') {
      criteriaType = 'Observation';
    }

    return criteriaType;
  }

  return '--';
};
