import { CsvBuilder } from 'filefy';
import { message } from 'antd';

export const exportToCsv = (fileName, columns, data) => {
  const exportColumns = columns.filter(columnDef => {
    return !columnDef.hidden && columnDef.dataIndex && columnDef.title;
  });

  const exportData = data.map(rowData => exportColumns.map(columnDef => rowData[columnDef.dataIndex]));

  new CsvBuilder(`${fileName}.csv`)
    .setColumns(exportColumns.map(columnDef => columnDef.title))
    .addRows(exportData)
    .exportFile();
  // Is there a try/catch with this plugin to give more meaning messages?
  message.success('CSV Exported Successfully', 3);
};
