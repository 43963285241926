import { RLeanConfig } from '@rlean/core/src/types';
import * as entities from 'lib/entities';
import { msalAdapter } from 'lib/adapters/msalAdapter';

export const rLean: RLeanConfig<typeof entities> = {
  entities,
  api: {
    // This is currently being managed by the adapter
    headers: {},
    baseURL: process.env.REACT_APP_API_URI ?? '',
    adapter: msalAdapter
  },
  logToConsole: process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'dev'
  // storage: {}
};
