import { useSave, usePost, RLeanState } from '@rlean/core';
import { hasValue, getValue, deepCopy } from '@rlean/utils';
import { EntityKey } from 'lib/enums/EntityKey';
import { tableColumnDefaultConfig } from 'components/pages/Projects/getProjectTableColumns';
import { projectCards, dashboardCards } from 'config/user-configuration';

const USER_CONFIG_SETTINGS = {
  projectTableColumns: {
    configKey: 'projectTableColumnVisibility',
    property: 'columns',
    defaultValue: { columns: tableColumnDefaultConfig }
  },
  dashboardOrder: {
    configKey: 'dashboard',
    property: 'order',
    defaultValue: dashboardCards.configurationSettings.order
  },
  projectDetailsOrder: {
    configKey: 'projectDetails',
    property: 'order',
    defaultValue: projectCards.configurationSettings.order
  },
  whatsNewStatus: {
    configKey: 'whatsNewStatus',
    property: 'status',
    defaultValue: { status: null }
  },
  fallout: {
    configKey: 'fallout',
    property: 'percentage',
    defaultValue: { percentage: null }
  }
};

export default function useUserConfig() {
  const save = useSave();
  const post = usePost();
  const [businessContact, userConfiguration] = RLeanState().select(({ state }) => [
    state.businessContact,
    state.userConfiguration
  ]);
  const businessContactId = getValue(businessContact, 'data.id', null);

  const saveConfig = (configKey, property, defaultValue, updatedValue) => {
    if (businessContact && !businessContact.data.isReadOnly) {
      // User is a business contact so save settings to configuration
      if (hasValue(userConfiguration, 'data')) {
        const updatedUserConfiguration = deepCopy(userConfiguration);
        const userConfigArray = updatedUserConfiguration.data.filter(config => config.name !== configKey);
        const updatedConfig = updatedUserConfiguration.data.find(config => config.name === configKey) ?? {
          businessContactId,
          name: configKey,
          inactiveInd: false
        };
        const configurationSettings = updatedConfig.configurationSettings
          ? JSON.parse(updatedConfig.configurationSettings)
          : defaultValue;

        configurationSettings[property] = updatedValue;
        updatedConfig.configurationSettings = JSON.stringify(configurationSettings);

        userConfigArray.push(updatedConfig);

        updatedUserConfiguration.data = userConfigArray;

        save({ key: EntityKey.UserConfiguration, value: updatedUserConfiguration });

        if (!businessContact.data.isReadOnly) {
          const body = {
            businessContactId,
            name: configKey,
            configurationSettings: updatedConfig.configurationSettings,
            inactiveInd: false
          };

          post({
            key: EntityKey.UserConfiguration,
            body
          });
        }
      }
    }
  };

  const saveDashboardOrder = updatedValue => {
    saveConfig(
      USER_CONFIG_SETTINGS.dashboardOrder.configKey,
      USER_CONFIG_SETTINGS.dashboardOrder.property,
      USER_CONFIG_SETTINGS.dashboardOrder.defaultValue,
      updatedValue
    );
  };

  const saveProjectDetailsOrder = updatedValue => {
    saveConfig(
      USER_CONFIG_SETTINGS.projectDetailsOrder.configKey,
      USER_CONFIG_SETTINGS.projectDetailsOrder.property,
      USER_CONFIG_SETTINGS.projectDetailsOrder.defaultValue,
      updatedValue
    );
  };

  const saveProjectTableColumns = updatedValue => {
    saveConfig(
      USER_CONFIG_SETTINGS.projectTableColumns.configKey,
      USER_CONFIG_SETTINGS.projectTableColumns.property,
      USER_CONFIG_SETTINGS.projectTableColumns.defaultValue,
      updatedValue
    );
  };

  const savewhatsNewStatus = updatedValue => {
    saveConfig(
      USER_CONFIG_SETTINGS.whatsNewStatus.configKey,
      USER_CONFIG_SETTINGS.whatsNewStatus.property,
      USER_CONFIG_SETTINGS.whatsNewStatus.defaultValue,
      updatedValue
    );
  };

  const savefallout = updatedValue => {
    saveConfig(
      USER_CONFIG_SETTINGS.fallout.configKey,
      USER_CONFIG_SETTINGS.fallout.property,
      USER_CONFIG_SETTINGS.fallout.defaultValue,
      updatedValue
    );
  };

  return [{ saveDashboardOrder, saveProjectDetailsOrder, saveProjectTableColumns, savewhatsNewStatus, savefallout }];
}
