import React, { memo } from 'react';
import { Skeleton } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { strings } from 'config';
import moment from 'moment';
import { downloadFile } from 'lib/helpers';
import { ReportTable } from 'components/shared';
import MaterialIcon from '@material/react-material-icon';
import { RLeanState } from '@rlean/core';

export const ProjectResourcesTable = memo(() => {
  const [{ id: projectId }, projectResources] = RLeanState().select(({ state }) => [
    state.activeProject,
    state.projectResources
  ]);

  const downloadHandler = (id, title) => {
    const formattedTitle = title.replace(/\s+/g, '-').toLowerCase();

    const options = {
      endpoint: `/ProjectResources/${id}`,
      filename: `Project-${projectId}_${formattedTitle}`
    };

    downloadFile(options);
  };

  const columns = [
    {
      title: 'Resource Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => {
        if (a.title) {
          return b.title ? a.title.localeCompare(b.title) : -1;
        }
        if (b.title) {
          return a.title ? b.title.localeCompare(a.title) : 1;
        }
        return 0;
      }
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => {
        if (a.category) {
          return b.category ? a.category.localeCompare(b.category) : -1;
        }
        if (b.category) {
          return a.category ? b.category.localeCompare(a.category) : 1;
        }
        return 0;
      }
    },
    {
      title: 'Publisher',
      dataIndex: 'publishedByName',
      key: 'publishedByName',
      sorter: (a, b) => {
        if (a.publishedByName) {
          return b.publishedByName ? a.publishedByName.localeCompare(b.publishedByName) : -1;
        }
        if (b.publishedByName) {
          return a.publishedByName ? b.publishedByName.localeCompare(a.publishedByName) : 1;
        }
        return 0;
      }
    },
    {
      title: 'Added at',
      dataIndex: 'addedAt',
      key: 'addedAt',
      render: addedAt => moment(addedAt).format('MM/DD/YYYY'),
      sorter: (a, b) => {
        return moment(b.addedAt).diff(moment(a.addedAt));
      }
    },
    {
      title: 'Download',
      render: (_, record) => (
        <MaterialIcon
          onClick={() => downloadHandler(record.id, record.title)}
          role='button'
          className='action'
          icon={<DownloadOutlined />}
        />
      )
    }
  ];

  if (!projectResources || projectResources.isLoading || projectResources.isRefetching) {
    return <Skeleton />;
  }

  return (
    <ReportTable
      title={strings.projectDetailsDocumentProjectResourcesTitle}
      dataSource={projectResources.data}
      columns={columns}
      isDrawer={true}
    />
  );
});
