import { define } from '@rlean/core';
import { BusinessContactSearch as BusinessContactSearchType } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

const BusinessContactSearch = define<BusinessContactSearchType[]>(EntityKey.BusinessContactSearch, {
  getURL: '/BusinessContactSearch',
  persistData: false
});

export default BusinessContactSearch;
