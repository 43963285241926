import React, { memo, useEffect, useState, useRef } from 'react';
import { RLeanState } from '@rlean/core';
import { getValue } from '@rlean/utils';
import { Row, Col, Radio } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { pages, refreshInterval } from 'config';
import { downloadFileType, downloadFile, formatDate } from 'lib/helpers';
import { reportNames, formats } from 'components/shared/SQReport';
import { ProjectsTable } from './ProjectsTable';
import { ProjectsSummary } from './ProjectsSummary';
import { ProjectsSearch } from './ProjectsSearch';
import { useActivePage, useRequireImpersonation } from 'lib/hooks';

const removeAnimationClass = () => {
  const elements = document.querySelectorAll(
    '[class^="table-project-column-updated"],[class*=" table-project-column-updated"]'
  );
  Array.from(elements).forEach(element => {
    element.className = element.className.replace(/table-project-column-updated.+?/g, '');
  });
};

export const Projects = memo(() => {
  const [businessContact, userConfiguration, project] = RLeanState().select(({ state }) => [
    state.businessContact,
    state.userConfiguration,
    state.project
  ]);
  const [showProjectGrid, setShowProjectGrid] = useState(true);
  const [showProjectSummary, setShowProjectSummary] = useState(false);
  const [showProjectSearch, setShowProjectSearch] = useState(false);
  const [activeTab, setActiveTab] = useState('grid');

  useRequireImpersonation();
  useActivePage(pages.projects);

  const savedRefreshSetting = getValue(userConfiguration, 'data', []).find(config => config.name === 'shouldRefresh');
  const shouldRefresh = savedRefreshSetting
    ? JSON.parse(savedRefreshSetting.configurationSettings).shouldRefresh
    : false;
  const intervalId = useRef(null);
  const projectInit = getValue(project, 'init', false);

  const clearRefetchInterval = () => {
    window.clearInterval(intervalId.current);
  };

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed && shouldRefresh) {
      intervalId.current = window.setInterval(() => {
        removeAnimationClass();
        if (project.refetch) {
          project.refetch();
        }
      }, refreshInterval);
    }

    return () => {
      isSubscribed = false;
      clearRefetchInterval();
    };
  }, [projectInit, shouldRefresh]);

  const callback = e => {
    setActiveTab(e.target.value);

    switch (e.target.value) {
      case 'grid':
        setShowProjectGrid(true);
        setShowProjectSummary(false);
        setShowProjectSearch(false);
        break;
      case 'summary':
        setShowProjectGrid(false);
        setShowProjectSummary(true);
        setShowProjectSearch(false);
        break;
      case 'search':
        setShowProjectGrid(false);
        setShowProjectSummary(false);
        setShowProjectSearch(true);
        break;
      default:
        // unrecognized
        break;
    }
  };

  const content = () => {
    if (showProjectGrid) {
      return <ProjectsTable removeAnimationClass={removeAnimationClass} />;
    }
    if (showProjectSummary) {
      return <ProjectsSummary />;
    }

    return <ProjectsSearch />;
  };

  const download = () => {
    const businessContactId = getValue(businessContact, 'data.id', 0);

    const params = {
      businessContactId,
      format: formats.EXCELOPENXML,
      reportName: reportNames.PROJECT_SUMMARIES
    };

    const options = {
      filename: `Project Summary - ${formatDate(new Date())}`,
      endpoint: '/SsrsReportFiles',
      fileType: downloadFileType.EXCELOPENXML
    };

    downloadFile(options, params);
  };

  const exportLink = () => {
    if (showProjectSummary) {
      return (
        <button
          onClick={() => download()}
          type='button'
          className='link-button link-small'
          style={{ marginLeft: 15, fontSize: '.85rem', marginTop: 5 }}
        >
          Export All <CloudDownloadOutlined style={{ marginLeft: 2 }} />
        </button>
      );
    }

    return <></>;
  };

  return (
    <>
      <Row className='grid-row'>
        <Col className='grid-col' span={24}>
          <Radio.Group onChange={callback} value={activeTab}>
            <Radio.Button value='grid' checked={showProjectGrid}>
              Projects
            </Radio.Button>
            <Radio.Button value='summary' checked={showProjectSummary}>
              Project Summaries
            </Radio.Button>
            <Radio.Button value='search' checked={showProjectSearch}>
              Historic Project Search
            </Radio.Button>
          </Radio.Group>
          {exportLink()}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div style={{ marginTop: 25 }}>{content()}</div>
        </Col>
      </Row>
    </>
  );
});
