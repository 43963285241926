import { define } from '@rlean/core';
import { WorkSite as WorkSiteType } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

const WorkSite = define<WorkSiteType[]>(EntityKey.WorkSite, {
  getURL: '/BusinessContactCompanyViews',
  progressiveLoading: true
});

export default WorkSite;
