import React, { memo, useState } from 'react';
import { Button, Popover, Space, Switch } from 'antd';
import MaterialIcon from '@material/react-material-icon';

/**
 * CardVisibilityConfig
 * A card visibility editor.
 */
function CardVisibilityConfig({
  allAvailableCards,
  changeVisibilityEditStatus,
  shownCards,
  saveConfig,
  resetConfig,
  addCard,
  removeCard
}) {
  const [popoverIsVisible, setPopoverVisibility] = useState(false);

  const switchHandler = cardKey => checked => {
    if (checked) {
      addCard(cardKey, true);
    } else {
      removeCard(cardKey, true);
    }
  };

  const handlePopOverVisibility = (visible, save = false) => {
    // let the parent know that the popover visibliity is changing:
    changeVisibilityEditStatus(visible, save);
    if (save) {
      saveConfig();
    }
    setPopoverVisibility(visible);
  };

  const popOverContent = (
    <Space direction='vertical'>
      <div>
        {allAvailableCards.map(item => {
          const cardKey = item.key;
          const cardIsVisible = shownCards.some(savedCard => savedCard.key === cardKey);
          return (
            <div key={`pop-switch-${cardKey}`} className='switch-container'>
              <Switch checked={cardIsVisible} onChange={switchHandler(cardKey)} />
              <span className='switch-label'>{item.text}</span>
            </div>
          );
        })}
      </div>
      <Space>
        <Button onClick={() => handlePopOverVisibility(false, true)}>Save</Button>
        <Button onClick={resetConfig}>Reset</Button>
        <Button onClick={() => handlePopOverVisibility(false)}>Cancel</Button>
      </Space>
    </Space>
  );

  return (
    <Popover
      content={popOverContent}
      visible={popoverIsVisible}
      title='Cards Visibility'
      trigger='click'
      onVisibleChange={handlePopOverVisibility}
    >
      <MaterialIcon icon='add' className='edit-icon' />
    </Popover>
  );
}

export default memo(CardVisibilityConfig);
