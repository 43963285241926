import React, { memo, useState, useEffect, useContext } from 'react';
import { BaseCard, LOADING_STATUS } from 'components/shared';
import { navigate } from '@reach/router';
import { RLeanState } from '@rlean/core';
import { hasValue, deepCopy } from '@rlean/utils';
import { projectMetricType } from 'lib/types';
import { FilterContext } from 'components/shared/FiltersContext';

// Hooks
import { useProject } from 'lib/hooks/useProject';

export const ActiveProjects = memo(() => {
  const [project, workSite, businessContact] = RLeanState().select(({ state }) => [
    state.project,
    state.workSite,
    state.businessContact
  ]);

  const { filters, setFilters } = useContext(FilterContext);
  const [loadingStatus, setLoadingStatus] = useState();

  const { getActiveProjects } = useProject();

  const cardData = {
    title: 'Active Projects',
    loadingStatus,
    description: 'Total number currently active projects'
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  const activeProjects = getActiveProjects(
    filters.selectedWorkSite ? filters.selectedWorkSite.value : 0,
    workSite ? workSite.data : [],
    project ? project.data : [],
    businessContact
  );

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      if (!project) {
        setLoadingStatus(LOADING_STATUS.NODATA);
      }

      if (
        !project ||
        (project && project.isLoading) ||
        (project && project.lastUpdated && typeof project.isLoading === 'undefined') ||
        !activeProjects
      ) {
        setLoadingStatus(LOADING_STATUS.WAITING);
      } else if (hasValue(project, 'isLoading') && !project.isLoading && activeProjects) {
        setLoadingStatus(LOADING_STATUS.LOADED);
      } else {
        setLoadingStatus(LOADING_STATUS.NODATA);
      }
    }

    return () => (isSubscribed = false);
  }, [project, workSite, activeProjects]);

  const setMetric = () => {
    const updatedFilters = deepCopy(filters);

    updatedFilters['projectMetric'].value = projectMetricType.ACTIVE_PROJECTS;
    updatedFilters['projectMetric'].showTag = true;

    setFilters(updatedFilters);
    navigate('/projects');
  };

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      <button type='button' className='link-button general-card-total' onClick={setMetric}>
        <span>{activeProjects ? activeProjects.length : '--'}</span>
      </button>
    </BaseCard>
  );
});
