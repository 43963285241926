import React, { memo } from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { echoDataType } from 'lib/types';

const SQTableDefault = ({ value, dataTypeId }) => {
  const isTrue = value => {
    return value === true || value === 'true' || value === 'True' || value === 1;
  };

  const renderCell = value => {
    switch (dataTypeId) {
      case echoDataType.DATE:
        return value ? moment(value).format('MM/DD/YYYY') : '--';
      case echoDataType.DATETIME:
        return value ? moment(value).format('MM/DD/YYYY HH:mm') : '--';
      case echoDataType.INT:
        return value ?? '0';
      case echoDataType.STRING:
        return value ?? '--';
      case echoDataType.BOOLEAN:
        return isTrue(value) ? 'Yes' : 'No';
      default:
        return value ?? '--';
    }
  };

  const displayValue = renderCell(value, null);

  const TooltipComponent = () => {
    const handleCopy = () => {
      navigator.clipboard.writeText(value);
    };

    return (
      <>
        <span style={{ margin: '0px 12px 0px 0px' }}>{value}</span>
        <button
          style={{
            fontSize: '14px',
            padding: '0',
            background: 'none',
            border: 'none',
            cursor: 'pointer'
          }}
          type='button'
          onClick={handleCopy}
        >
          <CopyOutlined title='Copy to Clipboard' />
        </button>
      </>
    );
  };

  return (
    <Tooltip placement='topLeft' title={TooltipComponent}>
      {displayValue}
    </Tooltip>
  );
};

export default memo(SQTableDefault);
