import React, { FC, memo, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import ReactGA from 'react-ga';
import { Spin, BackTop } from 'antd';
import Routes from 'Routes';
import { RLeanState, useGet, useSave, Store } from '@rlean/core';
import { getValue, hasValue } from '@rlean/utils';
import { useTrackUser, USER_TRACKING_TYPE, EVENT_TYPE } from 'lib/hooks';
import { EntityKey } from 'lib/enums/EntityKey';
import 'antd/dist/antd.css';
import 'assets/site.css';
import { msalInstance } from 'config/msal';
import { useTokenRefreshOnVisibility } from 'lib/hooks/useTrackUser';
import * as entities from 'lib/entities';
import { BusinessContact } from 'lib/types';

const App: FC<RouteComponentProps> = () => {
  const [activeProject, businessContact, version, userDescription] = RLeanState<
    typeof entities
  >().select(({ state }) => [state.activeProject, state.businessContact, state.version, state.userDescription]);
  const [analyticsInit, setAnalyticsInit] = useState(false);
  const save = useSave();
  const get = useGet();
  const trackUserEvent = useTrackUser();

  const account = msalInstance.getActiveAccount(); // returns AccountInfo or null
  const oid = getValue(account, 'idTokenClaims.oid', null);
  const objectId = getValue(userDescription, 'data.objectId', null);

  const projectId = getValue(activeProject, 'id', null);

  useTokenRefreshOnVisibility();

  useGet({ key: EntityKey.UserDescription, params: { oid } }, (res: any) => {
    if (trackUserEvent) {
      trackUserEvent({
        userTrackingType: USER_TRACKING_TYPE.login,
        trackingEventType: EVENT_TYPE.passive,
        userDescription: res.data,
        businessContact
      });
    }
  });

  useEffect(() => {
    if (objectId && !hasValue(businessContact, 'data.id')) {
      // create business contact
      // let newBusinessContact: BusinessContact = {
      //   id: null,
      //   displayName: null,
      //   companyId: null,
      //   companyName: null,
      //   isReadOnly: null
      // };

      if (hasValue(userDescription, 'data') && userDescription.data.objectId && !userDescription.data.isMobileUser) {
        const newBusinessContact: BusinessContact = {
          id: userDescription.data.businessContactId,
          displayName: userDescription.data.displayName,
          companyId: userDescription.data.companyId,
          companyName: userDescription.data.companyName,
          isReadOnly: false
        };
        save({ key: EntityKey.BusinessContact, value: { data: newBusinessContact } });
      }
    }
  }, [objectId]);

  const isMobileUser = getValue(userDescription, 'isMobileUser', true);
  const businessContactId = getValue(businessContact, 'data.id', null);

  /* #region  Initialize Google Analytics */

  useEffect(() => {
    ReactGA.initialize('UA-137719189-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (businessContactId && !isMobileUser && !analyticsInit) {
      ReactGA.set({ userId: businessContactId });

      if (isMounted) {
        setAnalyticsInit(true);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [businessContactId, isMobileUser, analyticsInit]);

  /* #endregion */

  // populate state
  useGet({ key: EntityKey.UserConfiguration, params: { businessContactId } });
  useGet({ key: EntityKey.ProjectSummary, params: { businessContactId } });
  useGet({ key: EntityKey.Project, params: { businessContactId } });
  useGet({ key: EntityKey.Invoice, params: { businessContactId } });
  useGet({ key: EntityKey.WorkSite, params: { businessContactId } });
  useGet({ key: EntityKey.CompanyConcernCategories });
  useGet({ key: EntityKey.PartImageGridSpec });

  useEffect(() => {
    if (businessContactId) {
      get({ key: EntityKey.ProjectFavorite, params: { businessContactId } });
    }
  }, [businessContactId]);

  // Dashboard
  useGet({ key: EntityKey.PendingWorkInstruction, params: { businessContactId } });
  useGet({ key: EntityKey.PendingWorkAuthorization, params: { businessContactId } });
  useGet({ key: EntityKey.ProjectInformationFormRevisionReviews, params: { businessContactId } });

  // For Project Details
  useGet({ key: EntityKey.Parts, params: { projectId } });
  useGet({ key: EntityKey.PartWithPartImage, params: { projectId } });
  useGet({ key: EntityKey.ProjectContacts, params: { projectId } });
  useGet({ key: EntityKey.ProjectHoursNotYetInvoiced, params: { projectId } });
  useGet({ key: EntityKey.ProjectParts, params: { projectId } });
  useGet({ key: EntityKey.ProjectReports, params: { projectId } });
  useGet({ key: EntityKey.ProjectResources, params: { projectId } });
  useGet({ key: EntityKey.ProjectTrainingLog, params: { projectId } });
  useGet({ key: EntityKey.ProjectWorkInstructions, params: { solarProjectNumber: projectId } });
  useGet({ key: EntityKey.ProjectInformationFormRevisions, params: { solarProjectNumber: projectId } });
  useGet({ key: EntityKey.WorkAuthorization, params: { projectId } });

  // Clear storage if a new version is released
  useEffect(() => {
    if (hasValue(version, 'version.data.value') && version.data.value !== process.env.REACT_APP_BUILD_NUMBER) {
      Store.clear();

      const newVersion = process.env.REACT_APP_BUILD_NUMBER ?? '';
      save({ key: EntityKey.Version, value: { data: newVersion } });
    }
  }, []);

  if (
    !userDescription ||
    userDescription.isLoading ||
    userDescription.error ||
    !hasValue(userDescription, 'data.objectId')
  ) {
    return (
      <div className='loading-container'>
        <div className='loading-content'>
          <Spin size='large' tip='Loading...' />
        </div>
      </div>
    );
  }

  return (
    <>
      <Routes />
      <BackTop style={{ right: '50px' }} />
    </>
  );
};

export default memo(App);
