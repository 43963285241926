import React, { memo, useState, useEffect } from 'react';
import { BaseCard, LOADING_STATUS, BaseList, BaseListItem } from 'components/shared';
import { RLeanState } from '@rlean/core';
import { hasValue } from '@rlean/utils';
import RejectButton from './RejectButton';
import AcceptButton from './AcceptButton';
import DocumentButton from './DocumentButton';

export const PendingWorkInstructions = memo(() => {
  const [pendingWorkInstruction, businessContact] = RLeanState().select(({ state }) => [
    state.pendingWorkInstruction,
    state.businessContact
  ]);
  const [listData, setListData] = useState();
  const [loadingStatus, setLoadingStatus] = useState();
  const [controlsVisible, setControlsVisible] = useState(false);

  const cardData = {
    title: 'Pending Work Instructions',
    loadingStatus,
    description: ''
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  useEffect(() => {
    let isSubscribed = true;

    if (hasValue(businessContact, 'data.isReadOnly')) {
      if (isSubscribed) {
        setControlsVisible(!businessContact.data.isReadOnly);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [businessContact]);

  useEffect(() => {
    let isSubscribed = true;

    if (!pendingWorkInstruction) {
      if (isSubscribed) {
        setListData([]);
      }
    }

    if (hasValue(pendingWorkInstruction, 'data')) {
      if (isSubscribed) {
        setListData(pendingWorkInstruction.data);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [pendingWorkInstruction]);

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      if (
        !pendingWorkInstruction ||
        (hasValue(pendingWorkInstruction, 'isLoading') && pendingWorkInstruction.isLoading)
      ) {
        setLoadingStatus(LOADING_STATUS.WAITING);
      } else if ((hasValue(pendingWorkInstruction, 'isLoading') && !pendingWorkInstruction.isLoading) || listData) {
        setLoadingStatus(LOADING_STATUS.LOADED);
      } else {
        setLoadingStatus(LOADING_STATUS.NODATA);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [pendingWorkInstruction, listData]);

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      <BaseList
        itemsPerPage={5}
        data={listData}
        layout='horizontal'
        renderItem={(item, index) => {
          const { projectId, workInstructionRevisionId, id } = item;
          const actions = controlsVisible
            ? [
                <RejectButton workInstructionRevisionId={workInstructionRevisionId} id={id} />,
                <AcceptButton workInstructionRevisionId={workInstructionRevisionId} id={id} />,
                <DocumentButton projectId={projectId} workInstructionRevisionId={workInstructionRevisionId} />
              ]
            : [<DocumentButton projectId={projectId} workInstructionRevisionId={workInstructionRevisionId} />];

          return (
            <BaseListItem
              className='pending-item'
              title={projectId}
              style={index % 2 !== 0 ? { backgroundColor: '#fafafa' } : null}
              actionComponents={actions}
            />
          );
        }}
      />
    </BaseCard>
  );
});
