import { useEffect } from 'react';
import { getToken } from 'config';

const useTokenRefreshOnVisibility = () => {
  const refreshToken = () => {
    if (document.visibilityState === 'visible') {
      getToken();
    }
  };

  useEffect(() => {
    window.addEventListener('visibilitychange', refreshToken);

    return () => {
      window.removeEventListener('visibilitychange', refreshToken);
    };
  }, []);
};

export default useTokenRefreshOnVisibility;
