import React, { memo, useState, useEffect } from 'react';
import MaterialIcon from '@material/react-material-icon';
import { Button, Popover, Checkbox } from 'antd';
import { strings, cards } from 'config';
import { projectCards, dashboardCards } from 'config/user-configuration';
import CardVisibilityConfig from './CardVisibilityConfig';

const PopOverContent = memo(({ popSave, popReset, popCancel, popCheck, defaultCards, sortingCards, configKey }) => {
  const [checkboxGroupValue, setCheckboxGroupValue] = useState();
  const cardConfig =
    configKey === 'dashboard' ? dashboardCards.configurationSettings.order : projectCards.configurationSettings.order;
  const initialCards = cards(cardConfig);

  useEffect(() => {
    let isSubscribed = true;

    const groupMatchedList = initialCards.map(initialCard => {
      return defaultCards.some(defaultCard => defaultCard.key === initialCard.key) ? initialCard.key : null;
    });

    if (isSubscribed) {
      setCheckboxGroupValue(groupMatchedList);
    }

    return () => {
      isSubscribed = false;
    };
  }, [sortingCards, defaultCards, initialCards]);

  const handleSave = () => {
    popSave();
  };

  const handleReset = () => {
    popReset();
  };

  const handleCancel = () => {
    popCancel();
  };

  const handleCheck = e => {
    popCheck(e);
  };

  return (
    <div>
      <Checkbox.Group className='edit-popover-checklist' value={checkboxGroupValue}>
        {initialCards.map(box => (
          <Checkbox key={box.key} onChange={handleCheck} value={box.key}>
            {box.text}
          </Checkbox>
        ))}
      </Checkbox.Group>
      <div className='edit-popover-buttons'>
        <Button type='default' htmlType='button' onClick={handleSave}>
          {strings.gridPopOverSave}
        </Button>
        <Button type='default' htmlType='button' onClick={handleCancel}>
          {strings.gridPopOverCancel}
        </Button>
        <Button type='default' htmlType='button' onClick={handleReset} style={{ marginLeft: 'auto' }}>
          {strings.gridPopOverReset}
        </Button>
      </div>
    </div>
  );
});

export const EditGrid = memo(
  ({
    handleEditClick,
    changeVisibilityEditStatus,
    allAvailableCards,
    shownCards,
    addCard,
    removeCard,
    resetConfig,
    saveConfig,
    cancelConfig,
    editMode
  }) => {
    const handleOnSave = () => {
      saveConfig();
    };

    const handleCancel = () => {
      cancelConfig();
    };

    return (
      <>
        {editMode && (
          <>
            <Button onClick={handleOnSave} type='primary' htmlType='button'>
              {strings.gridEditModeSave}
            </Button>
            <Button onClick={handleCancel} type='primary' htmlType='button'>
              {strings.gridEditModeCancel}
            </Button>
          </>
        )}
        <CardVisibilityConfig
          allAvailableCards={allAvailableCards}
          changeVisibilityEditStatus={changeVisibilityEditStatus}
          shownCards={shownCards}
          addCard={addCard}
          saveConfig={saveConfig}
          removeCard={removeCard}
          resetConfig={resetConfig}
        />
        <MaterialIcon icon='edit' className='edit-icon' onClick={handleEditClick} />
      </>
    );
  }
);
