import React, { memo, useState } from 'react';
import { RLeanState } from '@rlean/core';
import { getValue, hasValue } from '@rlean/utils';
import { ReportTable, SQReport } from 'components/shared';
import { getUniquePartNumbers, getUniqueCriteriaNames, reportNames, formats } from 'components/shared/SQReport';
import { determineCriteriaType, downloadFileType, downloadFile } from 'lib/helpers';
import moment from 'moment';
import Highcharts from 'highcharts';
import Pareto from 'highcharts/modules/pareto';
import { strings } from 'config';
import { useCurrentProject, useFilters } from 'lib/hooks';

Pareto(Highcharts);

const filterProperties = [
  'shift',
  'startDateTime',
  'endDateTime',
  'partIdListCommaSeparated',
  'criteriaIdListCommaSeparated'
];

export const ParetoComponent = memo(() => {
  const [projectParts] = RLeanState().select(({ state }) => [state.projectParts]);
  const [chartData, setChartData] = useState({ defects: [], keyLetterLength: null, series: [], reportData: null });

  const parts = getValue(projectParts, 'data.parts', []);
  const defaultParts = getUniquePartNumbers(parts);
  const defaultCriteria = getUniqueCriteriaNames(parts);
  const currentProject = useCurrentProject();

  let defaultStartDate = new Date();
  let defaultEndDate = new Date();

  if (hasValue(currentProject, 'lastSortDate')) {
    defaultStartDate = new Date(currentProject.lastSortDate);
    defaultEndDate = new Date(currentProject.lastSortDate);
    defaultStartDate.setDate(defaultStartDate.getDate() - 30);
    defaultEndDate.setDate(defaultEndDate.getDate());
  }

  const { convertedFilters: filters, loadingFilters, updateFiltersWithFormData: updateFilters } = useFilters(
    {
      startDateTime: moment(defaultStartDate).startOf('day'),
      endDateTime: moment(defaultEndDate).endOf('day'),
      partIdListCommaSeparated: defaultParts,
      criteriaIdListCommaSeparated: defaultCriteria
    },
    filterProperties
  );

  const keyLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

  const detailsColumns = [
    {
      title: 'Key',
      dataIndex: 'key',
      sorter: (a, b) => {
        if (a.key) {
          return b.key ? a.key.localeCompare(b.key) : -1;
        }
        if (b.key) {
          return a.key ? b.key.localeCompare(a.key) : 1;
        }
        return 0 - 0;
      },
      defaultSortOrder: 'ascend',
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnCriteria,
      dataIndex: 'criteriaName',
      key: 'criteriaName',
      sorter: (a, b) => {
        if (a.criteriaName) {
          return b.criteriaName ? a.criteriaName.localeCompare(b.criteriaName) : -1;
        }
        if (b.criteriaName) {
          return a.criteriaName ? b.criteriaName.localeCompare(a.criteriaName) : 1;
        }
        return 0 - 0;
      },
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnCriteriaType,
      dataIndex: 'criteriaType',
      key: 'criteriaType',
      render: criteriaType => <>{determineCriteriaType(criteriaType)}</>,
      sorter: (a, b) => {
        if (a.criteriaType) {
          return b.criteriaType ? a.criteriaType.localeCompare(b.criteriaType) : -1;
        }
        if (b.criteriaType) {
          return a.criteriaType ? b.criteriaType.localeCompare(a.criteriaType) : 1;
        }
        return 0 - 0;
      },
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnCount,
      dataIndex: 'defectCount',
      key: 'defectCount',
      sorter: (a, b) => a.defectCount - b.defectCount,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnPercentOfTotal,
      dataIndex: 'percentOfTotal',
      key: 'percentOfTotal',
      sorter: (a, b) => a.percentOfTotal - b.percentOfTotal,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnPPM,
      dataIndex: 'defectPpm',
      key: 'defectPpm',
      sorter: (a, b) => a.defectPpm - b.defectPpm,
      render: ppm => <>{ppm.toFixed(2)}</>,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnFallout,
      dataIndex: 'defectFallout',
      key: 'defectFallout',
      sorter: (a, b) => a.defectFallout - b.defectFallout,
      render: defectFallout => <>{(defectFallout * 100).toFixed(2)}</>,
      sqVisible: true,
      sqToggle: true
    }
  ];

  const summaryColumns = [
    {
      title: strings.reportTableColumnTotalInspected,
      dataIndex: 'partsInspected',
      key: 'partsInspected',
      sorter: (a, b) => a.partsInspected - b.partsInspected,
      defaultSortOrder: 'descend',
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnTotalGood,
      dataIndex: 'partsConforming',
      key: 'partsConforming',
      sorter: (a, b) => a.partsConforming - b.partsConforming,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnTotalNonConforming,
      dataIndex: 'partsNonConforming',
      key: 'partsNonConforming',
      sorter: (a, b) => a.partsNonConforming - b.partsNonConforming,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnTotalReworked,
      dataIndex: 'partsReworked',
      key: 'partsReworked',
      sorter: (a, b) => a.partsReworked - b.partsReworked,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnTotalPPM,
      dataIndex: 'ppm',
      key: 'ppm',
      sorter: (a, b) => a.ppm - b.ppm,
      render: ppm => <>{ppm.toFixed(2)}</>,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnFallout,
      dataIndex: 'fallout',
      key: 'fallout',
      sorter: (a, b) => a.fallout - b.fallout,
      render: fallout => <>{(fallout * 100).toFixed(2)}</>,
      sqVisible: true,
      sqToggle: true
    }
  ];

  const configuration = {
    chart: { renderTo: 'pareto', type: 'column' },
    title: { text: '' },
    lang: { noData: 'No data to display' },
    noData: { position: { x: 0, y: 0, align: 'center', verticalAlign: 'middle' } },
    subtitle: { text: '' },
    xAxis: { categories: keyLetters.slice(0, chartData.keyLetterLength) },
    yAxis: [
      { title: { text: '' } },
      {
        title: { text: '' },
        minPadding: 0,
        maxPadding: 0,
        max: 100,
        min: 0,
        opposite: true,
        labels: { format: '{value}%' }
      }
    ],
    credits: { enabled: false },
    exporting: { filename: '' },
    tooltip: {
      formatter(tooltip, x = this.x, y = this.y, series = this.series, point = this.point) {
        return hasValue(chartData, 'categories')
          ? `<b>${chartData.categories[point.index]}</b><br/>${series.name}: ${y}`
          : `<b>${x}</b><br/>${series.name}: ${y}`;
      }
    },
    series: [
      {
        type: 'pareto',
        name: 'Pareto',
        yAxis: 1,
        zIndex: 10,
        baseSeries: 1
      },
      { name: 'Defects', type: 'column', zIndex: 2, data: chartData.defects.slice(0, chartData.keyLetterLength) }
    ]
  };

  const buildChart = responseData => {
    if (responseData != null) {
      const reportData = [];
      const categories = [];
      const defects = [];

      for (let i = 0; i < responseData.length; i += 1) {
        if (i < 10) {
          categories.push(`${keyLetters[i]}: ${responseData[i].criteriaName.replace('/', '')}`);
        }
        defects.push(responseData[i].defectCount);

        const data = responseData[i];
        data.i = i;
        data.key = keyLetters[i];

        reportData.push(data);
      }

      let keyLetterLength = 0;

      if (responseData.length > 10) {
        keyLetterLength = 10;
      } else {
        keyLetterLength = responseData.length;
      }

      setChartData({
        keyLetterLength,
        reportData,
        categories,
        defects
      });
    }
  };

  const downloadReport = reportParams => {
    const {
      projectId,
      businessContactId,
      shift,
      startDateTime,
      partIdListCommaSeparated,
      criteriaIdListCommaSeparated
    } = reportParams;
    const endDateTime = reportParams.endDateTime.endOf('day');

    const params = {
      Shift: shift,
      start: startDateTime.toISOString(true),
      end: endDateTime.toISOString(true),
      partList: partIdListCommaSeparated.toString(),
      criteriaList: criteriaIdListCommaSeparated.toString(),
      BusinessContactId: businessContactId,
      ProjectId: projectId,
      format: formats.EXCELOPENXML,
      reportName: reportNames.PARETO
    };

    const options = {
      filename: `Pareto-${projectId}.xlsx`,
      endpoint: '/SsrsReportFiles',
      fileType: downloadFileType.EXCELOPENXML
    };

    downloadFile(options, params);
  };

  const reportOptions = {
    reportTitle: strings.reportParetoTitle,
    urls: ['/ProjectParetos'],
    filterProperties,
    filters,
    updateFilters,
    buildChart,
    downloadReport,
    defaultParts,
    defaultCriteria
  };

  const chartOptions = {
    chartReady: !!chartData.reportData,
    configuration
  };

  const tableOptions = {
    titleBarData: {
      tableTitle: strings.reportParetoTableDetailsTitle
    },
    dataSource: chartData.reportData,
    summary: false,
    columns: detailsColumns
  };

  if (loadingFilters) {
    return <></>;
  }

  return (
    <SQReport reportOptions={reportOptions} chartOptions={chartOptions} tableOptions={tableOptions}>
      {chartData.reportData && (
        <>
          <ReportTable
            dataSource={chartData.reportData.length > 0 ? [chartData.reportData[0]] : []}
            columns={summaryColumns}
            title={strings.reportParetoTableSummaryTitle}
            style={{ marginBottom: '15px' }}
            pagination={false}
          />
        </>
      )}
    </SQReport>
  );
});
