export default class ImpersonationUserData {
  constructor({ businessContactId, firstName, lastName, companyId, companyName, sessionId }) {
    // check businessContact object for other things to add
    this.sessionId = sessionId;
    this.impersonatedUser = {
      businessContactId,
      firstName,
      lastName,
      companyId,
      companyName
    };
  }
}
