import React, { memo, useContext, useEffect, useState } from 'react';
import { BaseCard, LOADING_STATUS } from 'components/shared';
import { navigate } from '@reach/router';
import { RLeanState } from '@rlean/core';
import { hasValue, deepCopy } from '@rlean/utils';
import { Textfit } from 'react-textfit';
import { strings } from 'config';
import { FilterContext } from 'components/shared/FiltersContext';
import { invoiceMetricType } from 'lib/types';

// Hooks
import { useInvoicesLast30Days } from 'lib/hooks';

export const MyInvoicing = memo(() => {
  const [invoice, businessContact] = RLeanState().select(({ state }) => [state.invoice, state.businessContact]);
  const [total, setTotal] = useState('--');
  const [loadingStatus, setLoadingStatus] = useState('');
  const { filters, setFilters } = useContext(FilterContext);

  const getInvoicesLast30Days = useInvoicesLast30Days();

  const cardData = {
    title: 'My Invoiced',
    loadingStatus,
    description: strings.invoiceCard
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  useEffect(() => {
    let isSubscribed = true;

    if (hasValue(invoice, 'data')) {
      // TODO: remove after testing
      // const filteredInvoices = Invoice.last30Days(
      //   filters.selectedWorkSite ? filters.selectedWorkSite.value : 0,
      //   invoice
      // );

      const filteredInvoices = getInvoicesLast30Days(
        filters.selectedWorkSite ? filters.selectedWorkSite.value : 0,
        invoice
      );

      const invoiceTotal = filteredInvoices.reduce((acc, curr) => {
        return acc + +curr.invoiceAmount;
      }, 0);

      if (isSubscribed) {
        setTotal(invoiceTotal ?? '--');
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [invoice, businessContact, filters.selectedWorkSite]);

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      if (!invoice || (hasValue(invoice, 'isLoading') && invoice.isLoading)) {
        setLoadingStatus(LOADING_STATUS.WAITING);
      } else if (invoice && hasValue(invoice, 'data')) {
        setLoadingStatus(LOADING_STATUS.LOADED);
      } else {
        setLoadingStatus(LOADING_STATUS.NODATA);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [invoice, total]);

  const formattedTotal = () => {
    return typeof total === 'number'
      ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)
      : total;
  };

  const setInvoiceFilter = () => {
    const updatedFilters = deepCopy(filters);
    updatedFilters.invoiceMetric.label = 'Last 30 days';
    updatedFilters.invoiceMetric.value = invoiceMetricType.LAST_30_DAYS;
    updatedFilters.invoiceMetric.showTag = true;
    setFilters(updatedFilters);
    navigate('/invoices');
  };

  const CardContent = () => {
    const total = formattedTotal();

    if (total === '--') {
      return (
        <span class='general-card-total not-invoiced'>
          <Textfit mode='single'>{total}</Textfit>
        </span>
      );
    }

    return (
      <button type='button' className={'link-button general-card-total invoiced'} onClick={setInvoiceFilter}>
        <Textfit mode='single'>{total}</Textfit>
      </button>
    );
  };

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      <CardContent />
    </BaseCard>
  );
});
