import { define } from '@rlean/core';
import { Company } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

const BusinessContactCompany = define<Company>(EntityKey.BusinessContactCompany, {
  getURL: '/Company/:id',
  persistData: false,
  includeInState: false
});

export default BusinessContactCompany;
