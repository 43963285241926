import { define } from '@rlean/core';
import { UserTracking as UserTrackingType } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

const UserTracking = define<UserTrackingType>(EntityKey.UserTracking, {
  postURL: '/ClientApplicationUsages',
  nullableParams: true
});

export default UserTracking;
