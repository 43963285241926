import React, { memo } from 'react';
import { ReportFilters } from 'components/shared';
import { RLeanState } from '@rlean/core';
import { getValue, hasValue } from '@rlean/utils';
import { reportNames, formats, getUniquePartNumbers, lookupPartIdsByPartNumber } from 'components/shared/SQReport';
import { downloadFileType, downloadFile } from 'lib/helpers';
import moment from 'moment';
import { useCurrentProject, useFilters } from 'lib/hooks';

const filterProperties = ['startDateTime', 'endDateTime', 'iChartPartIdList'];

export const IChart = memo(() => {
  const [activeProject, businessContact, projectParts] = RLeanState().select(({ state }) => [
    state.activeProject,
    state.businessContact,
    state.projectParts
  ]);

  const parts = getValue(projectParts, 'data.parts', []);
  const defaultParts = getUniquePartNumbers(parts);
  const currentProject = useCurrentProject();

  const formatParts = descriptions => {
    if (parts) {
      const allDescriptionIds = lookupPartIdsByPartNumber(descriptions, parts);

      return allDescriptionIds;
    }

    // Need some type of error handling here
    return [];
  };

  let defaultStartDate = new Date();
  let defaultEndDate = new Date();

  if (hasValue(currentProject, 'lastSortDate')) {
    defaultStartDate = new Date(currentProject.lastSortDate);
    defaultEndDate = new Date(currentProject.lastSortDate);
    defaultStartDate.setDate(defaultStartDate.getDate() - 30);
    defaultEndDate.setDate(defaultEndDate.getDate());
  }

  const { convertedFilters: filters, loadingFilters, updateFiltersWithFormData: updateFilters } = useFilters(
    {
      startDateTime: moment(defaultStartDate).startOf('day'),
      endDateTime: moment(defaultEndDate).endOf('day'),
      iChartPartIdList: defaultParts
    },
    filterProperties
  );

  const handleFiltersSubmit = formValues => {
    updateFilters(formValues);

    const {
      // projectId,
      // businessContactId,
      startDateTime,
      iChartPartIdList
    } = formValues;
    const endDateTime = formValues.endDateTime.endOf('day');

    const params = {
      start: startDateTime.toISOString(true),
      end: endDateTime.toISOString(true),
      partList: iChartPartIdList !== 'all' ? formatParts(iChartPartIdList)[0] : 0,
      BusinessContactId: businessContact.data.id,
      ProjectId: activeProject.id,
      format: formats.EXCELOPENXML,
      reportName: reportNames.ICHART
    };

    const options = {
      filename: `iChart-${activeProject.id}.xlsx`,
      endpoint: '/SsrsReportFiles',
      fileType: downloadFileType.EXCELOPENXML
    };

    downloadFile(options, params);
  };

  if (loadingFilters) {
    return <></>;
  }

  return (
    <ReportFilters
      handleFiltersSubmit={handleFiltersSubmit}
      defaultFilters={filters}
      handleFiltersClose={() => null}
      defaultParts={defaultParts}
      directDownload
    />
  );
});
