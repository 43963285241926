import React, { memo } from 'react';
import { RLeanState } from '@rlean/core';
import { hasValue } from '@rlean/utils';
import { BaseCard, LOADING_STATUS } from 'components/shared';
import { strings } from 'config';
import moment from 'moment';

export const HoursNotYetInvoiced = memo(() => {
  const [projectHoursNotYetInvoiced, activeProject] = RLeanState().select(({ state }) => [
    state.projectHoursNotYetInvoiced,
    state.activeProject
  ]);

  const getLoadingStatus = () => {
    let loadingStatus = LOADING_STATUS.NODATA;

    if (
      hasValue(activeProject, 'id') &&
      (!projectHoursNotYetInvoiced ||
        (hasValue(projectHoursNotYetInvoiced, 'isLoading') && projectHoursNotYetInvoiced.isLoading))
    ) {
      loadingStatus = LOADING_STATUS.WAITING;
    } else if (
      hasValue(activeProject, 'id') &&
      hasValue(projectHoursNotYetInvoiced, 'isLoading') &&
      !projectHoursNotYetInvoiced.isLoading &&
      hasValue(projectHoursNotYetInvoiced, 'data')
    ) {
      loadingStatus = LOADING_STATUS.LOADED;
    }

    return loadingStatus;
  };

  const arrayHasData = hasValue(projectHoursNotYetInvoiced, 'data') && projectHoursNotYetInvoiced.data.length > 0;
  const total = arrayHasData ? projectHoursNotYetInvoiced.data[0].unbilledHours : 0;
  const mostRecentStartTime = arrayHasData ? projectHoursNotYetInvoiced.data[0].mostRecentStartTime : new Date();
  const hours = total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const lastUpdated = moment(mostRecentStartTime).format('MM/DD/YYYY');
  const needSmallFont = hours && hours.length > 5 ? 'small' : '';

  const cardData = {
    title: strings.projectDetailsDocumentHoursNotInvoicedTitle,
    loadingStatus: getLoadingStatus(),
    description: `Total as of ${lastUpdated}`
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      <span className={`general-card-total not-invoiced ${needSmallFont}`}>{hours}</span>
      <span className='not-invoiced-disclaimer'>* Hours are estimated.</span>
    </BaseCard>
  );
});
