import { projectCardVersions } from './projectCardVersions';

export const version = 'v4';

export const projectCards = {
  configurationSettings: {
    order: projectCardVersions[version],
    version
  }
};
