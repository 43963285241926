import React from 'react';
import { Link } from '@reach/router';
import { formatDate } from 'lib/helpers';
import { Tag } from 'antd';
import { getTypeOfTag } from './utils';

export const getAllConcernColumns = columnExtraOptions => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: id => <Link to={`/concerns/${id}`}>{id}</Link>,
      sorter: (a, b) => a.id - b.id,
      ...columnExtraOptions('id')
    },
    {
      title: 'Submitted By',
      dataIndex: 'addedByBusinessContactDisplay',
      key: 'addedByBusinessContactDisplay',
      sorter: (a, b) => (a.addedByBusinessContactDisplay ?? '').localeCompare(b.addedByBusinessContactDisplay ?? ''),
      ...columnExtraOptions('addedByBusinessContactDisplay')
    },

    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a, b) => (a.subject ?? '').localeCompare(b.subject ?? ''),
      ...columnExtraOptions('subject')
    },
    {
      title: 'Status',
      dataIndex: 'companyConcernStatusName',
      key: 'companyConcernStatusName',
      sorter: (a, b) => (a.companyConcernStatusName ?? '').localeCompare(b.companyConcernStatusName ?? ''),
      render: (statusName, { companyConcernStatusId }) => (
        <Tag color={getTypeOfTag(companyConcernStatusId)}>{statusName}</Tag>
      ),
      ...columnExtraOptions('companyConcernStatusName')
    },
    {
      title: 'Category',
      dataIndex: 'companyConcernCategoryName',
      key: 'companyConcernCategoryName',
      sorter: (a, b) => (a.companyConcernCategoryName ?? '').localeCompare(b.companyConcernCategoryName ?? ''),
      ...columnExtraOptions('companyConcernCategoryName')
    },
    // {
    //   title: 'Project',
    //   dataIndex: 'projectId',
    //   key: 'projectId',
    //   className: 'groupless-column',
    //   render: projectId => projectId ?? '--',
    //   sorter: (a, b) => a - b
    // },
    {
      title: 'Created',
      dataIndex: 'addedAt',
      key: 'addedAt',
      render: addedAt => formatDate(addedAt),
      sorter: (a, b) => a.addedAt.localeCompare(b.addedAt),
      ...columnExtraOptions('addedAt')
    },
    {
      title: 'Last Updated',
      dataIndex: 'modifiedAt',
      key: 'modifiedAt',
      render: modifiedAt => formatDate(modifiedAt),
      sorter: (a, b) => new Date(a.modifiedAt) - new Date(b.modifiedAt),
      defaultSortOrder: 'descend',
      ...columnExtraOptions('modifiedAt')
    }
  ];
};
