import React, { FC, memo, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Layout, Button, Row, Col, Menu, Drawer } from 'antd';
import { navigate, Link } from '@reach/router';
import logoWhite from 'assets/SQ-logo-white.png';
import logoColor from 'assets/brand.png';
import { useIsAuthenticated } from '@azure/msal-react';
import { MenuOutlined, SolutionOutlined, UnlockOutlined, ClockCircleOutlined } from '@ant-design/icons';
import FontAwesome from 'react-fontawesome';
import { strings } from 'config';
import { msalInstance } from 'config/msal';

const LandingPage: FC<RouteComponentProps> = () => {
  const { Header, Content, Footer } = Layout;
  const [visible, setVisible] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, []);

  const handleSignIn = async () => {
    await msalInstance.loginRedirect();
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleGeneralSupport = (e: any) => {
    navigate('mailto:itrequests@stratospherequality.com?subject=Engage: General Support');
    e.currentTarget.blur();
  };

  const handleRequestAccount = (e: any) => {
    navigate('mailto:customerservice@stratospherequality.com?subject=Engage: Request New Account');
    e.currentTarget.blur();
  };

  const handleDrawerSupport = () => {
    navigate('mailto:itrequests@stratospherequality.com?subject=Engage: General Support');
    setVisible(false);
  };

  const handleDrawerRequest = () => {
    navigate('mailto:customerservice@stratospherequality.com?subject=Engage: Request New Account');
    setVisible(false);
  };

  const LandingSidebar = () => (
    <>
      <button className='link-button mobile-only' onClick={() => showDrawer()} type='button'>
        <MenuOutlined />
      </button>
      <Drawer
        className='main-sider landing-menu'
        placement='left'
        closable={false}
        onClose={() => onClose()}
        visible={visible}
      >
        <Row justify='center'>
          <Col span={24}>
            <Link to='/'>
              <img src={logoColor} alt='Stratosphere Quality logo' />
            </Link>
          </Col>
        </Row>
        <Menu>
          <Menu.Item onClick={handleSignIn}>
            <UnlockOutlined />
            Sign In
          </Menu.Item>
          <Menu.Item onClick={handleDrawerRequest}>
            <SolutionOutlined />
            Request an Account
          </Menu.Item>
          <Menu.Item onClick={handleDrawerSupport}>
            <ClockCircleOutlined />
            Support
          </Menu.Item>
        </Menu>
      </Drawer>
    </>
  );

  return (
    <Layout className='landing-section'>
      <Header className='landing-header'>
        <Row className='landing-app-bar' justify='space-between'>
          <Col span={6} className='left'>
            <span className='desktop-only'>
              <strong>SOLAR engage</strong>
            </span>
            <span className='mobile-only'>
              <LandingSidebar />
            </span>
          </Col>
          <Col span={10} className='middle'>
            <Link to='/'>
              <img src={logoWhite} alt='Stratosphere Quality logo' />
            </Link>
          </Col>
          <Col span={6} className='right'>
            <Button
              className='desktop-only'
              type='primary'
              role='link'
              onClick={e => handleGeneralSupport(e)}
              style={{ margin: '0 10px' }}
            >
              Support
            </Button>
            <Button className='desktop-only' type='primary' onClick={handleSignIn} style={{ margin: '0 10px' }}>
              Sign In
            </Button>
          </Col>
        </Row>
      </Header>
      <Content className='landing-content'>
        <Row justify='center' align='middle' style={{ height: '100%' }}>
          <Col span={12} className='landing-call-to-action'>
            <h1 className='landing-message' style={{ color: 'white', fontSize: '3em' }}>
              Welcome to SOLAR engage
            </h1>
            <Button type='primary' onClick={e => handleRequestAccount(e)} size='large' className='desktop-only'>
              Request an Account from Customer Service
            </Button>
            <Button type='primary' onClick={e => handleRequestAccount(e)} size='large' className='mobile-only'>
              Request an Account
            </Button>
          </Col>
        </Row>
      </Content>
      <Footer className='landing-footer'>
        <Row justify='space-between'>
          <Col span={24} xl={6} className='left'>
            <Row justify='space-around' className='landing-social-icons'>
              <Col span={6}>
                <a
                  href='https://www.facebook.com/Stratosphere-Quality-LLC-468960463193346/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='facebook-icon' />
                  <FontAwesome name='facebook-square' className='facebook-icon' />
                </a>
              </Col>
              <Col span={6}>
                <a
                  href='https://www.linkedin.com/company/stratosphere-quality'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='linkedin-icon' />
                  <FontAwesome name='linkedin-square' className='linkedin-icon' />
                </a>
              </Col>
              <Col span={6}>
                <a href='http://twitter.com/improveproducts' target='_blank' rel='noopener noreferrer'>
                  <FontAwesome name='twitter-square' className='twitter-icon' />
                </a>
              </Col>
            </Row>
            <Row justify='space-between' className='landing-terms'>
              <Col span={10}>
                <Link to='/static/terms-of-use'>{strings.termsOfUse}</Link>
              </Col>
              <Col span={10}>
                <Link to='/static/privacy-policy'>{strings.privacyPolicy}</Link>
              </Col>
            </Row>
          </Col>
          <Col span={24} xl={18} className='right'>
            <p>
              © Stratosphere Quality {new Date().getFullYear()} {strings.allRightsReserved}. <br />
              Version: {process.env.REACT_APP_BUILD_NUMBER}
            </p>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
};

export default memo(LandingPage);
