import React, { memo } from 'react';
import { Button } from 'antd';
import MaterialIcon from '@material/react-material-icon';

export const StartAProjectButton = memo(() => {
  const startAProject = () => {
    window.open('http://www.stratospherequality.com/start-a-project/', '_blank');
  };

  return (
    <Button type='primary' onClick={() => startAProject()} style={{ display: 'flex' }}>
      <span style={{ display: 'flex' }}>
        <MaterialIcon icon='playlist_add' style={{ display: 'inline' }} />
      </span>
      <span style={{ display: 'flex', marginLeft: 5, marginTop: 2 }}>Start A Project</span>
    </Button>
  );
});
