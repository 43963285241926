import React, { memo } from 'react';
import { RLeanState, usePut, useSave } from '@rlean/core';
import { hasValue, deepCopy } from '@rlean/utils';
import { EntityKey } from 'lib/enums/EntityKey';
import { message } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';

const AcceptButton = ({ id }) => {
  const put = usePut();
  const save = useSave();
  const [businessContact, pendingWorkInstruction] = RLeanState().select(({ state }) => [
    state.businessContact,
    state.pendingWorkInstruction
  ]);

  const handleClick = () => {
    message.loading('Accepting Work Instruction...', 0);

    put(
      {
        key: EntityKey.PendingWorkInstruction,
        params: { id },
        body: {
          accepted: true,
          reviewerNote: '',
          reviewerBusinessContactId: businessContact.data.id,
          id
        }
      },
      response => {
        if (response.status === 400 || response.status === 500) {
          message.destroy();
          message.error('There was an error rejecting the work instruction.');
        } else {
          message.destroy();

          if (hasValue(pendingWorkInstruction, 'data')) {
            const pendingWorkInstructions = pendingWorkInstruction.data.filter(instruction => instruction.id !== id);
            const updatedPendingWorkInstructions = deepCopy(pendingWorkInstruction);

            updatedPendingWorkInstructions.data = pendingWorkInstructions;

            save({ key: EntityKey.PendingWorkInstruction, value: updatedPendingWorkInstructions });

            message.success('Work Instruction Accepted', 3);
          }
        }
      }
    );
  };

  return (
    <button type='button' className='action-button' onClick={() => handleClick(id)}>
      <CheckCircleTwoTone twoToneColor='#b2bb1c' title='Accept Work Instructions' />
    </button>
  );
};

export default memo(AcceptButton);
