import React, { memo } from 'react';
import FontAwesome from 'react-fontawesome';
import { Menu, Dropdown, Layout, Row, Col, Card } from 'antd';
import { Link, navigate } from '@reach/router';
import { LogoutOutlined, CaretDownOutlined } from '@ant-design/icons';
import { RLeanState } from '@rlean/core';
import { getValue, hasValue, uuid } from '@rlean/utils';
import { Auth } from 'lib/utilities';
import { strings } from 'config';
import { SidebarNavigation } from './SidebarNavigation';
import ImpersonateMenuItem from '../ImpersonateMenuItem';

export const Sidebar = memo(({ mobileNavClick }) => {
  const { Header, Content, Footer } = Layout;
  const [businessContact, impersonation, userDescription] = RLeanState().select(({ state }) => [
    state.businessContact,
    state.impersonation,
    state.userDescription
  ]);

  const signOut = async navigateTo => {
    await Auth.signOut(navigateTo);
  };

  const impersonatingBusinessContact = () => {
    if (
      impersonation.isImpersonating &&
      hasValue(businessContact, 'data.displayName') &&
      hasValue(businessContact, 'data.companyName')
    ) {
      return (
        <Card className='impersonating-business-contact'>
          As {`${businessContact.data.displayName} - ${businessContact.data.companyName}`}
        </Card>
      );
    }

    return <></>;
  };

  const menu = (
    <Menu className='user-dropdown-menu'>
      <ImpersonateMenuItem />
      <Menu.Item key={uuid()}>
        <button className='link-button menu-link-button' type='button' onClick={() => signOut(navigate)}>
          <LogoutOutlined className='menu-link-icon' /> {strings.signOut}
        </button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Header>
        <Dropdown overlay={menu} trigger={['click']} placement='bottomCenter'>
          <a className='ant-dropdown-link' href='#!'>
            {getValue(userDescription, 'data.displayName', '')}
            <CaretDownOutlined />
          </a>
        </Dropdown>
      </Header>
      <Content>
        {impersonatingBusinessContact()}
        <SidebarNavigation mobileNavClick={mobileNavClick} />
      </Content>
      <Footer>
        <Card>
          <Row type='flex' justify='space-around' className='sidebar-social-icons'>
            <Col>
              <a
                href='https://www.facebook.com/Stratosphere-Quality-LLC-468960463193346/'
                className='link-small'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='facebook-icon' />
                <FontAwesome name='facebook' className='facebook-icon' />
              </a>
            </Col>
            <Col>
              <a
                href='https://www.linkedin.com/company/stratosphere-quality'
                className='link-small'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='linkedin-icon' />
                <FontAwesome name='linkedin' className='linkedin-icon' />
              </a>
            </Col>
            <Col>
              <a
                href='http://twitter.com/improveproducts'
                className='link-small'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FontAwesome name='twitter' className='twitter-icon' />
              </a>
            </Col>
          </Row>
          <Row type='flex' justify='space-between' className='terms'>
            <Link className='link-small' to='/terms-of-use'>
              {strings.termsOfUse}
            </Link>
            <Link className='link-small' to='/privacy-policy'>
              {strings.privacyPolicy}
            </Link>
          </Row>
          <Row className='copyright'>
            © Stratosphere Quality {new Date().getFullYear()} {strings.allRightsReserved}.
          </Row>
        </Card>
      </Footer>
    </Layout>
  );
});
