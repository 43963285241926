import React, { memo, useEffect, useState } from 'react';
import { RLeanState } from '@rlean/core';
import { hasValue } from '@rlean/utils';
import { notification } from 'antd';
import { actionItemType } from './actionItemType';
import { ReviewLink } from './ReviewLink';

export const PendingActionItem = memo(() => {
  const [
    activeProject,
    pendingWorkAuthorization,
    pendingWorkInstruction,
    project
  ] = RLeanState().select(({ state }) => [
    state.activeProject,
    state.pendingWorkAuthorization,
    state.pendingWorkInstruction,
    state.project
  ]);
  const [shouldRenderWorkInstruction, setShouldRenderWorkInstruction] = useState(true);
  const [shouldRenderWorkAuthorization, setShouldRenderWorkAuthorization] = useState(true);

  useEffect(() => {
    let isSubscribed = true;

    if (
      hasValue(pendingWorkInstruction, 'data') &&
      hasValue(activeProject, 'id') &&
      hasValue(project, 'data') &&
      project.data.find(x => x.projectId === activeProject.id) &&
      shouldRenderWorkInstruction &&
      isSubscribed
    ) {
      setShouldRenderWorkInstruction(false);
      // delay loading the notification
      setTimeout(() => {
        pendingWorkInstruction.data.map(item => {
          if (item.projectId === activeProject.id) {
            const args = {
              message: 'Pending Work Instruction',
              description: (
                <ReviewLink
                  type={actionItemType.WORK_INSTRUCTION}
                  id={item.id}
                  workInstructionRevisionId={item.workInstructionRevisionId}
                />
              )
            };

            return notification.open(args);
          }

          return null;
        });
      }, 2000);
    }

    return () => {
      isSubscribed = false;
    };
  }, [project, activeProject, pendingWorkInstruction, shouldRenderWorkInstruction]);

  useEffect(() => {
    let isSubscribed = true;

    if (
      hasValue(pendingWorkAuthorization, 'data') &&
      hasValue(activeProject, 'id') &&
      hasValue(project, 'data') &&
      project.data.find(x => x.projectId === activeProject.id) &&
      shouldRenderWorkAuthorization &&
      isSubscribed
    ) {
      setShouldRenderWorkAuthorization(false);
      // delay loading the notification
      setTimeout(() => {
        pendingWorkAuthorization.data.map(item => {
          if (item.projectId === activeProject.id) {
            const args = {
              message: 'Pending Work Authorization',
              description: <ReviewLink type={actionItemType.WORK_AUTHORIZATION} id={item.id} />
            };

            return notification.open(args);
          }

          return null;
        });
      }, 2000);
    }

    return () => {
      isSubscribed = false;
    };
  }, [project, activeProject, pendingWorkAuthorization, shouldRenderWorkAuthorization]);

  return <></>;
});
