import React, { memo, useContext } from 'react';
import { navigate } from '@reach/router';
import { useSave, RLeanState, useGet } from '@rlean/core';
import { hasValue, deepCopy, uuid } from '@rlean/utils';
import { Skeleton, Card, Dropdown, Menu, Table } from 'antd';
import MaterialIcon from '@material/react-material-icon';
import { EntityKey } from 'lib/enums/EntityKey';
import { exportToCsv, formatDate } from 'lib/helpers';
import { strings } from 'config';
import ProjectMobileCards from './ProjectMobileCards';
import { getProjectTableColumns } from './getProjectTableColumns';
import TableSearchOptions from '../_shared/TableSearchOptions';
import { FilterContext } from 'components/shared/FiltersContext';

export const ProjectsSearch = memo(() => {
  const [historicProject, businessContact, projectTableColumns] = RLeanState().select(({ state }) => [
    state.historicProject,
    state.businessContact,
    state.projectTableColumns
  ]);
  const { filters, setFilters } = useContext(FilterContext);
  const save = useSave();
  const get = useGet();

  const searchResults = () => {
    if (hasValue(historicProject, 'data')) {
      return historicProject.data;
    }

    return [];
  };

  const searchOptions = [
    {
      key: 'projectId',
      placeholder: 'Project Number',
      initValue: filters.projectId.value
    },
    {
      key: 'customerReferenceNumber',
      placeholder: 'Customer Reference Number',
      initValue: filters.customerReferenceNumber.value
    },
    {
      key: 'partNumber',
      placeholder: 'Part Number',
      initValue: filters.partNumber.value
    }
  ];

  const search = searchValues => {
    const updatedFilters = deepCopy(filters);
    const params = {
      businessContactId: businessContact.data.id
    };

    searchOptions.forEach(opt => {
      params[opt.key] = searchValues[opt.key];
      updatedFilters[opt.key].value = searchValues[opt.key];
    });

    setFilters(updatedFilters);
    get({ key: EntityKey.HistoricProject, params });
  };

  const setActiveProject = project => {
    save({ key: EntityKey.ActiveProject, value: { id: project } });
  };

  const goToProjectDetails = project => {
    save({ key: EntityKey.ActiveProject, value: { id: project } }, () => {
      save({ key: EntityKey.ProjectContacts, value: null });
      save({ key: EntityKey.ProjectHoursNotYetInvoiced, value: null });
      save({ key: EntityKey.ProjectParts, value: null });
      save({ key: EntityKey.ProjectReports, value: null });
      save({ key: EntityKey.ProjectTrainingLog, value: null });
      save({ key: EntityKey.ProjectWorkInstructions, value: null });
    });

    navigate('/project/details');
  };

  const columns = getProjectTableColumns(projectTableColumns, setActiveProject, goToProjectDetails, () => {}, [], null);

  const menu = (
    <Menu>
      <Menu.Item
        key={uuid()}
        onClick={() => exportToCsv(`Projects-${formatDate(new Date())}`, columns, searchResults())}
      >
        Export to CSV
      </Menu.Item>
    </Menu>
  );

  const extra = (
    <Dropdown className='export-to-csv' overlay={menu} trigger={['click']} placement='bottomRight'>
      <button className='link-button' type='button'>
        <MaterialIcon role='button' icon='more_vert' />
      </button>
    </Dropdown>
  );

  const SearchTable = () => {
    if (historicProject && historicProject.isLoading) {
      return (
        <div style={{ marginLeft: 25, marginRight: 25, marginBottom: 10 }}>
          <Skeleton active />
        </div>
      );
    }

    if (historicProject && historicProject.data) {
      return (
        <Table
          className='project-table hide-on-mobile'
          rowClassName={projectRow =>
            projectRow.projectStatus === 'A' ? 'table-active-project' : 'table-closed-project'
          }
          scroll={{ x: true }}
          columns={columns}
          dataSource={searchResults()}
        />
      );
    }

    return <></>;
  };

  const renderSearch = () => {
    return (
      <>
        <Card
          id='project_table'
          className='table-card'
          style={{ marginTop: 25 }}
          title={strings.projectsSearchResults}
          extra={extra}
        >
          <TableSearchOptions needsInput searchOptions={searchOptions} callback={search} />
          <SearchTable />
        </Card>
        <div className='mobile-cards'>
          <ProjectMobileCards projects={searchResults()} />
        </div>
      </>
    );
  };

  return <>{renderSearch()}</>;
});
