import React, { createContext, useRef } from 'react';

export const DrilldownContext = createContext({});

export const DrilldownContextProvider = ({ children }) => {
  const getExportedChartInPNG = useRef(null);

  const value = {
    getExportedChartInPNG
  };

  return <DrilldownContext.Provider value={value}>{children}</DrilldownContext.Provider>;
};
