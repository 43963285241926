import { dashboardCardVersions } from './dashboardCardVersions';

export const version = 'v1';

export const dashboardCards = {
  configurationSettings: {
    order: dashboardCardVersions[version],
    version
  }
};
