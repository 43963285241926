import React, { memo, useState, useEffect } from 'react';
import { Card, Row, Col, Menu } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { getValue, deepCopy, hasValue } from '@rlean/utils';
import { RLeanState } from '@rlean/core';
import { formatDate } from 'lib/helpers';
import { SettingsMenu } from './SettingsMenu';
import { isHignNonConforming } from '../getProjectTableColumns';

export const ProjectCards = memo(() => {
  const [project, userConfiguration] = RLeanState().select(({ state }) => [state.project, state.userConfiguration]);
  const [filteredProjects, setFilteredProjects] = useState();
  const [hideNoDataProjects, setHideNoDataProjects] = useState(false);
  const [fallout, setFallout] = useState(null);
  const projectData = getValue(project, 'data', []);

  useEffect(() => {
    setFilteredProjects(projectData);
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    if (hasValue(userConfiguration, 'data') && isSubscribed) {
      const savedFallout = userConfiguration.data.find(x => x.name === 'fallout');

      if (savedFallout) {
        setFallout(JSON.parse(savedFallout.configurationSettings).percentage);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [userConfiguration]);

  const getCards = () => {
    if (!filteredProjects) {
      return <></>;
    }

    const projectDataSorted = deepCopy(filteredProjects);

    projectDataSorted.sort((a, b) => b.projectId - a.projectId);

    const closeCard = projectId => {
      const updatedProjects = filteredProjects.filter(x => x.projectId !== projectId);

      setFilteredProjects(updatedProjects);
    };

    const visibleProjects = hideNoDataProjects
      ? projectDataSorted.filter(project => project.inspected)
      : projectDataSorted;

    return visibleProjects.map(item => {
      const lastSortColumnClass = item.lastSortDate ? 'card-view-blue' : 'card-view-blue hide';

      const referenceNumber =
        item.customerReference && item.customerReference.trim() !== '' ? ` - Ref #${item.customerReference}` : '';

      const cardClass =
        item.fallout && item.fallout * 100 >= fallout
          ? { width: '100%', border: '1px solid #dd6675' }
          : { width: '100%' };

      const textColor = isHignNonConforming(item.fallout * 100, fallout)
        ? { color: '#ff4d4f' }
        : { color: 'rgba(0, 0, 0, 0.85)' };

      return (
        <Col className='gutter-row' span={6}>
          <Card
            className='card-view-card'
            title={`Project #${item.projectId}${referenceNumber}`}
            bordered={false}
            style={cardClass}
            extra={
              <button onClick={() => closeCard(item.projectId)} className='link-button' type='button'>
                <CloseOutlined />
              </button>
            }
          >
            <div style={{ fontSize: 'smaller' }}>
              <b>Start Date:</b> {formatDate(item.startDate)}
              <br />
              <b>Work Site Name:</b> {item.workSiteCompanyName}
              <br />
              <b>Customer/Supplier Name:</b> {item.customerSupplierName}
              <br />
              <b>Part Number:</b> {item.partNumber ?? '--'}
              <br />
              <b>Criteria Name:</b> {item.criteriaName ?? '--'}
              <br />
              <b>Last Defect Found:</b> {item.lastDefectDate ? formatDate(item.lastDefectDate) : '--'}
              <br />
              <b>Clean Point Detail:</b> {item.cleanPointDetail ?? '--'}
              <table style={{ width: '100%', margin: '10px 0' }}>
                <tr>
                  <td className={lastSortColumnClass}>
                    <b>{item.lastSortDate ? formatDate(item.lastSortDate) : '--'}</b>
                  </td>

                  <td className={lastSortColumnClass} />
                  <td className='card-view-yellow'>
                    <b>Totals</b>
                  </td>
                  <td className='card-view-yellow' />
                </tr>
                <tr>
                  <td className={lastSortColumnClass}>Inspected:</td>
                  <td className={lastSortColumnClass}>{item.lastSortDateInspectedTotal ?? '--'}</td>
                  <td className='card-view-yellow'>Inspected:</td>
                  <td className='card-view-yellow'>{item.inspected ?? '--'}</td>
                </tr>
                <tr>
                  <td className={lastSortColumnClass}>Non-Conforming:</td>
                  <td className={lastSortColumnClass}>{item.lastSortDateNonConformTotal ?? '--'}</td>
                  <td className='card-view-yellow'>Non-Conforming:</td>
                  <td className='card-view-yellow'>
                    <div style={textColor}>{item.defects ?? '--'}</div>
                  </td>
                </tr>
                <tr>
                  <td className={lastSortColumnClass}>Reworked:</td>
                  <td className={lastSortColumnClass}>{item.lastSortDateReworkTotal ?? '--'}</td>
                  <td className='card-view-yellow'>Reworked:</td>
                  <td className='card-view-yellow'>{item.reworked ?? '--'}</td>
                </tr>
                <tr>
                  <td className={lastSortColumnClass}>Good:</td>
                  <td className={lastSortColumnClass}>{item.lastSortDateGoodTotal ?? '--'}</td>
                  <td className='card-view-yellow'>Good:</td>
                  <td className='card-view-yellow'>{item.good ?? '--'}</td>
                </tr>
                <tr>
                  <td className={lastSortColumnClass} />
                  <td className={lastSortColumnClass} />
                  <td className='card-view-yellow'>Fallout:</td>
                  <td className='card-view-yellow'>{item.fallout ? (item.fallout * 100).toFixed(2) : '--'}</td>
                </tr>
              </table>
            </div>
          </Card>
        </Col>
      );
    });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setFilteredProjects(projectData)}>
        <button type='button' className='link-button' style={{ width: '100%', textAlign: 'left' }}>
          Restore removed projects
        </button>
      </Menu.Item>
      <Menu.Item onClick={() => setHideNoDataProjects(previousState => !previousState)}>
        <button type='button' className='link-button' style={{ width: '100%', textAlign: 'left' }}>
          {hideNoDataProjects ? 'Unhide' : 'Hide'} projects with no data
        </button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <SettingsMenu menu={menu} />
      <Row style={{ marginTop: 15 }} gutter={[16, 24]}>
        {getCards()}
      </Row>
    </>
  );
});
