import { msalApiFetch, getToken } from './msal';
import { strings } from './strings';
import { pages } from './pages';
import { cards } from './cards';
import { reports } from './reports';

export const apiUri = path => {
  return process.env.REACT_APP_API_URI + path;
};

export const refreshInterval = 300000;

export { msalApiFetch, cards, reports, getToken, pages, strings };
export * from './rLean';
