import React, { memo, useEffect, useState } from 'react';
import { useGet, RLeanState } from '@rlean/core';
import { getValue } from '@rlean/utils';
import { navigate } from '@reach/router';
import { pages, strings } from 'config';
import PlusIcon from '@ant-design/icons/PlusOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { useActivePage } from 'lib/hooks';
import { Card, Table, Button, Input } from 'antd';
import { getAllConcernColumns } from './ConcernTableColumns';
import { CONCERN_STATUS } from './utils';

// Enums
import { EntityKey } from 'lib/enums/EntityKey';

/**
 * Concerns
 * Component that shows a table with the list of concerns available.
 * The concerns, by default, are only the open concerns and concerns closed
 * within 30 days.
 */
export const Concerns = memo(() => {
  const get = useGet();
  const [companyConcerns, businessContact] = RLeanState().select(({ state }) => [
    state.companyConcerns,
    state.businessContact
  ]);

  // Store the input values which are used for searching
  const [searchInputs, setSearchInputs] = useState({});

  // Store the dataIndex selected for search
  const [selectedDataIndexSearch, setSelectedDataIndexSearch] = useState();

  const isMobileUser = getValue(businessContact, 'data.isMobileUser', false);

  if (isMobileUser) {
    navigate('/unauthorized');
  }

  useActivePage(pages.concerns);

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      const businessContactCompanyId = getValue(businessContact, 'data.companyId', null);
      get({ key: EntityKey.CompanyConcerns, params: { companyId: businessContactCompanyId } });
    }

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Go to create concern view
  const goToCreateConcernView = () => {
    navigate('/concerns/new');
  };

  // Handle the change of a column search
  const handleColumnSearchChange = (value, dataIndex) => {
    setSearchInputs(previousState => ({ ...previousState, [dataIndex]: value }));
  };

  // Use an specific dataIndex for search
  const handleSearch = dataIndex => {
    setSelectedDataIndexSearch(dataIndex);
  };

  // Clean all the search fields
  const handleReset = () => {
    setSearchInputs({});
    setSelectedDataIndexSearch();
  };

  // The column titles that will have a search functionality
  // currently only the subject dataIndex has the search functionality
  const columnTitles = [
    {
      dataIndex: 'subject',
      title: 'Subject'
    }
  ];

  // Add search to the subject column
  const getColumnSearchProps = dataIndex => {
    if (!columnTitles.some(col => col.dataIndex === dataIndex)) {
      return {};
    }
    return {
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${columnTitles.find(column => column.dataIndex === dataIndex).title}`}
            value={searchInputs[dataIndex] ?? ''}
            onChange={e => handleColumnSearchChange(e.target.value, dataIndex)}
            onPressEnter={() => handleSearch(dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <div>
            <Button
              type='primary'
              onClick={() => handleSearch(dataIndex)}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90, marginRight: 10 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset()} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    };
  };

  const filteredConcerns = () => {
    let filteredList = getValue(companyConcerns, 'data', []);
    if (selectedDataIndexSearch && searchInputs[selectedDataIndexSearch]) {
      filteredList = filteredList.filter(
        item =>
          item[selectedDataIndexSearch] &&
          item[selectedDataIndexSearch]
            .toString()
            .toLowerCase()
            .includes(searchInputs[selectedDataIndexSearch].toLowerCase())
      );
    }
    return filteredList;
  };

  // define columns
  const columns = getAllConcernColumns(getColumnSearchProps);

  // extra button to add a new Concern
  const addNewConcernButton = (
    <Button type='primary' icon={<PlusIcon />} onClick={goToCreateConcernView}>
      Add
    </Button>
  );

  // Returns the classname of a highlighted row
  const highlightRow = concern => {
    return concern.companyConcernStatusId === CONCERN_STATUS.WAITING_ON_CUSTOMER ? 'highlighted' : '';
  };

  return (
    <>
      <div>
        <p style={{ fontWeight: 500 }}>* Includes open concerns and concerns in the past 30 days.</p>
      </div>
      <Card className='table-card' extra={addNewConcernButton} title={strings.concernsCardTitle}>
        <Table scroll={{ x: true }} columns={columns} dataSource={filteredConcerns()} rowClassName={highlightRow} />
      </Card>
    </>
  );
});
