import React, { memo } from 'react';
import { ProjectsTable } from '../ProjectsTable';

export const FullscreenTable = memo(() => {
  const removeAnimationClass = () => {
    const elements = document.querySelectorAll(
      '[class^="table-project-column-updated"],[class*=" table-project-column-updated"]'
    );
    Array.from(elements).forEach(element => {
      element.className = element.className.replace(/table-project-column-updated.+?/g, '');
    });
  };

  return (
    <>
      <ProjectsTable isFullscreen removeAnimationClass={removeAnimationClass} />
    </>
  );
});
