import { RLeanState } from '@rlean/core';
import { getValue } from '@rlean/utils';

export default function useCurrentProject() {
  const [activeProject, project, historicProject] = RLeanState().select(({ state }) => [
    state.activeProject,
    state.project,
    state.historicProject
  ]);

  const projects = getValue(project, 'data', []);
  const historicProjects = getValue(historicProject, 'data', []);
  const currentProject = projects.some(item => item.projectId === activeProject.id)
    ? projects.find(item => item.projectId === activeProject.id)
    : historicProjects.find(item => item.projectId === activeProject.id);

  return currentProject;
}
