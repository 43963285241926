import React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Row, Col, Avatar } from 'antd';
import MaterialIcon from '@material/react-material-icon';

const DragHandle = SortableHandle(() => {
  return (
    <Avatar shape='square' size={56} className='handle'>
      <MaterialIcon icon='open_with' className='avatar-icon' style={{ fontSize: 40, color: '#41b0a6' }} />
    </Avatar>
  );
});

const Card = SortableElement(({ removeCard, card, editMode }) => {
  const handleClick = () => {
    removeCard(card.key);
  };

  return (
    <Col xl={8} className={`sortable-card ${editMode ? 'user-select-none' : ''}`}>
      {card.component}
      {editMode && (
        <>
          <div className='overlay' />
          <Avatar shape='square' size={48} className='trash' onClick={handleClick}>
            <MaterialIcon icon='delete' className='avatar-icon' style={{ fontSize: 32, color: '#dd6675' }} />
          </Avatar>
          <DragHandle />
        </>
      )}
    </Col>
  );
});

export const CardGrid = SortableContainer(({ removeCard, cards, editMode }) => {
  return (
    <Row gutter={[15, 15]}>
      {(cards || []).map((card, index) => (
        <Card
          card={card}
          key={card.key}
          index={index}
          editMode={editMode}
          disabled={!editMode}
          removeCard={removeCard}
        />
      ))}
    </Row>
  );
});
