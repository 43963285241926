import React, { memo } from 'react';
import MaterialIcon from '@material/react-material-icon';

const AuthorizationDetails = authorization => {
  const {
    approved,
    businessContactName,
    businessContactEmail,
    declinedExplanation,
    declinedReason,
    respondedAt
  } = authorization;

  const getStatus = () => {
    let status;

    if (approved) {
      status = 'Approved';
    } else if (!respondedAt) {
      status = 'Pending';
    } else {
      const reason = declinedReason ?? declinedExplanation;
      const reasonText = reason ? `: ${reason}` : '';
      status = `Declined${reasonText}`;
    }

    return status;
  };

  return (
    <div>
      <div>
        <MaterialIcon icon='person' style={{ fontSize: 15 }} />
        <span className='directory-contact-info' style={{ marginLeft: 10 }}>
          {businessContactName}
        </span>
      </div>
      <div>
        <MaterialIcon icon='mail_outline' style={{ fontSize: 15 }} />
        <a href={`mailto:${businessContactEmail}`} className='directory-contact-info' style={{ marginLeft: 10 }}>
          {businessContactEmail}
        </a>
      </div>
      <div>
        <MaterialIcon icon='info' style={{ fontSize: 15 }} />
        <span className='directory-contact-info' style={{ marginLeft: 10 }}>
          {getStatus()}
        </span>
      </div>
    </div>
  );
};

export default memo(AuthorizationDetails);
