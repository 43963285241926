import React, { memo } from 'react';
import { Card } from 'antd';
import parse from 'html-react-parser';
import { useActivePage, useImpersonateRedirect, useRequireMobileUser, useWindowSize } from 'lib/hooks';
import { pages, strings } from 'config';
import howToImpersonate from 'assets/howToImpersonate.png';
import findAProjectForm from 'assets/findAProjectForm.png';
import projectSearchResults from 'assets/projectSearchResults.png';

const StratosphereQualityHome = () => {
  useImpersonateRedirect();
  useRequireMobileUser();
  useActivePage(pages.stratosphereQualityHome);
  const [width] = useWindowSize();

  return (
    <Card className='page-card' title='Stratosphere Quality Home'>
      {parse(strings.stratosphereQualityHomeIntro)}

      {/** Impersonation */}
      <div style={{ display: 'flex', paddingTop: 30 }}>
        <img src={howToImpersonate} alt='How to impersonate' style={{ height: 200, width: 'auto' }} />
        <div style={{ marginLeft: 30 }}>{parse(strings.stratosphereQualityHomeImpersonate)}</div>
      </div>
      {/** End Impersonation */}

      {/** Find a Project form */}
      <div style={{ display: 'flex' }}>
        <p style={{ marginTop: 70, maxWidth: 400, marginLeft: 20, display: width > 900 ? 'block' : 'none' }}>
          {strings.stratosphereQualityHomeFindAProject}
        </p>
        <div>
          <p style={{ marginTop: 40, display: width < 901 ? 'block' : 'none' }}>
            {strings.stratosphereQualityHomeFindAProject}
          </p>
          <img src={findAProjectForm} alt='Find a Project form' style={{ height: 500, width: 'auto' }} />
        </div>
      </div>
      {/** End Find a Project form */}

      {/** Project Search results */}
      <div style={{ display: 'flex' }}>
        <p style={{ maxWidth: 400, marginTop: 115, marginLeft: 20 }}>
          {strings.stratosphereQualityHomeProjectSearchResults}
        </p>
        <div style={{ display: 'block', width: 600 }}>
          <p style={{ textAlign: 'right' }}>{strings.stratosphereQualityHomeProjectSearchResultsDownload}</p>
          <img src={projectSearchResults} alt='Project Search Results' style={{ height: 'auto', width: 600 }} />
        </div>
      </div>
      {/** End Project Search results */}
    </Card>
  );
};

export default memo(StratosphereQualityHome);
