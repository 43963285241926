import React, { memo } from 'react';
import { RLeanState } from '@rlean/core';
import { hasValue, getValue, formatPhoneNumber, removeNonNumericChars } from '@rlean/utils';
import { BaseCard, LOADING_STATUS, BaseList, BaseListItem } from 'components/shared';
import MaterialIcon from '@material/react-material-icon';
import { strings } from 'config';

export const Directory = memo(() => {
  const [projectContacts, activeProject] = RLeanState().select(({ state }) => [
    state.projectContacts,
    state.activeProject
  ]);

  const listData = getValue(projectContacts, 'data', []);

  const getLoadingStatus = () => {
    let loadingStatus = LOADING_STATUS.NODATA;

    if (
      hasValue(activeProject, 'id') &&
      (!projectContacts || (hasValue(projectContacts, 'isLoading') && projectContacts.isLoading))
    ) {
      loadingStatus = LOADING_STATUS.WAITING;
    } else if (
      hasValue(activeProject, 'id') &&
      hasValue(projectContacts, 'isLoading') &&
      !projectContacts.isLoading &&
      hasValue(projectContacts, 'data')
    ) {
      loadingStatus = LOADING_STATUS.LOADED;
    }

    return loadingStatus;
  };

  const cardData = {
    title: strings.projectDetailsDirectoryTitle,
    loadingStatus: getLoadingStatus(),
    description: null
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  const Title = ({ contactType, firstName, lastName }) => {
    return (
      <>
        <strong>{contactType}:</strong> {firstName} {lastName}
      </>
    );
  };

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      <BaseList
        data={listData}
        renderItem={item => (
          <BaseListItem
            title={<Title contactType={item.contactType} firstName={item.firstName} lastName={item.lastName} />}
          >
            <div>
              <MaterialIcon icon='local_phone' style={{ fontSize: 18 }} />
              <a
                href={`tel:${removeNonNumericChars(item.mobilePhone)}`}
                className='directory-contact-info link-small'
                style={{ marginLeft: 10 }}
              >
                {formatPhoneNumber(item.mobilePhone)}
              </a>
            </div>
            <div>
              <MaterialIcon icon='mail_outline' style={{ fontSize: 15 }} />
              <a href={`mailto:${item.email}`} className='directory-contact-info' style={{ marginLeft: 10 }}>
                {item.email}
              </a>
            </div>
          </BaseListItem>
        )}
      />
    </BaseCard>
  );
});
