import React, { memo } from 'react';
import { Breadcrumb } from 'antd';
import { HomeFilled } from '@ant-design/icons';
import { RLeanState } from '@rlean/core';
import { getValue } from '@rlean/utils';
import { Link } from '@reach/router';
import { strings } from 'config/strings';

export const Breadcrumbs = memo(() => {
  const [activeProject] = RLeanState().select(({ state }) => [state.activeProject]);

  const projectId = getValue(activeProject, 'id', '');

  return (
    <Breadcrumb separator='>'>
      <Breadcrumb.Item>
        <Link to='/dashboard'>
          <HomeFilled />
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link className='link-button link-small' to='/projects'>
          {strings.projectDetailsBreadcrumbsTitle}
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        {strings.projectDetailsBreadcrumbsItem} #{projectId}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
});
