import React, { memo } from 'react';
import { RLeanState } from '@rlean/core';
import { getValue } from '@rlean/utils';
import { Button, Col, Row, Table } from 'antd';
import MaterialIcon from '@material/react-material-icon';
import { exportToCsv, downloadFile } from 'lib/helpers';
import moment from 'moment';

// Hooks
import { useProject } from 'lib/hooks/useProject';

export const getInvoicedData = ({
  invoice,
  activeProject,
  workSite,
  project,
  historicProject,
  isSupplier,
  isImposer
} = {}) => {
  let tableData = [];
  let recentInvoiceDate = moment(new Date()).format('MM/DD/YYYY');
  let invoicedTotal = '--';
  const workSites = getValue(workSite, 'data', []);
  const projectId = getValue(activeProject, 'id', null);
  let currentProject = getValue(project, 'data', []).find(x => x.projectId === projectId);
  const filteredList = getValue(invoice, 'data', []).filter(x => +x.projectId === +projectId);

  // if project doesn't exist in project array, check historic project
  if (!currentProject) {
    currentProject = getValue(historicProject, 'data', []).find(x => x.projectId === projectId);
  }

  const isDisabled = currentProject
    ? isSupplier(currentProject, workSites) || isImposer(currentProject, workSites)
    : true;

  if (!isDisabled && filteredList && filteredList.length > 0) {
    const total = filteredList.reduce((state, value) => state + value.invoiceAmountUsd, 0);
    const recentDate = filteredList.reduce(
      (state, value) => (state > value.invoiceDate ? state : value.invoiceDate),
      0
    );

    tableData = filteredList;
    invoicedTotal = total.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    recentInvoiceDate = moment(recentDate).format('MM/DD/YYYY');
  }

  return { tableData, recentInvoiceDate, invoicedTotal, isDisabled };
};

export const InvoicedTable = memo(() => {
  const [invoice, activeProject, project, workSite, historicProject] = RLeanState().select(({ state }) => [
    state.invoice,
    state.activeProject,
    state.project,
    state.workSite,
    state.historicProject
  ]);
  const { isSupplier, isImposer } = useProject();

  const { tableData } = getInvoicedData({
    invoice,
    activeProject,
    project,
    workSite,
    historicProject,
    isSupplier,
    isImposer
  });

  const getInvoice = id => {
    const currentInvoice = invoice.data.find(x => x.id === id);

    if (currentInvoice) {
      return {
        id,
        invoiceNumber: currentInvoice.invoiceNumber,
        invoiceDate: currentInvoice.invoiceDate,
        projectId: currentInvoice.projectId
      };
    }

    return null;
  };

  const downloadInvoice = id => {
    const currentInvoice = getInvoice(id);
    const options = {
      endpoint: `/InvoiceFiles?projectId=${currentInvoice.projectId}&invoiceDate=${currentInvoice.invoiceDate}&invoiceNumber=${currentInvoice.invoiceNumber}`,
      filename: `Invoice-${currentInvoice.invoiceNumber}`
    };

    if (currentInvoice) {
      downloadFile(options);
    }
  };

  const downloadLaborDetail = id => {
    const currentInvoice = getInvoice(id);

    if (currentInvoice) {
      const options = {
        endpoint: `/InvoiceLaborFiles?invoiceNumber=${currentInvoice.invoiceNumber}&invoiceId=${currentInvoice.id}`,
        filename: `Invoice-Labor-${currentInvoice.invoiceNumber}`
      };

      downloadFile(options);
    }
  };

  const invoicedColumns = [
    {
      title: '',
      dataIndex: 'id',
      render: id => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <button className='link-button' onClick={() => downloadInvoice(id)} type='button'>
            <MaterialIcon role='button' icon='attach_money' />
          </button>
          <button className='link-button' onClick={() => downloadLaborDetail(id)} type='button'>
            <MaterialIcon role='button' icon='people' />
          </button>
        </div>
      )
    },
    {
      title: 'Project',
      dataIndex: 'projectId',
      key: 'projectId'
    },
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      sorter: (a, b) => {
        if (a.invoiceNumber) {
          return b.invoiceNumber ? a.invoiceNumber.localeCompare(b.invoiceNumber) : -1;
        }
        if (b.invoiceNumber) {
          return a.invoiceNumber ? b.invoiceNumber.localeCompare(a.invoiceNumber) : 1;
        }
        return 0 - 0;
      },
      defaultSortOrder: 'descend'
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      render: date => <>{moment(date).format('MM/DD/YYYY')}</>,
      sorter: (a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate)
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'invoiceAmount',
      key: 'invoiceAmount',
      render: amount => <>{amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</>,
      sorter: (a, b) => a.invoiceAmount - b.invoiceAmount
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      sorter: (a, b) => a.balance - b.balance,
      render: balance => balance.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    }
  ];

  const handleClick = () => {
    exportToCsv(`invoice-${activeProject.id}`, invoicedColumns, tableData);
  };

  return (
    <>
      {tableData && tableData.length > 0 && (
        <>
          <p style={{ fontWeight: 500, marginTop: '5px' }}>
            * Invoice data might not reflect the latest updates in our financial systems. Data is updated once per day.
            Invoice and balance amounts displayed in US dollars. Invoice document will reflect the billing currency.
          </p>
          <Row type='flex' justify='space-between' style={{ marginTop: 35 }}>
            <Col span={8}>
              <h1>Invoiced for {activeProject.id}</h1>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Button type='primary' htmlType='button' onClick={handleClick}>
                Export
              </Button>
            </Col>
          </Row>
          <Table
            dataSource={tableData}
            columns={invoicedColumns}
            rowKey={row => row.id}
            bordered
            scroll={{ x: true }}
          />
        </>
      )}
    </>
  );
});
