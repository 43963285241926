import React, { memo } from 'react';
import MaterialIcon from '@material/react-material-icon';
import { downloadFile } from 'lib/helpers';

const DocumentButton = ({ id, projectId, workInstructionRevisionId }) => {
  const handleDownload = () => {
    const options = {
      endpoint: `/WorkInstructionRevisions/${workInstructionRevisionId}`,
      filename: `Pending-Work-Instruction-${projectId}`
    };

    downloadFile(options);
  };

  return (
    <button type='button' className='action-button' onClick={() => handleDownload(id)}>
      <MaterialIcon icon='description' />
    </button>
  );
};

export default memo(DocumentButton);
