import { define } from '@rlean/core';
import { Impersonation as ImpersonationType } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

/**
 * Impersonation  should be used to set isImpersonating flags.
 * The UserDescription.isMobileUser flag can be used to set an
 * isReadOnly flag for the Impersonation object. If that is set,
 * then we know the user needs to impersonated.
 */
const Impersonation = define<ImpersonationType>(EntityKey.Impersonation, {
  initialState: {
    isImpersonating: false,
    isReadOnly: false,
    init: false
  }
});

export default Impersonation;
