import { useEffect, useState } from 'react';
import { RLeanState } from '@rlean/core';
import { hasValue, uuid } from '@rlean/utils';
import { determineCriteriaType } from 'lib/helpers';

export const useProjectsParts = () => {
  const [projectParts] = RLeanState().select(({ state }) => [state.projectParts]);
  const [data, setData] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    const partsArrayExists = hasValue(projectParts, 'data.parts') && projectParts.data.parts.length > 0;

    if (partsArrayExists) {
      const data = projectParts.data.parts.map(part => {
        const partWithCriteria = { ...part };
        const hasMultipleCriteria = partWithCriteria.criteria.length > 1;

        // Add part data to the criteria array for multiple (expandable) criteria in the parts table
        if (hasMultipleCriteria) {
          partWithCriteria.criteria.forEach(criteria => {
            criteria.criteriaType = determineCriteriaType(criteria.criteriaType);
            criteria.number = partWithCriteria.number;
            criteria.description = partWithCriteria.description;
            criteria.key = uuid();
          });

          partWithCriteria.children = [...partWithCriteria.criteria].slice(1);
        }

        // Add criteria data to part for card view and first row of criteria in table
        if (partWithCriteria && partWithCriteria.criteria[0]) {
          partWithCriteria.name = partWithCriteria.criteria[0].name;
          partWithCriteria.criteriaType = determineCriteriaType(partWithCriteria.criteria[0].criteriaType);
          partWithCriteria.key = uuid();
        }

        return partWithCriteria;
      });

      if (isSubscribed) {
        setData(data);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [projectParts]);

  return [data];
};
