export const dashboardCardVersions = {
  v1: [
    { card: 'MyProjects' },
    { card: 'SupplierProjects' },
    { card: 'ActiveProjects' },
    { card: 'HighNonconforming' },
    { card: 'ZeroDefects' },
    { card: 'MyInvoiced' },
    { card: 'PendingWorkInstructions' },
    { card: 'PendingWorkAuthorizations' },
    { card: 'FavoriteProjects' }
  ],
  v2: [
    { card: 'MyProjects' },
    { card: 'SupplierProjects' },
    { card: 'ActiveProjects' },
    { card: 'HighNonconforming' },
    { card: 'ZeroDefects' },
    { card: 'MyInvoiced' },
    { card: 'PendingWorkInstructions' },
    { card: 'PendingWorkAuthorizations' },
    { card: 'FavoriteProjects' },
    { card: 'SiteReports' }
  ]
};
