import React, { memo, useState, useEffect } from 'react';
import MaterialIcon from '@material/react-material-icon';
import { Pagination, Row, Col, List } from 'antd';
import { uuid } from '@rlean/utils';

function Footer({
  paginationSettings: { defaultCurrent, defaultPageSize, currentPage, handlePageChange, total },
  paginationSize = 'small',
  pagination = null,
  launchAction = null
}) {
  const handleClick = () => (launchAction ? launchAction() : null);

  return (
    <Row type='flex' justify='space-between' align='bottom'>
      <Col span={4}>
        {launchAction && (
          <button type='button' className='action-button' onClick={handleClick}>
            <MaterialIcon icon='table_chart' />
          </button>
        )}
      </Col>
      <Col span={16} className='pagination'>
        {pagination && (
          <Pagination
            size={paginationSize}
            defaultCurrent={defaultCurrent}
            defaultPageSize={defaultPageSize}
            onChange={handlePageChange}
            total={total}
            current={currentPage}
          />
        )}
      </Col>
    </Row>
  );
}

export const BaseListItem = memo(
  ({ key, actionComponents, title = null, description = null, className = '', style = {}, onClick, children }) => {
    return (
      <List.Item key={key} actions={actionComponents} className={className} style={style} onClick={onClick}>
        {(title || description) && <List.Item.Meta title={title} description={description} />}
        {children}
      </List.Item>
    );
  }
);

export const BaseList = memo(
  ({
    data = [],
    renderItem,
    itemsPerPage = 3,
    size = 'small',
    layout = 'vertical',
    launchAction = null,
    key = uuid(),
    footerText = null,
    className = '',
    style = null
  }) => {
    const [paginationMin, setPaginationMin] = useState(0);
    const [paginationMax, setPaginationMax] = useState(itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [needPagination, setNeedPagination] = useState();

    useEffect(() => {
      let isSubscribed = true;

      if (isSubscribed) {
        setNeedPagination(data.length > itemsPerPage);
      }

      return () => {
        isSubscribed = false;
      };
    }, [itemsPerPage, data]);

    const handlePageChange = pageNumber => {
      if (pageNumber <= 1) {
        setPaginationMin(0);
        setPaginationMax(itemsPerPage);
      } else {
        setPaginationMin(itemsPerPage * (pageNumber - 1));
        setPaginationMax(itemsPerPage * pageNumber);
      }
      setCurrentPage(pageNumber);
    };

    const paginationSettings = {
      defaultCurrent: 1,
      defaultPageSize: itemsPerPage,
      total: data.length,
      currentPage,
      handlePageChange
    };

    return (
      <>
        <List
          className={`base-list ${className}`}
          itemLayout={layout}
          size={size}
          style={style}
          dataSource={data.slice(paginationMin, paginationMax)}
          key={key}
          footer={
            <>
              {Array.isArray(footerText) ? (
                footerText.map(text => (
                  <>
                    <span className='card-foot-disclaimer'>{text}</span>
                    <br />
                  </>
                ))
              ) : (
                <span className='card-foot-disclaimer'>{footerText}</span>
              )}
              {((launchAction && data.length > 0) || needPagination) && (
                <Footer
                  launchAction={launchAction}
                  pagination={needPagination}
                  paginationSettings={paginationSettings}
                />
              )}
            </>
          }
          renderItem={renderItem}
        />
      </>
    );
  }
);
