import { useEffect, useState } from 'react';
import { RLeanState } from '@rlean/core';
import { hasValue } from '@rlean/utils';
import * as entities from 'lib/entities';

// Types
import { Impersonation } from 'lib/types';

export const useDetermineImpersonation = () => {
  const [businessContact, userDescription] = RLeanState<typeof entities>().select(({ state }) => [
    state.businessContact,
    state.userDescription
  ]);

  useEffect(() => {
    const determineImpersonation = (): Impersonation => {
      if (
        hasValue(userDescription, 'data') &&
        userDescription.data.isMobileUser &&
        !hasValue(businessContact, 'data.id')
      ) {
        return {
          isImpersonating: false,
          isReadOnly: true,
          init: true
        };
      } else if (
        hasValue(userDescription, 'data') &&
        userDescription.data.isMobileUser &&
        hasValue(businessContact, 'data.id')
      ) {
        return {
          isImpersonating: true,
          isReadOnly: true,
          init: true
        };
      } else {
        return {
          isImpersonating: false,
          isReadOnly: false,
          init: true
        };
      }
    };

    determineImpersonation();
  }, [businessContact, userDescription]);

  // return impersonation;
};
