import { useEffect } from 'react';
import { RLeanState, useSave } from '@rlean/core';
import { getValue, hasValue } from '@rlean/utils';
import { navigate } from '@reach/router';

// Enums
import { EntityKey } from 'lib/enums/EntityKey';

// Hooks
import { useDetermineImpersonation } from './useDetermineImpersonation';
import { useCreateBusinessContact } from './useCreateBusinessContact';

export default function useRequireImpersonation() {
  const [businessContact, impersonation, userDescription] = RLeanState().select(({ state }) => [
    state.businessContact,
    state.impersonation,
    state.userDescription
  ]);
  // const determineImpersonation = useDetermineImpersonation();
  // const createFromUserDescription = useCreateBusinessContact();
  const newBusinessContact = useCreateBusinessContact();
  const newImpersonation = useDetermineImpersonation();

  const save = useSave();

  const businessContactId = getValue(businessContact, 'data.id', null);
  const objectId = getValue(userDescription, 'data.objectId', null);

  useEffect(() => {
    // const newBusinessContact = createFromUserDescription();
    // TODO: remove after testing
    // const newImpersonation = Impersonation.determineImpersonation(userDescription, businessContact);
    // const newImpersonation = determineImpersonation();

    // save created businessContact and impersonation objects to state
    // if (!hasValue(businessContact, 'data.id') && hasValue(newBusinessContact, 'data.id')) {
    //   save({
    //     key: EntityKey.BusinessContact,
    //     value: { data: newBusinessContact }
    //   });
    // }
    if (newImpersonation) {
      save({ key: EntityKey.Impersonation, value: newImpersonation });
    }
    if (impersonation.isReadOnly && !impersonation.isImpersonating && !businessContactId) {
      navigate('/impersonate');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectId, businessContactId, impersonation.isReadOnly, impersonation.isLoading]);
}
