import moment from 'moment';

export const formatDate = date => {
  if (date != null) {
    const d = moment(date);

    if (d.isValid()) {
      return d.locale('en').format('MM/DD/YYYY');
    }
  }

  return date;
};
