import React, { memo, useState } from 'react';
import { RLeanState } from '@rlean/core';
import { deepCopy, uuid, getValue, hasValue } from '@rlean/utils';
import { Card, Row, Col, Select, Skeleton, DatePicker, Input, Button, Dropdown, Menu, Drawer } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import MaterialIcon from '@material/react-material-icon';
import { useActivePage, useInvoicesByWorkSite, useInvoicesLast30Days } from 'lib/hooks';
import { exportToCsv, formatDate, formatCurrency, downloadFile } from 'lib/helpers';
import { BaseFilters } from 'lib/models';
import { invoiceMetricType } from 'lib/types';
import { pages, strings } from 'config';
import { InvoiceTable } from './InvoiceTable';
import { InvoiceMobileCards } from './InvoiceMobileCards';
import { useFilters, useRequireImpersonation, useTableFilters } from 'lib/hooks';
import { TableFilterIndicators } from 'components/shared/TableFilterIndicators';
import { FilterIndicators } from 'components/shared/FilterIndicators';

const { Option } = Select;

const filterProperties = ['selectedWorkSite', 'invoiceMetric', 'startDate', 'endDate'];

export const Invoices = memo(() => {
  const [invoice, workSite] = RLeanState().select(({ state }) => [state.invoice, state.workSite]);
  const { filters, setFilters, resetSingleFilter } = useFilters({}, filterProperties);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const {
    searchedColumn,
    searchText,
    selectedKeys,
    handleResetSearchOnColumn,
    handleIsColumnSearched,
    handleSearchOnColumn,
    handleOnSearchColumnChange
  } = useTableFilters();

  useRequireImpersonation();
  useActivePage(pages.invoices);

  const getInvoicesByWorkSite = useInvoicesByWorkSite();
  const getInvoicesLast30Days = useInvoicesLast30Days();

  const getInvoice = id => {
    const currentInvoice = invoice.data.find(x => x.id === id);

    return {
      id,
      invoiceNumber: currentInvoice.invoiceNumber,
      invoiceDate: currentInvoice.invoiceDate,
      projectId: currentInvoice.projectId
    };
  };

  const downloadInvoice = id => {
    const currentInvoice = getInvoice(id);
    const options = {
      endpoint: `/InvoiceFiles?projectId=${currentInvoice.projectId}&invoiceDate=${currentInvoice.invoiceDate}&invoiceNumber=${currentInvoice.invoiceNumber}`,
      filename: `Invoice-${currentInvoice.invoiceNumber}`
    };

    if (currentInvoice) {
      downloadFile(options);
    }
  };

  const downloadLaborDetail = id => {
    const currentInvoice = getInvoice(id);
    const options = {
      endpoint: `/InvoiceLaborFiles?invoiceNumber=${currentInvoice.invoiceNumber}&invoiceId=${currentInvoice.id}`,
      filename: `Invoice-Labor-${currentInvoice.invoiceNumber}`
    };

    if (currentInvoice) {
      downloadFile(options);
    }
  };

  /* #region  Input change events */

  const onWorkSiteChange = value => {
    const updatedFilters = deepCopy(filters);

    if (value && value !== 'All Sites') {
      const currentWorkSite = workSite.data.find(x => x.companyId === value);
      if (currentWorkSite) {
        const label = `${currentWorkSite.companyName} - ${currentWorkSite.companyCity}`;
        updatedFilters.selectedWorkSite.id = uuid();
        updatedFilters.selectedWorkSite.value = value;
        updatedFilters.selectedWorkSite.label = label;
        updatedFilters.selectedWorkSite.showTag = true;
      }
    } else {
      updatedFilters.selectedWorkSite.value = 0;
      updatedFilters.selectedWorkSite.id = uuid();
      updatedFilters.selectedWorkSite.showTag = false;
    }

    setFilters(updatedFilters);
  };

  const onMetricChange = value => {
    const updatedFilters = deepCopy(filters);

    updatedFilters.invoiceMetric.value = value;
    if (value === invoiceMetricType.ALL_INVOICES) {
      updatedFilters.invoiceMetric.showTag = false;
    } else {
      updatedFilters.invoiceMetric.label = 'Last 30 days';
      updatedFilters.invoiceMetric.showTag = true;
    }

    setFilters(updatedFilters);
  };

  const onDateRangeChange = key => value => {
    setFilters({ ...filters, [key]: { ...filters[key], id: uuid(), value, showTag: true } });
  };

  const siteViewOptions = () => {
    const options = [];
    options.push(
      <Option key={uuid()} value={0}>
        All Sites
      </Option>
    );

    if (hasValue(workSite, 'data') && !workSite.isLoading && workSite.data.length > 0) {
      for (let i = 0; i < workSite.data.length; i += 1) {
        options.push(
          <Option
            key={uuid()}
            value={workSite.data[i].companyId}
          >{`${workSite.data[i].companyName} - ${workSite.data[i].companyCity}`}</Option>
        );
      }
    }

    return options;
  };

  const clearInputs = () => {
    setFilters({ ...filters, ...new BaseFilters(filterProperties) });
  };

  /* #endregion */

  const filteredInvoices = () => {
    let filteredList = getValue(invoice, 'data', []);
    const keys = Object.keys(filters);
    const keysLength = keys.length;

    for (let i = 0; i < keysLength; i += 1) {
      const filter = deepCopy(filters[keys[i]]);
      if (keys[i] === 'invoiceMetric' && hasValue(filter, 'value') && filter.value) {
        if (filter.value === invoiceMetricType.LAST_30_DAYS) {
          // TODO: remove after testing
          // filteredList = Invoice.last30Days(filters.selectedWorkSite.value, invoice);
          filteredList = getInvoicesLast30Days(filters.selectedWorkSite.value, invoice);
        }
      } else if (keys[i] === 'projectId' && hasValue(filter, 'value') && filter.value) {
        filteredList = filteredList.filter(x => parseInt(x.projectId, 10) === parseInt(filter.value, 10));
      } else if (keys[i] === 'startDate' && hasValue(filter, 'value') && filter.value) {
        filteredList = filteredList.filter(x =>
          moment(x.invoiceDate).isSameOrAfter(moment(filter.value).startOf('day'))
        );
      } else if (keys[i] === 'endDate' && hasValue(filter, 'value') && filter.value) {
        filteredList = filteredList.filter(x =>
          moment(x.invoiceDate).isSameOrBefore(moment(filter.value).endOf('day'))
        );
      }
    }

    // Filter by work sites if there's an active work site.
    if (filters.selectedWorkSite && filters.selectedWorkSite.value) {
      // TODO: Remove after testing
      // filteredList = Invoice.invoicesByWorkSite(filters.selectedWorkSite.value, filteredList);
      filteredList = getInvoicesByWorkSite(filters.selectedWorkSite.value, filteredList);
    }

    // Apply field search filter.
    searchedColumn.forEach(dataIndex => {
      filteredList = filteredList.filter(
        list =>
          list[dataIndex] &&
          list[dataIndex]
            .toString()
            .toLowerCase()
            .includes(searchText[dataIndex].toLowerCase())
      );
    });

    filteredList.forEach((invoice, idx, thisArr) => {
      thisArr[idx] = { ...invoice, key: invoice.id };
    });

    return filteredList;
  };

  const columnTitles = [
    {
      dataIndex: 'projectId',
      title: 'Project'
    },
    {
      dataIndex: 'invoiceNumber',
      title: 'Number'
    }
  ];

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${columnTitles.find(column => column.dataIndex === dataIndex).title}`}
          value={selectedKeys[dataIndex]}
          onChange={e => handleOnSearchColumnChange(e.target.value, dataIndex)}
          onPressEnter={() => handleSearchOnColumn(dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <div>
          <Button
            type='primary'
            onClick={() => handleSearchOnColumn(dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90, marginRight: 10 }}
          >
            Search
          </Button>
          <Button onClick={() => handleResetSearchOnColumn(dataIndex)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      </div>
    ),
    filterIcon: <SearchOutlined style={{ color: handleIsColumnSearched(dataIndex) ? '#1890ff' : undefined }} />
  });

  const columns = [
    {
      title: 'Actions',
      dataIndex: 'id',
      className: 'table-actions',
      render: id => (
        <div>
          <button className='link-button' onClick={() => downloadInvoice(id)} type='button'>
            <MaterialIcon role='button' icon='attach_money' title='Invoice' />
          </button>
          <button className='link-button' onClick={() => downloadLaborDetail(id)} type='button'>
            <MaterialIcon role='button' icon='people' title='Labor Detail' />
          </button>
        </div>
      )
    },
    {
      title: 'Project',
      dataIndex: 'projectId',
      sorter: (a, b) => a.projectId - b.projectId,
      defaultSortOrder: 'descend',
      ...getColumnSearchProps('projectId')
    },
    {
      title: 'Number',
      dataIndex: 'invoiceNumber',
      sorter: (a, b) => {
        if (a.invoiceNumber) {
          return b.invoiceNumber ? a.invoiceNumber.localeCompare(b.invoiceNumber) : -1;
        }
        if (b.invoiceNumber) {
          return a.invoiceNumber ? b.invoiceNumber.localeCompare(a.invoiceNumber) : 1;
        }
        return 0 - 0;
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('invoiceNumber')
    },
    {
      title: 'Work Site',
      dataIndex: 'workSiteCompanyName',
      sorter: (a, b) => {
        if (a.workSiteCompany) {
          return b.workSiteCompany ? a.workSiteCompany.localeCompare(b.workSiteCompany) : -1;
        }
        if (b.workSiteCompany) {
          return a.workSiteCompany ? b.workSiteCompany.localeCompare(a.workSiteCompany) : 1;
        }
        return 0 - 0;
      }
    },
    {
      title: 'Date',
      dataIndex: 'invoiceDate',
      sorter: (a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate),
      render: invoiceDate => <>{formatDate(invoiceDate)}</>
    },
    {
      title: 'Amount',
      dataIndex: 'invoiceAmount',
      sorter: (a, b) => a.invoiceAmount - b.invoiceAmount,
      render: invoiceAmount => formatCurrency(invoiceAmount)
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      sorter: (a, b) => a.balance - b.balance,
      render: balance => formatCurrency(balance)
    }
  ];

  const showFilters = () => {
    setFiltersVisible(true);
  };

  const handleFiltersClose = () => {
    setFiltersVisible(false);
  };

  const filtersDrawer = (
    <div className='table-filters-wrapper'>
      <div className='table-filters-col'>
        <Select
          style={{ width: '100%' }}
          value={filters.selectedWorkSite ? filters.selectedWorkSite.value : 0}
          className='table-filter'
          onChange={onWorkSiteChange}
        >
          {siteViewOptions()}
        </Select>
        <Select
          value={filters.invoiceMetric ? filters.invoiceMetric.value : invoiceMetricType.ALL_INVOICES}
          style={{ width: '100%' }}
          className='table-filter'
          onChange={onMetricChange}
        >
          <Option key={uuid()} value={invoiceMetricType.ALL_INVOICES}>
            All Invoices
          </Option>
          <Option key={uuid()} value={invoiceMetricType.LAST_30_DAYS}>
            Last 30 Days
          </Option>
        </Select>
        <DatePicker
          placeholder='Start Date'
          className='table-filter'
          format={'MM/DD/YYYY'}
          value={filters.startDate && filters.startDate.value ? moment(filters.startDate.value, 'MM/DD/YYYY') : null}
          onChange={onDateRangeChange('startDate')}
        />
        <DatePicker
          placeholder='End Date'
          className='table-filter'
          format={'MM/DD/YYYY'}
          value={filters.endDate && filters.endDate.value ? moment(filters.endDate.value, 'MM/DD/YYYY') : null}
          onChange={onDateRangeChange('endDate')}
        />
      </div>
      <div className='table-filters-col filters-buttons'>
        <Button type='primary' className='table-filter' htmlType='button' onClick={() => clearInputs()}>
          Clear
        </Button>
      </div>
    </div>
  );

  const menu = (
    <Menu>
      <Menu.Item
        key={uuid()}
        onClick={() => exportToCsv(`Invoices-${formatDate(new Date())}`, columns, filteredInvoices())}
      >
        Export to CSV
      </Menu.Item>
      <Menu.Item key={uuid()} onClick={() => showFilters()}>
        Filters
      </Menu.Item>
    </Menu>
  );

  const extra = (
    <Dropdown className='export-to-csv' overlay={menu} trigger={['click']} placement='bottomRight'>
      <button className='link-button' type='button'>
        <MaterialIcon role='button' icon='more_vert' />
      </button>
    </Dropdown>
  );

  const table = () => {
    if (!invoice || invoice.isLoading) {
      return (
        <Card style={{ marginTop: 25 }}>
          <Skeleton active />
        </Card>
      );
    }

    return (
      <>
        <p style={{ fontWeight: 500 }}>
          * Invoice data might not reflect the latest updates in our financial systems. Data is updated once per day.
          Invoice and balance amounts displayed in US dollars. Invoice document will reflect the billing currency.
        </p>
        <Card className='table-card' title={strings.invoices} extra={extra}>
          <div className='table-tags-container'>
            <FilterIndicators filterProperties={filterProperties} closableFilters onCloseFilter={resetSingleFilter} />
            <TableFilterIndicators
              searchedColumn={searchedColumn}
              searchText={searchText}
              handleReset={handleResetSearchOnColumn}
              allColumns={columns}
            />
          </div>
          <InvoiceTable
            columns={columns}
            dataSource={filteredInvoices()}
            downloadInvoice={downloadInvoice}
            downloadLaborDetail={downloadLaborDetail}
          />
        </Card>
        <div className='mobile-cards'>
          <InvoiceMobileCards
            invoices={filteredInvoices()}
            downloadInvoice={downloadInvoice}
            downloadLaborDetail={downloadLaborDetail}
            columns={columns}
          />
        </div>
      </>
    );
  };

  return (
    <Row className='grid-row'>
      <Col className='grid-col' span={24}>
        {table()}
        <Drawer visible={filtersVisible} onClose={handleFiltersClose} placement='right'>
          {filtersDrawer}
        </Drawer>
      </Col>
    </Row>
  );
});
