// Types
import { Invoice } from 'lib/types';

export const useInvoicesByWorkSite = () => {
  const getInvoicesByWorkSite = (activeWorkSite: any, invoices: Invoice[]) => {
    let filteredInvoices = null;

    if (invoices) {
      if (activeWorkSite) {
        filteredInvoices = invoices.filter((invoice: Invoice) => +invoice.workSiteCompanyId === +activeWorkSite);
      } else {
        // Don't filter. Return invoices for all sites.
        filteredInvoices = invoices;
      }
    }

    return filteredInvoices ?? [];
  };

  return (activeWorkSite: any, invoices: Invoice[]) => getInvoicesByWorkSite(activeWorkSite, invoices);
};
