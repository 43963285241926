/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
import { removeAll } from '@rlean/core';

class Auth {
  async signOut(navigate) {
    // clear session
    // clear storage
    await removeAll();

    navigate(
      `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT}/oauth2/logout?post_logout_redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`
    );
  }
}

export default new Auth();
