import React, { memo, useState } from 'react';
import { RLeanState } from '@rlean/core';
import { getValue, hasValue } from '@rlean/utils';
import { Card, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { Disclaimer } from 'components/shared/Disclaimer';
import { ProjectSummaryCard } from './ProjectSummaryCard';

export const ProjectsSummary = memo(() => {
  const [project, projectSummary] = RLeanState().select(({ state }) => [state.project, state.projectSummary]);
  const projectSummaryArray = getValue(projectSummary, 'data', null);
  const projectArray = getValue(project, 'data', null);
  const [loadedCards, setLoadedCards] = useState([]);
  let summaryCards = [];

  const handleInfiniteOnLoad = page => {
    setLoadedCards(loadedCards.concat(summaryCards[page]));
  };

  if (projectSummaryArray && projectArray) {
    summaryCards = projectArray
      .sort((p1, p2) => p2.projectId - p1.projectId)
      .map(currentProject => {
        return <ProjectSummaryCard currentProject={currentProject} key={currentProject.id} />;
      });
  }

  return (
    <div style={{ marginTop: 25 }} className='project-summary-container'>
      <Disclaimer />
      {!projectSummary || (hasValue(projectSummary, 'isLoading') && projectSummary.isLoading) ? (
        <Card>
          <Skeleton active />
        </Card>
      ) : (
        <InfiniteScroll
          initialLoad
          pageStart={-1}
          loadMore={handleInfiniteOnLoad}
          hasMore={summaryCards.length > loadedCards.length}
        >
          {loadedCards}
        </InfiniteScroll>
      )}
    </div>
  );
});
