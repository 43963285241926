import { memo, useRef, useState } from 'react';
import { getValue, uuid } from '@rlean/utils';
import { Radio, Button, Dropdown } from 'antd';
import ImageAreas from './_imageAreas';
import { HeatmapAreas } from 'lib/models/HeatmapAreas';
import { ImageHeatmap } from 'lib/models/ImageHeatmap';
import { useImageSizeDetection } from 'lib/hooks/useImageSizeDetection';
import useWindowSize from 'lib/hooks/useWindowSize';
import CanvasComponent from './_heatmapCanvas';

const Heatmap = ({ partWithPartImage, tableData }) => {
  const [selectedColor, setSelectedColor] = useState('red');
  const imageRef = useRef(null);
  const imageX = imageRef && imageRef.current ? imageRef.current.offsetLeft : null;
  const imageY = imageRef && imageRef.current ? imageRef.current.offsetTop : null;
  const data = getValue(partWithPartImage, 'data', [])[0];
  const partAreas = getValue(data, 'partAreas', []);
  const partImage = getValue(data, 'partImage', {});
  let newImage = null;

  const dataUrl = `data:${getValue(partImage, 'partImage.mimeType', 'image/png')};base64,${getValue(
    partImage,
    'imageBase64',
    ''
  )}`;

  const userDefinedAreas = partAreas.map(partArea => {
    if (partArea) {
      return {
        id: partArea.id,
        x: partArea.xOffset,
        y: partArea.yOffset,
        height: partArea.height,
        width: partArea.width,
        label: partArea.description ?? ''
      };
    } else {
      return {
        id: 0,
        x: 0,
        y: 0,
        height: 0,
        width: 0,
        label: ''
      };
    }
  });

  const capturedImage = {
    dataUrl: `data:${getValue(partImage, 'mimeType', '')};base64,${getValue(partImage, 'imageBase64', '')}`,
    height: getValue(partImage, 'height', 0),
    width: getValue(partImage, 'width', 0),
    actualHeight: getValue(partImage, 'actualHeight', 0),
    actualWidth: getValue(partImage, 'actualWidth', 0),
    hasGrid: getValue(partImage, 'partImageGridSpec') ? true : false,
    gridXLength: getValue(partImage, 'partImageGridSpec.xSize', null),
    gridYLength: getValue(partImage, 'partImageGridSpec.ySize', null),
    userDefinedAreas: userDefinedAreas
  };
  const selectionAreas = HeatmapAreas.determineSelectionAreas(capturedImage);
  const initialImage = new ImageHeatmap({
    dataUrl,
    height: partImage.actualHeight,
    width: partImage.actualWidth,
    selectionAreas
  });

  const maxHeight = initialImage.height;
  const maxWidth = initialImage.width;

  const [windowWidth] = useWindowSize();
  const [imageHeight, imageWidth] = useImageSizeDetection({
    initialImage,
    maxHeight,
    maxWidth,
    paddingWidth: 40 // TODO: should be 40 but is messing up the aspect ratio calc
  });

  const containerWidth = windowWidth - 40; // Account for left and right padding
  const marginLeft = containerWidth > imageWidth ? (containerWidth - imageWidth) / 2 : 0;
  let newSelectionAreas = [];

  // Calculate/recalculate grid and set image selection areas
  newSelectionAreas = initialImage.calculateAreaCoordinates({
    height: imageHeight,
    width: imageWidth
  });

  newImage = new ImageHeatmap({
    height: imageHeight,
    width: imageWidth,
    selectionAreas: newSelectionAreas,
    dataUrl: initialImage.dataUrl
  });

  const overlayCoordinates =
    newImage && newImage.selectionAreas
      ? newImage.selectionAreas.map(selectionArea => {
          return {
            id: uuid(),
            label: '',
            imageHeight,
            imageWidth,
            topLeftCoordinates: {
              xCoordinate: selectionArea.x,
              yCoordinate: selectionArea.y
            },
            bottomRightCoordinates: {
              xCoordinate: selectionArea.x + selectionArea.width,
              yCoordinate: selectionArea.y + selectionArea.height
            }
          };
        })
      : [];

  const labels = () => {
    const values = [];

    if (!imageX || !imageY) {
      return values;
    }

    for (let i = 0; i < overlayCoordinates.length; i += 1) {
      const currentCoordinatePair = overlayCoordinates[i];
      const topLeftCoordinates = currentCoordinatePair.topLeftCoordinates;
      const labelLeft = marginLeft + topLeftCoordinates.xCoordinate + 25;
      const labelTop = imageY + topLeftCoordinates.yCoordinate;
      const label = i + 1;

      values.push(
        <div
          style={{
            color: '#fff',
            position: 'absolute',
            fontWeight: 'bold',
            fontSize: '13px',
            top: labelTop,
            left: labelLeft,
            zIndex: 10000000,
            paddingLeft: 5,
            height: 20,
            width: 20,
            backgroundColor: '#000'
          }}
        >
          {label}
        </div>
      );
    }

    return values;
  };

  const legend =
    initialImage && initialImage.selectionAreas
      ? initialImage.selectionAreas.map((selectionArea, i) => {
          return {
            number: i + 1,
            key: uuid(),
            label: selectionArea.label
          };
        })
      : [];

  const ViewHeatmapLabels = () => {
    return <>{labels()}</>;
  };

  const colorOptions = [
    { label: 'Red', value: 'red' },
    { label: 'Gray', value: 'gray' },
    { label: 'Green', value: 'green' },
    { label: 'Cyan', value: 'cyan' }
  ];

  const colorOnChange = ({ target }) => {
    setSelectedColor(target.value);
  };

  const items = legend.map((legendItem, i) => {
    return {
      key: uuid(),
      label: <>{`${i + 1}: ${legendItem.label}`}</>
    };
  });

  return (
    <>
      <div style={{ marginBottom: 20, marginLeft: 10 }}>
        <label style={{ paddingRight: 5 }}>Selection Area Color: </label>
        <Radio.Group options={colorOptions} onChange={colorOnChange} value={selectedColor} optionType='button' />
        <Dropdown
          menu={{
            items
          }}
          placement='bottom'
          arrow
          trigger={['click']}
        >
          <Button style={{ marginLeft: 20 }}>Legend</Button>
        </Dropdown>
      </div>
      <CanvasComponent
        canvasRef={imageRef}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        height={imageHeight}
        width={imageWidth}
        imageDataUrl={initialImage.dataUrl}
        imageAreas={{
          initialImage,
          newImage,
          selectedColor,
          tableData,
          legend
        }}
        marginLeft={marginLeft}
        overlayCoordinates={overlayCoordinates}
      />
    </>
  );
};

export default memo(Heatmap);
