import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { RLean, RLeanBaseHooks } from '@rlean/core';
import { getValue, hasValue } from '@rlean/utils';
import { Router } from '@reach/router';
import LandingPage from 'components/pages/LandingPage';
import LandingViewer from 'components/pages/LandingViewer';

// logging
import { init } from '@sentry/browser';
import { rLean as config } from 'config';

import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal
} from '@azure/msal-react';
import { EventMessage, EventType } from '@azure/msal-browser';
import { msalInstance } from 'config/msal';

import App from './components/App';
import * as serviceWorker from './serviceWorker';

import 'font-awesome/css/font-awesome.min.css';

// configure logging
init({
  dsn: 'https://d4b8369cafa84aecb922a5bef508e220@sentry.io/1308432',
  environment: process.env.REACT_APP_ENV
});

RLean.init(config);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && hasValue(event, 'payload.account')) {
    // const { account } = event.payload;
    const account = getValue(event, 'payload.account', null);
    msalInstance.setActiveAccount(account);
  }
});

msalInstance
  .handleRedirectPromise()
  .then(_result => {
    // read session storage for redirect path
    // if not found, redirect to home page
    const redirectPath = sessionStorage.getItem('redirectPath');
    sessionStorage.removeItem('redirectPath');

    if (redirectPath !== null) {
      window.location.href = redirectPath;
    }
  })
  .catch(error => {
    // In case of error, send the user back to the home page
    console.log('MSAL Error', error);
    window.location.href = '/';
  });

const AuthDirector = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const checkAndRedirect = async () => {
      // If user is not in the landing page and not authenticated
      // redirect to login page
      if (window.location.pathname !== '/' && !isAuthenticated) {
        // store current path in session storage for later redirection
        sessionStorage.setItem('redirectPath', window.location.pathname);

        await msalInstance.loginRedirect();
      }
    };

    // Only check when MSAL process is completed
    if (inProgress === 'none') {
      checkAndRedirect();
    }
  }, [inProgress, isAuthenticated]);

  return (
    <>
      <AuthenticatedTemplate>
        <Router>
          <App path='/*' />
        </Router>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Router>
          <LandingPage path='/' />
          <LandingViewer path='/static/*' />
        </Router>
      </UnauthenticatedTemplate>
    </>
  );
};

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <RLeanBaseHooks />
    <AuthDirector />
  </MsalProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
