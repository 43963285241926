import React, { useEffect, useState } from 'react';
import { Tag, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { uuid } from '@rlean/utils';
import { echoElementType, shiftValues, statusType } from 'lib/types';

export const TableFilterIndicators = ({
  fieldIds = {},
  searchedColumn = [],
  searchText = {},
  allColumns = [],
  handleReset
}) => {
  const [tags, setTags] = useState([]);

  const createTags = () => {
    const tagsList = [];

    // Add search tags.
    searchedColumn.forEach(dataIndex => {
      let label = '';
      let currentColumn = {};
      switch (dataIndex) {
        case undefined:
          console.error('DataIndex is undefined');
          break;
        case fieldIds[echoElementType.SortShift]:
          label = `Shift: ${searchText[dataIndex].map(val => shiftValues[val] ?? val).join(', ')}`;
          break;
        case fieldIds[echoElementType.UserDefinedChoice]:
          currentColumn = allColumns.find(column => column.dataIndex === dataIndex);
          label = `${currentColumn.title}: ${searchText[dataIndex].join(', ')}`;
          break;
        case 'projectStatus':
          label = `Status: ${searchText[dataIndex].map(val => statusType[val] ?? val).join(', ')}`;
          break;
        case 'businessUnitName':
          label = `Business Unit Name: ${searchText[dataIndex].join(', ')}`;
          break;
        default:
          currentColumn = allColumns.find(column => column.dataIndex === dataIndex);
          label = `${currentColumn.title} contains '${searchText[dataIndex]}'`;
      }
      tagsList.push(
        <Tag key={uuid()} closable onClose={() => handleReset(dataIndex)}>
          {label}
        </Tag>
      );
    });

    return tagsList;
  };

  useEffect(() => {
    setTags(createTags());
  }, [searchedColumn, searchText]);

  const showTags = () => {
    if (searchedColumn.length > 0) {
      return true;
    }
    return false;
  };

  if (showTags()) {
    return (
      <div className='table-tags'>
        <span>Table filters applied:</span>
        <>{tags}</>
        <Tooltip title='Filters applied directly to the table'>
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    );
  }

  return <></>;
};
