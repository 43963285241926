import React, { memo, useState } from 'react';
import { usePost, useSave, RLeanState } from '@rlean/core';
import { deepCopy, getValue, hasValue } from '@rlean/utils';
import { navigate } from '@reach/router';
import { pages, strings } from 'config';
import { EntityKey } from 'lib/enums/EntityKey';
import { useActivePage } from 'lib/hooks';
import { Form, Card, Button, Select, Input, Alert } from 'antd';
import { CONCERN_POST_STATUS, CONCERN_STATUS } from './utils';

/**
 * concernsCreate
 * Component that allows the user to create a new concern
 */
export const ConcernsCreate = memo(() => {
  const save = useSave();
  const post = usePost();
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const [status, setStatus] = useState(CONCERN_POST_STATUS.IDLE);
  const [businessContact, companyConcerns, companyConcernCategories] = RLeanState().select(({ state }) => [
    state.businessContact,
    state.companyConcerns,
    state.companyConcernCategories
  ]);

  const isMobileUser = getValue(businessContact, 'data.isMobileUser', false);

  if (isMobileUser) {
    navigate('/unauthorized');
  }

  useActivePage(pages.concerns);

  // Save concern
  const saveConcern = payload => {
    setStatus(CONCERN_POST_STATUS.SENDING);

    // Add necessary attributes to the values
    const values = deepCopy(payload);
    values['companyId'] = businessContact.data.companyId;
    values['addedByBusinessContactId'] = businessContact.data.id;
    values['modifiedByBusinessContactId'] = businessContact.data.id;
    values['companyConcernStatusId'] = CONCERN_STATUS.SUBMITTED;

    // Post the new concern
    post({ key: EntityKey.CompanyConcerns, body: values }, (res, err) => {
      if (err) {
        setStatus(CONCERN_POST_STATUS.ERROR);
      } else {
        setStatus(CONCERN_POST_STATUS.SUCCESS);
        setTimeout(() => navigate('/concerns'), 2000);
      }

      // Clear the form
      form.resetFields();

      if (hasValue(res, 'data')) {
        // Get the concerns list and push the new concern
        const newConcern = res.data;
        const concerns = deepCopy(companyConcerns);
        concerns.data = concerns.data ?? [];
        concerns.data.push(newConcern);

        // Save the new concerns list to the global state
        save({ key: EntityKey.CompanyConcerns, value: concerns });
      }
    });
  };

  return (
    <>
      <p style={{ fontWeight: 500 }}>* Please provide details on your concern</p>
      <Card className='page-card' title={strings.concernsCreateCardTitle}>
        {status === CONCERN_POST_STATUS.SUCCESS && (
          <Alert
            className='alert-box'
            message={strings.concernsFormAlertMessageSuccess}
            description={strings.concernsFormAlertDescriptionSuccess}
            type='success'
          />
        )}
        {status === CONCERN_POST_STATUS.ERROR && (
          <Alert
            className='alert-box'
            message={strings.concernsFormAlertMessageFail}
            description={strings.concernsFormAlertUpdateMessageFail}
            type='error'
          />
        )}
        <Form onFinish={saveConcern} form={form} layout='vertical'>
          <Form.Item
            label={strings.concernsFormCategorySelectLabel}
            name='companyConcernCategoryId'
            rules={[{ required: true }]}
          >
            <Select placeholder={strings.concernsFormCategorySelectPlaceholder}>
              {companyConcernCategories &&
                companyConcernCategories.data &&
                companyConcernCategories.data.map(cat => (
                  <Option value={cat.id} key={`cat-opt-${cat.id}`}>
                    {cat.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label={strings.concernsFormSubjectTextAreaLabel} name='subject' rules={[{ required: true }]}>
            <Input type='text' placeholder={strings.concernsFormSubjectTextAreaPlaceholder} />
          </Form.Item>
          <p>
            (Please provide detail on time, place, project and specific SQ personnel that are involved in your concern
            below)
          </p>
          <Form.Item label={strings.concernsFormDetailsTextAreaLabel} name='detail' rules={[{ required: true }]}>
            <TextArea type='text' rows={6} placeholder={strings.concernsFormDetailsTextAreaPlaceholder} />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' disabled={status === CONCERN_POST_STATUS.SENDING}>
              {status === CONCERN_POST_STATUS.SENDING ? strings.concernsFormButtonSending : strings.concernsFormButton}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
});
