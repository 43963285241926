import React, { memo, useState, useEffect } from 'react';
import { Table, Drawer, Button, Input, Space, InputNumber, Row, Col, Checkbox } from 'antd';
import { deepCopy } from '@rlean/utils';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import TitleBar from './TitleBar';
import SettingsDrawer from './SettingsDrawer';
import SummaryTable from './SummaryTable';
import { TableFilterIndicators } from '../TableFilterIndicators';
import { useTableFilters } from 'lib/hooks';
import { echoElementType, shiftValues } from 'lib/types';

const choiceTypes = [echoElementType.SortShift, echoElementType.UserDefinedChoice];

/*
SQTable Expected columns
{
  dataIndex - required string used to match properties in dataSource
  title - required string used as column header
  key - required string used settings
  sqVisible - optional bool used to hide columns
  sqToggle - optional bool used to hide columns
  sqSearch - optional bool used to add search to column
  sqCalc - option bool used to add column total to summary

  any other ant-design column properties may be added
}

SQTable Expected dataSource
[
  {
    prop1: value1,
    prop2: value2
  }
]
*/
const SQTable = ({
  dataSource = [],
  columns,
  skipCustomSearchPropsOf = [],
  titleBarData = {
    extraMenuButtons: null,
    downloadInfo: null,
    tableTitle: '',
    downloadBtn: false,
    fullscreenBtn: false,
    settingsBtn: false
  },
  pagination = true,
  summary = false,
  style
}) => {
  // Local Table Filters and Tags
  const [choicesAvailable, setChoicesAvailable] = useState({});
  const [fieldIds, setFieldIds] = useState({});

  const {
    searchedColumn,
    searchText,
    selectedKeys,
    handleResetSearchOnColumn,
    handleIsColumnSearched,
    handleSearchOnColumn,
    handleOnSearchColumnChange
  } = useTableFilters();

  useEffect(() => {
    const result = {};
    const fieldIds = {};

    // Get the fieldIds for the choice-like element types
    choiceTypes.forEach(choiceType => {
      const fieldId = columns.find(column => column.elementTypeId === choiceType)?.dataIndex;
      fieldIds[choiceType] = fieldId;
      result[fieldId] = [];
    });

    // Save the choice values available for all choice element types
    dataSource.forEach(row => {
      choiceTypes.forEach(choiceType => {
        const choice = row[fieldIds[choiceType]] ?? '--';
        if (!result[fieldIds[choiceType]].includes(choice)) {
          result[fieldIds[choiceType]].push(choice);
        }
      });
    });

    setFieldIds(fieldIds);
    setChoicesAvailable(result);
  }, [dataSource]);

  const getCheckboxLabelByElementType = (elementTypeId, value) => {
    switch (elementTypeId) {
      case echoElementType.SortShift:
        return shiftValues[value] ?? value;
      default:
        return value;
    }
  };

  const getColumnSearchProps = (elementTypeId, dataIndex, label) => {
    if (skipCustomSearchPropsOf.includes(elementTypeId)) return {};

    switch (elementTypeId) {
      case echoElementType.VIN:
      case echoElementType.PartNumber:
      case echoElementType.SerialNumber:
        return {
          filterDropdown: () => (
            <div style={{ padding: 8 }}>
              <Input
                placeholder={`Search ${label}`}
                value={selectedKeys[dataIndex]}
                onChange={e => handleOnSearchColumnChange(e.target.value, dataIndex)}
                onPressEnter={() => handleSearchOnColumn(dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button onClick={() => handleResetSearchOnColumn(dataIndex)} size='small' style={{ width: 90 }}>
                  Reset
                </Button>
                <Button
                  type='primary'
                  onClick={() => handleSearchOnColumn(dataIndex)}
                  icon={<SearchOutlined />}
                  size='small'
                  style={{ width: 90 }}
                >
                  Search
                </Button>
              </Space>
            </div>
          ),
          filterIcon: <SearchOutlined style={{ color: handleIsColumnSearched(dataIndex) ? '#1890ff' : undefined }} />
        };
      case echoElementType.UserDefinedChoice:
      case echoElementType.SortShift:
        return {
          filterDropdown: () => (
            <div>
              <Checkbox.Group
                className='custom-filter-checkbox'
                options={
                  choicesAvailable && choicesAvailable[dataIndex]
                    ? choicesAvailable[dataIndex].sort().map(value => ({
                        label: getCheckboxLabelByElementType(elementTypeId, value),
                        value
                      }))
                    : null
                }
                value={selectedKeys[dataIndex]}
                onChange={e => handleOnSearchColumnChange(e, dataIndex)}
              />
              <div className='custom-filter-buttons'>
                <Button
                  disabled={!handleIsColumnSearched(dataIndex)}
                  type='link'
                  size='small'
                  onClick={() => handleResetSearchOnColumn(dataIndex)}
                >
                  Reset
                </Button>
                <Button type='primary' size='small' onClick={() => handleSearchOnColumn(dataIndex)}>
                  OK
                </Button>
              </div>
            </div>
          ),
          filterIcon: <FilterFilled style={{ color: handleIsColumnSearched(dataIndex) ? '#1890ff' : undefined }} />
        };
      default:
        return {};
    }
  };

  const addSearchProps = columnsToSearch => {
    if (columnsToSearch && columnsToSearch.length > 0) {
      return columnsToSearch.map(column => {
        if (column.sqSearch) {
          return {
            ...column,
            ...getColumnSearchProps(column.elementTypeId, column.dataIndex, column.title)
          };
        }
        return { ...column };
      });
    }

    return columnsToSearch;
  };

  const filterData = dataSource => {
    let filteredData = deepCopy(dataSource);

    searchedColumn.forEach(dataIndex => {
      switch (dataIndex) {
        case fieldIds[echoElementType.SortShift]:
        case fieldIds[echoElementType.UserDefinedChoice]:
          filteredData = filteredData.filter(row => searchText[dataIndex].includes(row[dataIndex] ?? '--'));
          break;
        default:
          filteredData = filteredData.filter(
            list =>
              list[dataIndex] &&
              list[dataIndex]
                .toString()
                .toLowerCase()
                .includes(searchText[dataIndex].toLowerCase())
          );
      }
    });

    // Add key to each table row
    filteredData.forEach((row, idx, thisArr) => {
      thisArr[idx] = { ...row, key: thisArr[idx]['key'] ?? row.id };
    });

    return filteredData;
  };

  const [currentFilteredTableData, setCurrentFilteredTableData] = useState(null);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setCurrentFilteredTableData(filterData(dataSource));
    }

    return () => {
      isMounted = false;
      setCurrentFilteredTableData(null);
    };
  }, [dataSource, searchText]);

  // Table Settings
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [columnConfig, setColumnConfig] = useState([]);

  const createColumnConfig = columnsToScan => {
    const newColumnConfig = [];

    if (columnsToScan && columnsToScan.length > 0) {
      columnsToScan.forEach(column => {
        const { sqVisible, sqToggle, title, key } = column;
        newColumnConfig.push({ visible: sqVisible, canToggle: sqToggle, label: title, key });
      });
    }

    return newColumnConfig;
  };

  useEffect(() => {
    // TODO: check for config defaults or userConfig
    setColumnConfig(createColumnConfig(columns));
  }, [columns]);

  const showSettings = () => {
    setSettingsVisible(true);
  };
  const handleSettingsClose = () => {
    setSettingsVisible(false);
  };

  const filteredColumns =
    columns && columns.length > 0 && columnConfig.length > 0
      ? columns.filter(column => columnConfig.find(config => config.key === column.key).visible)
      : null;

  // End of Table Settings

  const filtersIndicators = (
    <TableFilterIndicators
      fieldIds={fieldIds}
      searchedColumn={searchedColumn}
      searchText={searchText}
      handleReset={handleResetSearchOnColumn}
      allColumns={filteredColumns}
    />
  );

  return (
    <>
      {summary && (
        <SummaryTable
          filtersIndicators={filtersIndicators}
          dataSource={currentFilteredTableData}
          columns={filteredColumns}
        />
      )}
      <div className='report-table' style={style}>
        <Table
          title={() => (
            <TitleBar
              titleBarData={titleBarData}
              showSettings={showSettings}
              filteredColumns={filteredColumns}
              currentFilteredTableData={currentFilteredTableData}
              filtersIndicators={filtersIndicators}
            />
          )}
          dataSource={currentFilteredTableData ?? []}
          columns={addSearchProps(filteredColumns)}
          scroll={{ x: true }}
          pagination={pagination}
        />
        <Drawer visible={settingsVisible} onClose={handleSettingsClose} placement='right'>
          <SettingsDrawer columnConfig={columnConfig} setColumnConfig={setColumnConfig} />
        </Drawer>
      </div>
    </>
  );
};

export default memo(SQTable);
