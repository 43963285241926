// Types
import { Invoice } from 'lib/types';

export const useInvoicesLast30Days = () => {
  const getInvoicesLast30Days = (activeWorkSite: any, invoice: any) => {
    const currentDate: Date = new Date();
    const lowerLimit: number = new Date(currentDate.setDate(currentDate.getDate() - 30)).getTime();
    const filteredInvoices: Array<Invoice> = invoice.data.filter(
      (x: Invoice) => new Date(x.invoiceDate).getTime() > lowerLimit
    );

    if (activeWorkSite) {
      return filteredInvoices.filter((x: Invoice) => +activeWorkSite === +x.workSiteCompanyId);
    }

    return filteredInvoices ?? [];
  };

  return (activeWorkSite: any, invoice: Invoice) => getInvoicesLast30Days(activeWorkSite, invoice);
};
