import { getValue, uuid } from '@rlean/utils';

export default class TableFilter {
  constructor(props) {
    this.id = getValue(props, 'id', uuid());
    this.defaultValue = getValue(props, 'defaultValue', '');
    this.value = getValue(props, 'value', '');
    this.altValue = getValue(props, 'altValue', '');
    this.emptyValue = getValue(props, 'emptyValue', '');
    this.showTag = getValue(props, 'showTag', false);
    this.isTag = getValue(props, 'isTag', true);
    this.appendValueToLabel = getValue(props, 'appendValueToLabel', true);
    this.useAltValueForLabel = getValue(props, 'useAltValueForLabel', false);
    this.label = getValue(props, 'label', '');
    this.originalLength = getValue(props, 'originalLength', 0);
  }
}
