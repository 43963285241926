import React, { createContext, useState } from 'react';
import { BaseFilters } from 'lib/models';

export const FilterContext = createContext({});

export const FilterContextProvider = ({ children }) => {
  const [filters, setFilters] = useState(new BaseFilters());

  const value = {
    filters,
    setFilters
  };

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};
