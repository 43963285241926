import React, { memo } from 'react';
import { RLeanState } from '@rlean/core';
import { hasValue } from '@rlean/utils';
import { formatDate } from 'lib/helpers';
import { BaseCard, LOADING_STATUS, BaseList, BaseListItem } from 'components/shared';
import { strings } from 'config';
import { navigate } from '@reach/router';

export const Summary = memo(() => {
  const [project, activeProject, historicProject] = RLeanState().select(({ state }) => [
    state.project,
    state.activeProject,
    state.historicProject
  ]);

  const getListData = () => {
    let projectToSummarize = 0;
    const listData = [];
    let dataReportingDescription = null;
    let lastSortDate = '';
    let startDate = '';
    let closedDate = '';

    if (hasValue(activeProject, 'id') && hasValue(project, 'data')) {
      let projectData = Array.isArray(project.data) ? project.data : [];
      projectToSummarize = projectData.find(item => item.projectId === activeProject.id);

      if (!projectToSummarize && hasValue(historicProject, 'data')) {
        let historicProjectData = Array.isArray(historicProject.data) ? historicProject.data : [];
        projectToSummarize = historicProjectData.find(item => item.projectId === activeProject.id);
      }
    }

    if (projectToSummarize) {
      dataReportingDescription = projectToSummarize.dataReportingDescription;

      listData.push(
        {
          title: strings.projectDetailsSummaryInspectedTitle,
          content: projectToSummarize.inspected ?? '--'
        },
        {
          title: strings.projectDetailsSummaryNonconformingTitle,
          content: projectToSummarize.defects ?? '--'
        },
        {
          title: strings.projectDetailsSummaryReworkedTitle,
          content: projectToSummarize.reworked ?? '--'
        },
        {
          title: strings.projectDetailsSummaryGoodTitle,
          content: projectToSummarize.good ?? '--'
        }
      );
      lastSortDate = projectToSummarize.lastSortDate ?? new Date();
      startDate = projectToSummarize.startDate ?? '--';
      closedDate = projectToSummarize.closedDate;
    }

    return { listData, dataReportingDescription, lastSortDate, startDate, closedDate };
  };

  const { listData, dataReportingDescription, lastSortDate, startDate, closedDate } = getListData();

  const getLoadingStatus = () => {
    let loadingStatus = LOADING_STATUS.NODATA;

    if (
      hasValue(activeProject, 'id') &&
      (!project ||
        (project && project.isLoading) ||
        (project && project.lastUpdated && typeof project.isLoading === 'undefined'))
    ) {
      loadingStatus = LOADING_STATUS.WAITING;
    } else if (hasValue(activeProject, 'id') && hasValue(project, 'isLoading') && !project.isLoading && listData) {
      loadingStatus = LOADING_STATUS.LOADED;
    }

    return loadingStatus;
  };

  const showDrawer = () => {
    if (listData && dataReportingDescription) {
      navigate(`/project/details/report/scan-data-summary`);
    } else {
      navigate(`/project/details/report/tabular-activity`);
    }
  };

  const cardData = {
    title: strings.projectDetailsSummaryTitle,
    loadingStatus: getLoadingStatus(),
    description: null
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  const closedDateText = closedDate
    ? `${strings.projectDetailsSummaryClosedDate}: ${formatDate(closedDate)}`
    : 'Status: Active';

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      <BaseList
        data={listData}
        itemsPerPage={5}
        launchAction={showDrawer}
        footerText={[
          `${strings.projectDetailsSummaryTotalAsOf} ${formatDate(lastSortDate)}`,
          `${strings.projectDetailsSummaryStartDate}: ${formatDate(startDate)}`,
          closedDateText
        ]}
        layout='horizontal'
        renderItem={item => (
          <BaseListItem title={item.title} description={item.description}>
            <b style={item.title === strings.projectDetailsSummaryNonconformingTitle ? { color: '#dd6675' } : null}>
              {item.content}
            </b>
          </BaseListItem>
        )}
      />
    </BaseCard>
  );
});
