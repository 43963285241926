import React, { memo } from 'react';
import { FileImageOutlined } from '@ant-design/icons';

const SQTablePicture = ({ value, handlePictureModal }) => {
  const enabledButtonStyle = {
    fontSize: '20px',
    padding: '0'
  };
  const disabledButtonStyle = {
    fontSize: '20px',
    padding: '0',
    cursor: 'not-allowed',
    color: '#8c8c8c'
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <button
        className='link-button'
        style={!value ? disabledButtonStyle : enabledButtonStyle}
        type='button'
        onClick={() => handlePictureModal(value)}
        disabled={!value}
      >
        <FileImageOutlined title='See attached photos' />
      </button>
    </div>
  );
};

export default memo(SQTablePicture);
