export const echoElementType = Object.freeze({
  Projectid: 1,
  SortDate: 2,
  SortShift: 3,
  Location: 4,
  CapturedAt: 5,
  EnteredByUserId: 6,
  Inspector: 7,
  Note: 8,
  Image: 9,
  PartNumber: 10,
  SupplierNumber: 11,
  PONumber: 12,
  PalletNumber: 13,
  LotNumber: 14,
  ContainerNumber: 15,
  SerialNumber: 16,
  ReceivedQuantity: 17,
  ProcessedQuantity: 18,
  TotalInspectedCount: 19,
  TotalDefectCount: 20,
  TotalReworkCount: 21,
  UserDefinedString: 22,
  UserDefinedInteger: 23,
  UserDefinedDate: 24,
  DefectCount: 25,
  DefectFlag: 26,
  PartCriteriaMap: 27,
  Part: 28,
  PartImage: 29,
  PartImageArea: 30,
  UserDefinedFloat: 31,
  UserDefinedChoice: 32,
  MfgDate: 33,
  TotalGoodCount: 34,
  RejectFlag: 35,
  TestElementType: 36,
  NewTestElementType: 42,
  TestElementType2: 46,
  TestElementType3: 52,
  SortDataId: 54,
  Latitude: 55,
  Longitude: 56,
  TimeZone: 57,
  FormDataGuid: 58,
  xxx: 59,
  RelatedImage: 60,
  FormRevisionOptionAllowDefectImages: 61,
  FormRevisionOptionSupportDefaultToPreviousValue: 62,
  VIN: 63,
  SortResult: 64,
  DOALStatus: 67,
  UserDefinedBoolean: 68,
  CapturedAtMapUri: 70
});
