import { define } from '@rlean/core';
import { ProjectInformationFormRevisionReview } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

// This entity is for the pending reviews

const ProjectInformationFormRevisionReviews = define<
  ProjectInformationFormRevisionReview[]
>(EntityKey.ProjectInformationFormRevisionReviews, {
  getURL: '/ProjectInformationFormRevisionReviews',
  putURL: '/ProjectInformationFormRevisionReviews/:id'
});

export default ProjectInformationFormRevisionReviews;
