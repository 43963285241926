import { hasValue } from '@rlean/utils';

// Types
import { BusinessContact } from 'lib/types/BusinessContact';
import { Project } from 'lib/types/Project';
import { WorkSite } from 'lib/types/WorkSite';

export const useProject = () => {
  const customerWorkSites = (workSites: WorkSite[]) => {
    return workSites.filter((workSite: WorkSite) => workSite.customerFlag);
  };

  /**
   * A project is a customer project if paying customer is one of the customer's
   * work locations.
   *
   * @param {Object} project
   * @param {Array} workSites
   */
  const isCustomer = (project: Project, workSites: WorkSite[]) => {
    const filteredWorkSites = customerWorkSites(workSites);
    const projectIsCustomerPaid = filteredWorkSites.some(
      (workSite: WorkSite) => +workSite.companyId === +project.customerCompanyId
    );

    return projectIsCustomerPaid;
  };

  /**
   * A project is a supplier project if the project is not paid for by the customer,
   * takes place at the customer's work location(s), and shared data for the location is true.
   * Assume that if it's not customer paid, then it's a supplier project, even if it doesn't
   * take place at the customer's work location(s). It may take place at a work location that
   * the customer has access to.
   *
   * @param {Object} project
   * @param {Array} workSites
   */
  const isSupplier = (project: Project, workSites: WorkSite[]) => {
    const filteredWorkSites = customerWorkSites(workSites);
    const projectIsCustomerPaid = filteredWorkSites.some(
      workSite => +workSite.companyId === +project.customerCompanyId
    );

    //#region Work takes place at customer

    // If the project is not customer paid and does not take place at customer site, assume that
    // it's a customer data access supplier project.
    // const workTakesPlaceAtCustomer = filteredWorkSites.some(
    //   workSite => +workSite.companyId === +project.workSiteCompanyId
    // );
    // return !projectIsCustomerPaid && workTakesPlaceAtCustomer && project.sharedDataFlag;

    //#endregion

    return !projectIsCustomerPaid && project.sharedDataFlag;
  };

  /**
   * isImposer projects will be treated the same as isSupplier projects
   *
   * @param {Object} project
   * @param {Array} workSites
   */
  const isImposer = (project: Project, workSites: WorkSite[]) => {
    const filteredWorkSites = customerWorkSites(workSites);
    const projectIsCustomerPaid = filteredWorkSites.some(
      workSite => +workSite.companyId === +project.customerCompanyId
    );

    return !projectIsCustomerPaid && project.imposerFlag;
  };

  const getProjectsByWorkSite = (activeWorkSite: number, projects: Project[]) => {
    let filteredProjects: Project[] = [];

    if (projects) {
      if (activeWorkSite) {
        // Filter to a work site.
        filteredProjects = projects.filter(project => +project.workSiteCompanyId === +activeWorkSite);
      } else {
        // Don't filter. Return projects for all sites.
        filteredProjects = projects;
      }
    }

    return filteredProjects;
  };

  const getActiveProjects = (
    activeWorkSite: number,
    workSites: WorkSite[],
    projects: Project[],
    businessContact: BusinessContact
  ) => {
    if (!workSites || !projects || !hasValue(businessContact, 'data.companyId')) {
      return null;
    }

    const filteredProjects = getProjectsByWorkSite(activeWorkSite, projects);

    const activeCustomerProjects = filteredProjects.filter(
      project => isCustomer(project, workSites) && project.projectStatus !== 'C'
    );

    const activeSupplierProjects = filteredProjects.filter(
      project => (isSupplier(project, workSites) || isImposer(project, workSites)) && project.projectStatus !== 'C'
    );

    const combinedProjects: Project[] = [];
    return combinedProjects.concat([], ...activeCustomerProjects, ...activeSupplierProjects);
  };

  /**
   * Filter projects by any attribute
   *
   * @param {Array} projects List of projects that will be filtered
   * @param {string} attrName Attribute name that is used for filtering
   * @param {Array} attrValues Values of the attributes being searched
   * @param {Boolean} exact search for the exact value
   * @returns {Array} Filtered projects
   */
  const filterByAttribute = (projects: Project[], attrName: string, attrValues: any, exact = true) => {
    if (!attrValues || attrValues.length === 0) {
      return null;
    }
    if (exact) {
      return projects.filter((project: any) => {
        if (project[attrName]) {
          return attrValues.includes(project[attrName].toString());
        }
        return false;
      });
    } else {
      // filter projects with the list of attribute values being searched
      return projects.reduce((pV: any, project: any) => {
        // skip projects already in the list or that doesn't have the attribute
        if (!project[attrName] || pV.some((proj: any) => proj.projectId === project.projectId)) {
          return pV;
        }

        // if the project includes at least one of the values being searched, add it to the list
        if (
          project[attrName] &&
          attrValues.some((val: string) => {
            project[attrName]
              .toString()
              .toLowerCase()
              .includes(val.toLowerCase());
          })
        ) {
          return [...pV, project];
        } else {
          return pV;
        }
      }, []);
    }
  };

  const columnNameIsNotEmpty = (project: any, columnName: string) => {
    return project[columnName] && (project[columnName] > 0 || project[columnName] !== '');
  };

  const filterEmptyRowsOnColumn = (projects: Project[], columnName: string) => {
    return projects.filter((project: Project) => columnNameIsNotEmpty(project, columnName));
  };

  const getCustomerProjects = (activeWorkSite: number, workSites: WorkSite[], projects: Project[]) => {
    if (!workSites || !projects) {
      return null;
    }

    const filteredProjects = getProjectsByWorkSite(activeWorkSite, projects);

    return filteredProjects.filter(project => isCustomer(project, workSites));
  };

  const getSupplierProjects = (activeWorkSite: number, workSites: WorkSite[], projects: Project[]) => {
    if (!workSites || !projects) {
      return null;
    }

    const filteredProjects = getProjectsByWorkSite(activeWorkSite, projects);

    return filteredProjects.filter(project => isSupplier(project, workSites) || isImposer(project, workSites));
  };

  const getHighNonConformingProjects = (
    activeWorkSite: number,
    workSites: WorkSite[],
    projects: Project[],
    businessContact: BusinessContact,
    percentage: number
  ) => {
    if (!workSites || !projects || !hasValue(businessContact, 'data.companyId')) {
      return null;
    }

    const filteredProjects = getProjectsByWorkSite(activeWorkSite, projects);

    const customerHighNonConformingProjects: Project[] = filteredProjects.filter(
      project => isCustomer(project, workSites) && project.fallout * 100 >= percentage
    );

    const supplierHighNonConformingProjects = filteredProjects.filter(
      project =>
        (isSupplier(project, workSites) || isImposer(project, workSites)) && project.fallout * 100 >= percentage
    );

    const combinedProjects: Project[] = [];
    return combinedProjects.concat([], ...customerHighNonConformingProjects, ...supplierHighNonConformingProjects);
  };

  const getZeroDefectProjects = (
    activeWorkSite: number,
    workSites: WorkSite[],
    projects: Project[],
    businessContact: BusinessContact
  ) => {
    if (!workSites || !projects || !hasValue(businessContact, 'data.companyId')) {
      return null;
    }

    const filteredProjects = getProjectsByWorkSite(activeWorkSite, projects);

    const zeroDefectCustomerProjects = filteredProjects.filter(
      project => isCustomer(project, workSites) && !project.defects
    );

    const zeroDefectSupplierProjects = filteredProjects.filter(
      project => (isSupplier(project, workSites) || isImposer(project, workSites)) && !project.defects
    );

    // TODO: remove after testing
    // return [].concat([], ...zeroDefectCustomerProjects, ...zeroDefectSupplierProjects);
    const combinedProjects: Project[] = [];
    return combinedProjects.concat([], ...zeroDefectCustomerProjects, ...zeroDefectSupplierProjects);
  };

  return {
    getActiveProjects,
    columnNameIsNotEmpty,
    getCustomerProjects,
    filterByAttribute,
    filterEmptyRowsOnColumn,
    getHighNonConformingProjects,
    isImposer,
    isSupplier,
    getProjectsByWorkSite,
    getSupplierProjects,
    getZeroDefectProjects
  };
};
