import { define } from '@rlean/core';
import { ProjectReport } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

// TODO: Rename to ProjectReport
const ProjectReports = define<ProjectReport[]>(EntityKey.ProjectReports, {
  getURL: '/ProjectActivityReportViews'
});

export default ProjectReports;
