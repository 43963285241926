import React from 'react';
import { Router, Location, Redirect } from '@reach/router';

import { Main } from 'components/layout/Main';

import { Dashboard } from 'components/pages/Dashboard';
import { Impersonate } from 'components/pages/Impersonate';
import { PageNotFound } from 'components/pages/PageNotFound';
import { Projects } from 'components/pages/Projects';
import { Details } from 'components/pages/Projects/Details';
import { PendingWorkInstructionReview } from 'components/pages/Projects/Details/PendingWorkInstructionReview';
import { PendingWorkAuthorizationReview } from 'components/pages/Projects/Details/PendingWorkAuthorizationReview';
import { PendingProjectInformationFormRevisionReview } from 'components/pages/Projects/Details/PendingProjectInformationFormRevisionReview';
import { Invoices } from 'components/pages/Invoices';
import { PrivacyPolicy } from 'components/pages/PrivacyPolicy';
import { Fullscreen } from 'components/pages/Projects/Fullscreen';
import { Support } from 'components/pages/Support';
import { TermsOfUse } from 'components/pages/TermsOfUse';
import { Unauthorized } from 'components/pages/Unauthorized';
import { UnexpectedError } from 'components/pages/UnexpectedError';
import { WhatsNew } from 'components/pages/WhatsNew';
import { Concerns } from 'components/pages/Concerns';
import { ConcernsCreate } from 'components/pages/Concerns/ConcernCreate';
import { ConcernDetail } from 'components/pages/Concerns/ConcernDetail';
import ReportQuickView from 'components/pages/Projects/Details/ReportQuickView';
import { FilterContextProvider } from 'components/shared/FiltersContext';
import ClearImpersonation from 'components/pages/ClearImpersonation';
import FindAProject from 'components/pages/StratosphereQualityHome/Projects/FindAProject';
import ProjectDetailsViewer from 'components/shared/ProjectDetailsViewer';
import StratosphereQualityHome from 'components/pages/StratosphereQualityHome';

class OnRouteChangeWorker extends React.Component {
  componentDidUpdate(prevProps) {
    const { location, action } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      action();
    }
  }

  render() {
    return null;
  }
}

const OnRouteChange = ({ action }) => (
  <Location>{({ location }) => <OnRouteChangeWorker location={location} action={action} />}</Location>
);

const Routes = () => (
  <Location>
    {({ location }) => (
      <Main location={location}>
        <FilterContextProvider>
          <Router>
            <StratosphereQualityHome path='/' />
            <Dashboard path='/dashboard' />
            <Impersonate path='/impersonate' />
            <Projects path='/projects' />
            <Details path='/project/details' />
            <ProjectDetailsViewer path='/project/details/report/:reportKey' />
            <Fullscreen path='/projects/fullscreen' />
            <PendingWorkInstructionReview path='/project/pending-work-instructions/:id' />
            <PendingWorkAuthorizationReview path='/project/pending-work-authorizations/:id' />
            <PendingProjectInformationFormRevisionReview path='/project/pending-pif/:id' />
            <Invoices path='/invoices' />
            <PageNotFound path='/page-not-found' />
            <PrivacyPolicy path='/privacy-policy' />
            <Support path='support' />
            <Concerns path='/concerns' />
            <ConcernDetail path='/concerns/:id' />
            <ConcernsCreate path='/concerns/new' />
            <TermsOfUse path='/terms-of-use' />
            <Unauthorized path='/unauthorized' />
            <UnexpectedError path='/unexpected-error' />
            <WhatsNew path='/whats-new' />
            <ClearImpersonation path='/clear-impersonation' />
            <FindAProject path='/find-a-project' />
            <ReportQuickView path='/quickpreview/:reportName' />
            <Redirect from='/projects/details' to='/project/details' noThrow />
            <Redirect
              from='/projects/pending-work-instructions/:id'
              to='/project/pending-work-instructions/:id'
              noThrow
            />
            <Redirect
              from='/projects/pending-work-authorizations/:id'
              to='/project/pending-work-authorizations/:id'
              noThrow
            />
            <Redirect from='/projects/pending-pif/:id' to='/project/pending-pif/:id' noThrow />
            <Redirect from='/*' to='/page-not-found' noThrow />
          </Router>
        </FilterContextProvider>

        <OnRouteChange
          action={() => {
            window.scrollTo(0, 0);
          }}
        />
      </Main>
    )}
  </Location>
);

export default Routes;
