import { version as dashboardVersion } from 'config/user-configuration/dashboardCards';
import { version as projectsVersion } from 'config/user-configuration/projectCards';
import { dashboardCardVersions } from 'config/user-configuration/dashboardCardVersions';
import { projectCardVersions } from 'config/user-configuration/projectCardVersions';

export const updateConfigurationVersion = (input, configurationType) => {
  const savedVersion = input.version;
  const savedConfigurationOrder = input.order;
  const savedConfiguration = input;
  const currentVersion = configurationType === 'dashboard' ? dashboardVersion : projectsVersion;
  const oldConfiguration =
    configurationType === 'dashboard' ? dashboardCardVersions[savedVersion] : projectCardVersions[savedVersion];
  const newConfiguration =
    configurationType === 'dashboard' ? dashboardCardVersions[currentVersion] : projectCardVersions[currentVersion];

  if (savedVersion !== currentVersion && (configurationType === 'dashboard' || configurationType === 'project')) {
    const equal = JSON.stringify(oldConfiguration) === JSON.stringify(newConfiguration);

    if (!equal) {
      // search for new cards to add
      for (let i = 0; i < newConfiguration.length; i++) {
        const obj = newConfiguration[i];
        let isNew = true;

        // for each object, check if it exists in the old configuration
        if (oldConfiguration) {
          for (let n = 0; n < oldConfiguration.length; n++) {
            if (obj.card === oldConfiguration[n].card) {
              isNew = false;
            }
          }
        }

        // if not, then add it to the user's saved configuration
        if (isNew) {
          savedConfigurationOrder.push(obj);
        }
      }

      // TODO: This needs to be tested
      // search for old cards to remove
      for (let i = 0; i < oldConfiguration.length; i++) {
        const obj = oldConfiguration[i];
        let isRemoved = true;

        // for each object, check if it exists in the new configuration
        if (oldConfiguration) {
          for (let n = 0; n < newConfiguration.length; n++) {
            if (obj === newConfiguration[n]) {
              isRemoved = false;
            }
          }
        }

        // if not, then remove it from the user's saved configuration
        if (isRemoved) {
          const index = savedConfigurationOrder
            .map(function(o) {
              return o;
            })
            .indexOf(obj);
          if (index > -1) {
            savedConfigurationOrder.splice(index, obj);
          }
        }
      }
    }

    savedConfiguration.order = savedConfigurationOrder;
    savedConfiguration.version = dashboardVersion;

    return [savedConfiguration, true];
  }

  return [input, false];
};
