import React, { memo } from 'react';
import { Card } from 'antd';
import parse from 'html-react-parser';
import { useActivePage } from 'lib/hooks';
import { strings, pages } from 'config';

export const PrivacyPolicy = memo(() => {
  useActivePage(pages.privacyPolicy);

  return (
    <Card className='page-card' title={strings.privacyPolicy}>
      {parse(strings.privacyPolicyContent)}
    </Card>
  );
});
