import React, { memo, useState } from 'react';
import { RLeanState, usePost, useGet } from '@rlean/core';
import { EntityKey } from 'lib/enums/EntityKey';
import { useActivePage } from 'lib/hooks';
import { pages, strings } from 'config';
import { Form, Input, Button, Select, Card, Alert, message } from 'antd';

const SupportForm = () => {
  const { TextArea } = Input;
  const { Option } = Select;
  const [form] = Form.useForm();

  const [messageSent, setMessageSent] = useState();
  const [supportContactUs] = RLeanState().select(({ state }) => [state.supportContactUs]);
  const post = usePost();

  useGet({ key: EntityKey.SupportContactUs });
  useActivePage(pages.support);

  const handleFinish = values => {
    let selectedId = '';
    if (supportContactUs && supportContactUs.data) {
      selectedId = supportContactUs.data.reduce((match, item) => {
        return item.name === values.contactType ? item.id : match;
      }, null);
    }

    message.loading('Sending...', 0);
    post(
      {
        key: EntityKey.SupportContactUs,
        body: {
          email: '',
          requestTypeId: selectedId,
          message: values.contactMessage
        }
      },
      (response, error) => {
        message.destroy();

        if (error) {
          setMessageSent(false);
          form.resetFields();
        } else {
          setMessageSent(true);
          form.resetFields();
        }
      }
    );
  };

  return (
    <>
      {messageSent === true && (
        <Alert
          message={strings.supportAlertMessageSuccess}
          description={strings.supportAlertDescriptionSuccess}
          type='success'
        />
      )}
      {messageSent === false && (
        <Alert
          message={strings.supportAlertMessageFail}
          description={strings.supportAlertDescriptionFail}
          type='error'
        />
      )}
      <Form onFinish={handleFinish} form={form} name='contact_us' layout='vertical' hideRequiredMark>
        <Form.Item
          label={strings.supportFormSelectLabel}
          name='contactType'
          rules={[{ required: true, message: strings.supportErrorSelect }]}
        >
          <Select placeholder={strings.supportSelectPlaceholder}>
            <Option value='Website Support'>{strings.supportOptionSupport}</Option>
            <Option value='Website Feedback'>{strings.supportOptionFeedback}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={strings.supportFormTextAreaLabel}
          name='contactMessage'
          rules={[{ required: true, message: strings.supportErrorText }]}
        >
          <TextArea type='text' rows='6' placeholder={strings.supportFormTextAreaPlaceholder} />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit'>
            {strings.supportOptionButton}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export const Support = memo(() => {
  return (
    <Card className='page-card' title={strings.supportCardTitle}>
      <SupportForm />
    </Card>
  );
});
