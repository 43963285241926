import React, { memo, useEffect, useState } from 'react';
import { RLeanState } from '@rlean/core';
import { hasValue, uuid, deepCopy } from '@rlean/utils';
import * as Sentry from '@sentry/browser';
import { Skeleton } from 'antd';
import { useActivePage, useRequireImpersonation } from 'lib/hooks';
import { pages } from 'config';
import { BaseGridPage } from 'components/shared/BaseGridPage';
import { Disclaimer } from 'components/shared/Disclaimer';
import { dashboardCards } from 'config/user-configuration';
import { StartAProjectButton } from './StartAProjectButton';

import { updateConfigurationVersion } from 'lib/helpers/updateConfigurationVersion';
import { useUserConfig } from 'lib/hooks';

export const Dashboard = memo(() => {
  const [userConfiguration] = RLeanState().select(({ state }) => [state.userConfiguration]);
  const [savedCards, setSavedCards] = useState([]);
  const [{ saveDashboardOrder }] = useUserConfig();

  useRequireImpersonation();

  useEffect(() => {
    let isSubscribed = true;
    const defaultCards = dashboardCards.configurationSettings.order;
    let cardsToSave = deepCopy(defaultCards);

    try {
      if (hasValue(userConfiguration, 'data') && userConfiguration.data.some(config => config.name === 'dashboard')) {
        const cardConfig = userConfiguration
          ? userConfiguration.data.find(config => config.name === 'dashboard')
          : null;

        if (cardConfig) {
          const [updatedCards, hasNewVersion] = updateConfigurationVersion(
            JSON.parse(cardConfig.configurationSettings),
            'dashboard'
          );

          cardsToSave = hasNewVersion ? updatedCards.order : JSON.parse(cardConfig.configurationSettings).order;

          if (hasNewVersion && Array.isArray(updatedCards.order)) {
            // save updated userConfig if user is not readonly
            saveDashboardOrder(updatedCards);
          }
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      // If there is an error, use the default card configuration.
      cardsToSave = defaultCards;
    }

    if (isSubscribed) {
      if (Array.isArray(cardsToSave)) {
        setSavedCards(cardsToSave);
      } else {
        setSavedCards(defaultCards);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [userConfiguration]);

  useActivePage(pages.dashboard);

  if (!userConfiguration || typeof userConfiguration.isLoading === 'undefined' || userConfiguration.isLoading) {
    return <Skeleton active />;
  }

  return (
    <BaseGridPage
      key={uuid()}
      savedCards={savedCards ?? []}
      DashboardLeftControl={Disclaimer}
      DashboardRightControl={StartAProjectButton}
      configKey='dashboard'
    />
  );
});
