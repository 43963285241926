import React, { memo, useEffect, useState } from 'react';
import { RLeanState } from '@rlean/core';
import { getValue } from '@rlean/utils';
import { Row, Col, Button, Table } from 'antd';
import { exportToCsv, formatDate } from 'lib/helpers';
import { strings } from 'config';

export const WorkInstructionsTable = memo(() => {
  const [projectWorkInstructions, activeProject] = RLeanState().select(({ state }) => [
    state.projectWorkInstructions,
    state.activeProject
  ]);
  let data = getValue(projectWorkInstructions, 'data', []);

  // Get workInstructionRevisionReviews only and sort them
  data = data.reduce((pv, cv) => [...pv, ...cv.workInstructionRevisionReviews], []);
  data.sort(
    (w1, w2) => w2.workInstructionRevisionPublishedVersionNumber - w1.workInstructionRevisionPublishedVersionNumber
  );

  const workInstructionsColumns = [
    {
      title: strings.projectDetailsBreadcrumbsItem,
      dataIndex: 'projectId',
      key: 'projectId',
      sorter: (a, b) => a.projectId - b.projectId
    },
    {
      title: strings.projectDetailsWorkInstructions.workInstructionRevisionPublishedVersionNumber,
      dataIndex: 'workInstructionRevisionPublishedVersionNumber',
      key: 'workInstructionRevisionPublishedVersionNumber',
      sorter: (a, b) =>
        a.workInstructionRevisionPublishedVersionNumber - b.workInstructionRevisionPublishedVersionNumber
    },
    {
      title: strings.projectDetailsWorkInstructions.workInstructionRevisionReviewStatus,
      dataIndex: 'workInstructionRevisionReviewStatus',
      key: 'workInstructionRevisionReviewStatus',
      sorter: (a, b) => (
        <>
          {a.workInstructionRevisionReviewStatus
            ? a.workInstructionRevisionReviewStatus.localeCompare(b.workInstructionRevisionReviewStatus)
            : '--'}
        </>
      )
    },
    {
      title: strings.sharedTableColumns.requestorFullName,
      dataIndex: 'requestorFullName',
      key: 'requestorFullName',
      sorter: (a, b) => <>{a.requestorFullName ? a.requestorFullName.localeCompare(b.requestorFullName) : '--'}</>
    },
    {
      title: strings.sharedTableColumns.requestorEmail,
      dataIndex: 'requestorEmail',
      key: 'requestorEmail',
      sorter: (a, b) => <>{a.requestorEmail ? a.requestorEmail.localeCompare(b.requestorEmail) : '--'}</>
    },
    {
      title: strings.sharedTableColumns.reviewerFullName,
      dataIndex: 'reviewerFullName',
      key: 'reviewerFullName',
      sorter: (a, b) => <>{a.reviewerFullName ? a.reviewerFullName.localeCompare(b.reviewerFullName) : '--'}</>
    },
    {
      title: strings.sharedTableColumns.reviewerEmail,
      dataIndex: 'reviewerEmail',
      key: 'reviewerEmail',
      sorter: (a, b) => <>{a.reviewerEmail ? a.reviewerEmail.localeCompare(b.reviewerEmail) : '--'}</>
    },
    {
      title: strings.sharedTableColumns.reviewerNote,
      dataIndex: 'reviewerNote',
      key: 'reviewerNote',
      sorter: (a, b) => <>{a.reviewerNote ? a.reviewerNote.localeCompare(b.reviewerNote) : '--'}</>
    },
    {
      title: strings.sharedTableColumns.requestedAt,
      dataIndex: 'requestedAt',
      key: 'requestedAt',
      sorter: (a, b) => <>{a.requestedAt ? a.requestedAt.localeCompare(b.requestedAt) : '--'}</>,
      render: requestedAt => <>{requestedAt ? formatDate(requestedAt) : '--'}</>
    },
    {
      title: strings.sharedTableColumns.reviewedAt,
      dataIndex: 'reviewedAt',
      key: 'reviewedAt',
      sorter: (a, b) => <>{a.reviewedAt ? a.reviewedAt.localeCompare(b.reviewedAt) : '--'}</>,
      render: reviewedAt => <>{reviewedAt ? formatDate(reviewedAt) : '--'}</>
    }
  ];

  const handleExportToCSV = () => {
    exportToCsv(`workInstructions-${activeProject.id}`, workInstructionsColumns, data);
  };

  return (
    <>
      <Row type='flex' justify='space-between' style={{ marginTop: 35 }}>
        <Col span={8}>
          <h1>{strings.projectDetailsWorkInstructionsReportTitle}</h1>
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <Button disabled={data.length === 0} type='primary' htmlType='button' onClick={handleExportToCSV}>
            {strings.sharedTableColumns.exportToCSV}
          </Button>
        </Col>
      </Row>
      <Table dataSource={data} columns={workInstructionsColumns} bordered scroll={{ x: true }} />
    </>
  );
});
