import { msalApiFetch, apiUri } from 'config';
import moment from 'moment';

// Random report helper functions
export const getUniquePartNumbers = parts => {
  let partNumbers = [];

  if (parts) {
    partNumbers = parts.reduce((accumulator, part) => accumulator.concat(part.number ? [part.number] : []), []);
  }

  const uniquePartNumbers = partNumbers.filter((number, index, self) => self.indexOf(number) === index);

  return uniquePartNumbers;
};

export const lookupPartIdsByPartNumber = (selectedPartNumbers, parts) => {
  let partIds = [];

  if (parts) {
    partIds = parts.reduce(
      (accumulator, part) => accumulator.concat(selectedPartNumbers.indexOf(part.number) !== -1 ? [part.id] : []),
      []
    );
  }

  const uniquePartIds = partIds.filter((number, index, self) => self.indexOf(number) === index);
  const areAllPartNumbersSelected = uniquePartIds.length === getUniquePartNumbers(parts).length;

  return areAllPartNumbersSelected ? [0] : uniquePartIds;
};

export const getUniqueCriteriaNames = (parts, criteriaWithoutParts) => {
  // api call needs ids, while the ui needs name. So here we are creating an array with every unique criteria name from the criteria array inside of the parts array and the criteria without parts array
  let partCriteriaNames = [];
  let criteriaWithoutPartNames = [];

  if (parts) {
    partCriteriaNames = parts.reduce(
      (accumulator, part) => accumulator.concat(part.criteria.map(criteria => criteria.name)),
      []
    );
  }

  if (criteriaWithoutParts) {
    criteriaWithoutPartNames = criteriaWithoutParts.map(criteria => criteria.name);
  }

  // combine both criteria arrays and filter out empty strings
  const allCriteriaNames = partCriteriaNames.concat(criteriaWithoutPartNames).filter(criteria => criteria.length > 0);
  const uniqueCriteriaNames = allCriteriaNames.filter((criteria, index, self) => self.indexOf(criteria) === index);

  return uniqueCriteriaNames;
};

export const lookupCriteriaIdsByCriteriaNames = (selectedCriteriaNames, parts, selectedCriteriaWithoutParts) => {
  let partCriteriaIds = [];
  let criteriaWithoutPartIds = [];

  if (parts) {
    partCriteriaIds = parts.reduce(
      (accumulator, part) =>
        // filter criteria by unique names user selected, then create an array of part ids with those matching names, finally filter for duplicate part ids
        accumulator
          .concat(
            part.criteria
              .filter(criteria => selectedCriteriaNames.indexOf(criteria.name) !== -1)
              .map(criteria => `${criteria.id}`)
          )
          .filter((criteria, index, self) => self.indexOf(criteria) === index),
      []
    );
  }

  if (selectedCriteriaWithoutParts) {
    criteriaWithoutPartIds = selectedCriteriaWithoutParts.map(criteria => criteria.id);
  }
  const allCriteriaIds = partCriteriaIds.concat(criteriaWithoutPartIds);
  const areAllCriteriaSelected =
    allCriteriaIds.length === getUniqueCriteriaNames(parts, selectedCriteriaWithoutParts).length;

  return areAllCriteriaSelected ? [0] : allCriteriaIds;
};

// Everything below is for the report api call
const extractAndFormatFilters = params => {
  const formattedParams = {
    ...params,
    projectId: null,
    businessContactId: null
  };

  if (params.startDateTime) {
    formattedParams.startDateTime = moment(formattedParams.startDateTime).format('YYYY-MM-DDTHH:mm');
  }

  if (params.endDateTime) {
    formattedParams.endDateTime = moment(formattedParams.endDateTime).format('YYYY-MM-DDTHH:mm');
  }

  if (params.startDate) {
    formattedParams.startDate = moment(formattedParams.startDate).format('YYYY-MM-DD');
  }

  if (params.endDate) {
    formattedParams.endDate = moment(formattedParams.endDate).format('YYYY-MM-DD');
  }

  if (params.parts) {
    formattedParams.parts = formattedParams.parts.toString();
  }

  if (params.criteria) {
    formattedParams.criteria = formattedParams.criteria.toString();
  }

  return formattedParams;
};

const parseFilters = params => {
  const filters = extractAndFormatFilters(params);
  let parsedParams = '';

  Object.keys(filters).forEach(key => {
    if (filters[key]) {
      parsedParams += `&${key}=${filters[key]}`;
    }
  });
  return parsedParams;
};

export const getReportData = async (endpoints, params) => {
  if (!endpoints) {
    console.warn('endpoints should not be null');
    return [];
  }

  const formatedFilters = parseFilters(params);
  const formatedIds = `?projectId=${params.projectId}&businessContactId=${params.businessContactId}`;
  const apiCalls = endpoints.map(endpoint =>
    msalApiFetch(fetch, apiUri(endpoint) + formatedIds + formatedFilters).then(resp => {
      return resp.json();
    })
  );

  return Promise.all(apiCalls).then(responses => responses);
};
