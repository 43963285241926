import React, { memo, useState } from 'react';
import { removeDuplicates, hasValue } from '@rlean/utils';
import { formats, reportNames } from 'components/shared/SQReport';
import { SQReport } from 'components/shared';
import moment from 'moment';
import { chartColors } from 'config/chartColors';
import { strings } from 'config';
import { downloadFileType, downloadFile } from 'lib/helpers';
import { useCurrentProject, useFilters } from 'lib/hooks';

const filterProperties = ['startDate', 'endDate'];

export const DailyHours = memo(() => {
  const [chartData, setChartData] = useState({ series: null, drillDown: null, reportData: null });

  const currentProject = useCurrentProject();

  let defaultStartDate = new Date();
  let defaultEndDate = new Date();

  if (hasValue(currentProject, 'lastSortDate')) {
    defaultStartDate = new Date(currentProject.lastSortDate);
    defaultEndDate = new Date(currentProject.lastSortDate);
    defaultStartDate.setDate(defaultStartDate.getDate() - 30);
    defaultEndDate.setDate(defaultEndDate.getDate());
  }

  const { convertedFilters: filters, loadingFilters, updateFiltersWithFormData: updateFilters } = useFilters(
    {
      startDate: moment(defaultStartDate),
      endDate: moment(defaultEndDate)
    },
    filterProperties
  );

  const columns = [
    {
      title: strings.reportTableColumnProject,
      dataIndex: 'projectId',
      key: 'projectId',
      sorter: (a, b) => a.projectId - b.projectId,
      defaultSortOrder: 'descend',
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnDate,
      dataIndex: 'calendarDate',
      key: 'calendarDate',
      render: date => <>{moment(date).format('MM/DD/YYYY')}</>,
      sorter: (a, b) => new Date(a.calendarDate) - new Date(b.calendarDate),
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnHours,
      dataIndex: 'dayHours',
      key: 'dayHours',
      sorter: (a, b) => a.dayHours - b.dayHours,
      sqVisible: true,
      sqToggle: true
    }
  ];

  const configuration = {
    chart: { type: 'column' },
    title: { text: '' },
    lang: { noData: 'No data to display' },
    noData: { position: { x: 0, y: 0, align: 'center', verticalAlign: 'middle' } },
    tooltip: { valueDecimals: 2 },
    credits: { enabled: false },
    subtitle: { text: '' },
    xAxis: { type: 'category' },
    yAxis: { title: { text: '' } },
    legend: { enabled: false },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: { enabled: true, format: '{point.y:.2f} hrs' }
      },
      column: { colorByPoint: true }
    },
    colors: [chartColors.GRAY],
    series: chartData.series,
    drilldown: chartData.drillDown
  };

  const buildChart = responseData => {
    if (responseData != null && responseData !== undefined) {
      if (responseData.length > 0) {
        const monthNames = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ];
        const months = [];
        const updatedData = [];
        const sortedData = responseData.sort((a, b) => a.calendarDate.localeCompare(b.calendarDate));
        const dates = [];

        for (let i = 0; i < sortedData.length; i += 1) {
          const data = sortedData[i];
          const date = `${new Date(data.calendarDate).getMonth()}${new Date(data.calendarDate).getFullYear()}`;
          data['date'] = date;

          updatedData.push(data);
          dates.push(date);
          months.push(`${new Date(data.calendarDate).getMonth()}${new Date(data.calendarDate).getFullYear()}`);
        }

        const filteredMonths = removeDuplicates(months);
        const filteredDates = removeDuplicates(dates);
        const seriesData = [];
        let drillDown = null;
        let series = null;

        if (filteredMonths.length > 1) {
          // data spans multiple months so the show drilldown
          const drillDownSeries = [];

          for (let i = 0; i < filteredDates.length; i += 1) {
            const drillDownSeriesData = [];
            let y = 0;
            const currentData = updatedData.filter(r => r.date === filteredDates[i]);
            const name = `${monthNames[new Date(currentData[0].calendarDate).getMonth()]} ${new Date(
              currentData[0].calendarDate
            ).getFullYear()}`;

            // create top level series
            for (let n = 0; n < currentData.length; n += 1) {
              y += currentData[n].dayHours;
              const keyValuePairs = [];
              keyValuePairs.push(`${moment(currentData[n].calendarDate).format('MM/DD/YYYY')}`);
              keyValuePairs.push(currentData[n].dayHours);
              drillDownSeriesData.push(keyValuePairs);
            }

            drillDownSeries.push({
              name,
              id: name,
              data: drillDownSeriesData
            });

            seriesData.push({
              name,
              y,
              drilldown: name
            });
          }

          drillDown = {
            series: drillDownSeries
          };

          series = {
            name: 'Months',
            colorByPoint: true,
            data: seriesData
          };
        } else {
          // show standard column chart
          for (let i = 0; i < sortedData.length; i += 1) {
            const data = sortedData[i];
            const recordData = [];
            recordData.push(moment(data.calendarDate).format('MM/DD/YYYY'));
            recordData.push(data.dayHours);
            seriesData.push(recordData);
          }

          series = {
            name: 'Estimated Daily Hours',
            colorByPoint: true,
            data: seriesData
          };
        }

        setChartData({ reportData: sortedData, series: [series], drillDown });
      } else {
        setChartData({ reportData: null, series: null, drillDown: () => {} });
      }
    }
  };

  const downloadReport = reportParams => {
    const { projectId, startDate, endDate } = reportParams;

    const params = {
      start: startDate.toISOString(true),
      end: endDate.toISOString(true),
      projectId,
      format: formats.EXCELOPENXML,
      reportName: reportNames.DAILY_HOURS
    };

    const options = {
      filename: `Estimated-Daily-Hours-${projectId}.xlsx`,
      endpoint: '/SsrsReportFiles',
      fileType: downloadFileType.EXCELOPENXML
    };

    downloadFile(options, params);
  };

  const reportOptions = {
    reportTitle: strings.reportDailyHoursTitle,
    urls: ['/ProjectDailyHours'],
    filterProperties,
    filters,
    updateFilters,
    buildChart,
    downloadReport
  };

  const chartOptions = {
    chartReady: !!chartData.reportData,
    configuration
  };

  const tableOptions = {
    titleBarData: {
      tableTitle: strings.reportDailyHoursTableTitle
    },
    dataSource: chartData.reportData,
    summary: false,
    columns
  };

  if (loadingFilters) {
    return <></>;
  }

  return <SQReport reportOptions={reportOptions} tableOptions={tableOptions} chartOptions={chartOptions} />;
});
