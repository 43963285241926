import React, { memo } from 'react';
import { Dropdown } from 'antd';
import { SettingFilled } from '@ant-design/icons';

export const SettingsMenu = memo(props => {
  return (
    <div className='fullscreen-settings'>
      <Dropdown overlay={props.menu} trigger={['click']} placement='bottomRight'>
        <SettingFilled />
      </Dropdown>
    </div>
  );
});
