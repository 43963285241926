import React, { memo } from 'react';
import { Dropdown } from 'antd';
import MaterialIcon from '@material/react-material-icon';

export const KabobMenu = memo(({ overlay, isDrawer = false }) => (
  <Dropdown
    className='export-to-csv'
    overlay={overlay}
    trigger={['click']}
    placement='bottomRight'
    overlayClassName={isDrawer ? 'drawer-dropdown' : 'not-drawer-dropdown'}
  >
    <button className='link-button' type='button'>
      <MaterialIcon role='button' icon='more_vert' />
    </button>
  </Dropdown>
));
