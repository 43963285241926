import { define } from '@rlean/core';
import { CompanyConcernCategory } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

// TODO: rename to CompanyConcernCategoryState
const CompanyConcernCategories = define<CompanyConcernCategory>(EntityKey.CompanyConcernCategories, {
  getURL: '/CompanyConcernCategories'
});

export default CompanyConcernCategories;
