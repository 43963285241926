import React, { memo } from 'react';
import { RLeanState } from '@rlean/core';
import { hasValue } from '@rlean/utils';
import { BaseCard, LOADING_STATUS, BaseList, BaseListItem } from 'components/shared';
import MaterialIcon from '@material/react-material-icon';
import { strings } from 'config';
import { navigate } from '@reach/router';

export const Documents = memo(() => {
  const [activeProject] = RLeanState().select(({ state }) => [state.activeProject]);

  const getLoadingStatus = () => {
    return hasValue(activeProject, 'id') ? LOADING_STATUS.LOADED : LOADING_STATUS.NODATA;
  };

  const showDrawer = detailReportRoute => {
    navigate(`/project/details/report/${detailReportRoute}`);
  };

  const cardData = {
    title: strings.projectDetailsDocuments,
    loadingStatus: getLoadingStatus(),
    description: null
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  const listData = [
    {
      title: strings.projectDetailsDocumentTrainingTitle,
      description: strings.projectDetailsDocumentTrainingDescription,
      icon: 'table_chart',
      action: () => showDrawer('training-logs')
    },
    {
      title: strings.projectDetailsDocumentProjectResourcesTitle,
      description: strings.projectDetailsDocumentProjectResourcesDescription,
      icon: 'table_chart',
      action: () => showDrawer('project-resources')
    }
  ];

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      <BaseList
        data={listData}
        renderItem={item => (
          <BaseListItem
            actionComponents={[
              <button type='button' className='action-button' onClick={item.action}>
                <MaterialIcon icon={item.icon} />
              </button>
            ]}
            title={item.title}
            description={item.description}
          />
        )}
      />
    </BaseCard>
  );
});
