import { define } from '@rlean/core';
import { ImpersonatedProject as ImpersonatedProjectType } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

const ImpersonatedProject = define<ImpersonatedProjectType>(EntityKey.ImpersonatedProject, {
  persistData: false,
  getURL: '/Projects/:projectId'
});

export default ImpersonatedProject;
