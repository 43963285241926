import { useEffect, useState } from 'react';

export const useTableFilters = () => {
  const [searchedColumn, setSearchedColumn] = useState([]);
  const [searchText, setSearchText] = useState({});
  const [selectedKeys, setSelectedKeys] = useState({});

  useEffect(() => {}, [selectedKeys]);

  const handleResetSearchOnColumn = dataIndex => {
    setSearchText(previousValue => {
      const { [dataIndex]: _, ...filtered } = previousValue;
      return filtered;
    });
    setSelectedKeys(previousValue => {
      const { [dataIndex]: _, ...filtered } = previousValue;
      return filtered;
    });
    setSearchedColumn(previousValue => [...previousValue.filter(item => item !== dataIndex)]);
  };

  const handleIsColumnSearched = dataIndex => {
    return (
      searchedColumn.includes(dataIndex) &&
      dataIndex in searchText &&
      searchText[dataIndex] &&
      searchText[dataIndex].length > 0
    );
  };

  const handleSearchOnColumn = dataIndex => {
    const value = selectedKeys[dataIndex];

    if (!value || (Array.isArray(value) && value.length === 0)) {
      handleResetSearchOnColumn(dataIndex);
      return;
    }

    if (selectedKeys[dataIndex]) {
      setSearchText(previousValue => ({ ...previousValue, [dataIndex]: selectedKeys[dataIndex] }));
      if (!searchedColumn.includes(dataIndex)) {
        setSearchedColumn(previousValue => [...previousValue, dataIndex]);
      }
    }
  };

  const handleOnSearchColumnChange = (value, key) => {
    setSelectedKeys(previousValue => ({ ...previousValue, [key]: value }));
  };

  return {
    searchedColumn,
    searchText,
    selectedKeys,
    handleResetSearchOnColumn,
    handleIsColumnSearched,
    handleSearchOnColumn,
    handleOnSearchColumnChange
  };
};
