import React, { memo } from 'react';
import { RLeanState } from '@rlean/core';
import { hasValue } from '@rlean/utils';
import { BaseCard, BaseList, BaseListItem, LOADING_STATUS } from 'components/shared';
import { strings } from 'config';
import AuthorizationDetails from './AuthorizationDetails';
import NoData from './NoData';

const Authorization = () => {
  const [activeProject, workAuthorization] = RLeanState().select(({ state }) => [
    state.activeProject,
    state.workAuthorization
  ]);

  const getLoadingStatus = () => {
    let loadingStatus = LOADING_STATUS.NODATA;

    if (
      hasValue(activeProject, 'id') &&
      (!workAuthorization || (hasValue(workAuthorization, 'isLoading') && workAuthorization.isLoading))
    ) {
      loadingStatus = LOADING_STATUS.WAITING;
    } else if (
      hasValue(activeProject, 'id') &&
      hasValue(workAuthorization, 'isLoading') &&
      !workAuthorization.isLoading &&
      hasValue(workAuthorization, 'data')
    ) {
      loadingStatus = LOADING_STATUS.LOADED;
    }

    return loadingStatus;
  };

  const cardData = {
    title: strings.projectDetailsAuthorizationTitle,
    loadingStatus: getLoadingStatus(),
    description: null
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  const listData =
    hasValue(workAuthorization, 'data', []) && workAuthorization.data.length > 0
      ? workAuthorization.data.map(item => {
          return {
            businessContactName: item.businessContactName,
            businessContactEmail: item.businessContactEmail,
            approved: item.approved,
            respondedAt: item.respondedAt,
            declinedReason: item.declinedReason,
            declinedExplanation: item.declinedExplanation,
            hasData: true
          };
        })
      : [
          {
            businessContactName: null,
            businessContactEmail: null,
            approved: null,
            respondedAt: null,
            declinedReason: null,
            declinedExplanation: null,
            hasData: false
          }
        ];

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      <BaseList
        data={listData}
        renderItem={authorization => (
          <BaseListItem title=''>
            {authorization.hasData ? (
              <AuthorizationDetails
                businessContactName={authorization.businessContactName}
                businessContactEmail={authorization.businessContactEmail}
                approved={authorization.approved}
                respondedAt={authorization.respondedAt}
                declinedExplanation={authorization.declinedExplanation}
                declinedReason={authorization.declinedReason}
              />
            ) : (
              <NoData />
            )}
          </BaseListItem>
        )}
      />
    </BaseCard>
  );
};

export default memo(Authorization);
