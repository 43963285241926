import React, { memo, useEffect, useState } from 'react';
import { RLeanState, useSave } from '@rlean/core';
import { hasValue, getValue, uuid, deepCopy } from '@rlean/utils';
import * as Sentry from '@sentry/browser';
import { Dropdown, List, Badge } from 'antd';
import { BellFilled } from '@ant-design/icons';
import { useActivePage, useRequireImpersonation, useUserConfig } from 'lib/hooks';
import { updateConfigurationVersion } from 'lib/helpers/updateConfigurationVersion';
import { pages } from 'config';
import { BaseGridPage } from 'components/shared/BaseGridPage';
import { FavoriteButton } from 'components/shared';
import { projectCards } from 'config/user-configuration';
import { Breadcrumbs } from './Breadcrumbs';
import { PendingActionItem } from './shared/PendingActionItem';
import { actionItemType } from './shared/actionItemType';
import { ReviewLink } from './shared/ReviewLink';

export const Details = memo(() => {
  const [id, setId] = useState();
  const [savedCards, setSavedCards] = useState([]);
  const [{ saveProjectDetailsOrder }] = useUserConfig();
  const [
    activeProject,
    userConfiguration,
    pendingWorkAuthorization,
    pendingWorkInstruction,
    parts,
    partWithPartImage,
    project,
    projectParts,
    projectContacts,
    projectHoursNotYetInvoiced,
    projectInformationFormRevisionReviews,
    projectInformationFormRevisions,
    projectReports,
    projectResources,
    projectTrainingLog,
    projectWorkInstructions
  ] = RLeanState().select(({ state }) => [
    state.activeProject,
    state.userConfiguration,
    state.pendingWorkAuthorization,
    state.pendingWorkInstruction,
    state.parts,
    state.partWithPartImage,
    state.project,
    state.projectParts,
    state.projectContacts,
    state.projectHoursNotYetInvoiced,
    state.projectInformationFormRevisionReviews,
    state.projectInformationFormRevisions,
    state.projectReports,
    state.projectResources,
    state.projectTrainingLog,
    state.projectWorkInstructions
  ]);
  const projectId = getValue(activeProject, 'id');

  useRequireImpersonation();
  useActivePage(pages.projectDetails);

  useEffect(() => {
    if (projectParts && projectParts.data && projectParts.data.projectId === projectId) {
      if (projectParts.refetch) {
        projectParts.refetch();
      }
      if (projectContacts.refetch) {
        projectContacts.refetch();
      }
      if (projectHoursNotYetInvoiced.refetch) {
        projectHoursNotYetInvoiced.refetch();
      }
      if (projectReports.refetch) {
        projectReports.refetch();
      }
      if (projectTrainingLog.refetch) {
        projectTrainingLog.refetch();
      }
      if (projectWorkInstructions.refetch) {
        projectWorkInstructions.refetch();
      }
      if (projectInformationFormRevisions.refetch) {
        projectInformationFormRevisions.refetch();
      }
      if (parts && parts.refetch) {
        parts.refetch();
      }
      if (partWithPartImage && partWithPartImage.refetch) {
        partWithPartImage.refetch();
      }
      if (projectResources.refetch) {
        projectResources.refetch();
      }
    }
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    const defaultCards = projectCards.configurationSettings.order;
    let cardsToSave = deepCopy(defaultCards);

    if (
      hasValue(userConfiguration, 'data') &&
      userConfiguration.data.some(config => config.name === 'projectDetails')
    ) {
      const cardConfig = userConfiguration
        ? userConfiguration.data.find(config => config.name === 'projectDetails')
        : null;

      try {
        if (cardConfig) {
          const [updatedCards, hasNewVersion] = updateConfigurationVersion(
            JSON.parse(cardConfig.configurationSettings),
            'project'
          );

          cardsToSave = JSON.parse(cardConfig.configurationSettings).order;

          if (hasNewVersion && Array.isArray(updatedCards.order)) {
            cardsToSave = updatedCards.order;

            // save updated userConfig if user is not readonly
            saveProjectDetailsOrder(updatedCards);
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        // If there is an error, use the default card configuration.
        cardsToSave = defaultCards;
      }
    }

    if (isSubscribed) {
      if (Array.isArray(cardsToSave)) {
        setSavedCards(cardsToSave);
      } else {
        setSavedCards(defaultCards);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [userConfiguration]);

  useEffect(() => {
    let isSubscribed = true;

    if (hasValue(activeProject, 'id') && isSubscribed) {
      setId(activeProject.id);
    }

    return () => {
      isSubscribed = false;
    };
  }, [activeProject]);

  const list = () => {
    const data = [];

    if (
      hasValue(pendingWorkAuthorization, 'data') &&
      hasValue(activeProject, 'id') &&
      hasValue(project, 'data') &&
      project.data.find(x => x.projectId === activeProject.id)
    ) {
      const item = pendingWorkAuthorization.data.find(x => x.projectId === activeProject.id);

      if (item) {
        const formatted = {
          message: 'Pending Work Authorization',
          description: <ReviewLink type={actionItemType.WORK_AUTHORIZATION} id={item.id} />
        };

        data.push(formatted);
      }
    }

    if (
      hasValue(pendingWorkInstruction, 'data') &&
      hasValue(activeProject, 'id') &&
      hasValue(project, 'data') &&
      project.data.find(x => x.projectId === activeProject.id)
    ) {
      const item = pendingWorkInstruction.data.find(x => x.projectId === activeProject.id);

      if (item) {
        const formatted = {
          message: 'Pending Work Instruction',
          description: <ReviewLink type={actionItemType.WORK_INSTRUCTION} id={item.id} />
        };

        data.push(formatted);
      }
    }

    if (
      hasValue(projectInformationFormRevisionReviews, 'data') &&
      hasValue(activeProject, 'id') &&
      hasValue(project, 'data') &&
      project.data.find(x => x.projectId === activeProject.id)
    ) {
      const item = projectInformationFormRevisionReviews.data.find(x => x.projectId === activeProject.id);

      if (item) {
        const formatted = {
          message: 'Pending Project Information Form',
          description: <ReviewLink type={actionItemType.PROJECT_INFORMATION_FORM} id={item.id} />
        };

        data.push(formatted);
      }
    }

    return (
      <List
        bordered
        dataSource={data}
        renderItem={item => (
          <List.Item style={{ paddingRight: 40 }}>
            {item.message}
            <br />
            {item.description}
          </List.Item>
        )}
      />
    );
  };

  const RightControl = () => {
    const pendingWorkInstructions = getValue(pendingWorkInstruction, 'data', []).filter(x => x.projectId === projectId)
      .length;
    const pendingWorkAuthorizations = getValue(pendingWorkAuthorization, 'data', []).filter(
      x => x.projectId === projectId
    ).length;
    const pendingProjectInformationFormRevisionReviews = getValue(
      projectInformationFormRevisionReviews,
      'data',
      []
    ).filter(x => x.projectId === activeProject.id).length;
    const total = +pendingWorkInstructions + +pendingWorkAuthorizations + +pendingProjectInformationFormRevisionReviews;

    return (
      <>
        <Dropdown overlay={list} trigger={['click']} placement='bottomRight' overlayClassName='not-drawer-dropdown'>
          <Badge count={total} style={{ marginTop: 10, marginRight: 5, display: 'inline-block' }}>
            <BellFilled
              className='action'
              style={{ fontSize: 'large', marginTop: 12, marginRight: 5, display: 'inline-block' }}
            />
          </Badge>
        </Dropdown>
        <FavoriteButton projectId={projectId} />
      </>
    );
  };

  return (
    <>
      <BaseGridPage
        key={uuid()}
        savedCards={savedCards ?? []}
        DashboardLeftControl={Breadcrumbs}
        DashboardRightControl={RightControl}
        configKey='projectDetails'
        projectId={id}
      />
      <PendingActionItem />
    </>
  );
});
