export class ImageHeatmap {
  height;
  width;
  selectionAreas;
  dataUrl;

  constructor(props) {
    this.height = props.height;
    this.width = props.width;
    this.selectionAreas = props.selectionAreas;
    this.dataUrl = props.dataUrl;
  }

  calculateAreaCoordinates(currentImage) {
    const newSelectionAreas = this.selectionAreas
      ? this.selectionAreas.map(selectionArea => {
          const percentage = currentImage.width / this.width;
          const x = selectionArea.x * percentage;
          const y = selectionArea.y * percentage;
          const height = selectionArea.height * percentage;
          const width = selectionArea.width * percentage;

          return {
            id: selectionArea.id,
            x,
            y,
            height,
            width,
            label: selectionArea.label
          };
        })
      : [];

    return newSelectionAreas;
  }

  determineGridSize(gridOptions) {
    const { xLength, yLength, imageWidth, imageHeight } = gridOptions;

    const areaHeight = imageHeight / yLength;
    const areaWidth = imageWidth / xLength;

    return {
      yLength,
      xLength,
      areaHeight,
      areaWidth
    };
  }
}
