import React, { memo, useState, useEffect, useContext } from 'react';
import { navigate } from '@reach/router';
import { RLeanState } from '@rlean/core';
import { hasValue, deepCopy } from '@rlean/utils';
import { BaseCard, LOADING_STATUS } from 'components/shared';
import { projectMetricType } from 'lib/types';
import { FilterContext } from 'components/shared/FiltersContext';

// Hooks
import { useProject } from 'lib/hooks/useProject';

export const MyProjects = memo(() => {
  const [project, workSite] = RLeanState().select(({ state }) => [state.project, state.workSite]);
  const [loadingStatus, setLoadingStatus] = useState('');
  const { filters, setFilters } = useContext(FilterContext);
  const { getCustomerProjects } = useProject();

  const cardData = {
    title: 'My Projects',
    loadingStatus,
    description: 'My total number of projects'
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  const myProjects = getCustomerProjects(
    filters.selectedWorkSite ? filters.selectedWorkSite.value : 0,
    workSite ? workSite.data : [],
    project ? project.data : []
  );

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      if (
        !project ||
        (project && project.isLoading) ||
        (project && project.lastUpdated && typeof project.isLoading === 'undefined') ||
        !myProjects
      ) {
        setLoadingStatus(LOADING_STATUS.WAITING);
      } else if (hasValue(project, 'isLoading') && !project.isLoading && myProjects) {
        setLoadingStatus(LOADING_STATUS.LOADED);
      } else {
        setLoadingStatus(LOADING_STATUS.NODATA);
      }
    }

    return () => (isSubscribed = false);
  }, [project, workSite, myProjects]);

  const setMetric = () => {
    const updatedFilters = deepCopy(filters);

    updatedFilters['projectMetric'].value = projectMetricType.MY_PROJECTS;
    updatedFilters['projectMetric'].showTag = true;

    setFilters(updatedFilters);
    navigate('/projects');
  };

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      <button type='button' className='link-button general-card-total' onClick={setMetric}>
        <span>{myProjects ? myProjects.length : '--'}</span>
      </button>
    </BaseCard>
  );
});
