export enum EntityKey {
  AccountRep = 'accountRep',
  ActivePage = 'activePage',
  ActiveProject = 'activeProject',
  BusinessContact = 'businessContact',
  BusinessContactCompany = 'businessContactCompany',
  BusinessContactSearch = 'businessContactSearch',
  BusinessContactUser = 'businessContactUser',
  BusinessContactUserByCompany = 'businessContactUserByCompany',
  BusinessContactUsers = 'businessContactUsers',
  CompanyConcernCategories = 'companyConcernCategories',
  CompanyConcerns = 'companyConcerns',
  DashboardFavorite = 'dashboardFavorite',
  EchoFormData = 'echoFormData',
  FindAProjectForm = 'findAProjectForm',
  FindAProjectSearch = 'findAProjectSearch',
  HistoricProject = 'historicProject',
  ImpersonatedProject = 'impersonatedProject',
  Impersonation = 'impersonation',
  Invoice = 'invoice',
  Office = 'office',
  PendingWorkAuthorization = 'pendingWorkAuthorization',
  PendingWorkInstruction = 'pendingWorkInstruction',
  Parts = 'parts',
  PartImageGridSpec = 'partImageGridSpec',
  PartWithPartImage = 'partWithPartImage',
  Project = 'project',
  ProjectPreviousData = 'projectPreviousData',
  ProjectContacts = 'projectContacts',
  ProjectFavorite = 'projectFavorite',
  ProjectHoursNotYetInvoiced = 'projectHoursNotYetInvoiced',
  ProjectInformationFormRevisionReviews = 'projectInformationFormRevisionReviews',
  ProjectInformationFormRevisions = 'projectInformationFormRevisions',
  ProjectParts = 'projectParts',
  ProjectReports = 'projectReports',
  ProjectResources = 'projectResources',
  ProjectSummary = 'projectSummary',
  ProjectTableColumns = 'projectTableColumns',
  ProjectTrainingLog = 'projectTrainingLog',
  ProjectWorkInstructions = 'projectWorkInstructions',
  ServiceType = 'serviceType',
  SupportContactUs = 'supportContactUs',
  UserConfiguration = 'userConfiguration',
  UserDescription = 'userDescription',
  UserTracking = 'userTracking',
  Version = 'version',
  WorkAuthorization = 'workAuthorization',
  WorkSite = 'workSite'
}
