import React, { memo } from 'react';
import { Button, Switch, Divider } from 'antd';

const SettingsDrawer = ({ columnConfig, setColumnConfig }) => {
  const styles = {
    switchContainer: { paddingTop: 3, paddingBottom: 3, display: 'block', width: '100%' },
    switchLabel: { marginLeft: 5, fontSize: 'small' }
  };
  // Behavior
  const handleAutoRefreshToggle = e => {};

  // Toggles
  const handleToggleChange = (value, key) => {
    const newConfig = columnConfig.map(column => (column.key === key ? { ...column, visible: value } : column));

    setColumnConfig(newConfig);
  };

  // TODO: Redesign to handle a "default" config instead of assuming all columns should start visible
  const handleReset = () => {
    const newConfig = columnConfig.map(column => ({ ...column, visible: true }));

    setColumnConfig(newConfig);
  };

  return (
    <>
      <div className='table-filters-wrapper'>
        {/* <div>
          <h3>Filters</h3>
        </div>
        <div className='table-filters-col'>Filters Here</div>
        <Divider />
        <div>
          <h3>Behavior</h3>
        </div>
        <div style={styles.switchContainer}>
          <Switch defaultChecked={true} onChange={e => handleAutoRefreshToggle(e)} />
          <span style={styles.switchLabel}>Auto Refresh</span>
        </div>
        <Divider /> */}
        <div>
          <h3>Column Visibility</h3>
        </div>
        {columnConfig &&
          columnConfig.map(column => {
            const { visible, canToggle, label, key } = column;
            return (
              <div key={`switches-${key}`} style={styles.switchContainer}>
                <Switch checked={visible} disabled={!canToggle} onChange={e => handleToggleChange(e, key)} />
                <span style={styles.switchLabel}>{label ?? ''}</span>
              </div>
            );
          })}
        <Button className='reset-visibility' type='primary' htmlType='button' onClick={handleReset}>
          Reset
        </Button>
      </div>
    </>
  );
};

export default memo(SettingsDrawer);
