import React, { memo } from 'react';
import { Card, Popover, Skeleton, Empty } from 'antd';
import MaterialIcon from '@material/react-material-icon';

export const LOADING_STATUS = {
  LOADED: 'loaded',
  NODATA: 'noData',
  WAITING: 'waiting'
};

function EmptyCard({ data: { description, Content } }) {
  return (
    <>
      <Card.Meta description={description} />
      {Content ? <Content /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className='empty-card' />}
    </>
  );
}

function GearComponent({ data: { popOverContent, popOverTitle } }) {
  return (
    <Popover placement='bottomRight' title={popOverTitle ?? ''} trigger='click' content={popOverContent}>
      <button type='button' className='action-button'>
        <MaterialIcon icon='settings' />
      </button>
    </Popover>
  );
}

// <Button type='primary' shape='circle' icon='settings' size='large' />

function LoadingAnimation() {
  return <Skeleton active />;
}

export const BaseCard = memo(
  ({
    cardData,
    emptyCardData,
    size = 'small',
    gearData = null,
    actionComponents = null,
    className = '',
    style = null,
    children
  }) => {
    return (
      <Card
        className={`base-card ${className}`}
        style={style}
        size={size}
        title={cardData.title}
        extra={gearData && <GearComponent data={gearData} />}
        actions={actionComponents && actionComponents}
      >
        {(() => {
          switch (cardData.loadingStatus) {
            case LOADING_STATUS.LOADED:
              return (
                <>
                  {cardData.description && <Card.Meta description={cardData.description} />}
                  {children}
                </>
              );
            case LOADING_STATUS.NODATA:
              return <EmptyCard data={emptyCardData} />;
            case LOADING_STATUS.WAITING:
              return <LoadingAnimation />;
            default:
              return <></>;
          }
        })()}
      </Card>
    );
  }
);
