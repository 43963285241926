import React, { memo } from 'react';
import MaterialIcon from '@material/react-material-icon';
import { echoElementType } from 'lib/types';

const SQTableUri = ({ value, elementTypeId }) => {
  let tableUri;

  if (!value) {
    return '--';
  }

  switch (elementTypeId) {
    case echoElementType.CapturedAtMapUri:
      tableUri = (
        <a href={value} target='_blank' rel='noreferrer'>
          <MaterialIcon className='action' role='button' icon='map' />
        </a>
      );
      break;
    default:
      tableUri = (
        <a href={value} target='_blank' rel='noreferrer'>
          {value}
        </a>
      );
      break;
  }

  return tableUri;
};

export default memo(SQTableUri);
