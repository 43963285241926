import React from 'react';
import { uuid } from '@rlean/utils';

// Reports
import {
  ActivitySummary,
  DailyHours,
  DefectHistory,
  DOALActivity,
  DOALAllInOne,
  EchoCustomForm,
  EchoInspection,
  IChart,
  ParetoComponent,
  PartAreaDefectIntensity,
  ScanSummary,
  Tabular,
  Trend,
  UninspectedVINs
} from 'components/pages/Projects/Details/reports';
import { MobileDataSummary } from 'components/pages/Projects/Details/reports/MobileDataSummary';

// Tables
import { TrainingTable } from 'components/pages/Projects/Details/cards/details/TrainingTable';
import { AuditsTable } from 'components/pages/Projects/Details/cards/details/AuditsTable';
import { InvoicedTable } from 'components/pages/Projects/Details/cards/details/InvoicedTable';
import { PartsTable } from 'components/pages/Projects/Details/cards/details/PartsTable';
import { WorkInstructionsTable } from 'components/pages/Projects/Details/cards/details/WorkInstructionsTable';
import { ProjectInformationFormsTable } from 'components/pages/Projects/Details/cards/details/ProjectInformationFormsTable';
import { ProjectResourcesTable } from 'components/pages/Projects/Details/cards/details/ProjectResourcesTable';

export const reports = {
  pareto: { component: <ParetoComponent />, key: uuid(), text: 'Pareto', report: 'Pareto' },
  trend: { component: <Trend />, key: uuid(), text: 'Trend', report: 'Trend' },
  'activity-summary': {
    component: <ActivitySummary />,
    key: uuid(),
    text: 'Activy Summary',
    report: 'ActivitySummary'
  },
  'tabular-activity': {
    component: <Tabular />,
    key: uuid(),
    text: 'Tabular Summary',
    report: 'TabularActivity'
  },
  'defect-history': {
    component: <DefectHistory />,
    key: uuid(),
    text: 'Defect History',
    report: 'DefectHistory'
  },
  'estimated-daily-hours': {
    component: <DailyHours />,
    key: uuid(),
    text: 'Estimated Daily Hours',
    report: 'EstimatedDailyHours'
  },
  ichart: { component: <IChart />, key: uuid(), text: 'iChart', report: 'iChart' },
  'mobile-data-summary': {
    component: <MobileDataSummary />,
    key: uuid(),
    text: 'Mobile Data Summary',
    report: 'MobileDataSummary'
  },
  'scan-data-summary': {
    component: <ScanSummary />,
    key: uuid(),
    text: 'Scan Data Summary',
    report: 'ScanDataSummary'
  },
  'uninspected-vehicles': {
    component: <UninspectedVINs />,
    key: uuid(),
    text: 'Uninspected Vehicles',
    report: 'UninspectedVehicles'
  },
  'vehicle-inspection-all-in-one': {
    component: <DOALAllInOne />,
    key: uuid(),
    text: 'Vehicle Inspection All In One',
    report: 'VehicleInspectionAllInOne'
  },
  'project-audits': {
    component: <AuditsTable />,
    key: uuid(),
    text: 'Project Audits',
    report: 'Audits'
  },
  'standard-vehicle-inspection': {
    component: <DOALActivity />,
    key: uuid(),
    text: 'Standard Vehicle Inspection',
    report: 'StandardVehicleInspection'
  },
  'echo-standard-data': {
    component: <EchoInspection />,
    key: uuid(),
    text: 'Echo Standard Data',
    report: 'EchoStandardData'
  },
  'echo-custom-data': {
    component: <EchoCustomForm />,
    key: uuid(),
    text: 'Echo Custom Data',
    report: 'EchoCustomData'
  },
  'part-area-defect-intensity': {
    component: <PartAreaDefectIntensity />,
    key: uuid(),
    text: 'Part Area Defect Intensity',
    report: 'PartAreaDefectIntensity'
  },
  'training-logs': {
    component: <TrainingTable />,
    key: uuid(),
    text: 'Training Table',
    report: 'TrainingTable'
  },
  'project-resources': {
    component: <ProjectResourcesTable />,
    key: uuid(),
    text: 'Project Resources',
    report: 'ProjectResourcesTable'
  },
  invoiced: { component: <InvoicedTable />, key: uuid(), text: 'Invoiced Table', report: 'InvoicedTable' },
  'work-instructions': {
    component: <WorkInstructionsTable />,
    key: uuid(),
    text: 'Work Instructions Table',
    report: 'WorkInstructionsTable'
  },
  'project-information-forms': {
    component: <ProjectInformationFormsTable />,
    key: uuid(),
    text: 'Project Information Forms Table',
    report: 'ProjectInformationFormsTable'
  },
  parts: { component: <PartsTable />, key: uuid(), text: 'Parts Table', report: 'PartsTable' }
};
