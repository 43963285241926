import React, { memo } from 'react';
import { ReportFilters } from 'components/shared';
import { RLeanState } from '@rlean/core';
import { hasValue } from '@rlean/utils';
import { reportNames, formats } from 'components/shared/SQReport';
import moment from 'moment';
import { downloadFileType, downloadFile } from 'lib/helpers';
import { useCurrentProject, useFilters } from 'lib/hooks';

const filterProperties = ['startDateTime', 'endDateTime'];

export const MobileDataSummary = memo(() => {
  const [activeProject] = RLeanState().select(({ state }) => [state.activeProject]);

  const currentProject = useCurrentProject();

  let defaultStartDate = new Date();
  let defaultEndDate = new Date();

  if (hasValue(currentProject, 'lastSortDate')) {
    defaultStartDate = new Date(currentProject.lastSortDate);
    defaultEndDate = new Date(currentProject.lastSortDate);
    defaultStartDate.setDate(defaultStartDate.getDate() - 30);
    defaultEndDate.setDate(defaultEndDate.getDate());
  }

  const defaultFilters = {
    startDateTime: moment(defaultStartDate).startOf('day'),
    endDateTime: moment(defaultEndDate).endOf('day')
  };

  const { convertedFilters: filters, loadingFilters, updateFiltersWithFormData: updateFilters } = useFilters(
    defaultFilters,
    filterProperties
  );

  const handleFiltersSubmit = formValues => {
    updateFilters(formValues);

    const { startDateTime } = formValues;
    const endDateTime = formValues.endDateTime.endOf('day');

    const params = {
      startDate: startDateTime.toISOString(true),
      endDate: endDateTime.toISOString(true),
      project: activeProject.id,
      format: formats.EXCELOPENXML,
      reportName: reportNames.MOBILE_DATA_SUMMARY
    };

    const options = {
      filename: `Mobile-Data-Summary-${activeProject.id}.xlsx`,
      endpoint: '/SsrsReportFiles',
      fileType: downloadFileType.EXCELOPENXML
    };

    downloadFile(options, params);
  };

  if (loadingFilters) {
    return <></>;
  }

  return (
    <ReportFilters
      handleFiltersSubmit={handleFiltersSubmit}
      defaultFilters={filters}
      handleFiltersClose={() => null}
      directDownload
    />
  );
});
