import React, { memo } from 'react';
import { RLeanState } from '@rlean/core';
import { getValue, hasValue } from '@rlean/utils';
import { Skeleton } from 'antd';
import moment from 'moment';
import { strings } from 'config';
import { SQTable } from 'components/shared';
import { SQTableBuilder } from 'lib/models';
import { sqTableElementType, sqTableModuleType } from 'lib/types';

const ProjectSearchResults = () => {
  const [findAProjectSearch] = RLeanState().select(({ state }) => [state.findAProjectSearch]);

  if (getValue(findAProjectSearch, 'isLoading', null)) {
    return <Skeleton active />;
  }

  if (hasValue(findAProjectSearch, 'data')) {
    // todo: add searchable property that takes elementType or dataType
    // todo: replace sqTableElementType with echoElementType
    const tableProps = {
      fieldDefinitions: getValue(findAProjectSearch, 'data.fieldDefinitions'),
      rows: getValue(findAProjectSearch, 'data.rows'),
      modules: [
        {
          elementType: sqTableElementType.PROJECTID,
          moduleType: sqTableModuleType.IMPERSONATE
        }
      ]
    };

    const sqTableBuilder = new SQTableBuilder(tableProps);
    const dataSource = sqTableBuilder.getDataSource();
    const columns = sqTableBuilder.getColumns();

    const titleBarData = {
      tableTitle: strings.projectSearchResults,
      downloadBtn: true,
      settingsBtn: true,
      downloadInfo: {
        reportName: 'Project Search Results',
        fileName: `Project_Search_Results_${moment().format('YYYY-MM-DDTHH:MM:SSZ')}`
      }
    };

    return <SQTable dataSource={dataSource} columns={columns} titleBarData={titleBarData} />;
  }

  return null;
};

export default memo(ProjectSearchResults);
