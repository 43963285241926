import React, { memo, useEffect, useState } from 'react';
import { LineChartOutlined, BarChartOutlined } from '@ant-design/icons';
import moment from 'moment';
import { RLeanState } from '@rlean/core';
import { hasValue } from '@rlean/utils';
import { SQReport } from 'components/shared';
import { useCurrentProject, useFilters } from 'lib/hooks';
import { buildTableColumns } from 'lib/helpers';
import ImageModal from './ImageModal';
import { DrilldownContextProvider } from './CommonDrilldown/DrilldownContext';
import DrilldownChartModal from './CommonDrilldown/DrilldownChartModal';

const echoDetailsBuilder = row => {
  const echoDetails = {};

  row.forEach(field => (echoDetails[field.fieldId] = field.value));

  return echoDetails;
};

// Build summary from endpoint data
/* const echoSummaryBuilder = data => {
  const { fieldDefinitions, rows } = data;
  const echoSummary = {};
  const calculations = fieldDefinitions.filter(definition => definition.supportCalculation);

  if (calculations.length > 0) {
    calculations.forEach(calculation => {
      const columnTotal = rows.reduce((total, row) => {
        const correspondingCell = row.filter(cell => cell.fieldId === calculation.fieldId);
        return correspondingCell.length === 1 ? total + parseFloat(correspondingCell[0].value) : total;
      }, 0);

      echoSummary[calculation.fieldId] = columnTotal;
    });
  }

  return Object.keys(echoSummary).length > 0 ? [echoSummary] : null;
}; */

const filterProperties = ['startDate', 'endDate', 'extraStartDate', 'extraEndDate'];

function EchoInspection() {
  const [activeProject] = RLeanState().select(({ state }) => [state.activeProject]);
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [imageModal, setImageModal] = useState({ visibility: false, formId: null });
  const [chartCreateModals, setChartCreateModal] = useState({
    selectColumnModal: false,
    selectedFieldId: null,
    resultChartModal: false,
    selectedTitle: ''
  });
  const [echoDrilldownDataSource, setEchoDrilldownDataSource] = useState([]);
  const currentProject = useCurrentProject();

  const hasStartDate = hasValue(currentProject, 'startDate', null);
  const initialFilters = hasStartDate
    ? {
        startDate: moment(new Date(currentProject.lastSortDate)).subtract(30, 'days'),
        endDate: moment(new Date(currentProject.lastSortDate))
      }
    : {
        startDate: null,
        endDate: null
      };

  const { convertedFilters: filters, loadingFilters, updateFiltersWithFormData: updateFilters } = useFilters(
    initialFilters,
    filterProperties
  );

  useEffect(() => {
    const dataSourceNoEmptyValues =
      dataSource && dataSource.length > 0 && chartCreateModals.selectedFieldId
        ? dataSource.filter(row => row[chartCreateModals.selectedFieldId])
        : [];

    setEchoDrilldownDataSource(dataSourceNoEmptyValues);
  }, [dataSource, chartCreateModals]);

  const buildChart = data => {
    const builtDataSource = hasValue(data, 'rows') ? data.rows.map(row => echoDetailsBuilder(row)) : null;
    const builtColumns = buildTableColumns(data, setImageModal);

    setColumns(builtColumns);
    setDataSource(builtDataSource);
  };

  const handleCloseImageModal = () => {
    setImageModal(previousState => ({ ...previousState, visibility: false }));
  };

  const createChartModal = () => {
    setChartCreateModal(previousState => ({ ...previousState, resultChartModal: false, selectColumnModal: true }));
  };

  // This component is passed down to the TitleBar to show the Chart icon above the "Details" table
  const extraTableButtons = (
    <button
      className='link-button'
      style={{ fontSize: '24px', margin: '5px' }}
      type='button'
      onClick={createChartModal}
    >
      <BarChartOutlined title='Create Chart' />
    </button>
  );

  const reportOptions = {
    reportTitle: 'Echo Standard Data',
    urls: ['/FormDataGrid'],
    filterProperties: filterProperties.filter(prop => !prop.includes('extra')),
    filters,
    updateFilters,
    buildChart
  };

  const tableOptions = {
    titleBarData: {
      tableTitle: 'Details',
      extraTableButtons: extraTableButtons,
      downloadBtn: true,
      settingsBtn: true,
      downloadInfo: {
        reportName: 'Standard Echo Data Report',
        fileName: `Echo_Standard_Data_${moment().format('YYYY-MM-DDTHH:MM:SSZ')}`
      }
    },
    summary: true,
    dataSource,
    columns
  };

  if (loadingFilters) {
    return <></>;
  }

  return (
    <SQReport reportOptions={reportOptions} tableOptions={tableOptions}>
      <ImageModal formId={imageModal.formId} visibility={imageModal.visibility} handleClose={handleCloseImageModal} />
      <DrilldownContextProvider>
        <DrilldownChartModal
          dataSource={echoDrilldownDataSource}
          columns={columns}
          chartCreateModals={chartCreateModals}
          setChartCreateModal={setChartCreateModal}
          projectId={activeProject.id}
          filters={filters}
          updateFilters={updateFilters}
          options={{
            title: 'Echo Drilldown',
            reportName: 'Standard Echo Data Report',
            filenamePrefix: 'Echo_Standard_Data'
          }}
        />
      </DrilldownContextProvider>
    </SQReport>
  );
}

export default memo(EchoInspection);
