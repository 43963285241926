import React, { memo, useState } from 'react';
import { RLeanState } from '@rlean/core';
import { getValue, hasValue } from '@rlean/utils';
import { SQReport, ReportTable } from 'components/shared';
import { getUniquePartNumbers, getUniqueCriteriaNames, reportNames, formats } from 'components/shared/SQReport';
import moment from 'moment';
import { strings } from 'config';
import { downloadFileType, downloadFile } from 'lib/helpers';
import { useCurrentProject, useFilters } from 'lib/hooks';

const filterProperties = [
  'shift',
  'startDateTime',
  'endDateTime',
  'partIdListCommaSeparated',
  'criteriaIdListCommaSeparated'
];

export const Tabular = memo(props => {
  const [projectParts] = RLeanState().select(({ state }) => [state.projectParts]);
  const [chartData, setChartData] = useState([]);

  const parts = getValue(projectParts, 'data.parts', []);
  const defaultParts = getUniquePartNumbers(parts);
  const currentProject = useCurrentProject();
  const defaultCriteria = getUniqueCriteriaNames(parts);
  const { showAllData } = props;

  let defaultStartDate = new Date();
  let defaultEndDate = new Date();

  if (hasValue(currentProject, 'lastSortDate')) {
    defaultStartDate = showAllData ? new Date(currentProject.startDate) : new Date(currentProject.lastSortDate);
    defaultEndDate = new Date(currentProject.lastSortDate);
    defaultStartDate.setDate(defaultStartDate.getDate() - 30);
    defaultEndDate.setDate(defaultEndDate.getDate());
  }

  const { convertedFilters: filters, loadingFilters, updateFiltersWithFormData: updateFilters } = useFilters(
    {
      startDateTime: moment(defaultStartDate).startOf('day'),
      endDateTime: moment(defaultEndDate).endOf('day'),
      partIdListCommaSeparated: defaultParts,
      criteriaIdListCommaSeparated: defaultCriteria
    },
    filterProperties
  );

  const summaryColumns = [
    {
      title: strings.reportTableColumnDate,
      dataIndex: 'sortDate',
      key: 'sortDate',
      render: date => <>{moment(date).format('MM/DD/YYYY')}</>,
      sorter: (a, b) => new Date(a.sortDate) - new Date(b.sortDate),
      defaultSortOrder: 'descend',
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnShift,
      dataIndex: 'sortShift',
      key: 'sortShift',
      sorter: (a, b) => a.sortShift - b.sortShift,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnPartNumber,
      dataIndex: 'partNumber',
      key: 'partNumber',
      sorter: (a, b) => a.partNumber - b.partNumber,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnPartName,
      dataIndex: 'partName',
      key: 'partName',
      sorter: (a, b) => {
        if (a.partName) {
          return b.partName ? a.partName.localeCompare(b.partName) : -1;
        }
        if (b.partName) {
          return a.partName ? b.partName.localeCompare(a.partName) : 1;
        }
        return 0 - 0;
      },
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnTotalGood,
      dataIndex: 'goodTotal',
      key: 'goodTotal',
      sorter: (a, b) => a.goodTotal - b.goodTotal,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnTotalReworked,
      dataIndex: 'reworkTotal',
      key: 'reworkTotal',
      sorter: (a, b) => a.reworkTotal - b.reworkTotal,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnTotalNonConforming,
      dataIndex: 'nonconformTotal',
      key: 'nonconformTotal',
      sorter: (a, b) => a.nonconformTotal - b.nonconformTotal,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnTotalObservation,
      dataIndex: 'observationTotal',
      key: 'observationTotal',
      sorter: (a, b) => a.observationTotal - b.observationTotal,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnTotalInspected,
      dataIndex: 'inspectedTotal',
      key: 'inspectedTotal',
      sorter: (a, b) => a.inspectedTotal - b.inspectedTotal,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnPPM,
      dataIndex: 'ppm',
      key: 'ppm',
      sorter: (a, b) => a.ppm - b.ppm,
      render: ppm => <>{ppm.toFixed(2)}</>,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnFallout,
      dataIndex: 'fallout',
      key: 'fallout',
      sorter: (a, b) => a.fallout - b.fallout,
      render: fallout => <>{(fallout * 100).toFixed(2)}</>,
      sqVisible: true,
      sqToggle: true
    }
  ];

  const detailsColumns = [
    {
      title: strings.reportTableColumnDate,
      dataIndex: 'sortDate',
      key: 'sortDate',
      render: date => <>{moment(date).format('MM/DD/YYYY')}</>,
      sorter: (a, b) => new Date(a.sortDate) - new Date(b.sortDate),
      defaultSortOrder: 'descend',
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnShift,
      dataIndex: 'sortShift',
      key: 'sortShift',
      sorter: (a, b) => a.sortShift - b.sortShift,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnPartNumber,
      dataIndex: 'partNumber',
      key: 'partNumber',
      sorter: (a, b) => a.partNumber - b.partNumber,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnPartName,
      dataIndex: 'partName',
      key: 'partName',
      sorter: (a, b) => {
        if (a.partName) {
          return b.partName ? a.partName.localeCompare(b.partName) : -1;
        }
        if (b.partName) {
          return a.partName ? b.partName.localeCompare(a.partName) : 1;
        }
        return 0 - 0;
      }
    },
    {
      title: strings.reportTableColumnDefect,
      dataIndex: 'defect',
      key: 'defect',
      sorter: (a, b) => {
        if (a.defect) {
          return b.defect ? a.defect.localeCompare(b.defect) : -1;
        }
        if (b.defect) {
          return a.defect ? b.defect.localeCompare(a.defect) : 1;
        }
        return 0 - 0;
      },
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnType,
      dataIndex: 'defectType',
      key: 'defectType',
      sorter: (a, b) => {
        if (a.defectType) {
          return b.defectType ? a.defectType.localeCompare(b.defectType) : -1;
        }
        if (b.defectType) {
          return a.defectType ? b.defectType.localeCompare(a.defectType) : 1;
        }
        return 0 - 0;
      },
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnCount,
      dataIndex: 'defectCount',
      key: 'defectCount',
      sorter: (a, b) => a.defectCount - b.defectCount,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnPPM,
      dataIndex: 'ppm',
      key: 'ppm',
      sorter: (a, b) => a.ppm - b.ppm,
      render: ppm => <>{ppm.toFixed(2)}</>,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnFallout,
      dataIndex: 'fallout',
      key: 'fallout',
      sorter: (a, b) => a.fallout - b.fallout,
      render: fallout => <>{(fallout * 100).toFixed(2)}</>,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnTotalCount,
      dataIndex: 'totalPartDefectCount',
      key: 'totalPartDefectCount',
      sorter: (a, b) => a.totalPartDefectCount - b.totalPartDefectCount,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnTotalInspected,
      dataIndex: 'totalPartInspectedTotal',
      key: 'totalPartInspectedTotal',
      sorter: (a, b) => a.totalPartInspectedTotal - b.totalPartInspectedTotal,
      sqVisible: true,
      sqToggle: true
    }
  ];

  const buildChart = data => {
    const responseData = {
      summaries: data[0],
      details: data[1]
    };

    let summaryData = [];
    let detailsData = [];

    if (responseData.summaries != null && responseData.summaries !== undefined && responseData.summaries.length > 0) {
      summaryData = responseData.summaries;
    }

    if (responseData.details != null && responseData.details !== undefined && responseData.details.length > 0) {
      detailsData = responseData.details;
    }

    setChartData({ summaryData, detailsData });
  };

  const downloadReport = reportParams => {
    const {
      projectId,
      businessContactId,
      shift,
      startDateTime,
      partIdListCommaSeparated,
      criteriaIdListCommaSeparated
    } = reportParams;
    const endDateTime = reportParams.endDateTime.endOf('day');

    const params = {
      Shift: shift,
      start: startDateTime.toISOString(true),
      end: endDateTime.toISOString(true),
      partList: partIdListCommaSeparated.toString(),
      criteriaList: criteriaIdListCommaSeparated.toString(),
      BusinessContactId: businessContactId,
      ProjectId: projectId,
      format: formats.EXCELOPENXML,
      reportName: reportNames.TABULAR_ACTIVITY
    };

    const options = {
      filename: `Tabular-Activity-${projectId}.xlsx`,
      endpoint: '/SsrsReportFiles',
      fileType: downloadFileType.EXCELOPENXML
    };

    downloadFile(options, params);
  };

  const reportOptions = {
    reportTile: strings.reportTabularTitle,
    urls: ['/ProjectTabularSummaries', '/ProjectTabularDetails'],
    filterProperties,
    filters,
    updateFilters,
    buildChart,
    defaultCriteria,
    defaultParts,
    downloadReport
  };

  const tableOptions = {
    titleBarData: {
      tableTitle: strings.reportTabularTableDetails
    },
    dataSource: chartData.detailsData,
    summary: false,
    columns: detailsColumns
  };

  if (loadingFilters) {
    return <></>;
  }

  return (
    <SQReport reportOptions={reportOptions} tableOptions={tableOptions}>
      {chartData && (
        <>
          <ReportTable
            dataSource={chartData.summaryData}
            columns={summaryColumns}
            title={strings.reportTabularTableSummary}
            style={{ marginBottom: '10px' }}
          />
        </>
      )}
    </SQReport>
  );
});
