import React, { memo } from 'react';
import moment from 'moment';
import { RLeanState } from '@rlean/core';
import { hasValue, getValue } from '@rlean/utils';
import { BaseCard, LOADING_STATUS, BaseList, BaseListItem } from 'components/shared';
import { downloadFile } from 'lib/helpers';
import { strings } from 'config';
import { navigate } from '@reach/router';

const styles = {
  button: {
    fontSize: '0.75rem',
    fontWeight: '700'
  }
};

export const WorkInstructions = memo(() => {
  const [projectWorkInstructions, activeProject] = RLeanState().select(({ state }) => [
    state.projectWorkInstructions,
    state.activeProject
  ]);

  const getLoadingStatus = () => {
    let loadingStatus = LOADING_STATUS.NODATA;

    if (
      hasValue(activeProject, 'id') &&
      (!projectWorkInstructions ||
        (hasValue(projectWorkInstructions, 'isLoading') && projectWorkInstructions.isLoading))
    ) {
      loadingStatus = LOADING_STATUS.WAITING;
    } else if (
      (hasValue(activeProject, 'id') &&
        hasValue(projectWorkInstructions, 'isLoading') &&
        !projectWorkInstructions.isLoading) ||
      hasValue(projectWorkInstructions, 'data')
    ) {
      loadingStatus = LOADING_STATUS.LOADED;
    }

    return loadingStatus;
  };

  const showDrawer = () => {
    navigate('/project/details/report/work-instructions');
  };

  const cardData = {
    title: strings.projectDetailsWorkInstructionsTitle,
    loadingStatus: getLoadingStatus(),
    description: null
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  const listData = getValue(projectWorkInstructions, 'data', []);

  const Button = ({ id }) => {
    const projectId = getValue(activeProject, 'id');

    const handleButtonClick = () => {
      const options = {
        endpoint: `/WorkInstructionRevisions/${id}`,
        filename: `Work-Instruction-${projectId}`
      };

      downloadFile(options);
    };

    return (
      <button type='button' style={styles.button} className='action-button link-small' onClick={handleButtonClick}>
        {strings.projectDetailsWorkInstructionsDownload}
      </button>
    );
  };

  const Title = ({ author }) => {
    return (
      <>
        <strong>{strings.projectDetailsWorkInstructionsAuthor}</strong> {author}
      </>
    );
  };

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      <BaseList
        data={listData}
        launchAction={showDrawer}
        renderItem={item => {
          const { publishedVersionNumber, publishedAt, id, publishedByFullName } = item;
          return (
            <BaseListItem
              actionComponents={[<Button id={id} />]}
              title={<Title author={publishedByFullName} />}
              description={`${strings.projectDetailsWorkInstructionsVersion} ${publishedVersionNumber} | ${
                strings.projectDetailsWorkInstructionsPublished
              } ${moment(publishedAt).format('MM/DD/YYYY')}`}
            />
          );
        }}
      />
    </BaseCard>
  );
});
