import React, { memo } from 'react';
import { Menu, Button } from 'antd';
import { navigate } from '@reach/router';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { RLeanState } from '@rlean/core';
import { getValue, uuid } from '@rlean/utils';
import { strings } from 'config';

const ImpersonateMenuItem = () => {
  const [impersonation, userDescription] = RLeanState().select(({ state }) => [
    state.impersonation,
    state.userDescription
  ]);
  const isMobileUser = getValue(userDescription, 'data.isMobileUser', true);

  const handleImpersonation = () => {
    if (impersonation && impersonation.isImpersonating) {
      // clear entities and navigate to stratosphere quality home
      navigate('/clear-impersonation');
    } else {
      navigate('/impersonate');
    }
  };

  if (isMobileUser) {
    return (
      <Menu.Item key={uuid()}>
        <Button onClick={() => handleImpersonation()} className='link-button'>
          <UsergroupAddOutlined className='link-icon-sidebar' />
          {impersonation && impersonation.isImpersonating ? 'End Impersonation' : strings.impersonate}
        </Button>
      </Menu.Item>
    );
  }

  return <></>;
};

export default memo(ImpersonateMenuItem);
