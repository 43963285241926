import React, { useState } from 'react';
import { Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Sidebar } from '../Sidebar';

export const SidebarMobile = () => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <button className='link-button mobile-only' onClick={() => showDrawer()} type='button'>
        <MenuOutlined />
      </button>
      <Drawer className='main-sider' placement='left' closable={false} onClose={() => onClose()} visible={visible}>
        <Sidebar mobileNavClick={onClose} />
      </Drawer>
    </>
  );
};
