import React, { memo, useState, useEffect } from 'react';
import { hasValue } from '@rlean/utils';
import { SQReport } from 'components/shared';
import { useCurrentProject, useFilters } from 'lib/hooks';
import moment from 'moment';
import { buildTableColumns } from 'lib/helpers';
import ImageModal from './ImageModal';
import { DrilldownContextProvider } from './CommonDrilldown/DrilldownContext';
import DrilldownChartModal from './CommonDrilldown/DrilldownChartModal';
import { BarChartOutlined } from '@ant-design/icons';

const echoDetailsBuilder = row => {
  const echoDetails = {};

  row.forEach(field => (echoDetails[field.fieldId] = field.value));

  return echoDetails;
};

const filterProperties = ['startDate', 'endDate', 'extraStartDate', 'extraEndDate'];

function EchoCustomForm() {
  const currentProject = useCurrentProject();
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [imageModal, setImageModal] = useState({ visibility: false, formId: null });
  const hasLastSortDate = hasValue(currentProject, 'lastSortDate', null);
  const [chartCreateModals, setChartCreateModal] = useState({
    selectColumnModal: false,
    selectedFieldId: null,
    resultChartModal: false,
    selectedTitle: ''
  });
  const [drilldownDataSource, setDrilldownDataSource] = useState([]);

  let defaultStartDate = new Date();
  let defaultEndDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 30);
  defaultEndDate.setDate(defaultEndDate.getDate());

  const initialFilters = hasLastSortDate
    ? {
        startDate: moment(new Date(currentProject.lastSortDate)).subtract(30, 'days'),
        endDate: moment(new Date(currentProject.lastSortDate))
      }
    : {
        startDate: moment(defaultStartDate).startOf('day'),
        endDate: moment(defaultEndDate).endOf('day')
      };

  const { convertedFilters: filters, loadingFilters, updateFiltersWithFormData: updateFilters } = useFilters(
    initialFilters,
    filterProperties
  );

  useEffect(() => {
    const dataSourceNoEmptyValues =
      dataSource && dataSource.length > 0 && chartCreateModals.selectedFieldId
        ? dataSource.filter(row => row[chartCreateModals.selectedFieldId])
        : [];

    setDrilldownDataSource(dataSourceNoEmptyValues);
  }, [dataSource, chartCreateModals]);

  const buildChart = data => {
    const builtDataSource = hasValue(data, 'rows') ? data.rows.map(row => echoDetailsBuilder(row)) : null;
    const builtColumns = buildTableColumns(data, setImageModal);

    setColumns(builtColumns);
    setDataSource(builtDataSource);
  };

  const handleCloseImageModal = () => {
    setImageModal(previousState => ({ ...previousState, visibility: false }));
  };

  const createChartModal = () => {
    setChartCreateModal(previousState => ({ ...previousState, resultChartModal: false, selectColumnModal: true }));
  };

  // This component is passed down to the TitleBar to show the Chart icon above the "Details" table
  const extraTableButtons = (
    <button
      className='link-button'
      style={{ fontSize: '24px', margin: '5px' }}
      type='button'
      onClick={createChartModal}
    >
      <BarChartOutlined title='Create Chart' />
    </button>
  );

  const reportOptions = {
    reportTitle: 'Echo Custom Data',
    urls: ['/FormDataNoPartsGrid'],
    filterProperties: filterProperties.filter(prop => !prop.includes('extra')),
    filters,
    updateFilters,
    buildChart
  };

  const tableOptions = {
    titleBarData: {
      tableTitle: 'Details',
      extraTableButtons: extraTableButtons,
      downloadBtn: true,
      settingsBtn: true,
      downloadInfo: {
        reportName: 'Echo Custom Data',
        fileName: `Echo_Customer_Data_${moment().format('YYYY-MM-DDTHH:MM:SSZ')}`
      }
    },
    summary: false,
    dataSource,
    columns
  };

  if (loadingFilters) {
    return <></>;
  }

  return (
    <SQReport reportOptions={reportOptions} tableOptions={tableOptions}>
      <ImageModal formId={imageModal.formId} visibility={imageModal.visibility} handleClose={handleCloseImageModal} />
      <DrilldownContextProvider>
        <DrilldownChartModal
          dataSource={drilldownDataSource}
          columns={columns}
          chartCreateModals={chartCreateModals}
          setChartCreateModal={setChartCreateModal}
          projectId={currentProject?.projectId}
          filters={filters}
          updateFilters={updateFilters}
          elementTypes='fromData'
          options={{
            title: 'Echo Custom Data',
            reportName: 'Echo Custom Data Report',
            filenamePrefix: 'Echo_Custom_Data'
          }}
        />
      </DrilldownContextProvider>
    </SQReport>
  );
}

export default memo(EchoCustomForm);
