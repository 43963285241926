import { getValue } from '@rlean/utils';
import { TableFilter } from 'lib/models';
import { invoiceMetricType, projectMetricType } from 'lib/types';

export default class BaseFilters {
  constructor(filterProperties = [], props = {}, originals = {}) {
    this.selectedWorkSite = new TableFilter({
      defaultValue: 0,
      emptyValue: 0,
      value: getValue(props, 'selectedWorkSite', 0),
      label: `Work Site `
    });
    this.projectMetric = new TableFilter({
      value: getValue(props, 'metric', projectMetricType.ALL_PROJECTS),
      emptyValue: projectMetricType.ALL_PROJECTS
    });
    this.invoiceMetric = new TableFilter({
      value: getValue(props, 'metric', invoiceMetricType.ALL_INVOICES),
      emptyValue: invoiceMetricType.ALL_INVOICES
    });
    this.startDate = new TableFilter({
      value: getValue(props, 'startDate', ''),
      label: `After`
    });
    this.endDate = new TableFilter({
      value: getValue(props, 'endDate', ''),
      label: `Before`
    });
    this.mostRecentFlag = new TableFilter({
      value: getValue(props, 'mostRecentFlag', true),
      defaultValue: true,
      label: `Most Recent Inspection by VIN`
    });

    this.extraStartDate = new TableFilter({
      value: getValue(props, 'startDate', ''),
      label: `After`
    });
    this.extraEndDate = new TableFilter({
      value: getValue(props, 'endDate', ''),
      label: `Before`
    });

    this.shift = new TableFilter({
      value: getValue(props, 'shift', '0'),
      label: 'Shift'
    });
    this.startDateTime = new TableFilter({
      value: getValue(props, 'startDateTime', ''),
      label: 'After'
    });
    this.endDateTime = new TableFilter({
      value: getValue(props, 'endDateTime', ''),
      label: 'Before'
    });
    this.partIdListCommaSeparated = new TableFilter({
      value: getValue(props, 'partIdListCommaSeparated', []),
      originalLength: getValue(
        originals,
        'partIdListCommaSeparated.originalLength',
        getValue(props, 'partIdListCommaSeparated', []).length
      )
    });
    this.criteriaIdListCommaSeparated = new TableFilter({
      value: getValue(props, 'criteriaIdListCommaSeparated', []),
      originalLength: getValue(
        originals,
        'criteriaIdListCommaSeparated.originalLength',
        getValue(props, 'criteriaIdListCommaSeparated', []).length
      )
    });
    this.iChartPartIdList = new TableFilter({
      value: getValue(props, 'iChartPartIdList', []),
      defaultValue: 'all'
    });

    this.projectId = new TableFilter({
      value: getValue(props, 'projectID', ''),
      label: `Project Number`
    });
    this.customerReferenceNumber = new TableFilter({
      value: getValue(props, 'customerReferenceNumber', ''),
      label: `Customer Reference Number`
    });
    this.partNumber = new TableFilter({
      value: getValue(props, 'partNumber', ''),
      label: `Part Number`
    });
    this.partId = new TableFilter({
      value: getValue(props, 'partId.value', ''),
      altValue: getValue(props, 'partId.altValue', ''),
      useAltValueForLabel: true,
      label: `Part`
    });
    this.hiddenEmptyRowsOnColumn = new TableFilter({
      value: {},
      label: 'Hiding projects with no inspection data.'
    });

    // Remove all properties that are not listed
    if (filterProperties.length > 0) {
      const allFiltersKeys = Object.keys(this);
      for (let i = 0; i < allFiltersKeys.length; i++) {
        if (!filterProperties.includes(allFiltersKeys[i])) {
          delete this[allFiltersKeys[i]];
        }
      }
    }
  }
}
