/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSave, RLeanState, usePost } from '@rlean/core';
import { deepCopy, uuid, hasValue, getValue } from '@rlean/utils';
import { Select, Divider, Switch, Button, message } from 'antd';
import { useUserConfig } from 'lib/hooks';
import { EntityKey } from 'lib/enums/EntityKey';
import { projectMetricType } from 'lib/types';
import { tableColumnDefaultConfig } from './getProjectTableColumns';

const styles = {
  switchContainer: { paddingTop: 3, paddingBottom: 3, display: 'block', width: '100%' },
  switchLabel: { marginLeft: 5, fontSize: 'small' }
};

const { Option } = Select;

export const FiltersDrawer = ({ allColumns, filters, setFilters, toggleHiddenEmptyRowsOnColumn }) => {
  const [{ saveProjectTableColumns }] = useUserConfig();
  const post = usePost();
  const save = useSave();
  const [businessContact, workSite, projectTableColumns, userConfiguration] = RLeanState().select(({ state }) => [
    state.businessContact,
    state.workSite,
    state.projectTableColumns,
    state.userConfiguration
  ]);
  const businessContactId = getValue(businessContact, 'data.id', null);

  const Settings = () => {
    const savedRefreshSetting = getValue(userConfiguration, 'data', []).find(config => config.name === 'shouldRefresh');
    const shouldRefresh = savedRefreshSetting
      ? JSON.parse(savedRefreshSetting.configurationSettings).shouldRefresh
      : false;

    const handleAutoRefreshToggle = e => {
      if (hasValue(userConfiguration, 'data')) {
        const updatedUserConfiguration = deepCopy(userConfiguration);
        const userConfigArray = updatedUserConfiguration.data.filter(config => config.name !== 'shouldRefresh');
        const updatedShouldRefreshConfig = updatedUserConfiguration.data.find(
          config => config.name === 'shouldRefresh'
        ) ?? {
          businessContactId,
          name: 'shouldRefresh',
          inactiveInd: false
        };
        const configurationSettings = updatedShouldRefreshConfig.configurationSettings
          ? JSON.parse(updatedShouldRefreshConfig.configurationSettings)
          : { shouldRefresh: null };

        configurationSettings.shouldRefresh = e;
        updatedShouldRefreshConfig.configurationSettings = JSON.stringify(configurationSettings);

        userConfigArray.push(updatedShouldRefreshConfig);

        updatedUserConfiguration.data = userConfigArray;

        save({ key: EntityKey.UserConfiguration, value: updatedUserConfiguration });

        if (!businessContact.data.isReadOnly) {
          const body = {
            businessContactId,
            name: 'shouldRefresh',
            configurationSettings: updatedShouldRefreshConfig.configurationSettings,
            inactiveInd: false
          };

          message.loading('Saving...', 0);

          post(
            {
              key: EntityKey.UserConfiguration,
              body
            },
            (response, error) => {
              message.destroy();

              if (error) {
                message.error('Saving failed', 3);
              } else {
                message.success('Saving successful', 3);
              }
            }
          );
        }
      }
    };

    return (
      <div style={styles.switchContainer}>
        <Switch defaultChecked={shouldRefresh} onChange={e => handleAutoRefreshToggle(e)} />
        <span style={styles.switchLabel}>Auto Refresh</span>
      </div>
    );
  };

  /* #region  Input change events */

  const onWorkSiteChange = value => {
    const updatedFilters = deepCopy(filters);

    if (value && value !== 'All Sites') {
      const currentWorkSite = workSite.data.find(x => x.companyId === value);
      if (currentWorkSite) {
        const label = `${currentWorkSite.companyName} - ${currentWorkSite.companyCity}`;
        updatedFilters.selectedWorkSite.id = uuid();
        updatedFilters.selectedWorkSite.value = value;
        updatedFilters.selectedWorkSite.label = label;
        updatedFilters.selectedWorkSite.showTag = true;
      }
    } else {
      updatedFilters.selectedWorkSite.value = 0;
      updatedFilters.selectedWorkSite.id = uuid();
      updatedFilters.selectedWorkSite.showTag = false;
    }

    setFilters(updatedFilters);
  };

  const onMetricChange = value => {
    const updatedFilters = deepCopy(filters);

    if (value && value !== projectMetricType.ALL_PROJECTS) {
      updatedFilters.projectMetric.value = value;
      updatedFilters.projectMetric.showTag = updatedFilters.projectMetric.isTag;
    } else {
      updatedFilters.projectMetric.value = projectMetricType.ALL_PROJECTS;
      updatedFilters.projectMetric.showTag = false;
    }

    setFilters(updatedFilters);
  };

  const siteViewOptions = () => {
    const options = [];

    options.push(
      <Option key={uuid()} value={0}>
        All Sites
      </Option>
    );

    if (hasValue(workSite, 'data') && !workSite.isLoading && workSite.data.length > 0) {
      for (let i = 0; i < workSite.data.length; i += 1) {
        options.push(
          <Option
            key={uuid()}
            value={workSite.data[i].companyId}
          >{`${workSite.data[i].companyName} - ${workSite.data[i].companyCity}`}</Option>
        );
      }
    }

    return options;
  };

  const isEmptyRowsHiddenOnColumn = dataIndex => {
    return filters.hiddenEmptyRowsOnColumn.value[dataIndex];
  };

  const columnSwitches = () => {
    // Set columns from user configuration.
    const updatedProjectTableColumns = deepCopy(projectTableColumns);

    let fields = Object.keys(updatedProjectTableColumns);

    if (!businessContact.data.isReadOnly) {
      fields = fields.filter(field => field !== 'workSiteCompanyId' && field !== 'customerCompanyId');
    }

    const onSwitchChange = (value, key) => {
      updatedProjectTableColumns[key].visible = value;

      save({ key: EntityKey.ProjectTableColumns, value: updatedProjectTableColumns });

      if (!businessContact.data.isReadOnly) {
        // User is a business contact so save settings to configuration
        if (hasValue(userConfiguration, 'data')) {
          const updatedUserConfiguration = deepCopy(userConfiguration);
          const userConfigArray = updatedUserConfiguration.data.filter(
            config => config.name !== 'projectTableColumnVisibility'
          );
          const updatedColumnConfig = updatedUserConfiguration.data.find(
            config => config.name === 'projectTableColumnVisibility'
          ) ?? {
            businessContactId,
            name: 'projectTableColumnVisibility',
            inactiveInd: false
          };
          const configurationSettings = updatedColumnConfig.configurationSettings
            ? JSON.parse(updatedColumnConfig.configurationSettings)
            : { columns: projectTableColumns };

          configurationSettings.columns = updatedProjectTableColumns;
          updatedColumnConfig.configurationSettings = JSON.stringify(configurationSettings);

          userConfigArray.push(updatedColumnConfig);

          updatedUserConfiguration.data = userConfigArray;

          save({ key: EntityKey.UserConfiguration, value: updatedUserConfiguration });

          if (!businessContact.data.isReadOnly) {
            const body = {
              businessContactId,
              name: 'projectTableColumnVisibility',
              configurationSettings: updatedColumnConfig.configurationSettings,
              inactiveInd: false
            };

            post({
              key: EntityKey.UserConfiguration,
              body
            });
          }
        }
      }
    };

    const switches = fields.map(key => {
      const field = updatedProjectTableColumns[key];
      const currentColumn = allColumns.find(column => column.dataIndex === key) ?? {};
      currentColumn.title = currentColumn.dataIndex === 'id' ? 'Summary View' : currentColumn.title;

      return (
        <div key={`switches-${key}`} style={styles.switchContainer}>
          <Switch checked={field.visible} disabled={!field.canToggle} onChange={e => onSwitchChange(e, key)} />
          <span style={styles.switchLabel}>{currentColumn.title ?? ''}</span>
        </div>
      );
    });

    return switches;
  };

  const handleDefaultVisibilityClick = () => {
    save({ key: EntityKey.ProjectTableColumns, value: tableColumnDefaultConfig });
    saveProjectTableColumns(tableColumnDefaultConfig);
  };

  return (
    <div className='table-filters-wrapper'>
      <div className='table-filters-subtitle'>
        <h3>Filters</h3>
      </div>
      <div className='table-filters-col'>
        <Select
          style={{ width: '100%' }}
          defaultValue={filters.selectedWorkSite ? filters.selectedWorkSite.value : 0}
          value={filters.selectedWorkSite.value}
          className='table-filter'
          onChange={onWorkSiteChange}
          id={filters.selectedWorkSite.id}
        >
          {siteViewOptions()}
        </Select>
        <Select
          defaultValue={
            filters.projectMetric && filters.projectMetric.value
              ? filters.projectMetric.value
              : projectMetricType.ALL_PROJECTS
          }
          value={filters.projectMetric.value}
          style={{ width: '100%' }}
          className='table-filter'
          onChange={onMetricChange}
        >
          <Option key={uuid()} value={projectMetricType.ALL_PROJECTS}>
            {projectMetricType.ALL_PROJECTS}
          </Option>
          <Option key={uuid()} value={projectMetricType.MY_PROJECTS}>
            {projectMetricType.MY_PROJECTS}
          </Option>
          <Option key={uuid()} value={projectMetricType.SUPPLIER_PROJECTS}>
            {projectMetricType.SUPPLIER_PROJECTS}
          </Option>
          <Option key={uuid()} value={projectMetricType.ACTIVE_PROJECTS}>
            {projectMetricType.ACTIVE_PROJECTS}
          </Option>
          <Option key={uuid()} value={projectMetricType.ZERO_DEFECTS}>
            {projectMetricType.ZERO_DEFECTS}
          </Option>
          <Option key={uuid()} value={projectMetricType.HIGH_NONCONFORMING}>
            {projectMetricType.HIGH_NONCONFORMING}
          </Option>
        </Select>
        <div style={styles.switchContainer}>
          <Switch
            checked={isEmptyRowsHiddenOnColumn('inspected')}
            onChange={() => toggleHiddenEmptyRowsOnColumn('inspected')}
          />
          <span style={styles.switchLabel}>Hide projects with no inspections</span>
        </div>
      </div>
      <Divider />
      <div>
        <h3>Behavior</h3>
      </div>
      <Settings />
      <Divider />
      <div>
        <h3>Column Visibility</h3>
      </div>
      {columnSwitches()}
      <Button
        className='reset-visibility'
        type='primary'
        htmlType='button'
        onClick={() => handleDefaultVisibilityClick()}
      >
        Reset
      </Button>
    </div>
  );
};
