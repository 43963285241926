import { useEffect } from 'react';
import { RLeanState } from '@rlean/core';
import { getValue } from '@rlean/utils';
import { navigate } from '@reach/router';

const useImpersonateRedirect = () => {
  const [impersonation] = RLeanState().select(({ state }) => [state.impersonation]);

  const isImpersonating = getValue(impersonation, 'isImpersonating', false);

  useEffect(() => {
    if (isImpersonating) {
      navigate('/dashboard');
    }
  }, [isImpersonating]);
};

export default useImpersonateRedirect;
