import React, { memo } from 'react';
import { RLeanState } from '@rlean/core';
import moment from 'moment';
import { ReportTable } from 'components/shared';
import { exportToCsv } from 'lib/helpers';
import { Menu, Skeleton } from 'antd';
import { strings } from 'config';
import { getValue, uuid } from '@rlean/utils';

export const TrainingTable = memo(() => {
  const [projectTrainingLog, activeProject] = RLeanState().select(({ state }) => [
    state.projectTrainingLog,
    state.activeProject
  ]);

  const data = getValue(projectTrainingLog, 'data', []);
  const trainingLogIsLoading = getValue(projectTrainingLog, 'isLoading', true);

  const columns = [
    {
      title: strings.projectDetailsTrainingTableRevisionLevel,
      dataIndex: 'revisionLevel',
      key: 'revisionLevel',
      sorter: (a, b) => a.revisionLevel - b.revisionLevel,
      defaultSortOrder: 'descend'
    },
    {
      title: strings.projectDetailsTrainingTableRevisionDate,
      dataIndex: 'revisionDate',
      key: 'revisionDate',
      sorter: (a, b) => new Date(a.revisionDate) - new Date(b.revisionDate)
    },
    {
      title: strings.projectDetailsTrainingTableTrainee,
      dataIndex: 'trainee',
      key: 'trainee',
      sorter: (a, b) => {
        if (a.trainee) {
          return b.trainee ? a.trainee.localeCompare(b.trainee) : -1;
        }
        if (b.trainee) {
          return a.trainee ? b.trainee.localeCompare(a.trainee) : 1;
        }
        return 0 - 0;
      }
    },
    {
      title: strings.projectDetailsTrainingTableCompletedDate,
      dataIndex: 'completedDate',
      key: 'completedDate',
      sorter: (a, b) => new Date(a.completedDate) - new Date(b.completedDate)
    },
    {
      title: strings.projectDetailsTrainingTableTrainer,
      dataIndex: 'trainer',
      key: 'trainer',
      sorter: (a, b) => {
        if (a.trainer) {
          return b.trainer ? a.trainer.localeCompare(b.trainer) : -1;
        }
        if (b.trainer) {
          return a.trainer ? b.trainer.localeCompare(a.trainer) : 1;
        }
        return 0 - 0;
      }
    }
  ];

  const dataSource =
    data && data.length > 0
      ? data.map((item, index) => {
          const modifiedItem = { ...item };
          modifiedItem.key = index;
          modifiedItem.completedDate = moment(item.completedDate).format('MM/DD/YYYY');
          modifiedItem.revisionDate = moment(item.revisionDate).format('MM/DD/YYYY');
          return modifiedItem;
        })
      : null;

  const handleExport = () => {
    exportToCsv(`training-logs-${activeProject.id}`, columns, dataSource);
  };

  const menu = (
    <Menu>
      <Menu.Item key={uuid()} onClick={handleExport} disabled={!dataSource}>
        {strings.projectDetailsDocumentExport}
      </Menu.Item>
    </Menu>
  );

  if (trainingLogIsLoading) {
    return <Skeleton />;
  }

  return (
    <ReportTable
      title={strings.projectDetailsDocumentTrainingTableTitle}
      menu={menu}
      dataSource={dataSource}
      columns={columns}
      isDrawer={true}
    />
  );
});
