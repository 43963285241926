import { define } from '@rlean/core';
import { UserDescription as UserConfigurationType } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

const UserDescription = define<UserConfigurationType>(EntityKey.UserDescription, {
  initialState: {},
  getURL: '/UserDescriptions/:oid'
});

export default UserDescription;
