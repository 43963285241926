import { define } from '@rlean/core';
import { SupportContactUs as SupportContactUsType } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

const SupportContactUs = define<SupportContactUsType[] | object>(EntityKey.SupportContactUs, {
  initialState: {},
  getURL: '/ContactUsRequestTypes',
  postURL: '/ContactUs',
  persistData: false
});

export default SupportContactUs;
