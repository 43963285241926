import React, { useState } from 'react';
import { RLeanState } from '@rlean/core';
import { getValue, hasValue } from '@rlean/utils';
import { SQReport } from 'components/shared';
import { getUniquePartNumbers, getUniqueCriteriaNames, formats, reportNames } from 'components/shared/SQReport';
import moment from 'moment';
import Highcharts from 'highcharts';
import { chartColors } from 'config/chartColors';
import { strings } from 'config';
import { downloadFileType, downloadFile } from 'lib/helpers';
import { useCurrentProject } from 'lib/hooks';
import { useFilters } from 'lib/hooks';

const filterProperties = [
  'shift',
  'startDateTime',
  'endDateTime',
  'partIdListCommaSeparated',
  'criteriaIdListCommaSeparated'
];

export const ActivitySummary = () => {
  const [projectParts] = RLeanState().select(({ state }) => [state.projectParts]);
  const [chartData, setChartData] = useState([]);

  const parts = getValue(projectParts, 'data.parts', []);
  const defaultParts = getUniquePartNumbers(parts);
  const defaultCriteria = getUniqueCriteriaNames(parts);
  const currentProject = useCurrentProject();

  let defaultStartDate = new Date();
  let defaultEndDate = new Date();

  if (hasValue(currentProject, 'lastSortDate')) {
    defaultStartDate = new Date(currentProject.lastSortDate);
    defaultEndDate = new Date(currentProject.lastSortDate);
    defaultStartDate.setDate(defaultStartDate.getDate() - 30);
    defaultEndDate.setDate(defaultEndDate.getDate());
  }

  const { convertedFilters: filters, loadingFilters, updateFiltersWithFormData: updateFilters } = useFilters(
    {
      startDateTime: moment(defaultStartDate).startOf('day'),
      endDateTime: moment(defaultEndDate).endOf('day'),
      partIdListCommaSeparated: defaultParts,
      criteriaIdListCommaSeparated: defaultCriteria
    },
    filterProperties
  );

  const columns = [
    {
      title: strings.reportTableColumnSortDate,
      dataIndex: 'sortDate',
      key: 'sortDate',
      sorter: (a, b) => new Date(a.sortDate) - new Date(b.sortDate),
      render: date => <>{moment(date).format('MM/DD/YYYY')}</>,
      defaultSortOrder: 'descend',
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnShift,
      dataIndex: 'sortShift',
      key: 'sortShift',
      sorter: (a, b) => a.sortShift - b.sortShift,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnGood,
      dataIndex: 'goodTotal',
      key: 'goodTotal',
      sorter: (a, b) => a.goodTotal - b.goodTotal,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnRework,
      dataIndex: 'reworkTotal',
      key: 'reworkTotal',
      sorter: (a, b) => a.reworkTotal - b.reworkTotal,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnNonconforming,
      dataIndex: 'nonconformTotal',
      key: 'nonconformTotal',
      sorter: (a, b) => a.nonconformTotal - b.nonconformTotal,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnObservation,
      dataIndex: 'observationTotal',
      key: 'observationTotal',
      sorter: (a, b) => a.observationTotal - b.observationTotal,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnInspected,
      dataIndex: 'inspectedTotal',
      key: 'inspectedTotal',
      sorter: (a, b) => a.inspectedTotal - b.inspectedTotal,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnPPM,
      dataIndex: 'ppm',
      key: 'ppm',
      sorter: (a, b) => a.ppm - b.ppm,
      render: ppm => <>{ppm.toFixed(2)}</>,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnFallout,
      dataIndex: 'fallout',
      key: 'fallout',
      sorter: (a, b) => a.fallout - b.fallout,
      render: fallout => <>{(fallout * 100).toFixed(2)}</>,
      sqVisible: true,
      sqToggle: true
    }
  ];

  const configuration = {
    title: { text: '' },
    credits: { enabled: false },
    lang: { noData: 'No data to display' },
    noData: { position: { x: 0, y: 0, align: 'center', verticalAlign: 'middle' } },
    subtitle: { text: '' },
    xAxis: { categories: chartData.categories },
    tooltip: { valueDecimals: 2 },
    yAxis: [
      {
        // Primary yAxis
        labels: { format: '{value}', style: { color: Highcharts.getOptions().colors[1] } },
        title: { text: '', style: { color: Highcharts.getOptions().colors[1] } },
        opposite: true
      },
      {
        // Secondary yAxis
        gridLineWidth: 0,
        title: { text: '', style: { color: Highcharts.getOptions().colors[1] } },
        labels: { format: '{value}', style: { color: Highcharts.getOptions().colors[1] } }
      }
    ],
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: { enabled: false, color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white' }
      }
    },
    series: [
      {
        type: 'column',
        name: strings.reportChartActivitySummaryNonconformingTotal,
        yAxis: 0,
        data: chartData.nonConformingData,
        color: chartColors.RED
      },
      {
        type: 'column',
        name: strings.reportChartActivitySummaryObservationTotal,
        yAxis: 0,
        data: chartData.observationData,
        color: chartColors.YELLOW
      },
      {
        type: 'column',
        name: strings.reportChartActivitySummaryReworkTotal,
        yAxis: 0,
        data: chartData.reworkData,
        color: chartColors.BLUE
      },
      {
        type: 'column',
        name: strings.reportChartActivitySummaryGoodTotal,
        yAxis: 0,
        data: chartData.goodData,
        color: chartColors.GREEN
      },
      {
        type: 'spline',
        name: strings.reportChartActivitySummaryPPM,
        yAxis: 1,
        data: chartData.ppmData,
        color: chartColors.RED,
        marker: { lineWidth: 2, lineColor: chartColors.RED, fillColor: 'white' }
      }
    ]
  };

  const buildChart = responseData => {
    if (responseData != null) {
      const reportData = [];
      const dates = [];
      const goodData = [];
      const reworkData = [];
      const nonConformingData = [];
      const observationData = [];
      const ppmData = [];

      const uniqueDates = responseData
        .map(item => item.sortDate)
        .filter((value, index, self) => self.indexOf(value) === index);

      for (let i = 0; i < uniqueDates.length; i += 1) {
        const dataByDate = responseData.filter(x => x.sortDate === uniqueDates[i]);
        let good = 0;
        let rework = 0;
        let nonConforming = 0;
        let observation = 0;
        let ppm = 0;

        for (let n = 0; n < dataByDate.length; n += 1) {
          good += +dataByDate[n].goodTotal;
          rework += +dataByDate[n].reworkTotal;
          nonConforming += +dataByDate[n].nonconformTotal;
          observation += +dataByDate[n].observationTotal;
        }

        const totalInspected = good + rework + nonConforming + observation;

        ppm = ((nonConforming + rework) / totalInspected) * 1000000;

        dates.push(`${moment(uniqueDates[i]).format('MM/DD/YYYY')}`);
        goodData.push(good);
        reworkData.push(rework);
        nonConformingData.push(nonConforming);
        observationData.push(observation);
        ppmData.push(ppm);
      }

      for (let i = 0; i < responseData.length; i += 1) {
        const data = responseData[i];
        data['i'] = i;

        reportData.push(data);
      }

      setChartData({
        reportData,
        categories: dates,
        goodData,
        reworkData,
        nonConformingData,
        observationData,
        ppmData
      });
    }
  };

  const downloadReport = reportParams => {
    const {
      projectId,
      businessContactId,
      shift,
      startDateTime,
      partIdListCommaSeparated,
      criteriaIdListCommaSeparated
    } = reportParams;

    const endDateTime = reportParams.endDateTime.endOf('day');

    const params = {
      Shift: shift,
      start: startDateTime.toISOString(true),
      end: endDateTime.toISOString(true),
      partList: partIdListCommaSeparated.toString(),
      criteriaList: criteriaIdListCommaSeparated.toString(),
      BusinessContactId: businessContactId,
      ProjectId: projectId,
      format: formats.EXCELOPENXML,
      reportName: reportNames.ACTIVITY_SUMMARY
    };

    const options = {
      filename: `Activity-Summary-${projectId}.xlsx`,
      endpoint: '/SsrsReportFiles',
      fileType: downloadFileType.EXCELOPENXML
    };

    downloadFile(options, params);
  };

  const reportOptions = {
    reportTitle: strings.reportActivitySummaryTitle,
    urls: ['/ProjectSummaries'],
    filterProperties,
    filters,
    updateFilters,
    defaultParts,
    defaultCriteria,
    buildChart,
    downloadReport
  };

  const chartOptions = {
    chartReady: !!chartData.reportData,
    configuration
  };

  const tableOptions = {
    titleBarData: {
      tableTitle: strings.reportActivitySummaryTableTitle
    },
    dataSource: chartData.reportData,
    columns
  };

  if (loadingFilters) {
    return <></>;
  }

  return <SQReport reportOptions={reportOptions} chartOptions={chartOptions} tableOptions={tableOptions} />;
};
