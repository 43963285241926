import * as Sentry from '@sentry/browser';

class Logger {
  constructor() {
    this.logLevel = {
      TRACE: 0,
      DEBUG: 1,
      INFO: 2,
      WARNING: 3,
      ERROR: 4,
      CRITICAL: 5
    };
  }

  get level() {
    return this.logLevel;
  }

  log(message) {
    const messageObject = message;

    // if messageObject is not given and we are not in production
    if ((!messageObject || messageObject === undefined) && process.env.REACT_APP_ENVIRONMENT !== 'prod') {
      throw new Error('logger called but no message object given');
    }

    // automatically add timestamp to object payload
    messageObject.timestamp = new Date();

    switch (messageObject.level) {
      case this.logLevel.TRACE:
        // do something with the trace log
        if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
          console.info(messageObject);
        }
        break;
      case this.logLevel.DEBUG:
        // do something with the debug log
        if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
          Sentry.captureException(new Error(JSON.stringify(messageObject)));
        }

        if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
          console.warn(messageObject);
        }
        break;
      case this.logLevel.INFO:
      case this.logLevel.WARNING:
      case this.logLevel.ERROR:
      case this.logLevel.CRITICAL:
        if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
          Sentry.captureException(new Error(JSON.stringify(messageObject)));
        }

        // do something with the error log
        if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
          console.error(messageObject);
        }
        break;
      default:
        // unknown log level
        break;
    }
  }
}

export default Logger;
