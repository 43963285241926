import React, { memo, useState } from 'react';
import { Card, Button } from 'antd';
import MaterialIcon from '@material/react-material-icon';
import { formatCurrency, formatDate } from 'lib/helpers';

export const InvoiceMobileCards = memo(props => {
  const [visibleCards, setVisibleCards] = useState(5);

  const mobileInvoices = [];

  if (!props.invoices || !Array.isArray(props.invoices)) return;

  const invoiceDetails = invoice => {
    const formatField = (field, value) => {
      let returnValue = value;

      if (field === 'invoiceDate') {
        returnValue = formatDate(value);
      } else if (field === 'invoiceAmount' || field === 'balance') {
        returnValue = formatCurrency(value);
      }

      return returnValue;
    };

    const details = [];

    for (let i = 0; i < props.columns.length; i += 1) {
      if (props.columns[i].title) {
        details.push(
          <div>
            <b>{props.columns[i].title}:</b>{' '}
            {formatField(props.columns[i].dataIndex, invoice[props.columns[i].dataIndex])}
          </div>
        );
      }
    }

    details.push(
      <div style={{ whiteSpace: 'nowrap' }}>
        <button className='link-button' onClick={() => props.downloadInvoice(invoice.id)} type='button'>
          <MaterialIcon role='button' icon='attach_money' />
        </button>
        <button className='link-button' onClick={() => props.downloadLaborDetail(invoice.id)} type='button'>
          <MaterialIcon role='button' icon='people' />
        </button>
      </div>
    );

    return details;
  };

  const loadMore = () => {
    setVisibleCards(visibleCards + 5);
  };

  const loadMoreButton = (
    <Button className='load-more' onClick={() => loadMore()}>
      Load More Invoices
    </Button>
  );

  const limit = props.invoices.length > visibleCards ? visibleCards : props.invoices.length;
  for (let i = 0; i < limit; i += 1) {
    mobileInvoices.push(
      <Card
        style={{ marginTop: 25 }}
        className='mobile-table-card'
        title={`Invoice #${props.invoices[i].invoiceNumber}`}
      >
        {invoiceDetails(props.invoices[i])}
      </Card>
    );
  }

  return (
    <>
      {mobileInvoices}
      {visibleCards >= props.invoices.length ? <></> : loadMoreButton}
    </>
  );
});
