import React, { memo, useState, useEffect, useContext } from 'react';
import { Button, Form, Radio, DatePicker, Checkbox } from 'antd';
import { strings } from 'config';
import { Switch } from 'antd';
import { deepCopy } from '@rlean/utils';
import { FilterContext } from '../FiltersContext';

function ReportFilters({
  defaultFilters = {},
  handleFiltersSubmit,
  handleFiltersClose,
  parts,
  defaultParts,
  defaultCriteria,
  directDownload
}) {
  const {
    shift = null,
    iChartPartIdList = [],
    partIdListCommaSeparated = [],
    criteriaIdListCommaSeparated = [],
    partId = null,
    startDateTime = null,
    endDateTime = null,
    startDate = null,
    endDate = null,
    mostRecentFlag = null
  } = defaultFilters;
  const [form] = Form.useForm();
  const [indeterminateParts, setIndeterminateParts] = useState();
  const [checkAllParts, setCheckAllParts] = useState();
  const [indeterminateCriteria, setIndeterminateCriteria] = useState();
  const [checkAllCriteria, setCheckAllCriteria] = useState();

  const formInitialValues = {};
  let ShiftFormItem;
  let PartsFormItem;
  let CriteriaFormItem;
  let DateTimeFormItem;
  let DateFormItem;
  let MostRecentOnly;
  let PartIdFormItem;

  const filterContext = useContext(FilterContext);

  if (shift) {
    formInitialValues.shift = shift;

    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px'
    };

    ShiftFormItem = () => (
      <Form.Item label={strings.reportFiltersShiftLabel} name='shift'>
        <Radio.Group>
          <Radio value='0' style={radioStyle}>
            All Shifts
          </Radio>
          <Radio value='1' style={radioStyle}>
            First Shift
          </Radio>
          <Radio value='2' style={radioStyle}>
            Second Shift
          </Radio>
          <Radio value='3' style={radioStyle}>
            Third Shift
          </Radio>
        </Radio.Group>
      </Form.Item>
    );
  }

  if (iChartPartIdList && iChartPartIdList.length > 0) {
    formInitialValues.iChartPartIdList = 'all';

    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px'
    };

    ShiftFormItem = () => (
      <Form.Item label={strings.reportFiltersPartsLabel} name='iChartPartIdList'>
        <Radio.Group>
          <Radio value={'all'} style={radioStyle}>
            All Parts
          </Radio>

          {defaultParts.map(part => (
            <Radio value={part} style={radioStyle} key={part}>
              {part}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  }

  if (partId) {
    formInitialValues.partId = partId;

    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px'
    };

    PartIdFormItem = () => (
      <Form.Item label={strings.reportFiltersPartsLabel} name='partId'>
        <Radio.Group>
          {(parts || []).map(part => (
            <Radio value={part.id} style={radioStyle} key={part.id}>
              {part.number} - {part.description}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  }

  useEffect(() => {
    if (partIdListCommaSeparated && partIdListCommaSeparated.length > 0) {
      formInitialValues.partIdListCommaSeparated = partIdListCommaSeparated;

      const checkboxOptionsParts = defaultParts ?? [];
      const initialIndeterminateParts =
        !!partIdListCommaSeparated.length && partIdListCommaSeparated.length < checkboxOptionsParts.length;
      const initialCheckAllParts = partIdListCommaSeparated.length === checkboxOptionsParts.length;

      setIndeterminateParts(initialIndeterminateParts);
      setCheckAllParts(initialCheckAllParts);
    }

    if (criteriaIdListCommaSeparated && criteriaIdListCommaSeparated.length > 0) {
      formInitialValues.criteriaIdListCommaSeparated = criteriaIdListCommaSeparated;

      const checkboxOptionsCriteria = defaultCriteria ?? [];
      const initialIndeterminateCriteria =
        !!criteriaIdListCommaSeparated.length && criteriaIdListCommaSeparated.length < checkboxOptionsCriteria.length;
      const initialCheckAllCriteria = criteriaIdListCommaSeparated.length === checkboxOptionsCriteria.length;

      setIndeterminateCriteria(initialIndeterminateCriteria);
      setCheckAllCriteria(initialCheckAllCriteria);
    }
  }, [criteriaIdListCommaSeparated, partIdListCommaSeparated]);

  if (partIdListCommaSeparated && partIdListCommaSeparated.length > 0) {
    formInitialValues.partIdListCommaSeparated = partIdListCommaSeparated;

    const checkboxOptionsParts = defaultParts ?? [];

    const handleCheckParts = checkEvent => {
      setIndeterminateParts(!!checkEvent.length && checkEvent.length < checkboxOptionsParts.length);
      setCheckAllParts(checkEvent.length === checkboxOptionsParts.length);
    };

    const handleCheckAllParts = e => {
      form.setFieldsValue({
        partIdListCommaSeparated: e.target.checked ? checkboxOptionsParts : []
      });
      setIndeterminateParts(false);
      setCheckAllParts(e.target.checked);
    };

    const CheckAllLabel = () => (
      <Checkbox indeterminate={indeterminateParts} onChange={handleCheckAllParts} checked={checkAllParts}>
        {strings.reportFiltersPartsLabel}
      </Checkbox>
    );

    PartsFormItem = () => (
      <>
        <Form.Item
          label={<CheckAllLabel />}
          name='partIdListCommaSeparated'
          rules={[{ required: true, message: 'Please select at least one part' }]}
        >
          <Checkbox.Group options={checkboxOptionsParts} onChange={handleCheckParts} />
        </Form.Item>
      </>
    );
  }

  if (criteriaIdListCommaSeparated && criteriaIdListCommaSeparated.length > 0) {
    formInitialValues.criteriaIdListCommaSeparated = criteriaIdListCommaSeparated;

    const checkboxOptionsCriteria = defaultCriteria ?? [];

    const handleCheckCriteria = checkEvent => {
      setIndeterminateCriteria(!!checkEvent.length && checkEvent.length < checkboxOptionsCriteria.length);
      setCheckAllCriteria(checkEvent.length === checkboxOptionsCriteria.length);
    };

    const handleCheckAllCriteria = e => {
      form.setFieldsValue({
        criteriaIdListCommaSeparated: e.target.checked ? checkboxOptionsCriteria : []
      });
      setIndeterminateCriteria(false);
      setCheckAllCriteria(e.target.checked);
    };

    const CheckAllLabel = () => (
      <Checkbox indeterminate={indeterminateCriteria} onChange={handleCheckAllCriteria} checked={checkAllCriteria}>
        {strings.reportFiltersCriteriaLabel}
      </Checkbox>
    );

    CriteriaFormItem = () => (
      <>
        <Form.Item
          label={<CheckAllLabel />}
          name='criteriaIdListCommaSeparated'
          rules={[{ required: true, message: 'Please select at least one criteria' }]}
        >
          <Checkbox.Group options={checkboxOptionsCriteria} onChange={handleCheckCriteria} />
        </Form.Item>
      </>
    );
  }

  if (startDateTime && endDateTime) {
    formInitialValues.startDateTime = startDateTime;
    formInitialValues.endDateTime = endDateTime;

    DateTimeFormItem = () => (
      <>
        <Form.Item
          label={strings.reportFiltersStartDateLabel}
          name='startDateTime'
          rules={[{ required: true, message: 'Please select a start date' }]}
        >
          <DatePicker format={'MM/DD/YYYY'} />
        </Form.Item>
        <Form.Item
          label={strings.reportFiltersEndDateLabel}
          name='endDateTime'
          rules={[{ required: true, message: 'Please select an end date' }]}
        >
          <DatePicker format={'MM/DD/YYYY'} />
        </Form.Item>
      </>
    );
  }

  if (startDate && endDate) {
    formInitialValues.startDate = startDate;
    formInitialValues.endDate = endDate;

    DateFormItem = () => (
      <>
        <Form.Item
          label={strings.reportFiltersStartDateLabel}
          name='startDate'
          rules={[{ required: true, message: 'Please select a start date' }]}
        >
          <DatePicker format={'MM/DD/YYYY'} />
        </Form.Item>
        <Form.Item
          label={strings.reportFiltersEndDateLabel}
          name='endDate'
          rules={[{ required: true, message: 'Please select an end date' }]}
        >
          <DatePicker format={'MM/DD/YYYY'} />
        </Form.Item>
      </>
    );
  }

  if (mostRecentFlag !== null) {
    formInitialValues.mostRecentFlag = mostRecentFlag;

    MostRecentOnly = () => (
      <Form.Item label={strings.reportFiltersMostRecent} name='mostRecentFlag' valuePropName='checked'>
        <Switch />
      </Form.Item>
    );
  }

  const handleFinish = values => {
    handleFiltersSubmit(values);
    handleFieldsChange(values);
    handleFiltersClose();
  };

  // Update raw BaseFilters on submit
  const handleFieldsChange = changedFields => {
    const updatedFilters = deepCopy(filterContext.filters);
    let filtersHasBeenUpdated = false;

    // Check if partId has changed
    if (Object.keys(changedFields).includes('partId')) {
      const partIdSelectedValue = changedFields.partId;
      const partNumber = parts.find(part => part.id === partIdSelectedValue)?.number;

      if (partNumber) {
        updatedFilters.partId.altValue = partNumber;
        filtersHasBeenUpdated = true;
      }
    }

    // only update if there's a change to avoid rerendering
    if (filtersHasBeenUpdated) filterContext.setFilters(updatedFilters);
  };

  const hasFilters =
    ShiftFormItem || PartsFormItem || CriteriaFormItem || DateTimeFormItem || DateFormItem || MostRecentOnly;

  return (
    <Form
      layout='vertical'
      onFinish={handleFinish}
      className='report-filters'
      initialValues={formInitialValues}
      form={form}
      name='filters'
    >
      {ShiftFormItem && <ShiftFormItem />}
      {PartsFormItem && <PartsFormItem />}
      {CriteriaFormItem && <CriteriaFormItem />}
      {DateTimeFormItem && <DateTimeFormItem />}
      {DateFormItem && <DateFormItem />}
      {MostRecentOnly && <MostRecentOnly />}
      {PartIdFormItem && <PartIdFormItem />}
      <Form.Item style={{ marginTop: '10px' }}>
        <Button type='primary' htmlType='submit'>
          {!hasFilters
            ? strings.reportDownloadButton
            : directDownload
            ? strings.reportFiltersApplyAndDownloadButton
            : strings.reportFiltersApplyButton}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default memo(ReportFilters);
