import React, { memo, useState, useEffect, useRef } from 'react';
import { Input, Row, Col } from 'antd';
import { deepCopy, uuid } from '@rlean/utils';

export const AutoComplete = memo(props => {
  const { disabled, inputLabel, data, handleSelect, alwaysShowDropDown } = props;

  const isMounted = useRef(true);

  const [filteredList, setfilteredList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dropdownIsVisible, setDropdownIsVisible] = useState(false);

  const getList = term => {
    if (!String.prototype.includes) {
      // eslint-disable-next-line no-extend-native
      String.prototype.includes = function() {
        // eslint-disable-next-line strict
        'use strict';
        return String.prototype.indexOf.apply(this, arguments) !== -1;
      };
    }

    let filtered = [];
    const minChar = props.minChar ? props.minChar - 1 : 0;

    if (!term && data) {
      // show all
      filtered = deepCopy(data);
    } else if (data && data.length > minChar && term) {
      const list = data.filter(x => String(x.text.toLowerCase()).includes(term.toLowerCase()));
      filtered = deepCopy(list);
    }

    if (isMounted.current) {
      setfilteredList(filtered);
    }
  };

  useEffect(() => {
    if (alwaysShowDropDown && isMounted.current) {
      setDropdownIsVisible(true);
    }

    return () => {
      isMounted.current = false;
    };
  }, [alwaysShowDropDown]);

  useEffect(() => {
    if (alwaysShowDropDown && isMounted.current) {
      getList();
    }

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (disabled) {
    return <></>;
  }

  const handleFilter = e => {
    const term = e.target.value;

    // if (!searchTermBase && term.length === 3 && term !== searchTermBase) {
    //   setSearchTermBase(term);
    // }

    if (alwaysShowDropDown || (term !== null && term.length > 0)) {
      getList(term);

      if (filteredList.length > props.minChar && isMounted.current) {
        setDropdownIsVisible(true);
      }
    } else if (term.length === 0 && isMounted.current) {
      setfilteredList([]);

      if (!alwaysShowDropDown) {
        setDropdownIsVisible(false);
      }
    }
  };

  const focus = () => {
    if (filteredList.length > 0 && isMounted.current) {
      setDropdownIsVisible(true);
    }
  };

  const selectListItem = e => {
    if (isMounted.current) {
      setSelectedItem(e.target.innerText);
      setfilteredList([]);

      if (!alwaysShowDropDown) {
        setDropdownIsVisible(false);
      }
    }

    if (props.handleSelect) {
      handleSelect(e);
    }
  };

  const list = () => {
    const limit = props.limit ? props.limit : 100;
    const listWithLimit = filteredList.slice(0, limit);

    const compare = (a, b) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    };

    listWithLimit.sort(compare);

    return listWithLimit.map(listItem => {
      return (
        <div
          className='ant-select-item ant-select-item-option'
          aria-selected='false'
          onClick={e => selectListItem(e)}
          key={uuid()}
          id={listItem.value}
        >
          {listItem.text}
        </div>
      );
    });
  };

  const DropDown = () => {
    if (dropdownIsVisible) {
      return (
        <div className='autocomplete-container'>
          <div style={{ maxHeight: 256, overflowY: 'auto', overflowAnchor: 'none' }}>
            <div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>{list()}</div>
            </div>
          </div>
        </div>
      );
    }

    return <></>;
  };

  const remove = () => {
    if (isMounted.current) {
      setSelectedItem(null);
    }
  };

  const input = () => {
    if (selectedItem) {
      return (
        <p>
          {selectedItem}{' '}
          <a href='#!' onClick={() => remove()} style={{ marginLeft: 20 }}>
            X
          </a>
        </p>
      );
    }

    return (
      <>
        <Input placeholder={inputLabel} onChange={e => handleFilter(e)} onFocus={() => focus()} />
        <DropDown />
      </>
    );
  };

  return (
    <Row>
      <Col span={24}>{input()}</Col>
    </Row>
  );
});
