import React, { memo, useState, useEffect } from 'react';
import { Input, Button, Col, Row } from 'antd';

/**
 * TableSearchOptions
 * Memoized functional component that returns the filters for any kind of
 * search.
 */
export default memo(function TableSearchOptions(props) {
  const { searchOptions, callback, needsInput, buttonStr = 'Search' } = props;

  const [searchInputs, setSearchInput] = useState({});
  const [showValidation, setShowValidation] = useState(false);

  // Event handlers
  const handleSearchChange = key => event => {
    const { value } = event.target;
    setSearchInput(pV => ({ ...pV, [key]: value }));
  };

  const search = () => {
    // Show validation if at least one input is empty and the search is needed
    if (needsInput && !Object.values(searchInputs).some(val => val.length > 0)) {
      setShowValidation(true);
      return;
    }
    setShowValidation(false);
    callback(searchInputs);
  };

  // Effects
  useEffect(() => {
    const inputs = searchOptions.reduce((pv, so) => ({ ...pv, [so.key]: so.initValue || '' }), {});
    setSearchInput(inputs);
    // Use the props to build the searchInputs
  }, [searchOptions]);

  const errorMessage = () => {
    if (showValidation) {
      return (
        <Row gutter={16} style={{ paddingLeft: 15, paddingBottom: 15 }}>
          <Col span={12}>
            <span className='red-text'>* A search option is required.</span>
          </Col>
        </Row>
      );
    }

    return <></>;
  };

  return (
    <>
      <Row gutter={[16, 8]} style={{ padding: 15 }}>
        {searchOptions.map(({ placeholder, key }) => (
          <Col xs={24} sm={6} key={`input-search-${key}`}>
            <Input
              placeholder={placeholder}
              key={`search-input-${key}`}
              value={searchInputs[key]}
              onChange={handleSearchChange(key)}
              onPressEnter={search}
            />
          </Col>
        ))}
        <Col xs={24} sm={6}>
          <Button className='ant-btn-primary' onClick={search}>
            {buttonStr}
          </Button>
        </Col>
      </Row>
      {errorMessage()}
    </>
  );
});
