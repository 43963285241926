import { uuid } from '@rlean/utils';

export class HeatmapAreas {
  static determineSelectionAreas = capturedImage => {
    return capturedImage.hasGrid
      ? this.calculateGridAreas(capturedImage)
      : this.calculateUserDefinedAreas(capturedImage);
  };

  static calculateGridAreas(capturedImage) {
    if (!capturedImage.gridYLength || !capturedImage.gridXLength) {
      return null;
    }

    const selectionAreas = [];
    const areaHeight = capturedImage.actualHeight / capturedImage.gridYLength;
    const areaWidth = capturedImage.actualWidth / capturedImage.gridXLength;
    let areaNumber = 0;
    const areaLabels = capturedImage.userDefinedAreas.map(userDefinedArea => {
      return userDefinedArea.label;
    });

    // for each row
    for (let y = 1; y <= capturedImage.gridYLength; y += 1) {
      // calculate each area
      for (let x = 1; x <= capturedImage.gridXLength; x += 1) {
        const startingX = areaWidth * x - areaWidth;
        const startingY = areaHeight * y - areaHeight;

        const selectionArea = {
          id: uuid(),
          x: startingX,
          y: startingY,
          height: areaHeight,
          width: areaWidth,
          label: areaLabels[areaNumber]
        };

        selectionAreas.push(selectionArea);
        areaNumber += 1;
      }
    }

    return selectionAreas;
  }

  static calculateCoordinates(coordinates, currentImageDimensions) {
    const newCoordinates = coordinates.map(coordinatePair => {
      const originalWidth = coordinatePair.imageWidth;
      const percentage = currentImageDimensions.width / originalWidth;

      const topLeftCoordinates = {
        xCoordinate: coordinatePair.topLeftCoordinates.xCoordinate * percentage,
        yCoordinate: coordinatePair.topLeftCoordinates.yCoordinate * percentage
      };

      const bottomRightCoordinates = {
        xCoordinate: coordinatePair.bottomRightCoordinates.xCoordinate * percentage,
        yCoordinate: coordinatePair.bottomRightCoordinates.yCoordinate * percentage
      };

      return {
        id: coordinatePair.id,
        label: coordinatePair.label,
        topLeftCoordinates,
        bottomRightCoordinates,
        imageHeight: currentImageDimensions.height,
        imageWidth: currentImageDimensions.width
      };
    });

    return newCoordinates;
  }

  static calculateUserDefinedAreas(capturedImage) {
    /**
     * If the captured height and width is the full size of the image,
     * we already have the correct size and coordinates for the areas.
     */
    if (capturedImage.height === capturedImage.actualHeight && capturedImage.width === capturedImage.actualWidth) {
      return capturedImage.userDefinedAreas;
    }

    // Scale the grid up to actual height and actual width.
    return capturedImage.userDefinedAreas
      ? capturedImage.userDefinedAreas.map(userDefinedArea => {
          const percentage = capturedImage.actualWidth / capturedImage.width;

          return {
            id: userDefinedArea.id,
            x: userDefinedArea.x * percentage,
            y: userDefinedArea.y * percentage,
            height: userDefinedArea.height * percentage,
            width: userDefinedArea.width * percentage,
            label: userDefinedArea.label
          };
        })
      : [];
  }

  // static determineGridEnum(capturedImage) {
  //   const x = capturedImage.gridXLength;
  //   const y = capturedImage.gridYLength;

  //   if (x === 2 && y === 2) {
  //     return GridAreaLabels2x2;
  //   } else {
  //     // default to 3x3
  //     return GridAreaLabels3x3;
  //   }
  // }

  static determineImageSize(url) {
    const img = document.createElement('img');

    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        // Natural size is the actual image size regardless of rendering.
        // The 'normal' `width`/`height` are for the **rendered** size.
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        // Resolve promise with the width and height
        resolve({ width, height });
      };

      // Reject promise on error
      img.onerror = reject;
    });

    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;

    return promise;
  }

  static collide(coordinatePair1, coordinatePair2) {
    let percentage = 1;

    if (coordinatePair1.imageWidth !== coordinatePair2.imageWidth) {
      percentage = coordinatePair2.imageWidth / coordinatePair1.imageWidth;
    }

    const rect1 = {
      right: coordinatePair1.bottomRightCoordinates.yCoordinate * percentage,
      left: coordinatePair1.topLeftCoordinates.yCoordinate * percentage,
      bottom: coordinatePair1.bottomRightCoordinates.xCoordinate * percentage,
      top: coordinatePair1.topLeftCoordinates.xCoordinate * percentage
    };

    const rect2 = {
      right: coordinatePair2.bottomRightCoordinates.yCoordinate,
      left: coordinatePair2.topLeftCoordinates.yCoordinate,
      bottom: coordinatePair2.bottomRightCoordinates.xCoordinate,
      top: coordinatePair2.topLeftCoordinates.xCoordinate
    };

    const isOverlapping = !(
      rect1.right < rect2.left ||
      rect1.left > rect2.right ||
      rect1.bottom < rect2.top ||
      rect1.top > rect2.bottom
    );

    return isOverlapping;
  }
}
