import { define } from '@rlean/core';
import { Invoice as InvoiceType } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

const Invoice = define<InvoiceType[] | object>(EntityKey.Invoice, {
  initialState: {},
  getURL: '/Invoices'
});

export default Invoice;
