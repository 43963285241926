import React, { memo, useState } from 'react';
import { RLeanState } from '@rlean/core';
import { removeDuplicates, getValue, hasValue } from '@rlean/utils';
import { SQReport } from 'components/shared';
import { getUniquePartNumbers, getUniqueCriteriaNames, reportNames, formats } from 'components/shared/SQReport';
import moment from 'moment';
import { strings } from 'config';
import { downloadFile, downloadFileType } from 'lib/helpers';
import { useCurrentProject, useFilters } from 'lib/hooks';

const filterProperties = [
  'shift',
  'startDateTime',
  'endDateTime',
  'partIdListCommaSeparated',
  'criteriaIdListCommaSeparated'
];

export const Trend = memo(() => {
  const [projectParts] = RLeanState().select(({ state }) => [state.projectParts]);
  const [chartData, setChartData] = useState({ categories: null, series: null, reportData: null });

  const parts = getValue(projectParts, 'data.parts', []);
  const defaultParts = getUniquePartNumbers(parts);
  const defaultCriteria = getUniqueCriteriaNames(parts);
  const currentProject = useCurrentProject();

  let defaultStartDate = new Date();
  let defaultEndDate = new Date();

  if (hasValue(currentProject, 'lastSortDate')) {
    defaultStartDate = new Date(currentProject.lastSortDate);
    defaultEndDate = new Date(currentProject.lastSortDate);
    defaultStartDate.setDate(defaultStartDate.getDate() - 30);
    defaultEndDate.setDate(defaultEndDate.getDate());
  }

  const { convertedFilters: filters, loadingFilters, updateFiltersWithFormData: updateFilters } = useFilters(
    {
      startDateTime: moment(defaultStartDate).startOf('day'),
      endDateTime: moment(defaultEndDate).endOf('day'),
      partIdListCommaSeparated: defaultParts,
      criteriaIdListCommaSeparated: defaultCriteria
    },
    filterProperties
  );

  const columns = [
    {
      title: strings.reportTableColumnSortDate,
      dataIndex: 'sortDate',
      key: 'sortDate',
      render: date => <>{moment(date).format('MM/DD/YYYY')}</>,
      sorter: (a, b) => new Date(a.sortDate) - new Date(b.sortDate),
      defaultSortOrder: 'descend',
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnShift,
      dataIndex: 'sortShift',
      key: 'sortShift',
      sorter: (a, b) => a.sortShift - b.sortShift,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnCriteria,
      dataIndex: 'criteriaName',
      key: 'criteriaName',
      sorter: (a, b) => {
        if (a.criteriaName) {
          return b.criteriaName ? a.criteriaName.localeCompare(b.criteriaName) : -1;
        }
        if (b.criteriaName) {
          return a.criteriaName ? b.criteriaName.localeCompare(a.criteriaName) : 1;
        }
        return 0 - 0;
      },
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnCriteriaType,
      dataIndex: 'criteriaType',
      key: 'criteriaType',
      sorter: (a, b) => {
        if (a.criteriaType) {
          return b.criteriaType ? a.criteriaType.localeCompare(b.criteriaType) : -1;
        }
        if (b.criteriaType) {
          return a.criteriaType ? b.criteriaType.localeCompare(a.criteriaType) : 1;
        }
        return 0 - 0;
      },
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnInspected,
      dataIndex: 'inspected',
      key: 'inspected',
      sorter: (a, b) => a.inspected - b.inspected,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnDefects,
      dataIndex: 'defects',
      key: 'defects',
      sorter: (a, b) => a.defects - b.defects,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnDefectPPM,
      dataIndex: 'ppm',
      key: 'ppm',
      sorter: (a, b) => a.ppm - b.ppm,
      render: ppm => <>{ppm.toFixed(2)}</>,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnFallout,
      dataIndex: 'fallout',
      key: 'fallout',
      sorter: (a, b) => a.fallout - b.fallout,
      render: fallout => <>{(fallout * 100).toFixed(2)}</>,
      sqVisible: true,
      sqToggle: true
    }
  ];

  const configuration = {
    chart: { type: 'line' },
    title: { text: '' },
    lang: { noData: 'No data to display' },
    noData: { position: { x: 0, y: 0, align: 'center', verticalAlign: 'middle' } },
    credits: { enabled: false },
    subtitle: { text: '' },
    xAxis: { categories: chartData.categories },
    yAxis: { title: { text: '' } },
    plotOptions: { line: { dataLabels: { enabled: true }, enableMouseTracking: false } },
    series: chartData.series
  };

  const buildChart = responseData => {
    if (responseData != null) {
      const reportData = [];
      const allCriteria = [];
      const dates = [];

      for (let i = 0; i < responseData.length; i += 1) {
        dates.push(`${moment(responseData[i].sortDate).format('MM/DD/YYYY')}`);
        allCriteria.push(responseData[i].criteriaName);
        const data = responseData[i];
        data['i'] = i;

        reportData.push(data);
      }

      const categories = removeDuplicates(dates);
      const uniqueCriteria = removeDuplicates(allCriteria);
      const series = [];

      if (
        uniqueCriteria !== undefined &&
        uniqueCriteria.length > 0 &&
        categories !== undefined &&
        categories.length > 0
      ) {
        for (let i = 0; i < uniqueCriteria.length; i += 1) {
          const criteriaData = reportData.filter(x => x.criteriaName === uniqueCriteria[i]);
          const seriesData = [];

          if (criteriaData) {
            for (let n = 0; n < categories.length; n += 1) {
              const allCategoryData = criteriaData.filter(
                a => moment(a.sortDate).format('MM/DD/YYYY') === categories[n]
              );

              let dataPoint = 0;

              for (let i = 0; i < allCategoryData.length; i += 1) {
                dataPoint += +allCategoryData[i].defects;
              }

              if (dataPoint !== undefined) {
                seriesData.push(dataPoint);
              } else {
                seriesData.push(null);
              }
            }
          }

          series.push({ name: uniqueCriteria[i] ? uniqueCriteria[i] : 'no defects', data: seriesData });
        }
      }

      setChartData({
        reportData,
        categories,
        series
      });
    }
  };

  const downloadReport = reportParams => {
    const {
      projectId,
      businessContactId,
      shift,
      startDateTime,
      partIdListCommaSeparated,
      criteriaIdListCommaSeparated
    } = reportParams;
    const endDateTime = reportParams.endDateTime.endOf('day');

    const params = {
      Shift: shift,
      start: startDateTime.toISOString(true),
      end: endDateTime.toISOString(true),
      partList: partIdListCommaSeparated.toString(),
      criteriaList: criteriaIdListCommaSeparated.toString(),
      BusinessContactId: businessContactId,
      ProjectId: projectId,
      format: formats.EXCELOPENXML,
      reportName: reportNames.TREND
    };

    const options = {
      filename: `Trend-${projectId}.xlsx`,
      endpoint: '/SsrsReportFiles',
      fileType: downloadFileType.EXCELOPENXML
    };
    downloadFile(options, params);
  };

  const reportOptions = {
    reportTitle: strings.reportTrendTitle,
    urls: ['/ProjectTrends'],
    filterProperties,
    filters,
    updateFilters,
    defaultParts,
    defaultCriteria,
    buildChart,
    downloadReport
  };

  const chartOptions = {
    chartReady: !!chartData.reportData,
    configuration
  };

  const tableOptions = {
    titleBarData: {
      tableTitle: strings.reportTrendTableTitle
    },
    dataSource: chartData.reportData,
    summary: false,
    columns
  };

  if (loadingFilters) {
    return <></>;
  }

  return <SQReport reportOptions={reportOptions} chartOptions={chartOptions} tableOptions={tableOptions} />;
});
