import { useEffect, useRef } from 'react';
import { getValue, hasValue, uuid } from '@rlean/utils';
import { usePost } from '@rlean/core';
import platform from 'platform';
import { EntityKey } from 'lib/enums/EntityKey';
import ImpersonationUserData from './ImpersonationUserData';
import LoginUserData from './LoginUserData';
import UserInfo from './UserInfo';

export const USER_TRACKING_TYPE = Object.freeze({
  login: 1,
  logout: 2,
  apiCall: 3,
  userAction: 4,
  impersonation: 5
});

export const EVENT_TYPE = Object.freeze({
  passive: 1,
  userResponse: 2
});

// custom hook currently expects 1 property, userTrackingType
export function useTrackUser(options) {
  const attemptingToTrack = useRef(false);
  const post = usePost();
  const sessionId = uuid();

  const toggleTrackingRef = () => {
    attemptingToTrack.current = !attemptingToTrack.current;
  };

  const createTrackingMessage = (userTrackingType, businessContact, userDescription) => {
    let additionalData;

    switch (userTrackingType) {
      case USER_TRACKING_TYPE.login:
        additionalData = new LoginUserData({
          sessionId,
          userAgent: platform.parse(navigator.userAgent)
        });
        break;
      case USER_TRACKING_TYPE.impersonation:
        additionalData = new ImpersonationUserData({
          sessionId,
          businessContactId: getValue(businessContact, 'data.id', null),
          displayName: getValue(businessContact, 'data.displayName', ''),
          companyId: getValue(businessContact, 'data.companyId', ''),
          companyName: getValue(businessContact, 'data.companyName', '')
        });
        break;
      default:
        additionalData = null;
    }

    // businessContactId could exist if a mobileUser is impersonating someone, so we check for mobileUser first before assigning a value to businessContactId
    const body = new UserInfo({
      businessContactId: hasValue(userDescription, 'mobileUserId') ? null : userDescription.businessContactId,
      mobileUserId: userDescription.mobileUserId,
      clientApplicationUsageTypeId: userTrackingType,
      additionalData: JSON.stringify(additionalData)
    });

    return body;
  };

  const trackUser = ({ userTrackingType, trackingEventType, businessContact, userDescription }) => {
    const isPosting = sessionStorage.getItem('userTracking.isPosting') === true;
    const trackingSessionId = sessionStorage.getItem('userTracking.sessionId');

    // Tracking user events should always post, while passive tracking only posts when session data doesn't exist
    const shouldPost =
      process.env.REACT_APP_TRACKING &&
      (trackingEventType === EVENT_TYPE.userResponse || (!isPosting && !trackingSessionId));

    if (shouldPost) {
      sessionStorage.setItem('userTracking.isPosting', true);

      post(
        {
          key: EntityKey.UserTracking,
          body: createTrackingMessage(userTrackingType, businessContact, userDescription)
        },
        (response, error) => {
          if (error) {
            sessionStorage.setItem('userTracking.isPosting', false);
            sessionStorage.setItem('userTracking.sessionId', '');
          } else {
            sessionStorage.setItem('userTracking.isPosting', false);
            sessionStorage.setItem('userTracking.sessionId', sessionId);
          }

          toggleTrackingRef();
        }
      );
    } else {
      toggleTrackingRef();
    }
  };

  const trackUserEvent = ({ userTrackingType, businessContact, trackingEventType, userDescription }) => {
    trackUser({
      userTrackingType,
      trackingEventType: trackingEventType ?? EVENT_TYPE.userResponse,
      businessContact,
      userDescription
    });
  };

  if (typeof options === 'undefined') {
    return ({ userTrackingType, businessContact, trackingEventType, userDescription }) => {
      trackUserEvent({ userTrackingType, businessContact, trackingEventType, userDescription });
    };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    let isMounted = true;

    if (!attemptingToTrack.current && isMounted) {
      toggleTrackingRef();
      trackUser({
        userTrackingType: options.userTrackingType,
        trackingEventType: EVENT_TYPE.passive,
        businessContact: options.businessContact,
        userDescription: options.userDescription
      });
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return null;
}
