import { PublicClientApplication } from '@azure/msal-browser';
import { navigate } from '@reach/router';
import { hasValue } from '@rlean/utils';
import * as Sentry from '@sentry/browser';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT}`,
    redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI
  },
  cache: {
    cacheLocation: 'sessionStorage'
  }
};

const scopes = [process.env.REACT_APP_MSAL_TOKEN_SCOPE];

export const msalInstance = new PublicClientApplication(msalConfig);

const redirectOnError = error => {
  console.error("Couldn't get token", { error });
  Sentry.captureException(new Error(`Could not get token: ${error}`));

  if (
    (hasValue(error, 'message') && error.message.contains('500')) ||
    error.message.contains('400') ||
    error.message.contains('404')
  ) {
    navigate('/unexpected-error');
  } else {
    msalInstance.loginRedirect();
  }
};

export const getToken = async () => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    console.error('No active account!');
    return null;
  }

  const response = await msalInstance
    .acquireTokenSilent({
      account,
      scopes
    })
    .catch(redirectOnError);

  return response.accessToken;
};

export const msalApiFetch = (fetch, url, options) => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    console.error('No active account!');
    return null;
  }

  return msalInstance
    .acquireTokenSilent({ account, scopes })
    .then(response => {
      const o = options || {};
      if (!o.headers) {
        o.headers = {};
      }
      o.headers.Authorization = `Bearer ${response.accessToken}`;

      return fetch(url, o);
    })
    .catch(redirectOnError);
};
