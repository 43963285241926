import { define } from '@rlean/core';
import { BusinessContactUser as BusinessContactUserType } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

const BusinessContactUser = define<BusinessContactUserType>(EntityKey.BusinessContactUser, {
  getURL: '/BusinessContacts/:id',
  persistData: false,
  includeInState: false
});

export default BusinessContactUser;
