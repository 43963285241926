export const echoDataType = Object.freeze({
  STRING: 1,
  DATETIME: 2,
  INT: 3,
  NUMBERIC: 4,
  DATE: 5,
  PICTURE: 6,
  TIME: 7,
  BOOLEAN: 8,
  CHOICE: 9,
  NONE: 10,
  GEO: 16,
  ARC: 17,
  COORDINATES: 18,
  LATITUDE: 21,
  URI: 25
});
