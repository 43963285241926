import { apiUri, msalApiFetch } from 'config';
import { message } from 'antd';
import { Logger } from 'lib/models';
import mime from 'mime';

export const downloadFileType = {
  PDF: 'application/pdf',
  EXCELOPENXML: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV: 'text/csv'
};

export const downloadFileHandleBlob = (blob, filename) => {
  message.destroy();
  if (blob.size > 0) {
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      message.success('Download Finished', 3);
    }, 500);

    return;
  }

  message.error('Download Failed', 3);

  const logger = new Logger();
  logger.log({ level: logger.level.ERROR, message: 'Download Failed' });
};

// Currently, downloadFile assumes responseType buffer/method: 'POST' if a fileType is specified vs responseType blob/method: 'GET' if fileType is left null or undefined
// options = {
//   endpoint, - required
//   filename, - required
//   fileType - optional
// }
export const downloadFile = (options, fetchBody) => {
  message.loading('Downloading...', 0);
  const path = apiUri(options.endpoint);
  const fetchOptions = {};

  if (options.fileType) {
    fetchOptions.method = 'POST';
    fetchOptions.headers = { 'Content-Type': 'application/json' };
  }
  if (fetchBody) {
    fetchOptions.body = JSON.stringify(fetchBody);
  }

  msalApiFetch(fetch, path, fetchOptions)
    .then(resp => {
      if (options.fileType) {
        return resp.arrayBuffer();
      }

      // This will cause a sentry error to get logged if there is a 404 but can be ignored.
      // This will ensure the user sees an onscreen error message and will not download a blank file if there is no 404.
      return resp.status === 404 ? null : resp.blob();
    })
    .then(responseBody => {
      // if filetype specified, return the response body as blob
      if (options.fileType) {
        responseBody = new Blob([responseBody], { type: options.fileType });
        return downloadFileHandleBlob(responseBody, options.filename);
      }

      // get the filetype from the blob
      const fileType = responseBody.type;

      // get extension using mime package
      const extension = mime.getExtension(fileType);

      // append the extension to the filename
      const filename = `${options.filename}.${extension}`;

      return downloadFileHandleBlob(responseBody, filename);
    })
    .catch(error => {
      message.destroy();
      message.error('Download Failed', 3);

      const logger = new Logger();
      logger.log({ level: logger.level.ERROR, message: `Download Failed: ${error}` });
    });
};
