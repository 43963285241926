import { define } from '@rlean/core';
import { BusinessContactUser } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

const BusinessContactUserByCompany = define<BusinessContactUser>(EntityKey.BusinessContactUserByCompany, {
  getURL: '/BusinessContacts',
  persistData: false,
  includeInState: false
});

export default BusinessContactUserByCompany;
