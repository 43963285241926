import { define } from '@rlean/core';
import { ProjectInformationFormRevision } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

const ProjectInformationFormRevisions = define<
  ProjectInformationFormRevision[]
>(EntityKey.ProjectInformationFormRevisions, {
  getURL: '/ProjectInformationFormRevisions'
});

export default ProjectInformationFormRevisions;
