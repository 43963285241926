import React, { memo, useState } from 'react';
import { BaseCard, LOADING_STATUS } from 'components/shared';

const SiteReports = () => {
  const [loadingStatus, setLoadingStatus] = useState(LOADING_STATUS.WAITING);

  const cardData = {
    title: 'Site Reports',
    loadingStatus,
    description: ''
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  return <BaseCard cardData={cardData} emptyCardData={emptyCardData}></BaseCard>;
};

export default memo(SiteReports);
