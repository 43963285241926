import React, { memo, useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { useSave } from '@rlean/core';
import { hasValue, getValue, uuid } from '@rlean/utils';
import { Card } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { formatDate } from 'lib/helpers';
import { EntityKey } from 'lib/enums/EntityKey';

const ProjectMobileCards = props => {
  const [sortedProjects, setSortedProjects] = useState([]);
  const [loadedCards, setLoadedCards] = useState([]);
  const save = useSave();

  useEffect(() => {
    // Reset the loadedCard state if the projects change (i.e. after
    // applying a filter)
    setLoadedCards([]);

    // Store and sort the projects DESC by ProjectID
    setSortedProjects([...props.projects].sort((p1, p2) => p2.projectId - p1.projectId));
  }, [props.projects]);

  if (!props.projects) {
    return null;
  }

  const projectDetails = project => {
    const statusClass = project.projectStatus === 'A' ? 'active-project' : 'closed-project';

    const setActiveProject = projectId => {
      save({ key: EntityKey.ActiveProject, value: { id: projectId } });
      navigate('/project/details');
    };

    return (
      <>
        <div className={`mobile-table-head ${statusClass}`}>
          <div className='project-number'>
            <button className='link-button' type='button' onClick={() => setActiveProject(project.projectId)}>
              {getValue(project, 'projectId', '--')}
            </button>
          </div>
          <div className='project-work-site'>
            {getValue(project, 'workSiteCompanyName', '--')} |{' '}
            {hasValue(project, 'startDate') ? formatDate(project.startDate) : '--'}
          </div>
        </div>
        <div className='mobile-table-fields'>
          <div>
            <b>CRN:</b> {getValue(project, 'customerReference', '--')}
          </div>
          <div>
            <b>Part Number:</b> {getValue(project, 'partNumber', '--')}
          </div>
          <div>
            <b>Part Name:</b> {getValue(project, 'partDescription', '--')}
          </div>
          <div>
            <b>Criteria:</b> {getValue(project, 'criteriaName', '--')}
          </div>
          <div>
            <b>Clean Point Detail:</b> {getValue(project, 'cleanPointDetail', '--')}
          </div>
        </div>
        <div className='mobile-table-metrics'>
          <div className='mobile-table-metrics-totals'>
            <div className='mobile-table-metric'>
              <div className='metric-label'>INSPECTED:</div>
              <div className='metric-value'>{getValue(project, 'inspected', '--')}</div>
            </div>
            <div className='mobile-table-metric'>
              <div className='metric-label'>NON-CONFORMING:</div>
              <div className='metric-value red-text'>{getValue(project, 'defects', '--')}</div>
            </div>
            <div className='mobile-table-metric'>
              <div className='metric-label'>REWORKED:</div>
              <div className='metric-value'>{getValue(project, 'reworked', '--')}</div>
            </div>
            <div className='mobile-table-metric'>
              <div className='metric-label'>GOOD:</div>
              <div className='metric-value'>{getValue(project, 'good', '--')}</div>
            </div>
          </div>
          {hasValue(project, 'lastSortDate') && (
            <div className='mobile-table-metrics-last-date'>
              <div className='mobile-table-metric'>
                <div className='metric-label'>LAST SORT DATE:</div>
                <div className='metric-value'>
                  {hasValue(project, 'lastSortDate') ? formatDate(project.lastSortDate) : '--'}
                </div>
              </div>
              <div className='mobile-table-metric'>
                <div className='metric-label'>LAST DATE INSPECTED:</div>
                <div className='metric-value'>{getValue(project, 'lastSortDateInspectedTotal', '--')}</div>
              </div>
              <div className='mobile-table-metric'>
                <div className='metric-label'>LAST DATE NON-CONFORM:</div>
                <div className='metric-value'>{getValue(project, 'lastSortDateNonConformTotal', '--')}</div>
              </div>
              <div className='mobile-table-metric'>
                <div className='metric-label'>LAST DATE REWORKED:</div>
                <div className='metric-value'>{getValue(project, 'lastSortDateReworkTotal', '--')}</div>
              </div>
              <div className='mobile-table-metric'>
                <div className='metric-label'>LAST DATE GOOD:</div>
                <div className='metric-value'>{getValue(project, 'lastSortDateGoodTotal', '--')}</div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const handleInfiniteOnLoad = () => {
    if (loadedCards.length < sortedProjects.length) {
      setLoadedCards(previousState => [
        ...previousState,
        <Card key={uuid()} style={{ marginTop: 25 }} className='mobile-table-card'>
          {projectDetails(sortedProjects[previousState.length])}
        </Card>
      ]);
    }
  };

  return (
    <InfiniteScroll
      initialLoad
      pageStart={0}
      loadMore={handleInfiniteOnLoad}
      hasMore={loadedCards.length < sortedProjects.length}
    >
      {loadedCards}
    </InfiniteScroll>
  );
};

export default memo(ProjectMobileCards);
