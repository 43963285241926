import React, { memo, useState } from 'react';
import { RLeanState, usePut, useSave } from '@rlean/core';
import { hasValue, getValue, deepCopy } from '@rlean/utils';
import { Card, Form, Button, Input, Skeleton, Descriptions, message } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';
import { strings } from 'config';
import { EntityKey } from 'lib/enums/EntityKey';
import { downloadFile } from 'lib/helpers';

export const PendingWorkInstructionReview = memo(props => {
  const { id } = props;
  const { TextArea } = Input;

  const [activeProject, project, businessContact, pendingWorkInstruction] = RLeanState().select(({ state }) => [
    state.activeProject,
    state.project,
    state.businessContact,
    state.pendingWorkInstruction
  ]);
  const put = usePut();
  const save = useSave();
  const [comment, setComment] = useState('');
  const [showError, setShowError] = useState(false);
  const isPendingWorkInstructionLoaded =
    hasValue(pendingWorkInstruction, 'isLoading') &&
    !pendingWorkInstruction.isLoading &&
    hasValue(pendingWorkInstruction, 'data');
  const isProjectLoaded = hasValue(project, 'isLoading') && !project.isLoading && hasValue(project, 'data');
  let currentProject;
  const currentWorkInstruction = hasValue(pendingWorkInstruction, 'data')
    ? pendingWorkInstruction.data.find(x => x.id === +id)
    : null;

  if (hasValue(activeProject, 'id') && isProjectLoaded) {
    currentProject = project.data.find(x => x.projectId === activeProject.id);
  } else if (isPendingWorkInstructionLoaded && isProjectLoaded) {
    if (currentWorkInstruction) {
      currentProject = project.data.find(x => x.projectId === currentWorkInstruction.projectId);
    }
  }

  let body = <Skeleton active />;
  const businessContactId = getValue(businessContact, 'data.id', 0);

  if (isPendingWorkInstructionLoaded && isProjectLoaded) {
    const selectedWorkInstruction = pendingWorkInstruction.data.find(x => +x.id === +id);

    if (!activeProject || !activeProject.id || activeProject.id !== selectedWorkInstruction.projectId) {
      save({ key: EntityKey.ActiveProject, value: { id: selectedWorkInstruction.projectId } });
    }

    const handleApprove = () => {
      put(
        {
          key: EntityKey.PendingWorkInstruction,
          params: { id },
          body: {
            accepted: true,
            reviewerNote: '',
            reviewerBusinessContactId: businessContactId,
            id
          }
        },
        response => {
          if (response.status === 400 || response.status === 500) {
            message.error('There was an error approving the work instruction.');
          } else if (isPendingWorkInstructionLoaded) {
            const pendingWorkInstructions = pendingWorkInstruction.data.filter(instruction => +instruction.id !== +id);
            const updatedPendingWorkInstructions = deepCopy(pendingWorkInstruction);

            updatedPendingWorkInstructions.data = pendingWorkInstructions;

            save({ key: EntityKey.PendingWorkInstruction, value: updatedPendingWorkInstructions });
          }
        }
      );

      navigate('/project/details');
    };

    const handleReject = () => {
      if (comment) {
        put(
          {
            key: EntityKey.PendingWorkInstruction,
            params: { id },
            body: {
              accepted: false,
              reviewerNote: comment,
              reviewerBusinessContactId: businessContactId,
              id
            }
          },
          response => {
            if (response.status === 400 || response.status === 500) {
              message.error('There was an error rejecting the work instruction.');
            } else if (isPendingWorkInstructionLoaded) {
              const pendingWorkInstructions = pendingWorkInstruction.data.filter(
                instruction => +instruction.id !== +id
              );
              const updatedPendingWorkInstructions = deepCopy(pendingWorkInstruction);

              updatedPendingWorkInstructions.data = pendingWorkInstructions;

              save({ key: EntityKey.PendingWorkInstruction, value: updatedPendingWorkInstructions });
            }
          }
        );

        navigate('/project/details');
      } else {
        setShowError(true);
      }
    };

    const handleComment = e => {
      setComment(e.target.value);
    };

    if (selectedWorkInstruction && currentProject) {
      body = (
        <>
          <Descriptions title='Project Info'>
            <Descriptions.Item label={strings.projectId}>{currentProject.projectId}</Descriptions.Item>
            <Descriptions.Item label={strings.customerReferenceNumber}>
              {currentProject.customerReference && currentProject.customerReference.trim()
                ? currentProject.customerReference
                : '--'}
            </Descriptions.Item>
          </Descriptions>

          <Form>
            <Form.Item>
              {showError ? <span className='red-text'>{strings.workInstructionValidation}</span> : ''}
              <TextArea type='text' rows='6' value={comment} onChange={handleComment} placeholder={strings.comment} />
            </Form.Item>
            <Form.Item>
              <Button
                type='primary'
                htmlType='button'
                onClick={handleApprove}
                disabled={businessContact.data.isReadOnly}
              >
                {strings.approve}
              </Button>
              <Button
                type='primary'
                htmlType='button'
                onClick={handleReject}
                style={{ marginLeft: 20 }}
                disabled={businessContact.data.isReadOnly}
              >
                {strings.reject}
              </Button>
            </Form.Item>
          </Form>
        </>
      );
    } else {
      body = <p>{strings.noWorkInstruction}</p>;
    }
  }

  const downloadWorkInstruction = () => {
    if (currentWorkInstruction) {
      const options = {
        endpoint: `/WorkInstructionRevisions/${currentWorkInstruction.workInstructionRevisionId}`,
        filename: `Pending-Work-Instruction-${activeProject.id}`
      };

      downloadFile(options);
    }
  };

  const downloadButton = (
    <button
      type='button'
      className='link-button'
      style={{ fontSize: 20, fontWeight: 'bold' }}
      onClick={() => downloadWorkInstruction()}
    >
      <CloudDownloadOutlined />
    </button>
  );

  return (
    <Card className='page-card' title={strings.pendingWorkInstruction} extra={downloadButton}>
      {body}
    </Card>
  );
});
