import React, { memo, useState } from 'react';
import { RLeanState, usePut, useSave } from '@rlean/core';
import { hasValue, getValue, deepCopy } from '@rlean/utils';
import { Card, Form, Button, Input, Skeleton, Descriptions, message } from 'antd';
import { navigate } from '@reach/router';
import { strings } from 'config';
import { EntityKey } from 'lib/enums/EntityKey';

export const PendingWorkAuthorizationReview = memo(props => {
  const { id } = props;
  const { TextArea } = Input;

  const [activeProject, project, businessContact, pendingWorkAuthorization] = RLeanState().select(({ state }) => [
    state.activeProject,
    state.project,
    state.businessContact,
    state.pendingWorkAuthorization
  ]);
  const put = usePut();
  const [comment, setComment] = useState('');
  const [showError, setShowError] = useState(false);
  const save = useSave();
  const isPendingWorkAuthorizationLoaded =
    hasValue(pendingWorkAuthorization, 'isLoading') &&
    !pendingWorkAuthorization.isLoading &&
    hasValue(pendingWorkAuthorization, 'data');
  const isProjectLoaded = hasValue(project, 'isLoading') && !project.isLoading && hasValue(project, 'data');

  let currentProject;

  if (hasValue(activeProject, 'id') && isProjectLoaded) {
    currentProject = project.data.find(x => x.projectId === activeProject.id);
  } else if (isPendingWorkAuthorizationLoaded && isProjectLoaded) {
    const currentWorkAuthorization = pendingWorkAuthorization.data.find(x => x.id === +id);
    if (currentWorkAuthorization)
      currentProject = project.data.find(x => x.projectId === currentWorkAuthorization.projectId);
  }

  let body = <Skeleton active />;
  const businessContactId = getValue(businessContact, 'data.id', 0);

  if (isPendingWorkAuthorizationLoaded && isProjectLoaded) {
    const selectedWorkAuthorization = pendingWorkAuthorization.data.find(x => +x.id === +id);

    if (!activeProject || !activeProject.id || activeProject.id !== selectedWorkAuthorization.projectId) {
      save({ key: EntityKey.ActiveProject, value: { id: selectedWorkAuthorization.projectId } });
    }

    const handleApprove = () => {
      put(
        {
          key: EntityKey.PendingWorkAuthorization,
          params: { id },
          body: {
            approved: true,
            declinedExplanation: '',
            declinedReason: '',
            secondaryRecipients: '',
            businessContactId: businessContact.data.id,
            id
          }
        },
        response => {
          if (response.status === 400 || response.status === 500) {
            message.error('There was an error approving the work authorization.');
          } else {
            if (isPendingWorkAuthorizationLoaded) {
              const pendingWorkAuthorizations = pendingWorkAuthorization.data.filter(
                authorization => +authorization.id !== +id
              );
              const updatedPendingWorkAuthorizations = deepCopy(pendingWorkAuthorizations);

              updatedPendingWorkAuthorizations.data = pendingWorkAuthorizations;

              save({ key: EntityKey.PendingWorkAuthorization, value: updatedPendingWorkAuthorizations });
            }
          }
        }
      );

      navigate('/project/details');
    };

    const handleReject = () => {
      if (comment) {
        put(
          {
            key: EntityKey.PendingWorkAuthorization,
            params: { id },
            body: {
              approved: false,
              declinedExplanation: comment,
              declinedReason: '',
              secondaryRecipients: '',
              businessContactId,
              id
            }
          },
          response => {
            if (response.status === 400 || response.status === 500) {
              message.error('There was an error rejecting the work authorization.');
            } else {
              if (isPendingWorkAuthorizationLoaded) {
                const pendingWorkAuthorizations = pendingWorkAuthorization.data.filter(
                  authorization => +authorization.id !== +id
                );
                const updatedPendingWorkAuthorizations = deepCopy(pendingWorkAuthorizations);

                updatedPendingWorkAuthorizations.data = pendingWorkAuthorizations;

                save({ key: EntityKey.PendingWorkAuthorization, value: updatedPendingWorkAuthorizations });
              }
            }
          }
        );

        navigate('/project/details');
      } else {
        setShowError(true);
      }
    };

    const handleComment = e => {
      setComment(e.target.value);
    };

    if (selectedWorkAuthorization && currentProject) {
      body = (
        <>
          <Descriptions title='Project Info'>
            <Descriptions.Item label={strings.projectId}>{currentProject.projectId}</Descriptions.Item>
            <Descriptions.Item label={strings.customerReferenceNumber}>
              {currentProject.customerReference && currentProject.customerReference.trim()
                ? currentProject.customerReference
                : '--'}
            </Descriptions.Item>
          </Descriptions>

          <Form>
            <Form.Item>
              {showError ? <span className='red-text'>{strings.workAuthorizationValidation}</span> : ''}
              <TextArea type='text' rows='6' value={comment} onChange={handleComment} placeholder={strings.comment} />
            </Form.Item>
            <Form.Item>
              <Button
                type='primary'
                htmlType='button'
                onClick={handleApprove}
                disabled={businessContact.data.isReadOnly}
              >
                {strings.approve}
              </Button>
              <Button
                type='primary'
                htmlType='button'
                onClick={handleReject}
                style={{ marginLeft: 20 }}
                disabled={businessContact.data.isReadOnly}
              >
                {strings.reject}
              </Button>
            </Form.Item>
          </Form>
        </>
      );
    } else {
      body = <p>{strings.noWorkAuthorization}</p>;
    }
  }

  return (
    <Card className='page-card' title={strings.pendingWorkAuthorization}>
      {body}
    </Card>
  );
});
