import { define } from '@rlean/core';
import { UserConfiguration as UserConfigurationType } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

const UserConfiguration = define<UserConfigurationType[]>(EntityKey.UserConfiguration, {
  getURL: '/BusinessContactConfigurations',
  postURL: '/BusinessContactConfigurations'
});

export default UserConfiguration;
