import React, { memo, useState } from 'react';
import { Row, Col, Radio, Button } from 'antd';
import { Link, navigate } from '@reach/router';
import logo from 'assets/sqlogo.png';
import { FullscreenTable } from './FullscreenTable';
import { ProjectCards } from './ProjectCards';

export const Fullscreen = memo(() => {
  const [activeTab, setActiveTab] = useState('table');
  const [showTableView, setShowTableView] = useState();
  const [showCardView, setShowCardView] = useState();

  const callback = e => {
    setActiveTab(e.target.value);

    switch (e.target.value) {
      case 'table':
        setShowTableView(true);
        setShowCardView(false);
        break;
      case 'cards':
        setShowTableView(false);
        setShowCardView(true);
        break;
      default:
        // unrecognized
        break;
    }
  };

  const content = () => {
    if (showCardView) {
      return <ProjectCards />;
    }

    return <FullscreenTable />;
  };

  const exit = () => {
    navigate('/projects');
  };

  return (
    <div className='project-cards-container'>
      <Row className='grid-row'>
        <Col className='grid-col' span={6}>
          <Radio.Group onChange={callback} value={activeTab}>
            <Radio.Button value='table' checked={showTableView}>
              Table View
            </Radio.Button>
            <Radio.Button value='cards' checked={showCardView}>
              Card View
            </Radio.Button>
          </Radio.Group>
          <Button onClick={() => exit()}>Exit</Button>
        </Col>
        <Col className='grid-col' span={12} style={{ textAlign: 'center' }}>
          <Link to='/'>
            <img
              src={logo}
              alt='Stratosphere Quality logo'
              className='fullscreen-logo'
              style={{ height: 45, width: 'auto' }}
            />
          </Link>
        </Col>
        <Col className='grid-col' span={6}>
          {/** Show settings icon */}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div style={{ marginTop: 15 }}>{content()}</div>
        </Col>
      </Row>
    </div>
  );
});
