import React, { memo, useState } from 'react';
import { RLeanState } from '@rlean/core';
import { getValue, hasValue } from '@rlean/utils';
import { SQReport } from 'components/shared';
import moment from 'moment';
import Highcharts from 'highcharts';
import Pareto from 'highcharts/modules/pareto';
import { strings } from 'config';
import { useCurrentProject, useFilters } from 'lib/hooks';
import { SQTableBuilder } from 'lib/models';
import Heatmap from './_heatmap';
import { DrilldownContextProvider } from './CommonDrilldown/DrilldownContext';

Pareto(Highcharts);

const filterProperties = ['startDate', 'endDate', 'partId'];

export const PartAreaDefectIntensity = memo(() => {
  const [parts, partWithPartImage] = RLeanState().select(({ state }) => [state.parts, state.partWithPartImage]);

  const currentProject = useCurrentProject();
  const partsData = getValue(parts, 'data', []);
  const [tableData, setTableData] = useState({ rows: [], columns: [] });

  // -----
  // Handle for setting up the table data
  const buildChart = data => {
    const tableBuilder = new SQTableBuilder(data);
    setTableData({
      rows: tableBuilder.getDataSource(),
      columns: tableBuilder.getColumns()
    });
  };

  // -----
  // Set filters
  let defaultStartDate = new Date();
  let defaultEndDate = new Date();

  if (hasValue(currentProject, 'lastSortDate')) {
    defaultStartDate = new Date(currentProject.lastSortDate);
    defaultEndDate = new Date(currentProject.lastSortDate);
    defaultStartDate.setDate(defaultStartDate.getDate() - 30);
    defaultEndDate.setDate(defaultEndDate.getDate());
  }

  const { convertedFilters: filters, loadingFilters, updateFiltersWithFormData: updateFilters } = useFilters(
    {
      startDate: moment(defaultStartDate),
      endDate: moment(defaultEndDate),
      partId: { value: partsData[0].id, altValue: partsData[0].number }
    },
    filterProperties
  );

  // -----
  // Set report options
  const reportOptions = {
    reportTitle: 'Part Area Defect Intensity',
    urls: ['/PartAreaDefectIntensity'],
    filterProperties,
    filters,
    updateFilters,
    parts: partsData,
    buildChart
  };

  const tableOptions = {
    titleBarData: {
      tableTitle: strings.reportParetoTableDetailsTitle,
      downloadBtn: true
    },
    dataSource: tableData.rows || [],
    summary: false,
    columns: tableData.columns || [],
    downloadBtn: true,
    downloadInfo: {
      reportName: 'Part Area Defect Intensity',
      fileName: `Part_Area_Defect_Intensity_${moment().format('YYYY-MM-DDTHH:MM:SSZ')}`
    }
  };

  if (loadingFilters) {
    return <></>;
  }

  return (
    <DrilldownContextProvider>
      <SQReport reportOptions={reportOptions} tableOptions={tableOptions}>
        <Heatmap partWithPartImage={partWithPartImage} tableData={tableData} />
      </SQReport>
    </DrilldownContextProvider>
  );
});
