import { define } from '@rlean/core';
import { ProjectWorkInstruction } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

// TODO: rename to ProjectWorkInstruction
const ProjectWorkInstructions = define<ProjectWorkInstruction[]>(EntityKey.ProjectWorkInstructions, {
  getURL: '/WorkInstructionRevisions'
});

export default ProjectWorkInstructions;
