import React from 'react';
import { Alert } from 'antd';
import { detectIE } from 'lib/helpers';

export const RenderChart = ({ chart }) => {
  if (detectIE()) {
    return (
      <Alert
        message='Unsupported Browser'
        description='Onscreen visualizations are incompatible with your browser. Please use Microsoft Edge, Chrome, or Safari to display reports onscreen. Reports can still be downloaded in Internet Explorer.'
        type='warning'
        style={{ marginBottom: 20 }}
      />
    );
  }

  return chart;
};
