import React, { memo, useState } from 'react';
import { Popover } from 'antd';
import { CloseCircleTwoTone } from '@ant-design/icons';
import { strings } from 'config/strings';
import RejectForm from './RejectForm';

const RejectButton = ({ workInstructionRevisionId, id }) => {
  const [popVisible, setPopVisible] = useState(false);
  const hide = () => {
    setPopVisible(false);
  };

  const handleVisibleChange = visible => setPopVisible(visible);

  return (
    <Popover
      title={strings.workInstructionPopUpTitle}
      trigger='click'
      content={<RejectForm hide={hide} id={id} workInstructionRevisionId={workInstructionRevisionId} />}
      visible={popVisible}
      onVisibleChange={handleVisibleChange}
    >
      <button type='button' className='action-button'>
        <CloseCircleTwoTone twoToneColor='#dd6675' title='Reject Work Instructions' />
      </button>
    </Popover>
  );
};

export default memo(RejectButton);
