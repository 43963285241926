import React, { memo, useEffect, useState, useRef, useCallback } from 'react';
import { Card, Input, Skeleton } from 'antd';
import { useSave, useGet, useRemove, RLeanState } from '@rlean/core';
import { hasValue, getValue } from '@rlean/utils';
import { navigate } from '@reach/router';
import parse from 'html-react-parser';
import { EntityKey } from 'lib/enums/EntityKey';
import { useActivePage, useImpersonateProject, USER_TRACKING_TYPE, useTrackUser } from 'lib/hooks';
import { pages, strings } from 'config';
import { AutoComplete } from '../_shared/AutoComplete';

const { Search } = Input;

export const Impersonate = memo(() => {
  const [businessContactSearch, userDescription] = RLeanState().select(({ state }) => [
    state.businessContactSearch,
    state.userDescription
  ]);
  const [projectIdSearch, setProjectIdSearch] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [selectedBusinessContactId, setSelectedBusinessContactId] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [businessContactSearchCriteria, setBusinessContactSearchCriteria] = useState();
  const isMounted = useRef(true);
  const save = useSave();
  const get = useGet();
  const remove = useRemove();
  const isMobileUser = getValue(userDescription, 'data.isMobileUser', null);
  const trackUserEvent = useTrackUser();
  const [searchProject, isSearchingProject, showNoProjectError] = useImpersonateProject();

  useEffect(() => {
    if (isMobileUser !== null && !isMobileUser) {
      navigate('/unauthorized');
    }
  }, [isMobileUser]);

  useActivePage(pages.impersonate);

  // create business contact object for impersonated user
  useGet(
    {
      key: EntityKey.BusinessContactUser,
      params: { id: selectedBusinessContactId }
    },
    userRes => {
      if (hasValue(userRes, 'data')) {
        const businessContactCompanyId = getValue(userRes, 'data.companyId', null);

        get({ key: EntityKey.BusinessContactCompany, params: { id: businessContactCompanyId } }, companyRes => {
          if (hasValue(companyRes, 'data')) {
            const businessContact = {
              id: selectedBusinessContactId,
              displayName: `${userRes.data.firstName} ${userRes.data.lastName}`,
              companyId: userRes.data.companyId,
              companyName: companyRes.data.name,
              isReadOnly: true
            };

            save(
              {
                key: EntityKey.BusinessContact,
                value: { data: businessContact }
              },
              () => {
                // track user
                trackUserEvent({
                  userTrackingType: USER_TRACKING_TYPE.impersonation,
                  businessContact,
                  userDescription: userDescription.data
                });
              }
            );
          }
        });
      }
    }
  );

  // TODO: This needs to be tested!!
  useGet({ key: EntityKey.BusinessContactSearch, params: { searchTerm } });
  const rawData = getValue(businessContactSearch, 'data', []);

  const formattedData = rawData.map(item => {
    return { value: item.id, text: item.display };
  });

  // Fired when the user clicks in one of the impersonation options
  // function handleSelect(e) {
  //   const businessContactId = e.target.id;

  //   if (businessContactId && isMounted.current) {
  //     setSelectedBusinessContactId(businessContactId);
  //   }

  //   save({
  //     key: EntityKey.Impersonation,
  //     value: { isImpersonating: true, isReadOnly: true, init: true }
  //   });

  //   // Remove any entity that needs to be cleared out after changing
  //   // impersonation
  //   remove({ key: EntityKey.HistoricProject });

  //   navigate('/dashboard');

  //   return () => {
  //     isMounted.current = false;
  //   };
  // }

  const handleSelect = useCallback(
    e => {
      const businessContactId = e.target.id;

      if (businessContactId && isMounted.current) {
        setSelectedBusinessContactId(businessContactId);
      }

      save({
        key: EntityKey.Impersonation,
        value: { isImpersonating: true, isReadOnly: true, init: true }
      });

      // Remove any entity that needs to be cleared out after changing
      // impersonation
      remove({ key: EntityKey.HistoricProject });

      navigate('/dashboard');

      return () => {
        isMounted.current = false;
      };
    },
    [isMounted]
  );

  const content = () => {
    const toggleImpersonateType = () => {
      setProjectIdSearch(!projectIdSearch);
    };

    const searchBusinessContact = () => {
      setSearchTerm(businessContactSearchCriteria);
    };

    const SearchResults = () => {
      if (!searchTerm || searchTerm.length === 0) {
        return <></>;
      }

      if (businessContactSearch && businessContactSearch.isLoading) {
        return (
          <div style={{ marginTop: 25 }}>
            <Skeleton active />
          </div>
        );
      }

      if (
        searchTerm &&
        businessContactSearch &&
        !businessContactSearch.isLoading &&
        (!formattedData || formattedData.length === 0)
      ) {
        return <p>No results</p>;
      }

      return (
        <div style={{ marginTop: 25 }}>
          <AutoComplete
            data={formattedData}
            handleSelect={handleSelect}
            inputLabel='Filter Search Results'
            minChar={0}
            limit={100}
            disabled={projectIdSearch}
            alwaysShowDropDown
          />
        </div>
      );
    };

    const businessContactView = (
      <div style={{ marginTop: 25 }}>
        <Search
          placeholder='First Name, Last Name, or Company'
          value={businessContactSearchCriteria}
          onChange={e => setBusinessContactSearchCriteria(e.target.value)}
          enterButton
          loading={businessContactSearch && businessContactSearch.isLoading ? businessContactSearch.isLoading : false}
          onSearch={searchBusinessContact}
        />

        <button
          className='link-button link-small'
          type='button'
          onClick={toggleImpersonateType}
          style={{ fontSize: 'smaller' }}
        >
          Don&apos;t know the Business Contact or Company? Search by Project ID instead.
        </button>

        <SearchResults />
      </div>
    );

    const ErrorMessage = () => {
      return (
        <div className='red-text' style={{ fontSize: 'smaller' }}>
          Could not find the project.
        </div>
      );
    };

    const projectIdView = (
      <div style={{ marginTop: 25 }}>
        {showNoProjectError ? <ErrorMessage /> : <></>}
        <div style={{ flexWrap: 'wrap', justifyContent: 'space-between', display: 'flex' }}>
          <Search
            placeholder='Project Number'
            value={projectId}
            onChange={e => setProjectId(e.target.value)}
            enterButton
            loading={isSearchingProject}
            onSearch={searchProject}
          />
        </div>
        <button
          className='link-button link-small'
          type='button'
          onClick={toggleImpersonateType}
          style={{ fontSize: 'smaller' }}
        >
          Search by Business Contact or Company.
        </button>
      </div>
    );

    return (
      <>
        {parse(strings.impersonateContent)}
        {projectIdSearch ? projectIdView : businessContactView}
      </>
    );
  };

  return (
    <Card className='page-card' title={strings.impersonate}>
      {content()}
    </Card>
  );
});
