import React, { memo } from 'react';
import { RLeanState } from '@rlean/core';
import { hasValue } from '@rlean/utils';
import { BaseCard, LOADING_STATUS, BaseList, BaseListItem } from 'components/shared';
import { strings } from 'config';
import { navigate } from '@reach/router';
import { useProjectsParts } from 'lib/hooks';

export const Parts = memo(() => {
  const [projectParts, activeProject] = RLeanState().select(({ state }) => [state.projectParts, state.activeProject]);

  const [partsData] = useProjectsParts();

  const getLoadingStatus = () => {
    let loadingStatus = LOADING_STATUS.NODATA;

    if (
      hasValue(activeProject, 'id') &&
      (!projectParts || (hasValue(projectParts, 'isLoading') && projectParts.isLoading))
    ) {
      loadingStatus = LOADING_STATUS.WAITING;
    } else if (
      hasValue(activeProject, 'id') &&
      hasValue(projectParts, 'isLoading') &&
      !projectParts.isLoading &&
      hasValue(projectParts, 'data.parts') &&
      projectParts.data.parts.length > 0
    ) {
      loadingStatus = LOADING_STATUS.LOADED;
    }

    return loadingStatus;
  };

  const showDrawer = () => {
    navigate('/project/details/report/parts');
  };

  const cardData = {
    title: strings.projectDetailsPartsTitle,
    loadingStatus: getLoadingStatus(),
    description: null
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  return (
    <BaseCard cardData={cardData} emptyCardData={emptyCardData}>
      <BaseList
        data={partsData}
        launchAction={showDrawer}
        renderItem={item => {
          const { number, description, name, criteriaType } = item;

          return (
            <BaseListItem title={`${strings.projectDetailsPartsPart} ${number}`} description={description}>
              <p style={{ marginBottom: '0' }}>{`${strings.projectDetailsPartsCriteria} ${name}`}</p>
              <p style={{ marginBottom: '0' }}>{`${strings.projectDetailsPartsCriteriaType} ${criteriaType}`}</p>
            </BaseListItem>
          );
        }}
      />
    </BaseCard>
  );
});
