import React, { memo } from 'react';
import { RLeanState, usePut, useSave } from '@rlean/core';
import { hasValue, deepCopy } from '@rlean/utils';
import { EntityKey } from 'lib/enums/EntityKey';
import { Input, Button, Form, message } from 'antd';
import { strings } from 'config/strings';

const RejectForm = ({ hide, id }) => {
  const [form] = Form.useForm();
  const [businessContact, pendingWorkInstruction] = RLeanState().select(({ state }) => [
    state.businessContact,
    state.pendingWorkInstruction
  ]);
  const put = usePut();
  const save = useSave();

  const handleFinish = values => {
    message.loading('Rejecting Work Instruction...', 0);
    put(
      {
        key: EntityKey.PendingWorkInstruction,
        params: { id },
        body: {
          accepted: false,
          reviewerNote: values.rejectMessage,
          reviewerBusinessContactId: businessContact.data.id,
          id
        }
      },
      response => {
        if (response.status === 400 || response.status === 500) {
          message.destroy();
          message.error('There was an error approving the work instruction.');
        } else {
          message.destroy();
          form.resetFields();
          if (hasValue(pendingWorkInstruction, 'data')) {
            const pendingWorkInstructions = pendingWorkInstruction.data.filter(instruction => instruction.id !== id);

            const updatedPendingWorkInstructions = deepCopy(pendingWorkInstruction);

            updatedPendingWorkInstructions.data = pendingWorkInstructions;

            save({ key: EntityKey.PendingWorkInstruction, value: updatedPendingWorkInstructions });
            message.success('Work Instruction Rejected', 3);
          }
          hide();
        }
      }
    );
  };

  return (
    <Form onFinish={handleFinish} name='reject_form' form={form} style={{ display: 'flex', flexDirection: 'column' }}>
      <Form.Item
        style={{ marginBottom: '0px' }}
        name='rejectMessage'
        rules={[{ required: true, message: 'required text' }]}
      >
        <Input.TextArea
          type='text'
          rows='4'
          placeholder={strings.workInstructionPopUpPlaceholder}
          style={{ width: '250px' }}
        />
      </Form.Item>
      <Form.Item style={{ alignSelf: 'center', marginBottom: '0px' }}>
        <Button type='primary' htmlType='submit'>
          {strings.workInstructionRejectButton}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default memo(RejectForm);
