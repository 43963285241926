import { define } from '@rlean/core';
import { ProjectFavorite as ProjectFavoriteType } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

const ProjectFavorite = define<ProjectFavoriteType>(EntityKey.ProjectFavorite, {
  getURL: '/BusinessContactFavoriteProjects',
  postURL: '/BusinessContactFavoriteProjects',
  nullableParams: true
});

export default ProjectFavorite;
