import React, { memo } from 'react';
import { Menu } from 'antd';
import {
  CommentOutlined,
  DollarCircleFilled,
  HomeFilled,
  LayoutFilled,
  FileTextFilled,
  MessageOutlined,
  PlusOutlined,
  QuestionCircleFilled,
  SearchOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';
import { Link } from '@reach/router';
import { RLeanState } from '@rlean/core';
import { getValue } from '@rlean/utils';
import { strings, pages } from 'config';

export const SidebarNavigation = memo(({ mobileNavClick = false }) => {
  const [activePage, impersonation, userDescription] = RLeanState().select(({ state }) => [
    state.activePage,
    state.impersonation,
    state.userDescription
  ]);
  const isMobileUser = getValue(userDescription, 'data.isMobileUser', true);
  const isImpersonating = getValue(impersonation, 'isImpersonating', false);

  const menuProps = {
    selectedKeys: typeof activePage.key !== 'undefined' ? [activePage.key] : null,
    mode: 'inline'
  };

  if (mobileNavClick) {
    menuProps.onClick = mobileNavClick;
  }

  const menuItemStyles = {
    paddingLeft: '18px'
  };

  const SurveyMenuItem = () => {
    if (!isMobileUser) {
      return (
        <>
          <Menu.Divider />
          <Menu.Item style={menuItemStyles}>
            <a href='https://www.surveymonkey.com/r/8KLHCK6' target='_blank' rel='noopener noreferrer'>
              <MessageOutlined />
              {strings.survey}
            </a>
          </Menu.Item>
        </>
      );
    }

    return null;
  };

  const MenuSelector = () => {
    if (isMobileUser && !isImpersonating) {
      return (
        <>
          <Menu.Item key={pages.dashboard.key} style={menuItemStyles}>
            <Link to='/'>
              <HomeFilled />
              SQ Home
            </Link>
          </Menu.Item>
          <Menu.Item key={pages.impersonate.key} style={menuItemStyles}>
            <Link to='/impersonate'>
              <UsergroupAddOutlined />
              {strings.impersonate}
            </Link>
          </Menu.Item>
          <Menu.SubMenu
            title={
              <>
                <span style={menuItemStyles}>
                  <FileTextFilled />
                  {strings.projects}
                </span>
              </>
            }
          >
            <Menu.Item key={pages.findAProject.key} style={menuItemStyles}>
              <Link to='/find-a-project'>
                <SearchOutlined />
                Find a Project
              </Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.Divider />
        </>
      );
    }

    return (
      <>
        <Menu.Item key={pages.dashboard.key} style={menuItemStyles}>
          <Link to='/dashboard'>
            <LayoutFilled />
            {strings.dashboard}
          </Link>
        </Menu.Item>
        <Menu.Item key={pages.projects.key} style={menuItemStyles}>
          <Link to='/projects'>
            <FileTextFilled />
            {strings.projects}
          </Link>
        </Menu.Item>
        <Menu.Item key={pages.invoices.key} style={menuItemStyles}>
          <Link to='/invoices'>
            <DollarCircleFilled />
            {strings.invoices}
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item style={menuItemStyles}>
          <a href='http://www.stratospherequality.com/start-a-project/' target='_blank' rel='noopener noreferrer'>
            <PlusOutlined />
            {strings.startAProject}
          </a>
        </Menu.Item>
        {impersonation.isReadOnly ? null : (
          <Menu.Item key={pages.concerns.key} style={menuItemStyles}>
            <Link to='/concerns'>
              <CommentOutlined />
              {strings.concerns}
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key={pages.support.key} style={menuItemStyles}>
          <Link to='/support'>
            <QuestionCircleFilled />
            {strings.support}
          </Link>
        </Menu.Item>
        <SurveyMenuItem />
      </>
    );
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Menu {...menuProps}>
      <MenuSelector />
    </Menu>
  );
});
