import { define } from '@rlean/core';
import { ProjectContact } from 'lib/types';
import { EntityKey } from 'lib/enums/EntityKey';

// TODO: Rename to ProjectContact
const ProjectContacts = define<ProjectContact[]>(EntityKey.ProjectContacts, {
  getURL: '/ProjectStratosphereContactViews'
});

export default ProjectContacts;
