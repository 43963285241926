import React, { memo, useState, useEffect } from 'react';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { usePost, useSave, RLeanState } from '@rlean/core';
import { hasValue, getValue, deepCopy } from '@rlean/utils';
import { message } from 'antd';
import { EntityKey } from 'lib/enums/EntityKey';

export const FavoriteButton = memo(({ projectId }) => {
  const [businessContact, projectFavorite] = RLeanState().select(({ state }) => [
    state.businessContact,
    state.projectFavorite
  ]);
  const [isFavorite, setIsFavorite] = useState();
  const post = usePost();
  const save = useSave();

  useEffect(() => {
    let isSubscribed = true;

    if (!projectFavorite && isSubscribed) {
      setIsFavorite(false);
    }

    if (hasValue(projectFavorite, 'data')) {
      const match = projectFavorite.data.some(project => project.projectId === projectId && !project.inactiveInd);

      if (isSubscribed) {
        setIsFavorite(match);
      }
    }

    return () => (isSubscribed = false);
  }, [projectFavorite, projectId]);

  const handleClick = () => {
    const businessContactId = businessContact.data.id;
    const inactiveInd = isFavorite;
    const body = {
      id: 0,
      project: null,
      businessContactId,
      projectId,
      inactiveInd
    };

    message.loading('Changing favorite status...', 0);

    post(
      {
        key: EntityKey.ProjectFavorite,
        body
      },
      (response, error) => {
        message.destroy();

        if (hasValue(response, 'data')) {
          const toggledProject = response.data;

          if (hasValue(projectFavorite, 'data')) {
            const oProjectFavorite = deepCopy(projectFavorite);

            const exists = oProjectFavorite.data.some(project => project.projectId === toggledProject.projectId);

            if (exists) {
              // remove the record so we can add the updated one
              oProjectFavorite.data = oProjectFavorite.data.filter(
                project => project.projectId !== toggledProject.projectId
              );
            }

            oProjectFavorite.data.push(toggledProject);

            save({ key: EntityKey.ProjectFavorite, value: oProjectFavorite });

            if (getValue(toggledProject, 'inactiveInd')) {
              message.success('Project removed from favorites', 3);
            } else {
              message.success('Project added to favorites', 3);
            }
          }
        } else {
          message.error('Failed to change favorite status', 3);
        }
      }
    );
  };

  const icon = () => {
    if (isFavorite) {
      return <StarFilled style={{ fontSize: 20, marginTop: 5 }} title='Favorite' />;
    }

    return <StarOutlined style={{ fontSize: 20, marginTop: 5 }} title='Favorite' />;
  };

  return (
    <button className='link-button' type='button' onClick={handleClick}>
      {icon()}
    </button>
  );
});
