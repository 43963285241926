import { useEffect } from 'react';
import { useSave } from '@rlean/core';
import { EntityKey } from 'lib/enums/EntityKey';

export default function useActivePage(page) {
  const save = useSave();

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      save({ key: EntityKey.ActivePage, value: page });
    }

    return () => {
      isSubscribed = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
