import React from 'react';
import { getValue } from '@rlean/utils';
import { echoDataType, sqTableModuleType } from 'lib/types';
import { SQTableDefault, SQTableImpersonate, SQTablePicture, SQTableUri } from 'components/shared/SQTable/modules';

const columnSorter = (id, typeId) => {
  switch (typeId) {
    case echoDataType.DATE:
    case echoDataType.DATETIME:
      return (a, b) => (a[id] ? new Date(a[id]) : new Date()) - (b[id] ? new Date(b[id]) : new Date());
    case echoDataType.INT:
    case echoDataType.NUMBERIC:
      return (a, b) => (a[id] ?? 0) - (b[id] ?? 0);
    case echoDataType.STRING:
      return (a, b) => (a[id] ?? '').localeCompare(b[id] ?? '');
    case echoDataType.BOOLEAN:
      return (a, b) => (a[id] ?? true) - (b[id] ?? false);
    default:
      return false;
  }
};

const buildDataSource = row => {
  const dataSource = {};
  row.forEach(field => (dataSource[field.fieldId] = field.value));

  return dataSource;
};

/**
 * props: {
 *  fieldDefinitions,
 *  rows,
 *  modules: [
 *    {
 *      sqTableElementType,
 *      echoDataType,
 *      sqTableModuleType
 *    }
 *  ]
 * }
 */
class SQTableBuilder {
  constructor(props) {
    this.fieldDefinitions = getValue(props, 'fieldDefinitions', null);
    this.rows = getValue(props, 'rows', null);
    this.modules = getValue(props, 'modules', null);
    this.handlePictureModal = getValue(props, 'handlePictureModule', null);

    // Types
    // this.tableElementType = {
    //   PROJECTID: 1
    // };
    // this.tableModuleType = {
    //   IMPERSONATE: 'impersonate',
    //   PICTURE: 'picture',
    //   URI: 'uri'
    // };
  }

  get elementType() {
    return this.tableElementType;
  }

  get moduleType() {
    return this.tableModuleType;
  }

  /**
   * getColumns gets the formatted columns from the columnDefinition.
   */
  getColumns() {
    if (this.fieldDefinitions) {
      const visibleFieldDefinitions = this.fieldDefinitions.filter(fieldDefinition => fieldDefinition.isVisible);

      const formattedFieldDefinitions = visibleFieldDefinitions.map(fieldDefinition => {
        const {
          label,
          fieldId,
          dataTypeId,
          elementTypeId,
          isVisible,
          supportCalculation,
          supportAggregation
        } = fieldDefinition;

        const module = this.modules
          ? this.modules?.find(x => x.elementType === elementTypeId)
          : this.modules?.find(x => x.dataType === dataTypeId);

        let moduleToRender = null;

        if (module) {
          moduleToRender = module.moduleType;
        }

        return {
          // Properties for antd
          title: label,
          dataIndex: fieldId,
          key: fieldId,
          ellipsis: {
            showTitle: false
          },
          render: value => {
            switch (moduleToRender) {
              case sqTableModuleType.IMPERSONATE:
                return <SQTableImpersonate projectId={value} />;
              case sqTableModuleType.PICTURE:
                return <SQTablePicture value={value} handlePictureModal={this.handlePictureModal} />;
              case sqTableModuleType.URI:
                return <SQTableUri value={value} elementTypeId={elementTypeId} />;
              default:
                return <SQTableDefault value={value} dataTypeId={dataTypeId} />;
            }
          },
          sorter: columnSorter(fieldId, dataTypeId),

          // Property for Chart creation
          supportAggregation,

          // Properties for Excel Download Endpoint
          dataTypeId,
          elementTypeId,

          // Properties for SQTable controls
          sqVisible: isVisible,
          sqToggle: isVisible,
          sqSearch: dataTypeId === echoDataType.PICTURE ? false : true,
          sqCalc: supportCalculation
        };
      });

      return formattedFieldDefinitions;
    }

    return null;
  }

  /**
   * getDataSource gets the formatted data from rows.
   */
  getDataSource() {
    const dataSource = this.rows ? this.rows.map(row => buildDataSource(row)) : [];

    return dataSource;
  }
}

export default SQTableBuilder;
