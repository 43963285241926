import React, { memo, useState } from 'react';
import { RLeanState } from '@rlean/core';
import { getValue, hasValue } from '@rlean/utils';
import { SQReport } from 'components/shared';
import { determineCriteriaType, downloadFileType, downloadFile } from 'lib/helpers';
import { getUniquePartNumbers, getUniqueCriteriaNames, reportNames, formats } from 'components/shared/SQReport';
import moment from 'moment';
import Highcharts from 'highcharts';
import { strings } from 'config';
import { useMatch } from '@reach/router';
import { useCurrentProject } from 'lib/hooks';
import { useFilters } from 'lib/hooks';

const filterProperties = [
  'shift',
  'startDateTime',
  'endDateTime',
  'partIdListCommaSeparated',
  'criteriaIdListCommaSeparated'
];

export const DefectHistory = memo(() => {
  const match = useMatch('/quickpreview/:reportName');
  const [projectParts] = RLeanState().select(({ state }) => [state.projectParts]);
  const [chartData, setChartData] = useState({ reportData: null, categories: null, series: null });

  const parts = getValue(projectParts, 'data.parts', []);
  const criteria = getValue(projectParts, 'data.criteria', []);
  const defaultParts = getUniquePartNumbers(parts);
  const defaultCriteria = getUniqueCriteriaNames(parts, criteria);
  const currentProject = useCurrentProject();

  let defaultStartDate = new Date();
  let defaultEndDate = new Date();

  if (hasValue(currentProject, 'lastSortDate')) {
    defaultEndDate = new Date(currentProject.lastSortDate);
    defaultEndDate.setDate(defaultEndDate.getDate());

    if (match && match.reportName === 'defectHistory' && hasValue(currentProject, 'startDate')) {
      defaultStartDate = new Date(currentProject.startDate);
    } else {
      defaultStartDate = new Date(currentProject.lastSortDate);
    }
    defaultStartDate.setDate(defaultStartDate.getDate() - 30);
  }

  const { convertedFilters: filters, loadingFilters, updateFiltersWithFormData: updateFilters } = useFilters(
    {
      startDateTime: moment(defaultStartDate).startOf('day'),
      endDateTime: moment(defaultEndDate).endOf('day'),
      partIdListCommaSeparated: defaultParts,
      criteriaIdListCommaSeparated: defaultCriteria
    },
    filterProperties
  );

  const columns = [
    {
      title: strings.reportTableColumnCriteria,
      dataIndex: 'criteriaName',
      key: 'criteriaName',
      sorter: (a, b) => {
        if (a.criteriaName) {
          return b.criteriaName ? a.criteriaName.localeCompare(b.criteriaName) : -1;
        }
        if (b.criteriaName) {
          return a.criteriaName ? b.criteriaName.localeCompare(a.criteriaName) : 1;
        }
        return 0 - 0;
      },
      defaultSortOrder: 'descend',
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnCriteriaType,
      dataIndex: 'criteriaType',
      key: 'criteriaType',
      render: criteriaType => <>{determineCriteriaType(criteriaType)}</>,
      sorter: (a, b) => {
        if (a.criteriaType) {
          return b.criteriaType ? a.criteriaType.localeCompare(b.criteriaType) : -1;
        }
        if (b.criteriaType) {
          return a.criteriaType ? b.criteriaType.localeCompare(a.criteriaType) : 1;
        }
        return 0 - 0;
      },
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnDefectCount,
      dataIndex: 'defects',
      key: 'defects',
      sorter: (a, b) => a.defects - b.defects,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnLastDefectDate,
      dataIndex: 'lastDefectDate',
      key: 'lastDefectDate',
      render: date => <>{moment(date).format('MM/DD/YYYY')}</>,
      sorter: (a, b) => new Date(a.lastDefectDate) - new Date(b.lastDefectDate),
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnDaysSinceLastDefect,
      dataIndex: 'daysSinceDefect',
      key: 'daysSinceDefect',
      sorter: (a, b) => a.daysSinceDefect - b.daysSinceDefect,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnPPM,
      dataIndex: 'defectPpm',
      key: 'defectPpm',
      sorter: (a, b) => a.defectPpm - b.defectPpm,
      render: ppm => <>{ppm.toFixed(2)}</>,
      sqVisible: true,
      sqToggle: true
    },
    {
      title: strings.reportTableColumnFallout,
      dataIndex: 'defectFallout',
      key: 'defectFallout',
      sorter: (a, b) => a.defectFallout - b.defectFallout,
      render: defectFallout => <>{(defectFallout * 100).toFixed(2)}</>,
      sqVisible: true,
      sqToggle: true
    }
  ];

  const configuration = {
    chart: { type: 'bar' },
    title: { text: '' },
    lang: { noData: 'No data to display' },
    noData: { position: { x: 0, y: 0, align: 'center', verticalAlign: 'middle' } },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF',
      shadow: true
    },
    tooltip: { valueDecimals: 2 },
    credits: { enabled: false },
    subtitle: { text: '' },
    xAxis: { categories: chartData.categories },
    yAxis: { title: { text: '' } },
    plotOptions: { line: { dataLabels: { enabled: true }, enableMouseTracking: false } },
    series: chartData.series
  };

  const buildChart = responseData => {
    if (responseData != null) {
      const reportData = [];
      const categories = [];
      const seriesData = [];

      for (let i = 0; i < responseData.length; i++) {
        const data = responseData[i];

        categories.push(data.criteriaName);
        seriesData.push(data.daysSinceDefect);

        data['i'] = i;
        reportData.push(data);
      }

      const series = [{ name: 'Days Since Defect', data: seriesData }];

      setChartData({
        reportData,
        categories,
        series
      });
    }
  };

  const downloadReport = reportParams => {
    const {
      projectId,
      businessContactId,
      shift,
      startDateTime,
      partIdListCommaSeparated,
      criteriaIdListCommaSeparated
    } = reportParams;
    const endDateTime = reportParams.endDateTime.endOf('day');

    const params = {
      Shift: shift,
      start: startDateTime.toISOString(true),
      end: endDateTime.toISOString(true),
      partList: partIdListCommaSeparated.toString(),
      criteriaList: criteriaIdListCommaSeparated.toString(),
      BusinessContactId: businessContactId,
      ProjectId: projectId,
      format: formats.EXCELOPENXML,
      reportName: reportNames.DEFECT_HISTORY
    };

    const options = {
      filename: `Defect-History-${projectId}.xlsx`,
      endpoint: '/SsrsReportFiles',
      fileType: downloadFileType.EXCELOPENXML
    };

    downloadFile(options, params);
  };

  const reportOptions = {
    reportTitle: strings.reportDefectHistoryTitle,
    urls: ['/ProjectDefectHistory'],
    filterProperties,
    filters,
    updateFilters,
    defaultParts,
    defaultCriteria,
    buildChart,
    downloadReport
  };

  const chartOptions = {
    chartReady: !!chartData.reportData,
    configuration
  };

  const tableOptions = {
    titleBarData: {
      tableTitle: strings.reportDefectHistoryTableTitle
    },
    dataSource: chartData.reportData,
    summary: false,
    columns
  };

  if (loadingFilters) {
    return <></>;
  }

  return <SQReport reportOptions={reportOptions} chartOptions={chartOptions} tableOptions={tableOptions} />;
});
