import React, { useState } from 'react';
import { ExpandOutlined } from '@ant-design/icons';
import { RLeanState } from '@rlean/core';
import { hasValue } from '@rlean/utils';
import { Typography, Spin, Button } from 'antd';
import { determineCriteriaType, formatDate } from 'lib/helpers';
import { statusType } from 'lib/types';
import { FullWidthDrawer } from 'components/shared';
import { ProjectSummaryCard } from './ProjectSummaryCard';
import { navigate } from '@reach/router';

const { Title } = Typography;

export const tableColumnDefaultConfig = {
  id: { visible: true, canToggle: false },
  projectId: { visible: true, canToggle: false },
  customerReference: { visible: true, canToggle: true },
  projectStatus: { visible: true, canToggle: true },
  authorization: { visible: false, canToggle: true },
  businessUnitName: { visible: false, canToggle: true },
  estimatedCompletionDate: { visible: false, canToggle: true },
  closedDate: { visible: false, canToggle: true },
  workSiteCompanyId: { visible: false, canToggle: true },
  customerCompanyId: { visible: false, canToggle: true },
  workSiteCity: { visible: false, canToggle: true },
  partNumber: { visible: true, canToggle: true },
  partDescription: { visible: false, canToggle: true },
  criteriaName: { visible: true, canToggle: true },
  criteriaType: { visible: false, canToggle: true },
  totalGroup: { visible: true, canToggle: true },
  latestDayGroup: { visible: true, canToggle: true },
  lastDefectDate: { visible: true, canToggle: true },
  startDate: { visible: true, canToggle: true },
  workSiteCompanyName: { visible: true, canToggle: true },
  customerSupplierName: { visible: true, canToggle: true },
  serviceTypeName: { visible: false, canToggle: true },
  cleanPointDetail: { visible: true, canToggle: true }
};

const Summary = ({ id }) => {
  const [project, projectSummary, historicProject] = RLeanState().select(({ state }) => [
    state.project,
    state.projectSummary,
    state.historicProject
  ]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentProject, setCurrentProject] = useState({});

  const handleDrawerClose = () => {
    setDrawerVisible(false);
  };

  const showSummary = () => {
    if (hasValue(project, 'data') && hasValue(projectSummary, 'data')) {
      const selectedProject = project.data.find(x => x.id === id);

      if (selectedProject) {
        setCurrentProject(selectedProject);
        setDrawerVisible(true);
      }

      if (hasValue(historicProject, 'data')) {
        const selectedHistoricProject = historicProject.data.find(x => x.id === id);

        if (selectedHistoricProject) {
          setCurrentProject(selectedHistoricProject);
          setDrawerVisible(true);
        }
      }
    }
  };

  const Icon = () => {
    if (!projectSummary || (hasValue(projectSummary, 'isLoading') && projectSummary.isLoading)) {
      return <Spin size='small' className='action' />;
    }

    return (
      <button className='link-button' type='button'>
        <ExpandOutlined onClick={() => showSummary(id)} title='Summary View' />
      </button>
    );
  };

  const DrawerContents = () => (
    <>
      <Title level={4}>Project Summary</Title>
      <ProjectSummaryCard currentProject={currentProject} />
    </>
  );

  return (
    <>
      <Icon style={{ minWidth: '24px' }} />
      <FullWidthDrawer
        visible={drawerVisible}
        onClose={handleDrawerClose}
        placement='right'
        innerComponent={<DrawerContents />}
      />
    </>
  );
};

export const isHignNonConforming = (fallout, percentage) => {
  return fallout && fallout >= percentage;
};

export const getAllColumns = (
  setActiveProject,
  goToProjectDetails,
  getColumnSearchProps,
  statusAvailable = [],
  businessUnitsAvailable = [],
  percentage
) => {
  return [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      className: 'table-actions',
      render: id => (
        <div>
          <Summary id={id} />
        </div>
      )
    },
    {
      title: 'Project',
      dataIndex: 'projectId',
      key: 'projectId',
      sorter: (a, b) => a.projectId - b.projectId,
      defaultSortOrder: 'descend',
      className: 'groupless-column',
      render: projectId => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <button className='link-button link-small' type='button' onClick={() => goToProjectDetails(projectId)}>
            {projectId}
          </button>
        </div>
      ),
      dataTypeId: 3,
      ...getColumnSearchProps('projectId')
    },
    {
      title: 'Ref Num',
      dataIndex: 'customerReference',
      key: 'customerReference',
      className: 'groupless-column',
      sorter: (a, b) => {
        if (a.customerReference) {
          return b.customerReference ? a.customerReference.localeCompare(b.customerReference) : -1;
        }
        if (b.customerReference) {
          return a.customerReference ? b.customerReference.localeCompare(a.customerReference) : 1;
        }
        return 0 - 0;
      },
      render: customerReference => <>{customerReference && customerReference.trim() ? customerReference : '--'}</>,
      dataTypeId: 1,
      ...getColumnSearchProps('customerReference')
    },
    {
      title: 'Status',
      dataIndex: 'projectStatus',
      key: 'projectStatus',
      className: 'projectStatus groupless-column',
      sorter: (a, b) => {
        if (a.projectStatus) {
          return b.projectStatus ? a.projectStatus.localeCompare(b.projectStatus) : -1;
        }
        if (b.projectStatus) {
          return a.projectStatus ? b.projectStatus.localeCompare(a.projectStatus) : 1;
        }
        return 0 - 0;
      },
      render: status => <>{statusType[status] ?? status}</>,
      dataTypeId: 1,
      ...getColumnSearchProps('projectStatus')
    },
    {
      title: 'Authorization',
      dataIndex: 'authorization',
      key: 'authorization',
      className: 'authorization groupless-column',
      sorter: (a, b) => {
        if (a.authorization) {
          return b.authorization ? a.authorization.localeCompare(b.authorization) : -1;
        }
        if (b.authorization) {
          return a.authorization ? b.authorization.localeCompare(a.authorization) : 1;
        }
        return 0;
      },
      render: authorization => <>{authorization && authorization.trim() ? authorization : '--'}</>,
      dataTypeId: 1
    },
    {
      title: 'Business Unit',
      dataIndex: 'businessUnitName',
      key: 'businessUnitName',
      className: 'groupless-column',
      sorter: (a, b) => {
        if (a.businessUnitName) {
          return b.businessUnitName ? a.businessUnitName.localeCompare(b.businessUnitName) : -1;
        }
        if (b.businessUnitName) {
          return a.businessUnitName ? b.businessUnitName.localeCompare(a.businessUnitName) : 1;
        }
        return 0 - 0;
      },
      render: businessUnitName => <>{businessUnitName && businessUnitName.trim() ? businessUnitName : '--'}</>,
      dataTypeId: 1,
      ...getColumnSearchProps('businessUnitName')
    },
    {
      title: 'ECD',
      dataIndex: 'estimatedCompletionDate',
      key: 'estimatedCompletionDate',
      className: 'groupless-column',
      sorter: (a, b) => (a.closedDate && b.closedDate ? new Date(a.closedDate) - new Date(b.closedDate) : -1),
      render: date => <>{date ? formatDate(date) : '--'}</>,
      dataTypeId: 5
    },
    {
      title: 'Closed Date',
      dataIndex: 'closedDate',
      key: 'closedDate',
      className: 'closedDate groupless-column',
      sorter: (a, b) => (a.closedDate && b.closedDate ? new Date(a.closedDate) - new Date(b.closedDate) : -1),
      render: date => <>{date ? formatDate(date) : '--'}</>,
      dataTypeId: 5
    },
    {
      title: 'Worksite ID',
      dataIndex: 'workSiteCompanyId',
      key: 'workSiteCompanyId',
      className: 'groupless-column',
      sorter: (a, b) => a.workSiteCompanyId - b.workSiteCompanyId,
      dataTypeId: 3
    },
    {
      title: 'Customer Company ID',
      dataIndex: 'customerCompanyId',
      key: 'customerCompanyId',
      className: 'groupless-column',
      sorter: (a, b) => a.customerCompanyId - b.customerCompanyId,
      dataTypeId: 3
    },
    {
      title: 'Work Site City',
      dataIndex: 'workSiteCity',
      key: 'workSiteCity',
      className: 'groupless-column',
      sorter: (a, b) => {
        if (a.workSiteCity) {
          return b.workSiteCity ? a.workSiteCity.localeCompare(b.workSiteCity) : -1;
        }
        if (b.workSiteCity) {
          return a.workSiteCity ? b.workSiteCity.localeCompare(a.workSiteCity) : 1;
        }
        return 0 - 0;
      },
      render: workSiteCity => <>{workSiteCity && workSiteCity.trim() ? workSiteCity : '--'}</>,
      dataTypeId: 1
    },
    {
      title: 'Part Number',
      dataIndex: 'partNumber',
      key: 'partNumber',
      className: 'groupless-column',
      sorter: (a, b) => {
        if (a.partNumber) {
          return b.partNumber ? a.partNumber.localeCompare(b.partNumber) : -1;
        }
        if (b.partNumber) {
          return a.partNumber ? b.partNumber.localeCompare(a.partNumber) : 1;
        }
        return 0 - 0;
      },
      render: partNumber => <>{partNumber ?? '--'}</>,
      dataTypeId: 1,
      ...getColumnSearchProps('partNumber')
    },
    {
      title: 'Part Description',
      dataIndex: 'partDescription',
      key: 'partDescription',
      className: 'groupless-column',
      sorter: (a, b) => {
        if (a.partDescription) {
          return b.partDescription ? a.partDescription.localeCompare(b.partDescription) : -1;
        }
        if (b.partDescription) {
          return a.partDescription ? b.partDescription.localeCompare(a.partDescription) : 1;
        }
        return 0 - 0;
      },
      render: partDescription => <>{partDescription ?? '--'}</>,
      dataTypeId: 1
    },
    {
      title: 'Criteria',
      dataIndex: 'criteriaName',
      key: 'criteriaName',
      className: 'groupless-column',
      sorter: (a, b) => {
        if (a.criteriaName) {
          return b.criteriaName ? a.criteriaName.localeCompare(b.criteriaName) : -1;
        }
        if (b.criteriaName) {
          return a.criteriaName ? b.criteriaName.localeCompare(a.criteriaName) : 1;
        }
        return 0 - 0;
      },
      render: criteriaName => <>{criteriaName ?? '--'}</>,
      dataTypeId: 1,
      ...getColumnSearchProps('criteriaName')
    },
    {
      title: 'Criteria Type',
      dataIndex: 'criteriaType',
      key: 'criteriaType',
      className: 'groupless-column',
      sorter: (a, b) => {
        if (a.criteriaType) {
          return b.criteriaType ? a.criteriaType.localeCompare(b.criteriaType) : -1;
        }
        if (b.criteriaType) {
          return a.criteriaType ? b.criteriaType.localeCompare(a.criteriaType) : 1;
        }
        return 0 - 0;
      },
      render: criteriaType => <>{determineCriteriaType(criteriaType)}</>,
      dataTypeId: 1
    },
    {
      title: 'Total',
      dataIndex: 'totalGroup', // 'totalGroup' is for UI sorting only, it does not exist in the data
      key: 'totalGroup',
      className: 'table-group-yellow',
      children: [
        {
          title: 'Inspected',
          dataIndex: 'inspected',
          key: 'inspected',
          className: 'inspected table-group-yellow',
          sorter: (a, b) => a.inspected - b.inspected,
          render: inspected => <>{inspected ?? '--'}</>,
          dataTypeId: 3,
          ...getColumnSearchProps('inspected')
        },
        {
          title: 'Non-Conforming',
          dataIndex: 'defects',
          key: 'defects',
          className: 'defects table-group-yellow',
          sorter: (a, b) => a.defects - b.defects,
          render: (defects, record) => (
            <>
              {defects ? (
                <Button
                  type='link'
                  size='small'
                  onClick={() => {
                    setActiveProject(record.projectId);
                    navigate(`/quickpreview/defectHistory`);
                  }}
                  danger={isHignNonConforming(record.fallout * 100, percentage)}
                  style={{
                    fontWeight: isHignNonConforming(record.fallout * 100, percentage) ? 'bold' : undefined,
                    fontSize: '0.8125rem'
                  }}
                >
                  {defects}
                </Button>
              ) : (
                '--'
              )}
            </>
          ),
          dataTypeId: 3
        },
        {
          title: 'Reworked',
          dataIndex: 'reworked',
          key: 'reworked',
          className: 'reworked table-group-yellow',
          sorter: (a, b) => a.reworked - b.reworked,
          render: reworked => <>{reworked ?? '--'}</>,
          dataTypeId: 3
        },
        {
          title: 'Good',
          dataIndex: 'good',
          key: 'good',
          className: 'good table-group-yellow',
          sorter: (a, b) => a.good - b.good,
          render: good => <>{good ? good : '--'}</>,
          dataTypeId: 3
        },
        {
          title: 'Fallout',
          dataIndex: 'fallout',
          key: 'fallout',
          className: 'fallout table-group-yellow',
          sorter: (a, b) => a.fallout - b.fallout,
          render: fallout => <>{fallout ? (fallout * 100).toFixed(2) : '--'}</>,
          dataTypeId: 4
        }
      ]
    },
    {
      title: 'Latest Day',
      className: 'table-group-blue',
      dataIndex: 'latestDayGroup', // 'lastSortGroup' is for UI sorting only, it does not exist in the data
      key: 'latestDayGroup',
      children: [
        {
          title: 'Date',
          dataIndex: 'lastSortDate',
          key: 'lastSortDate',
          className: 'lastSortDate table-group-blue',
          sorter: (a, b) => new Date(a.lastSortDate) - new Date(b.lastSortDate),
          render: date => <>{date ? formatDate(date) : '--'}</>,
          dataTypeId: 5
        },
        {
          title: 'Inspected',
          dataIndex: 'lastSortDateInspectedTotal',
          key: 'lastSortDateInspectedTotal',
          className: 'lastSortDateInspectedTotal table-group-blue',
          sorter: (a, b) => a.lastSortDateInspectedTotal - b.lastSortDateInspectedTotal,
          render: inspected => <>{inspected ?? '--'}</>,
          dataTypeId: 3
        },
        {
          title: 'Non-Conforming',
          dataIndex: 'lastSortDateNonConformTotal',
          key: 'lastSortDateNonConformTotal',
          className: 'lastSortDateNonConformTotal table-group-blue',
          sorter: (a, b) => a.lastSortDateNonConformTotal - b.lastSortDateNonConformTotal,
          render: NonConformTotal => <>{NonConformTotal ?? '--'}</>,
          dataTypeId: 3
        },
        {
          title: 'Reworked',
          dataIndex: 'lastSortDateReworkTotal',
          key: 'lastSortDateReworkTotal',
          className: 'lastSortDateReworkTotal table-group-blue',
          sorter: (a, b) => a.lastSortDateReworkTotal - b.lastSortDateReworkTotal,
          render: ReworkTotal => <>{ReworkTotal ?? '--'}</>,
          dataTypeId: 3
        },
        {
          title: 'Good',
          dataIndex: 'lastSortDateGoodTotal',
          key: 'lastSortDateGoodTotal',
          className: 'lastSortDateGoodTotal table-group-blue',
          sorter: (a, b) => a.lastSortDateGoodTotal - b.lastSortDateGoodTotal,
          render: GoodTotal => <>{GoodTotal ?? '--'}</>,
          dataTypeId: 3
        }
      ]
    },
    {
      title: 'Last Defect Date',
      dataIndex: 'lastDefectDate',
      key: 'lastDefectDate',
      className: 'groupless-column',
      sorter: (a, b) => new Date(a.lastDefectDate) - new Date(b.lastDefectDate),
      render: date => <>{date ? formatDate(date) : '--'}</>,
      dataTypeId: 5
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      className: 'groupless-column',
      sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
      render: date => <>{date ? formatDate(date) : '--'}</>,
      dataTypeId: 5
    },
    {
      title: 'Work Site',
      dataIndex: 'workSiteCompanyName',
      key: 'workSiteCompanyName',
      className: 'groupless-column',
      sorter: (a, b) => {
        if (a.workSiteCompanyName) {
          return b.workSiteCompanyName ? a.workSiteCompanyName.localeCompare(b.workSiteCompanyName) : -1;
        }
        if (b.workSiteCompanyName) {
          return a.workSiteCompanyName ? b.workSiteCompanyName.localeCompare(a.workSiteCompanyName) : 1;
        }
        return 0 - 0;
      },
      dataTypeId: 1,
      ...getColumnSearchProps('workSiteCompanyName')
    },
    {
      title: 'Customer/Supplier',
      dataIndex: 'customerSupplierName',
      key: 'customerSupplierName',
      className: 'groupless-column',
      sorter: (a, b) => {
        if (a.customerSupplierName) {
          return b.customerSupplierName ? a.customerSupplierName.localeCompare(b.customerSupplierName) : -1;
        }
        if (b.customerSupplierName) {
          return a.customerSupplierName ? b.customerSupplierName.localeCompare(a.customerSupplierName) : 1;
        }
        return 0 - 0;
      },
      dataTypeId: 1,
      ...getColumnSearchProps('customerSupplierName')
    },
    {
      title: 'Service Type',
      dataIndex: 'serviceTypeName',
      key: 'serviceTypeName',
      className: 'groupless-column',
      sorter: (a, b) => {
        if (a.serviceTypeName) {
          return b.serviceTypeName ? a.serviceTypeName.localeCompare(b.serviceTypeName) : -1;
        }
        if (b.serviceTypeName) {
          return a.serviceTypeName ? b.serviceTypeName.localeCompare(a.serviceTypeName) : 1;
        }
        return 0;
      },
      render: serviceTypeName => <>{serviceTypeName ?? '--'}</>,
      dataTypeId: 1
    },
    {
      title: 'Clean Point Detail',
      dataIndex: 'cleanPointDetail',
      key: 'cleanPointDetail',
      className: 'groupless-column',
      ellipsis: true,
      sorter: (a, b) => {
        if (a.cleanPointDetail) {
          return b.cleanPointDetail ? a.cleanPointDetail.localeCompare(b.cleanPointDetail) : -1;
        }
        if (b.cleanPointDetail) {
          return a.cleanPointDetail ? b.cleanPointDetail.localeCompare(a.cleanPointDetail) : 1;
        }
        return 0 - 0;
      },
      render: cleanPoint => <>{cleanPoint ?? '--'}</>,
      dataTypeId: 1
    }
  ];
};

export const getProjectTableColumns = (
  userColumns,
  setActiveProject,
  goToProjectDetails,
  getColumnSearchProps,
  statusAvailable,
  businessUnitsAvailable,
  percentage
) => {
  const allColumns = getAllColumns(
    setActiveProject,
    goToProjectDetails,
    getColumnSearchProps,
    statusAvailable,
    businessUnitsAvailable,
    percentage
  );
  const fields = Object.keys(userColumns);

  const selectedColumns = fields
    .filter(field => userColumns[field].visible)
    .map(field => {
      return allColumns.find(column => column.dataIndex === field);
    });

  return selectedColumns;
};
