import React, { useContext, useEffect, useState } from 'react';
import { uuid } from '@rlean/utils';
import { Tag, Tooltip } from 'antd';
import { FilterContext } from './FiltersContext';
import moment from 'moment';
import { formatDate } from 'lib/helpers';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { shiftValues } from 'lib/types';

export const FilterIndicators = ({
  filterProperties = [],
  forceShowTags = false,
  closableFilters = false,
  onCloseFilter
}) => {
  const { filters } = useContext(FilterContext);
  const [tags, setTags] = useState([]);

  const createSingleTag = (key, text) => {
    return (
      <Tag key={uuid()} closable={closableFilters} onClose={() => onCloseFilter(key)}>
        {text}
      </Tag>
    );
  };

  const createTags = () => {
    const tagsList = [];
    const objectKeys = Object.keys(filters);

    for (let i = 0; i < objectKeys.length; i += 1) {
      const key = objectKeys[i];
      if (filterProperties.includes(key)) {
        if (filters[key].isTag && (filters[key].showTag || forceShowTags)) {
          if (key === 'selectedWorkSite' || key === 'invoiceMetric') {
            tagsList.push(createSingleTag(key, filters[key].label));
          } else if ('partIdListCommaSeparated' === key) {
            const currentPartsCount = filters[key].value.length;
            const originalPartsCount = filters[key].originalLength;
            let value = `Selected ${currentPartsCount}/${originalPartsCount} Parts`;
            if (currentPartsCount === originalPartsCount) {
              value = `Selected All Parts`;
            }
            tagsList.push(createSingleTag(key, value));
          } else if ('criteriaIdListCommaSeparated' === key) {
            const currentPartsCount = filters[key].value.length;
            const originalPartsCount = filters[key].originalLength;
            let value = `Selected ${currentPartsCount}/${originalPartsCount} Criterias`;
            if (currentPartsCount === originalPartsCount) {
              value = `Selected All Criterias`;
            }
            tagsList.push(createSingleTag(key, value));
          } else if ('shift' === key) {
            tagsList.push(createSingleTag(key, shiftValues[filters[key].value]));
          } else if ('mostRecentFlag' === key) {
            tagsList.push(createSingleTag(key, `${filters[key].label}: ${filters[key].value ? 'Yes' : 'No'}`));
          } else if (moment.isMoment(filters[key].value) || moment.isDate(filters[key].value)) {
            tagsList.push(createSingleTag(key, `${filters[key].label} ${formatDate(filters[key].value)}`));
          } else if ('hiddenEmptyRowsOnColumn' === key) {
            Object.keys(filters[key].value).forEach(column => {
              tagsList.push(createSingleTag(key, filters[key].label));
            });
          } else {
            const labelValue = filters[key].useAltValueForLabel ? filters[key].altValue : filters[key].value;
            tagsList.push(
              createSingleTag(
                key,
                filters[key].appendValueToLabel ? `${filters[key].label} ${labelValue}` : filters[key].label
              )
            );
          }
        }
      }
    }

    return tagsList;
  };

  useEffect(() => {
    setTags(createTags());
  }, [filters]);

  const showTags = () => {
    if (forceShowTags) {
      return true;
    } else {
      let showTags = false;

      const keys = Object.keys(filters);

      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (filterProperties.includes(key)) {
          const filter = filters[keys[i]];

          if (filter.showTag) {
            showTags = true;
            break;
          }
        }
      }

      return showTags;
    }
  };

  if (showTags()) {
    return (
      <div className='table-tags'>
        <span>Filters applied:</span>
        <>{tags}</>
        <Tooltip title='Change filters in top right Filters or Table settings'>
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    );
  }

  return <></>;
};
