import React, { memo, useState, useEffect, useContext } from 'react';
import { navigate } from '@reach/router';
import { RLeanState, useSave, usePost } from '@rlean/core';
import { hasValue, deepCopy, getValue, uuid } from '@rlean/utils';
import { InputNumber, Button, message } from 'antd';
import { BaseCard, LOADING_STATUS } from 'components/shared';
import { projectMetricType } from 'lib/types';
import { FilterContext } from 'components/shared/FiltersContext';

// Enums
import { EntityKey } from 'lib/enums/EntityKey';

// Hooks
import { useProject } from 'lib/hooks/useProject';

export const HighNonconforming = memo(() => {
  const [project, workSite, businessContact, userConfiguration] = RLeanState().select(({ state }) => [
    state.project,
    state.workSite,
    state.businessContact,
    state.userConfiguration
  ]);
  const [loadingStatus, setLoadingStatus] = useState();
  const save = useSave();
  const post = usePost();
  const [fallout, setFallout] = useState(null);
  const businessContactId = getValue(businessContact, 'data.id', null);
  const { filters, setFilters } = useContext(FilterContext);

  const { getHighNonConformingProjects } = useProject();

  // Store a key of the card, which is updated at the cancel button of the popover/gear
  // forcing the re-render of the BaseCard component and reseting the popover/gear visibility
  const [cardKey, setCardKey] = useState(0);

  useEffect(() => {
    let isSubscribed = true;

    if (hasValue(userConfiguration, 'data')) {
      const savedFallout = userConfiguration.data.find(x => x.name === 'fallout');

      if (savedFallout && isSubscribed) {
        setFallout(JSON.parse(savedFallout.configurationSettings).percentage);
      }
    }

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveFallout = () => {
    if (hasValue(userConfiguration, 'data')) {
      const updatedUserConfiguration = deepCopy(userConfiguration);
      const userConfigArray = updatedUserConfiguration.data.filter(config => config.name !== 'fallout');
      const updatedFalloutConfig = updatedUserConfiguration.data.find(config => config.name === 'fallout') ?? {
        businessContactId,
        name: 'fallout',
        inactiveInd: false
      };
      const configurationSettings = updatedFalloutConfig.configurationSettings
        ? JSON.parse(updatedFalloutConfig.configurationSettings)
        : { percentage: null };

      configurationSettings.percentage = fallout;
      updatedFalloutConfig.configurationSettings = JSON.stringify(configurationSettings);

      userConfigArray.push(updatedFalloutConfig);

      updatedUserConfiguration.data = userConfigArray;

      save({ key: EntityKey.UserConfiguration, value: updatedUserConfiguration });

      if (!businessContact.data.isReadOnly) {
        const body = {
          businessContactId,
          name: 'fallout',
          configurationSettings: updatedFalloutConfig.configurationSettings,
          inactiveInd: false
        };

        message.loading('Saving...', 0);
        post(
          {
            key: EntityKey.UserConfiguration,
            body
          },
          (response, error) => {
            message.destroy();

            if (error) {
              message.error('Saving failed', 3);
            } else {
              message.success('Saving successful', 3);
            }
          }
        );
      }
    }
  };

  const cancelFallout = () => {
    if (hasValue(userConfiguration, 'data')) {
      const savedFallout = userConfiguration.data.find(x => x.name === 'fallout');

      if (savedFallout) {
        setFallout(JSON.parse(savedFallout.configurationSettings).percentage);
      } else {
        setFallout(null);
      }
    }
    setCardKey(uuid());
  };

  const handleChange = value => {
    setFallout(value);
  };

  const styles = {
    popover: {
      display: 'block',
      marginTop: '0',
      marginBottom: '1em',
      marginLeft: '0',
      marginRight: '0'
    }
  };

  const popOverContent = (
    <>
      <div style={styles.popover}>
        <InputNumber min={0} max={100} value={fallout} onChange={handleChange} onPressEnter={saveFallout} /> %
      </div>
      <div style={styles.popover}>
        <Button onClick={saveFallout}>Save</Button> <Button onClick={cancelFallout}>Cancel</Button>
      </div>
    </>
  );

  const gearData = {
    popOverTitle: 'Fallout Percentage',
    popOverContent
  };

  // Set percentage from user configuration.
  const falloutConfiguration = hasValue(userConfiguration, 'data')
    ? userConfiguration.data.find(x => x.name === 'fallout')
    : null;

  const percentage = falloutConfiguration ? JSON.parse(falloutConfiguration.configurationSettings).percentage : null;

  const highNonConforming = getHighNonConformingProjects(
    filters.selectedWorkSite ? filters.selectedWorkSite.value : 0,
    workSite ? workSite.data : [],
    project ? project.data : [],
    businessContact,
    percentage
  );

  const linkClass = `card-total ${
    highNonConforming && highNonConforming.length > 0 ? 'card-total-red' : 'card-total-green'
  }`;

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      if (
        !project ||
        (project && project.isLoading) ||
        (project && project.lastUpdated && typeof project.isLoading === 'undefined') ||
        !highNonConforming
      ) {
        setLoadingStatus(LOADING_STATUS.WAITING);
      } else if (hasValue(project, 'isLoading') && !project.isLoading && highNonConforming) {
        setLoadingStatus(LOADING_STATUS.LOADED);
      } else {
        setLoadingStatus(LOADING_STATUS.NODATA);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [project, workSite, highNonConforming]);

  const setMetric = () => {
    const updatedFilters = deepCopy(filters);

    updatedFilters['projectMetric'].value = projectMetricType.HIGH_NONCONFORMING;
    updatedFilters['projectMetric'].showTag = true;

    setFilters(updatedFilters);
    navigate('/projects');
  };

  const cardData = {
    title: 'High Nonconforming',
    loadingStatus,
    description: percentage === null ? 'Set a fallout percentage to view project totals above that threshold.' : ''
  };

  const emptyCardData = {
    description: '',
    Content: null
  };

  return (
    <BaseCard key={cardKey} cardData={cardData} emptyCardData={emptyCardData} gearData={gearData}>
      {percentage !== null && (
        <button type='button' className='link-button general-card-total' onClick={setMetric}>
          <span className={linkClass}>{highNonConforming ? highNonConforming.length : '--'}</span>
        </button>
      )}
    </BaseCard>
  );
});
