import React, { memo } from 'react';
import { RLeanState } from '@rlean/core';
import { useParams, navigate } from '@reach/router';
import { reports } from 'config';
import { FullWidthDrawer } from '.';

const ProjectDetailsViewer = () => {
  const [activeProject] = RLeanState().select(({ state }) => [state.activeProject]);
  const { reportKey } = useParams();

  if (!activeProject || activeProject.id === null || activeProject.id === undefined) {
    navigate('/projects');
  }

  // Search for component
  const cardComponent = reports[reportKey].component;

  const goBack = () => {
    navigate(-1);
  };

  // @todo might need another component
  return <FullWidthDrawer visible={true} onClose={goBack} innerComponent={cardComponent} />;
};

export default memo(ProjectDetailsViewer);
