import React, { FC, memo, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Card } from 'antd';
import { useLocation } from '@reach/router';
import parse from 'html-react-parser';
import { strings } from 'config';

const LandingViewer: FC<RouteComponentProps> = () => {
  const location = useLocation();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    if (location.pathname === '/static/terms-of-use') {
      setTitle(strings.termsOfUse);
      setContent(strings.termsOfUseContent);
    }
    if (location.pathname === '/static/privacy-policy') {
      setTitle(strings.privacyPolicy);
      setContent(strings.privacyPolicyContent);
    }
  }, [location]);

  return (
    <Card title={title} className='page-card'>
      {parse(content)}
    </Card>
  );
};

export default memo(LandingViewer);
