import React, { memo } from 'react';
import { Card } from 'antd';
import { useActivePage } from 'lib/hooks';
import { pages, strings } from 'config';

export const UnexpectedError = memo(() => {
  useActivePage(pages.unexpectedError);

  const tryAgain = () => {
    window.location = '/';
  };

  return (
    <Card className='page-card' title={strings.unexpectedError}>
      An unexpected error has occurred. Please contact our help desk at{' '}
      <a href='mailto:itrequests@stratospherequality.com'>itrequests@stratospherequality.com</a> for assistance or{' '}
      <span className='link-button' onClick={() => tryAgain()}>
        try your request again
      </span>
      .
    </Card>
  );
});
