import React, { memo, useState, useEffect } from 'react';
import { Modal, message, Row, Col, Button } from 'antd';
import { RLeanState } from '@rlean/core';
import { getValue, uuid } from '@rlean/utils';
import { msalApiFetch, apiUri } from 'config';
import imageCompression from 'browser-image-compression';
import JSZip from 'jszip';
import { downloadFileHandleBlob } from 'lib/helpers';
import EchoImage from './Image';
import { Logger } from 'lib/models';

function getImages(formId) {
  return msalApiFetch(fetch, apiUri(`/FormImageData?formId=${formId}`), {
    method: 'GET'
  })
    .then(response => response.json())
    .then(data => {
      const imageSources = data.map((image, index) => ({
        src: image.imageBase64 ? `data:${image.mimeType};base64,${image.imageBase64}` : '',
        label: image.label,
        mimeType: image.mimeType,
        key: index + 1
      }));

      return imageSources;
    });
}

async function createZipFile(images, formId, activeProjectId) {
  const zip = new JSZip();

  const imageFiles = await Promise.all(
    images.map(image =>
      imageCompression.getFilefromDataUrl(image.src, `${image.key}-${image.label}.${image.mimeType.split('/')[1]}`)
    )
  );

  imageFiles.forEach(imageFile => {
    zip.file(imageFile.name, imageFile);
  });

  const filename = `sq project ${activeProjectId} form id ${formId} Images`;
  message.loading('Downloading...', 0);
  zip
    .generateAsync({ type: 'blob' })
    .then(blob => downloadFileHandleBlob(blob, filename))
    .catch(() => {
      message.destroy();
      message.error('Download Failed', 3);

      const logger = new Logger();
      logger.log({ level: logger.level.ERROR, message: 'Download Failed' });
    });
}

function ImageModal({ formId, visibility, handleClose }) {
  const [images, setImages] = useState([]);
  const [modalKey, setModalKey] = useState(uuid());
  const [activeImage, setActiveImage] = useState(null);
  const [activeProject] = RLeanState().select(({ state }) => [state.activeProject]);
  const activeProjectId = getValue(activeProject, 'id', []);

  useEffect(() => {
    let isMounted = true;
    const fetchImages = async () => {
      const fetchedImages = await getImages(formId);
      setImages(fetchedImages);
    };

    if (formId && isMounted) {
      fetchImages();
    }

    return () => {
      isMounted = false;
      setImages([]);
    };
  }, [formId]);

  const closeModal = () => {
    setModalKey(uuid());
    handleClose();
  };

  return (
    <Modal
      title={activeImage && activeImage.label ? activeImage.label : 'No Label'}
      key={modalKey}
      visible={visibility}
      onCancel={closeModal}
      footer={
        <Row align='middle'>
          <Col span={8}>
            {images.length > 1 && activeImage && (
              <p style={{ textAlign: 'left', margin: '0px' }}>
                Image {activeImage.key} of {images.length}
              </p>
            )}
          </Col>
          <Col span={16}>
            <Button onClick={closeModal}>Cancel</Button>
            <Button onClick={() => createZipFile(images, formId, activeProjectId)} type='primary'>
              Download All
            </Button>
          </Col>
        </Row>
      }
    >
      <EchoImage images={images} activeImage={activeImage} setActiveImage={setActiveImage} />
    </Modal>
  );
}

export default memo(ImageModal);
