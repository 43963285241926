import React, { memo } from 'react';
import { Drawer, Button } from 'antd';

const Header = memo(({ onClose }) => {
  return (
    <Button type='danger' htmlType='button' onClick={onClose}>
      Close
    </Button>
  );
});

const FullWidthDrawer = ({ visible, onClose, placement, innerComponent }) => {
  return (
    <>
      {innerComponent && (
        <Drawer
          className='full-width-drawer'
          visible={visible}
          onClose={onClose}
          closable={false}
          placement={placement}
          width='100vw'
          title={<Header onClose={onClose} />}
        >
          {innerComponent}
        </Drawer>
      )}
    </>
  );
};

export default memo(FullWidthDrawer);
